import React from "react";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import classNames from "classnames";
import { renderItemQty } from "HelperFunctions/rentals";
import { Items } from "Utils/SvgIcons";
import Imgix from "react-imgix";
import CurrencyLabel from "Utils/CurrencyLabel";

class RentalBundleItem extends React.Component {
  renderInventoryPrice = () => {
    const { item, bundle, bundlePeriod, hideSubtotalPricing } = this.props;

    if (hideSubtotalPricing) {
      return null;
    }
    const inventoryType = item.productType;

    let price;
    if (inventoryType === "items") {
      if (bundlePeriod !== "editedFlatUnitPrice") {
        price = item.product[bundlePeriod];
        if (!price) {
          price = item.selectedPrice / item.quantity;
        }
      } else {
        price = item.selectedPrice;
      }
    } else if (inventoryType === "addons") {
      price = item.selectedPrice;
    } else {
      price = 0;
    }

    if (Number(price) === 0) {
      return "N/A";
    } else {
      return (
        <CurrencyLabel
          value={price * (1 - Number(bundle.discountPercent))}
        />
      );
    }
  };

  render() {
    const { item, bundle, tileView, showUnitPricingToCustomers, hideSubtotalPricing, showItemNotes } = this.props;
    const { name, hasShortage, imgixUrl } = item;
    const itemNotesVisibleToCustomer = item.itemNotes.filter(itemNote => itemNote.shouldIncludeNotesInRentals); 

    return (
      <MediaBreakpoints
        desktop={
          <tbody className="itemContent">
            <tr>
              {tileView && (
                <td className="image">
                  <figure>
                    {imgixUrl ? (
                      <Imgix
                        src={imgixUrl}
                        alt={name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <Items />
                    )}
                  </figure>
                </td>
              )}
              <td className="normalText">
                {name}
                {showItemNotes && itemNotesVisibleToCustomer?.map(
                  itemNote => <span 
                    className="caption"
                    style={{ display: "block" }}>
                      {itemNote.message}
                  </span>)}
              </td>
              <td
                className={classNames({
                  sm: true,
                  right: true,
                  alert: hasShortage
                })}
              >
                {renderItemQty(item)}
              </td>
              {showUnitPricingToCustomers && (
                <td className="medSm normalText">
                  {!bundle.priceLocked && this.renderInventoryPrice()}
                </td>
              )}
              <td className="medSm">
                {hideSubtotalPricing ? null : item.selectedPrice === 0 ? (
                  "N/A"
                ) : (
                  <CurrencyLabel value={item.selectedPrice || 0} />
                )}
              </td>
            </tr>
          </tbody>
        }
        mobile={
          <tbody className="mobileItemContent">
            {tileView && (
              <tr>
                <td colSpan="3">
                  <figure>
                    {imgixUrl ? (
                      <Imgix
                        src={imgixUrl}
                        alt={name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <Items />
                    )}
                  </figure>
                </td>
              </tr>
            )}
            <tr className="noBorder">
              <td colSpan="3">{name}</td>
            </tr>
            <tr className="noBorder">
              <td
                className={classNames({
                  alert: hasShortage
                })}
              >
                {renderItemQty(item)}
              </td>
            </tr>
            {showItemNotes && itemNotesVisibleToCustomer?.map(
              itemNote => <tr className="top noBorder">
                <td
                  colSpan="3"
                  className="caption">
                  {itemNote.message}
                </td>
              </tr>)}
          </tbody>
        }
      />
    );
  }
}

export default RentalBundleItem;
