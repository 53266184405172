import {
  RELOAD_INVENTORY_REQUEST,
  RELOAD_INVENTORY_SUCCESS,
  SAVE_INVENTORY_FILTER,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  SHOW_TILES,
  HIDE_TILES
} from "Constants/redux";
import { apiResponseTransform } from "Constants/displayConstants";
const INITIAL_STATE = {
  inventory: [],
  pagination: {},
  loading: false,
  loaded: false,
  pagesRemaining: true,
  page: null,
  numberPer: 10,
  tileView: false,
  inventoryFilter: {},
  product: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    sfSubCategories: [],
    inventoryCategorizations: [],
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    subCategories: [],
    businessInfo: {},
    location: null,
  },
  bundle: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    subCategories: [],
    businessInfo: {},
    sfSubCategories: [],
    inventoryCategorizations: [],
    productBundleRelationships: [],
    accessoryBundleRelationships: [],
    addOnBundleRelationships: []
  },
  add_on: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    sfSubCategories: [],
    inventoryCategorizations: [],
    subCategories: [],
    businessInfo: {}
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RELOAD_INVENTORY_REQUEST:
    case FETCH_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case SAVE_INVENTORY_FILTER:
      return { ...state, inventoryFilter: action.inventoryFilter };
    case RELOAD_INVENTORY_SUCCESS:
      const newState = Object.assign(
        {
          inventory: action.products,
          inventoryType: action.inventoryType,
          loading: false,
          loaded: true,
          page: 1
        },
        action.meta && {
          pagination: action.meta.pagination
        }
      );
      return {
        ...state,
        ...newState
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        [action.productType]:
          action.response.data[apiResponseTransform[action.productType]],
        loading: false,
        loaded: true
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        errorCode: action.errorCode
      };
    case SHOW_TILES:
      return {
        ...state,
        tileView: true
      };
    case HIDE_TILES:
      return {
        ...state,
        tileView: false
      };
    default:
      return state;
  }
};
