const convertDeliveryTime = (value, prop, deliverySetting) => {
  if (value.includes('delivery')) {
    if (value.includes('morning')) {
      value = deliverySetting.morningEndTime;
    } else if (value.includes('afternoon')) {
      value = deliverySetting.afternoonEndTime;
    } else if (value.includes('evening')) {
      value = deliverySetting.eveningEndTime;
    }
  } else if (value.includes('pickup')) {
    if (value.includes('morning')) {
      value = deliverySetting.morningStartTime;
    } else if (value.includes('afternoon')) {
      value = deliverySetting.afternoonStartTime;
    } else if (value.includes('evening')) {
      value = deliverySetting.eveningStartTime;
    }
  }
  const settingTime = new Date(value);
  value = new Date(prop);
  value.setHours(settingTime.getHours(), settingTime.getMinutes(), settingTime.getSeconds());

  return value;
}

export default {
  required: function (value, prop) {
    return prop ? value == null || value === "" : false;
  },

  minLength: function (value, prop) {
    return prop && value ? value.length < prop : false;
  },

  maxLength: function (value, prop) {
    return prop && value ? value.length > prop : false;
  },

  email: function (value, prop) {
    return prop && value
      ? !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        value
      )
      : false;
  },

  min: function (value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) < prop : false;
  },

  greaterThanOrEqual: function (value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) < prop : false;
  },
  greaterThanOrEqualToDate: function (value, prop, values) {
    if (typeof value == 'string' && (value.includes('delivery') || value.includes('pickup'))) {
      value = convertDeliveryTime(value, prop, values.deliverySetting);
    }

    return prop && value ? !(value instanceof Date) || value < prop : false;
  },

  lessThanOrEqual: function (value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) > prop : false;
  },
  lessThanOrEqualToDate: function (value, prop, values) {
    if (typeof value == 'string' && (value.includes('delivery') || value.includes('pickup'))) {
      value = convertDeliveryTime(value, prop, values.deliverySetting);
    }

    return prop && value ? !(value instanceof Date) || value > prop : false;
  },

  max: function (value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) > prop : false;
  },

  pattern: function (value, prop) {
    return !value ? false : !prop.test(value);
  },

  equalTo: function (value, prop) {
    return !value ? false : prop !== value;
  },

  oneOf: function (value, prop) {
    return !value ? false : prop.indexOf(value) === -1;
  },

  promise: function (value, prop, dispatch) {
    if (typeof prop === "function") {
      return prop(value, dispatch);
    }
    throw new Error("FormValidation: type promise must be a function!");
  },
  digits: function (value, prop) {
    return prop ? isNaN(Number(value)) : false;
  },
  creditcard: function (value, prop) {
    if (!value) {
      return true;
    }
    // accept only spaces, digits and dashes
    if (/[^0-9 -]+/.test(value)) {
      return false;
    }

    value = value.replace(/\D/g, "");

    // Basing min and max length on
    // http://developer.ean.com/general-info/valid-card-types/
    if (value.length < 13 || value.length > 19) {
      return true;
    }

    return false;
  },
  cvv: function (value, prop) {
    if (!value) {
      return true;
    }
    return value.length >= 3 && value.length <= 4 && Number(value)
      ? false
      : true;
  },
  routingNumber: function (value, prop) {
    if (!value) {
      return true;
    }
    if (value.length !== 9) {
      return true;
    }

    // http://en.wikipedia.org/wiki/value_transit_number#MICR_value_number_format
    var checksumTotal =
      7 *
      (parseInt(value.charAt(0), 10) +
        parseInt(value.charAt(3), 10) +
        parseInt(value.charAt(6), 10)) +
      3 *
      (parseInt(value.charAt(1), 10) +
        parseInt(value.charAt(4), 10) +
        parseInt(value.charAt(7), 10)) +
      9 *
      (parseInt(value.charAt(2), 10) +
        parseInt(value.charAt(5), 10) +
        parseInt(value.charAt(8), 10));

    var checksumMod = checksumTotal % 10;
    if (checksumMod !== 0) {
      return true;
    } else {
      return false;
    }
  },
  bankAccountNumber: function (value, prop) {
    if (!value) {
      return true;
    }
    return !/^[0-9]{7,14}$/.test(value);
  },
  matchField: function (value, prop, dispatch, allValues) {
    return !value ? false : value !== allValues[prop];
  },
  lengthEqualTo: function (value, prop) {
    if (!value) {
      return true;
    }
    return value.length === prop
      ? false
      : true;
  },
  phone: function (value, prop) {
    if (value) {
      if (value[0] === "1") {
        if (value[1] === "1") {
          if (value.length !== 13) return true
        } else if (value.length !== 14) return true
      } else if (value.length !== 12) return true
    }
    return false
  }
};
