import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from 'Utils/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const DeliveryMethods = ({ deliveryMethods, onChange, deliveryType }) => 
  <div className='fields'>
    <RadioGroup
      name='deliveryType'
      className='radioList'
      id='deliveryList'
      value={deliveryType}
      onChange={onChange}
    >
      {deliveryMethods.includes('ship') &&
        <FormControlLabel
          label='Ship'
          value='ship'
          control={<Radio />}
        />
      }
      {deliveryMethods.includes('default_delivery') &&
        <FormControlLabel
          label='Delivery'
          value='default_delivery'
          control={<Radio />}
        />
      }
      {deliveryMethods.includes('customer_pick_up') &&
        <FormControlLabel
          label='Pick Up'
          value='customer_pick_up'
          control={<Radio />}
        />
      }
    </RadioGroup>
  </div>

export default DeliveryMethods;
