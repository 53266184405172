import React from "react";
import moment from "moment";
import SubChangeRequestStatus from "./SubChangeRequestStatus";


const timeFormatter = str => moment(str).format("MMM DD, YYYY hh a");

function renderChange(data) {
  return (
    <div>
      {timeFormatter(data.eventStartDateTime)} -{" "}
      {timeFormatter(data.eventEndDateTime)}
    </div>
  );
}

export default function ScheduleChange(changeRequest) {
  const { subChangeRequests } = changeRequest;
  const subChangeRequest = subChangeRequests && subChangeRequests.filter( x => x.keyName === 'schedule_attributes' )[0];
  const changed = subChangeRequest !== undefined;

  if (!changed) {
    return null;
  }

  const { beforeAttributes, afterAttributes } = subChangeRequest;

  return (
    <div className="itemChanges">
      <h4>Order Times</h4>
      {renderChange(beforeAttributes)}
      {renderChange(afterAttributes)}
      {changeRequest.status !== 'pending' && <div>
        <SubChangeRequestStatus subChangeRequest={subChangeRequest} />
      </div>}
    </div>
  );
}
