import React from "react";
import { Link } from "react-router-dom";

const UnathorizedOrder = props => {
  return (
    <div className="notAuthorizedOrder">
      <div className="notAuthorizedOrderModal">
        <h3>Not Authorized to Access This Order</h3>
        <p>
          Oops, it looks like your account isn't authorized to access this
          order. To view this page and make payments, please log out and log
          back in as the account associated with the order.
        </p>
        <p>
          <Link to="/">Home</Link>
        </p>
      </div>
    </div>
  );
};

export default UnathorizedOrder;
