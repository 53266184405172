import React from "react";

const SuggestionTable = () => (
  <table className="grid">
    <thead>
      <tr>
        <th>Table</th>
        <th>Mid Length</th>
        <th>Floor Length</th>
        <th>Overlay</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>30" Round</td>
        <td>60" Round</td>
        <td>90" Round</td>
        <td>
          48" x 48"
          <br />
          60" Round
        </td>
      </tr>

      <tr>
        <td>30" Cocktail (42" height)</td>
        <td>
          60" Round
          <br />
          90" Round
        </td>
        <td>108" Round</td>
        <td>
          48" x 48" <br />
          72" x 72" <br />
          90" Round
        </td>
      </tr>

      <tr>
        <td>48" Round (seats 6)</td>
        <td>90" Round</td>
        <td>108" Round</td>
        <td>60" x 60" </td>
      </tr>

      <tr>
        <td>60" Round (setas 8)</td>
        <td>90" Round</td>
        <td>120" Round</td>
        <td>
          60" Round
          <br />
          72" x 72"
          <br />
          72" x 72"
        </td>
      </tr>

      <tr>
        <td>72" Round (seats 10)</td>
        <td>
          108" Round
          <br />
          72" x 72"
        </td>
        <td />
        <td>90" Round</td>
      </tr>

      <tr>
        <td>30" Square</td>
        <td>108" Round</td>
        <td>132" Round</td>
        <td>
          90" x 90"
          <br />
          108" Round
        </td>
      </tr>

      <tr>
        <td>60" Square (seats 8-12)</td>
        <td>
          60" x 60"
          <br />
          90" x 90"
        </td>
        <td>
          72" x 72" <br />
          special order
        </td>
        <td>
          48" x 48"
          <br />
          72" x 72"
        </td>
      </tr>

      <tr>
        <td>4' Banquet (seats 4)</td>
        <td>60" x 78"</td>
        <td>90" x 108"</td>
        <td>
          48" x 48" <br />
          Runner
        </td>
      </tr>

      <tr>
        <td>6' Banquet (seats 6)</td>
        <td>60" x 120"</td>
        <td>90" x 132"</td>
        <td>
          48" x 48"
          <br />
          60" x 60" <br />
          Runner
        </td>
      </tr>

      <tr>
        <td>8' Banquet (seats 8)</td>
        <td>60" x 120"</td>
        <td>90" x 156"</td>
        <td>
          48" x 48"
          <br />
          60" x 60"
          <br />
          60" x 120"
        </td>
      </tr>
    </tbody>
  </table>
);

export default SuggestionTable;
