import React from 'react';
import axios from 'axios';
import { NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import isSelected from 'HelperFunctions/ActiveStyling';
import TimeAgo from 'react-timeago';
import { getAuthToken } from 'Api';
import { connect } from 'react-redux';
import { ROOT_URL } from 'Api';
import * as actions from 'Actions';

const formatter = (value, unit, suffix, date) => {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date);
  var secondDate = new Date();
  if (date) {
    var diffDays = Math.round(Math.abs((date - secondDate.getTime()) / oneDay));

    if (diffDays > 7) {
      const date_formatter = Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
      });
      return date_formatter.format(firstDate);
    } else if (diffDays > 1) {
      const date_formatter = Intl.DateTimeFormat('en-US', {
        weekday: 'short',
      });
      return date_formatter.format(firstDate);
    } else {
      const date_formatter = Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      });
      return date_formatter.format(firstDate);
    }
  }
};

class ConversationCard extends React.Component {
  render() {
    const { conversation } = this.props;
    let first =
      conversation.messages.length > 0
        ? conversation.messages[conversation.messages.length - 1]
        : null;
    const link = `/conversations/${conversation.id}`;
    return (
      <li
        className={classnames({
          selected: isSelected(window.location, link, true),
          unread: conversation.unreadMessages > 0,
        })}
      >
        <NavLink to={link}>
          {conversation.rentalName && (
            <label className='highlight'>{conversation.rentalName}:</label>
          )}
          <label className='caption'>
            {conversation.locationName ||
              (conversation.supplierLocationInfo &&
                conversation.supplierLocationInfo.name)}
          </label>
          {first && <TimeAgo date={first.createdAt} formatter={formatter} />}
        </NavLink>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  const { unreadMessages } = state.conversationList;
  return {
    unreadMessages,
  };
};

export default withRouter(connect(mapStateToProps, actions)(ConversationCard));
