import React from "react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import * as actions from "Actions";

// It's now using react-notification-system(https://github.com/igorprado/react-notification-system).
// You can customize this more easily than Snackbar
class ValidationPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: []
    };
    this.parseErrors = this.parseErrors.bind(this);
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
    const errors = this.getErrors(this.props.errors);
    const open = errors.length > 0;

    this.setState({
      errors: errors,
      open: open
    });
    this._notificationSystem = this.refs.notificationSystem;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const errors = this.getErrors(nextProps.errors);
      const open = errors.length > 0;
      this.setState({
        errors: errors,
        open: open
      });
      if (open) {
        this._notificationSystem.clearNotifications();
        this._notificationSystem.addNotification({
          level: "error",
          position: "bc",
          title: "Oops! We noticed some errors:",
          message: (
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          ),
          autoDismiss: 10,
          onRemove: () => {
            this.onClose()
          }
        });
      }
    }
  }
  onClose() {
    this.setState({
      open: false
    });
  }
  parseErrors(errors) {
    let errorsArr = [];
    if (typeof errors === "object") {
      for (let key in errors) {
        if (errors.hasOwnProperty(key)) {
          // ensures that only true members of the object are passsed (removes linting error)
          if (key !== "full_messages") {
            if (typeof errors[key] === "object") {
              errorsArr.push(this.parseErrors(errors[key]));
            } else {
              errorsArr.push(`${errors[key]} `);
            }
          }
        }
      }
    } else if (errors instanceof Array) {
      for (let error of errors) {
        errorsArr.push(`${error} `);
      }
    } else {
      if (errors) {
        errorsArr.push(errors);
      } else {
        errorsArr.push("Oops - an internal server error occured.");
      }
    }
    return errorsArr;
  }
  getErrors(props) {
    let errors = this.parseErrors(props);
    // if (errors.length > 3) {
    //   errors = errors.slice(0, 2);
    //   errors.push("...");
    // }
    return errors;
  }
  render() {
    // Style attributes are for always displaying close button regardlesss of error length
    // eslint-disable-next-line react/style-prop-object
    return <NotificationSystem ref="notificationSystem" style={false} />;
  }
}
const mapStateToProps = state => {
  const { errorsOpen, errors } = state.dashboard;
  return { errorsOpen, errors };
};

export default connect(mapStateToProps, actions)(ValidationPopUp);
