import targetWidths from "./imgix/targetWidths";
import constructUrl from "./imgix/constructUrl";
import extractQueryParams from "./imgix/extractQueryParams";

const buildSrcSetPairWithFixedHeight = (url, targetWidth, fixedHeight, _) =>
  url + "&h=" + fixedHeight + "&w=" + targetWidth + " " + targetWidth + "w";

const buildSrcSetPairWithTargetWidth = (url, targetWidth, _1, _2) =>
  url + "&w=" + targetWidth + " " + targetWidth + "w";

const buildDprSrcWithQuality = (url, quality, dpr) =>
  url + "&q=" + quality + "&dpr=" + dpr + " " + dpr + "x";

const buildDprSrcWithoutQuality = (url, _, dpr) =>
  url + "&dpr=" + dpr + " " + dpr + "x";

const buildDprSrcWithQualityByDpr = (url, quality, dpr) =>
  url + "&q=" + quality + "&dpr=" + dpr + " " + dpr + "x";

// function aspectRatioIsValid(aspectRatio) {
//   if (typeof aspectRatio !== "string") {
//     return false;
//   }

//   return /^\d+(\.\d+)?:\d+(\.\d+)?$/.test(aspectRatio);
// }

const DPR_QUALITY = {
  q_dpr1: 75,
  q_dpr2: 50,
  q_dpr3: 35,
  q_dpr4: 23,
  q_dpr5: 20
};

const DPR_QUALITY_VALUES = Object.values(DPR_QUALITY);

/**
 * Build a imgix source url with parameters from a raw url
 */
function buildSrc({
  src: inputSrc,
  width,
  height,
  disableLibraryParam,
  disableSrcSet,
  type,
  imgixParams,
  disableQualityByDPR
}) {
  const fixedSize = width != null || height != null;

  const [rawSrc, params] = extractQueryParams(inputSrc);

  const srcOptions = Object.assign(
    {},
    params,
    imgixParams,
    disableLibraryParam ? {} : { ixlib: `react-8.6.1` },
    fixedSize && height ? { height } : {},
    fixedSize && width ? { width } : {}
  );

  const src = constructUrl(rawSrc, srcOptions);

  let srcSet;

  if (disableSrcSet) {
    srcSet = src;
  } else {
    if (fixedSize) {
      const { q, ...urlParams } = srcOptions;
      const constructedUrl = constructUrl(rawSrc, urlParams);

      let srcFn = buildDprSrcWithQualityByDpr;
      if (q) {
        srcFn = buildDprSrcWithQuality;
      } else if (disableQualityByDPR) {
        srcFn = buildDprSrcWithoutQuality;
      }

      srcSet = "";
      const len = DPR_QUALITY_VALUES.length;
      for (let i = 0; i < len; i++) {
        const quality = DPR_QUALITY_VALUES[i];
        srcSet += srcFn(constructedUrl, q || quality, i + 1) + ", ";
      }
      srcSet = srcSet.slice(0, -2);
    } else {
      const { width, w, height, ...urlParams } = srcOptions;
      const constructedUrl = constructUrl(rawSrc, urlParams);

      // const aspectRatio = imgixParams.ar;
      // let showARWarning =
      //   aspectRatio != null && aspectRatioIsValid(aspectRatio) === false;

      let srcFn = buildSrcSetPairWithTargetWidth;
      if (height) {
        srcFn = buildSrcSetPairWithFixedHeight;
      }

      srcSet = "";
      const len = targetWidths.length;
      for (let i = 0; i < len; i++) {
        const targetWidth = targetWidths[i];
        srcSet += srcFn(constructedUrl, targetWidth, height) + ", ";
      }
      srcSet = srcSet.slice(0, -2);
    }
  }

  return {
    src,
    srcSet
  };
}

export function imgixUrl(imgixParams) {
  return buildSrc(
    Object.assign({}, imgixParams, {
      type: "img",
      imgixParams: {}
    })
  );
}
