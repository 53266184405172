import React from "react";
import { FormattedDate } from "react-intl";
import { capitalizeFirstLetter } from "HelperFunctions/general";
import CurrencyLabel from "Utils/CurrencyLabel";
import { RecurringPayment } from "Utils/SvgIcons";
import ReactTooltip from "react-tooltip";

const DetailedAmountPaid = props => {
  const { payment, recurringTransactionInfo } = props;
  return (
    <div>
      <label>
        Payment{" "}
        {payment.mode !== "success" &&
          `(${capitalizeFirstLetter(payment.mode)})`}
        {
          payment.recurringPaymentDataId &&
          <a
            className="recurring-payment-info"
            data-tip
            data-for="recurring-payment-info"
            data-event="mouseenter"
            data-event-off="mouseleave"
          >
            <RecurringPayment />
          </a>
        }
        {payment.recurringPaymentDataId &&
          <ReactTooltip
            id="recurring-payment-info"
            class="tooltip top"
            place="top"
            effect="solid"
          >
            <p className="recurring-data-tooltip-header">
              Recurring Payment
            </p>
            <table className="recurring-data-tooltip-table">
              {recurringTransactionInfo && Object.keys(recurringTransactionInfo).map( (itemKey, index) => (
                <tr key={index}>
                  <td>{itemKey == "endDate" ? "End Date" : capitalizeFirstLetter(itemKey)}</td>
                  <td>
                    {recurringTransactionInfo[itemKey]}
                  </td>
                </tr>
              ))}
            </table>
          </ReactTooltip>
        }
      </label>
      {payment.mode === "failed" ? (
        <span>Failed</span>
      ) : (
        <CurrencyLabel value={0 - props.payment.amount} />
      )}
      <div>
        <label>
          <FormattedDate
            value={props.payment.paymentDate}
            year="numeric"
            month="numeric"
            day="numeric"
          />
        </label>
        <label>
          {capitalizeFirstLetter(props.payment.paymentData.type)}
          {props.payment.paymentData.number &&
            " " + props.payment.paymentData.number}
        </label>
      </div>
    </div>
  );
};

export default DetailedAmountPaid;
