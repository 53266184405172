/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

const PageView = props => {
  let linkClassName = props.pageLinkClassName;
  let cssClassName = props.pageClassName;
  let onClick = props.onClick;

  if (props.selected) {
    if (typeof cssClassName !== "undefined") {
      cssClassName = cssClassName + " " + props.activeClassName;
    } else {
      cssClassName = props.activeClassName;
    }
  }

  return (
    <li className={cssClassName}>
      <a
        onClick={onClick}
        className={linkClassName}
        tabIndex="0"
        onKeyPress={onClick}
      >
        {props.page}
      </a>
    </li>
  );
};

export default PageView;
