import React from 'react';
import CustomerPickUp from './delivery/CustomerPickUp';
import Delivery from './delivery/Delivery';
import Shipping from './delivery/Shipping';
import SetByTimeInfo from './delivery/SetByTime';

const RentalDelivery = ({
  delivery,
  checkout,
  checkoutWindowEnd,
  checkout_time,
  checkinWindowStart,
  checkin_time,
  hideDeliveryInfo,
  hideDeliveryTimes,
  schedule,
  rental,
}) => {
  if (rental.approvalStatus === 'lead') {
    return (
      <section className='delivery'>
        <h4>Delivery</h4>
        <div className='details'>
          <div>
            <label>
              {delivery.delivery_type === 'ship'
                ? 'Ship - Deliver'
                : 'Drop Off'}
            </label>
          </div>
          <div>
            <label>
              {delivery.delivery_type === 'ship' ? 'Ship - Return' : 'Pick Up'}
            </label>
          </div>
          <div>
            <label>Address</label>
          </div>
          {rental.hasSetByTime && rental.location.showSetByTimeToCustomers && (
            <SetByTimeInfo {...{ rental, schedule }} />
          )}
        </div>
      </section>
    );
  }

  if (delivery.delivery_type === 'customer_pick_up') {
    return (
      <CustomerPickUp
        {...{
          delivery,
          checkout,
          hideDeliveryTimes,
          hideDeliveryInfo,
          checkoutWindowEnd,
          checkout_time,
          checkinWindowStart,
          checkin_time,
          schedule,
          rental,
        }}
      />
    );
  }

  if (
    ['default_delivery', 'custom_delivery'].indexOf(delivery.delivery_type) >= 0
  ) {
    return (
      <Delivery
        {...{
          delivery,
          checkout,
          checkoutWindowEnd,
          checkout_time,
          checkinWindowStart,
          checkin_time,
          hideDeliveryInfo,
          hideDeliveryTimes,
          schedule,
          rental,
        }}
      />
    );
  }

  if (delivery.delivery_type === 'ship') {
    return (
      <Shipping
        {...{
          delivery,
          checkout,
          checkoutWindowEnd,
          checkout_time,
          checkinWindowStart,
          checkin_time,
          hideDeliveryInfo,
          hideDeliveryTimes,
          schedule,
          rental,
        }}
      />
    );
  }
};

export default RentalDelivery;
