import {
  ADD_UNREAD_MESSAGE,
  SET_UNREAD_MESSAGES_COUNT,
  FETCH_CONVERSATION_LIST_SUCCESS,
  REMOVE_CONVERSATION_UNREAD_MESSAGES,
} from 'Constants/redux';
import createReducer from '../lib/CreateReducer';

const INITIAL_STATE = {
  conversations: [],
  unreadMessages: 0,
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CONVERSATION_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversations: action.payload.conversations,
      unreadMessages: action.payload.unreadMessages,
    };
  },
  [ADD_UNREAD_MESSAGE]: (state, action) => {
    const unreadMessages = (state.unreadMessages += 1);

    return {
      ...state,
      unreadMessages: unreadMessages,
    };
  },
  [SET_UNREAD_MESSAGES_COUNT]: (state, action) => {
    return {
      ...state,
      unreadMessages: action.payload,
    };
  },
  [REMOVE_CONVERSATION_UNREAD_MESSAGES]: (state, action) => {
    const newConversations = state.conversations.map((conversation) => {
      if (conversation.id === action.payload) {
        return { ...conversation, unreadMessages: 0 };
      } else {
        return conversation;
      }
    });
    return {
      ...state,
      conversations: newConversations,
    };
  },
});
