import React from "react";
import SignUpForm from "Components/home/SignUpForm";
import HeroImage from "Components/home/widgets/HeroImage";
import * as actions from "Actions";
import { connect } from "react-redux";

class SignUp extends React.Component {
  render() {
    const { createUser, onSuccess } = this.props;
    return (
      <section className="signUp">
        <HeroImage />
        <div className="details">
          <h1>Sign Up for a New Way to Rent</h1>
          <p>Track rentals, make payments, request changes. You made it!</p>
        </div>
        <SignUpForm onSubmit={createUser} onSuccess={onSuccess} />
      </section>
    );
  }
}
export default connect(null, actions)(SignUp);
