import React from "react";
import axios from "axios";
import { ROOT_URL } from "Api";
import LoadingSpinner from "Utils/LoadingSpinner";
import { InstagramFollow } from "Utils/SvgIcons";

class InstagramBox extends React.Component {
  state = {
    loading: true,
    instagramImages: [],
    accountInfo: null
  };
  componentDidMount() {
    this.fetchInstagramImages();
  }
  fetchInstagramImages = () => {
    axios
      .get(
        `${ROOT_URL}/portal/locations/${this.props.location.id}/instagram_images`
      )
      .then(response => {
        this.setState({
          accountInfo: response.data.account_info,
          instagramImages: response.data.images,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        console.log(error);
      });
  };
  render() {
    const { loading, accountInfo, instagramImages } = this.state;
    if (loading) {
      return <LoadingSpinner />;
    } else {
      if (accountInfo && instagramImages.length > 0) {
        return (
          <div className="instagram">
            <InstagramFollow />
            <a
              href={`https://www.instagram.com/${accountInfo.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow Us On Instagram
            </a>
            <div className="images">
              {instagramImages.map((image, index) => (
                <figure>
                  <img src={image["media_url"]} alt="" />
                </figure>
              ))}
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  }
}
export default InstagramBox;
