export const FETCH_LOCATION_REQUEST = "FETCH_LOCATION_REQUEST";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_FAILURE = "FETCH_LOCATION_FAILURE";
export const FETCH_LOCATION_PICTURE_REQUEST = "FETCH_LOCATION_PICTURE_REQUEST";
export const FETCH_LOCATION_PICTURE_SUCCESS = "FETCH_LOCATION_PICTURE_SUCCESS";
export const FETCH_LOCATION_PICTURE_FAILURE = "FETCH_LOCATION_PICTURE_FAILURE";
export const FETCH_LOCATIONS_REQUEST = "FETCH_LOCATIONS_REQUEST";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
