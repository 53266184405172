import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import callAPIMiddleware from './middleware/callAPIMiddleware';
import { history } from 'Components/Routes';
import { composeWithDevTools } from 'redux-devtools-extension';

const routingMiddleware = routerMiddleware(history);


export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        routingMiddleware,
        callAPIMiddleware,
      )
    )
  );
}
