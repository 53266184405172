import {
  REQUEST_MESSAGE_ALERTS,
  RECEIVE_MESSAGE_ALERTS,
  BULK_READ_MESSAGE_ALERTS
} from "Constants/redux";
const INITIAL_STATE = {
  isFetching: false,
  shouldRefresh: true,
  alerts: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_MESSAGE_ALERTS:
      return { ...state, isFetching: true };
    case BULK_READ_MESSAGE_ALERTS:
      return { ...state, isFetching: true };
    case RECEIVE_MESSAGE_ALERTS:
      const alerts = action.payload;
      return { ...state, isFetching: false, alerts: alerts };
    default:
      return state;
  }
};
