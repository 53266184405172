/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import ReactSignature from './ReactSignature';
import Modal from 'Utils/Modal';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import validations from 'HelperFunctions/validations';

class SignaturePad extends React.Component {
  state = {
    open: false,
    signature: null,
    signerName: '',
    signerTitle: '',
    signerCompany: '',
    errors: {},
  };

  clearSignaturePad = () => {
    this.refs.signaturePad.handleClear();
  };

  toggleSignaturePad = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  validate = () => {
    const errors = {};
    const { signerName } = this.state;
    if (validations.required(signerName, true)) {
      errors.signerName = 'Required';
    }
    return errors;
  };

  saveSignaturePad = () => {
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors,
      });
    }
    const pad = this.refs.signaturePad;
    if (!pad.isEmpty) {
      const newSignature = this.refs.signaturePad.toDataURL();
      this.setState(
        {
          open: !this.state.open,
          signature: newSignature,
        },
        () => {
          this.props.saveSignature(
            this.props.fromDisclaimerModal
              ? {
                  signature: newSignature,
                  signerName: this.state.signerName,
                  signerTitle: this.state.signerTitle,
                  signerCompany: this.state.signerCompany,
                }
              : newSignature
          );
        }
      );
    }
  };

  render() {
    const { open, signature } = this.state;

    return (
      <div className='signaturePad'>
        <a onClick={this.toggleSignaturePad}>
          {signature && (
            <img src={signature ? signature : null} alt='Signature' />
          )}
          <div className='signatureDetails'>
            <p>{this.props.signatureDetails?.name}</p>
            <p>{this.props.signatureDetails?.submittedAt}</p>
          </div>
        </a>
        <Modal
          title='Signature'
          open={open}
          large={true}
          className='signatureModal'
          toggle={this.toggleSignaturePad}
          actions={[
            <button className='btn' onClick={this.saveSignaturePad}>
              Save
            </button>,
            <button className='btn cancel' onClick={this.toggleSignaturePad}>
              Cancel
            </button>,
            <button className='btn cancel' onClick={this.clearSignaturePad}>
              Clear
            </button>,
          ]}
        >
          <MediaBreakpoints
            desktop={<ReactSignature ref='signaturePad' />}
            mobile={
              <ReactSignature ref='signaturePad' width={340} height={70} />
            }
          />
        </Modal>
      </div>
    );
  }
}

export default SignaturePad;
