import React from "react";
import classnames from "classnames";
import MonthSelect from "Utils/MonthSelect";

const StandardMonthSelect = props => {
  const {
    input,
    className,
    meta: { touched, error },
    ...others
  } = props;

  return (
    <MonthSelect
      {...input}
      {...others}
      className={classnames({
        [className]: className,
        error: touched && error
      })}
    />
  );
};

export default StandardMonthSelect;
