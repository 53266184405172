import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Validator from "HelperFunctions/validator";
import classnames from "classnames";
import { setErrors } from "Actions";

const validations = {
  email: {
    required: {
      value: true,
      message: "Email is required"
    }
  },
  password: {
    required: {
      value: true,
      message: "Password is required"
    }
  }
};

class SignInForm extends React.Component {
  render() {
    const { errors, handleSubmit, pristine, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <Field
            name="email"
            className={classnames({ error: errors.login })}
            component={StandardTextField}
            type="text"
            placeholder="Email"
          />
          <Field
            name="password"
            className={classnames({ error: errors.login })}
            component={StandardTextField}
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="actions">
          <button
            className="full"
            type="submit"
            disabled={pristine || submitting}
          >
            Sign In
          </button>
        </div>
      </form>
    );
  }
}
export default reduxForm({
  form: "SignIn", // a unique identifier for this form
  validate: Validator(validations),
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(SignInForm);
