
import React from 'react';
import Modal from "../utils/Modal";

const SelectDatesModal = ({onClick, isOpen, onToggle}) => (
  <Modal
    actions={
      <button className='btn warn' onClick={onClick}>
        Select order dates
      </button>
    }
    open={isOpen}
    className='dialog'
    title='Select order dates'
    toggle={onToggle}
  >
    <p>
      Please select the Order Start and Order End dates using the filters to check the availability of your items.
    </p>
  </Modal>
)

export default SelectDatesModal;
