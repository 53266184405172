// TODO: Handle invalid item type on caller side
export const parseCartFromLocalStorage = () => {
  const products = [];
  const addOns = [];
  const bundles = [];

  const localStorageItems = localStorage.getItem('cartItems');

  const mapItemTypeToCollection = {
    items: products,
    addons: addOns,
    bundles: bundles,
  };

  if (localStorageItems) {
    try {
      JSON.parse(localStorageItems).forEach(
        ({ itemId, itemType, quantity }) => {
          if (!(itemType in mapItemTypeToCollection))
            throw new Error('Invalid item type');

          mapItemTypeToCollection[itemType].push({ id: itemId, quantity });
        }
      );
    } catch (e) {
      return { success: false, error: e, products, addOns, bundles };
    }
  }

  return {
    success: true,
    products,
    addOns,
    bundles,
  };
};
