import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { filterFromPropsAndState } from "HelperFunctions/urls";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { FormattedDate } from "react-intl";
import { Alert, Items, MessageBubble } from "Utils/SvgIcons";
import { isFinalBalanceDue } from "HelperFunctions/rentals";
import moment from "moment";
import classnames from "classnames";
import CurrencyLabel from "Utils/CurrencyLabel";
import { shouldHidePrice } from 'HelperFunctions/HidePricing';

class EventsTable extends React.Component {
  getActionsCount(event) {
    let count = 0;
    if (event.approvalStatus === "lead" || event.approvalStatus === "pending") {
      return 0;
    } else {
      if (event.amountRemaining > 0) {
        count += 1;
      }
      if (event.signatureRequired && event.hasSignature) {
        count += 1;
      }
    }
    return count;
  }

  render() {
    const { events, storefrontShopSetting } = this.props;
    const eventsByMonth = events.reduce((holder, event) => {
      const key = moment(event.schedule.eventStartDateTime).format("MMM YYYY");
      if (!holder.hasOwnProperty(key)) {
        holder[key] = [];
      }
      holder[key].push(event);
      return holder;
    }, {});

    return (
      <section className="items">
        {events && events.length > 0 ? (
          <MediaBreakpoints
            desktop={
              <div>
                {Object.keys(eventsByMonth).map((monthKey, monthIndex) => (
                  <div className="month">
                    <h4>{monthKey}</h4>
                    <table className="grid thumbnail" key={monthIndex}>
                      <tbody>
                        {eventsByMonth[monthKey].map((event, index) => {
                          const hideSubtotalPricing = shouldHidePrice(
                            event.approvalStatus,
                            storefrontShopSetting
                          );
                          let actionCount = this.getActionsCount(event);

                          return (
                            <Link
                              key={index}
                              className="rTableRow"
                              to={`/events/${event.token}`}
                            >
                              <td className="image">
                                <figure>
                                  {event.pictures[0] ? (
                                    <img
                                      src={event.pictures[0]}
                                      alt="event_picture"
                                    />
                                  ) : (
                                    <Items />
                                  )}
                                </figure>
                              </td>
                              <td>
                                <h4>{event.name}</h4>
                                <FormattedDate
                                  day="numeric"
                                  month="numeric"
                                  year="numeric"
                                  value={event.schedule.eventStartDateTime}
                                />
                              </td>
                              <td className="summary">
                                <div>
                                  <label>Event Total</label>
                                  { !hideSubtotalPricing ? (
                                    <CurrencyLabel value={event.overallTotal} />
                                    ) : 'TBD'
                                  }
                                </div>
                                <div
                                  className={classnames({
                                    alert: isFinalBalanceDue(event) &&
                                      !hideSubtotalPricing
                                  })}
                                >
                                  <label>
                                    {
                                      isFinalBalanceDue(event) &&
                                      !hideSubtotalPricing &&
                                       <Alert />
                                    }
                                    Balance Due
                                  </label>
                                  {
                                    !hideSubtotalPricing ? (
                                      <CurrencyLabel value={event.amountRemaining} />
                                    ) : 'TBD'
                                  }
                                </div>
                                {event.finalPaymentDueDate &&
                                  event.billingStatus !== "Full" && (
                                    <div
                                      className={classnames({
                                        alert: isFinalBalanceDue(event) &&
                                          !hideSubtotalPricing
                                      })}
                                    >
                                      <label>Due Date</label>
                                      <FormattedDate
                                        day="numeric"
                                        month="numeric"
                                        year="numeric"
                                        value={event.finalPaymentDueDate}
                                      />
                                    </div>
                                  )}
                                <div>
                                  <label>Action Required</label>
                                  {actionCount > 0 ? (
                                    <span>
                                      {actionCount} Item
                                      {actionCount !== 1 && "s"}
                                    </span>
                                  ) : (
                                    <span>None</span>
                                  )}
                                </div>
                              </td>
                              <td className="action">
                                {event.unreadMessagesCount > 0 && (
                                  <div className="unreadCount">
                                    <MessageBubble />
                                    <label>{event.unreadMessagesCount}</label>
                                  </div>
                                )}
                              </td>
                            </Link>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            }
            mobile={
              <div className="month mobileList">
                {Object.keys(eventsByMonth).map((monthKey, monthIndex) => (
                  <div key={monthIndex}>
                    <h4>{monthKey}</h4>
                    {eventsByMonth[monthKey].map((event, index) => {
                      const hideSubtotalPricing = shouldHidePrice(
                        event.approvalStatus,
                        storefrontShopSetting
                      );
                      let actionCount = this.getActionsCount(event);
                      return (
                        <Link key={index} to={`/events/${event.id}`}>
                          <figure>
                            {event.pictures[0] ? (
                              <img src={event.pictures[0]} alt="event_image" />
                            ) : (
                              <Items />
                            )}
                          </figure>
                          <h4>
                            {event.name}
                            {event.unreadMessagesCount > 0 && (
                              <div className="unreadCount">
                                <MessageBubble />
                                <label>{event.unreadMessagesCount}</label>
                              </div>
                            )}
                          </h4>
                          <FormattedDate
                            day="numeric"
                            month="numeric"
                            year="numeric"
                            value={event.schedule.eventStartDateTime}
                          />
                          <td className="summary">
                            <div>
                              <label>Event Total</label>
                              { !hideSubtotalPricing ? (
                                <CurrencyLabel value={event.overallTotal} />
                                ) : 'TBD'
                              }
                            </div>
                            <div
                              className={classnames({
                                alert: isFinalBalanceDue(event) &&
                                 !hideSubtotalPricing
                              })}
                            >
                              <label>
                                {
                                  isFinalBalanceDue(event) &&
                                  !hideSubtotalPricing &&
                                  <Alert />
                                }
                                Balance Due
                              </label>
                              {
                                !hideSubtotalPricing ? (
                                  <CurrencyLabel value={event.amountRemaining} />
                                ) : 'TBD'
                              }
                            </div>
                              {event.finalPaymentDueDate &&
                                event.billingStatus !== "Full" && (
                                  <div
                                    className={classnames({
                                      alert: isFinalBalanceDue(event) &&
                                       !hideSubtotalPricing
                                    })}
                                  >
                                    <label>Due Date</label>
                                    <FormattedDate
                                      day="numeric"
                                      month="numeric"
                                      year="numeric"
                                      value={event.finalPaymentDueDate}
                                    />
                                  </div>
                                )}
                              <div>
                                <label>Action Required</label>
                                  {actionCount > 0 ? (
                                    <span>
                                      {actionCount} Item{actionCount !== 1 && "s"}
                                    </span>
                                  ) : (
                                    <span style={{ textAlign: 'left' }}>None</span>
                                  )}
                              </div>
                            </td>
                        </Link>
                      );
                    })}
                  </div>
                ))}
              </div>
            }
          />
        ) : (
          <p className="noItems">There are currently no orders.</p>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const { rentals } = state.rentals;

  return {
    events: rentals,
    filter: newFilter
  };
};

export default withRouter(connect(mapStateToProps, actions)(EventsTable));
