/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import RegionDropdown from 'Utils/RegionDropdown';
import { connect } from 'react-redux';
import StandardTextField from 'Utils/redux_form_inputs/StandardTextField';
import StandardSelectField from 'Utils/redux_form_inputs/StandardSelectField';
import StandardTextareaField from 'Utils/redux_form_inputs/StandardTextareaField';
import Checkbox from 'Utils/Checkbox';
import CurrencyLabel from 'Utils/CurrencyLabel';
import classnames from 'classnames';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import DeliveryMethods from './DeliveryMethods';

class EventForm extends React.Component {
  state = {
    deliveryType: this.props.deliveryType,
  };

  componentDidMount() {
    this.props.setEventProperties({
      preferredDeliveryWindow: 'delivery_no_preference',
      preferredPickupWindow: 'pickup_no_preference',
    });
  }

  handleChange = (event) => {
    const { setEventProperties } = this.props;
    const { name, value } = event.target;
    const newEvent = Object.assign(
      { [name]: value },
      name === 'deliveryAddressCountry' && { deliveryAddressLocale: '' },
      name === 'pickupAddressCountry' && { pickupAddressLocale: '' }
    );
    setEventProperties(newEvent);
  };

  handlePickupSameCheck = (event) => {
    const { setEventProperties } = this.props;
    const { name, checked } = event.target;
    let newEvent = Object.assign(
      { [name]: checked },
      name === 'deliveryAddressCountry' && { deliveryAddressLocale: '' },
      name === 'pickupAddressCountry' && { pickupAddressLocale: '' }
    );
    if (checked) {
      newEvent = {
        ...newEvent,
        pickupAddressLocationName: '',
        pickupAddressStreetAddress1: '',
        pickupAddressStreetAddress2: '',
        pickupAddressCity: '',
        pickupAddressLocale: '',
        pickupAddressPostalCode: '',
        pickupAddressCountry: '',
      };
    }
    setEventProperties(newEvent);
  };

  handleDeliveryTypeChange = (event) => {
    const { name, value } = event.target;
    const { setDeliveryTypeState } = this.props;
    let newEventProps = {
      [name]: value,
    };
    if (value === 'customer_pick_up') {
      newEventProps['deliveryCost'] = 0;
    }
    this.props.setEventProperties(newEventProps);
    this.setState({
      deliveryType: value,
    });
    setDeliveryTypeState(value);
  };

  handleCalculateDeliveryPrice = () => {
    const { event, setEventProperties, calculateDefaultDeliveryPrice } =
      this.props;
    calculateDefaultDeliveryPrice({
      event,
      onSuccess: (deliveryCost) => {
        setEventProperties({
          deliveryCost: deliveryCost,
        });
      },
    });
  };

  toTimeString = (date) =>
    new Date(date)
      .toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })
      .replace(':00', '');

  deliveryTimesSelect = (inputName, labelType, type) => {
    const {
      event,
      errors,
      deliverySetting: {
        morningStartTime,
        morningEndTime,
        afternoonStartTime,
        afternoonEndTime,
        eveningStartTime,
        eveningEndTime,
      } = {},
    } = this.props;

    const options = [
      {
        value: `${labelType}_no_preference`,
        content: 'No Delivery Preference Time',
      },
    ];

    if (morningStartTime && morningEndTime) {
      options.push({
        value: `${labelType}_morning_time`,
        content: `Morning (${this.toTimeString(
          morningStartTime
        )} - ${this.toTimeString(morningEndTime)})`,
      });
    }
    if (afternoonStartTime && afternoonEndTime) {
      options.push({
        value: `${labelType}_afternoon_time`,
        content: `Afternoon (${this.toTimeString(
          afternoonStartTime
        )} - ${this.toTimeString(afternoonEndTime)})`,
      });
    }
    if (eveningStartTime && eveningEndTime) {
      options.push({
        value: `${labelType}_evening_time`,
        content: `Evening (${this.toTimeString(
          eveningStartTime
        )} - ${this.toTimeString(eveningEndTime)})`,
      });
    }

    return (
      <StandardSelectField
        meta={{
          touched: true,
          error: errors[inputName],
        }}
        input={{
          name: inputName,
          value: event[inputName],
          onChange: this.handleChange,
        }}
      >
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.content}
          </option>
        ))}
      </StandardSelectField>
    );
  };

  render() {
    const {
      event,
      storefrontShopSetting,
      errors,
      customer,
      authenticated,
      canSkipDelivery,
      makeLead,
    } = this.props;
    const { deliveryMethods } = storefrontShopSetting;

    const customerAddress = authenticated ? customer?.mainContactAddress : null;

    const {
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      pickupSameAsDelivery,
      pickupAddressLocationName,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupAddressCity,
      pickupAddressLocale,
      pickupAddressPostalCode,
      pickupAddressCountry,
    } = event;
    const { deliveryType } = this.state;

    return (
      <section className='eventForm'>
        <h4>Select how you would like to receive your items.</h4>
        <MediaBreakpoints
          desktop={
            <div className='form'>
              <DeliveryMethods
                deliveryMethods={deliveryMethods}
                onChange={this.handleDeliveryTypeChange}
                deliveryType={deliveryType}
              />
              {['default_delivery', 'ship'].includes(
                this.state.deliveryType
              ) && (
                <div>
                  <div className='fieldsContainer'>
                    <div className='fields' id='deliveryFields'>
                      <StandardTextField
                        type='text'
                        meta={{ error: {} }}
                        placeholder='Location Name (optional)'
                        input={{
                          name: 'deliveryAddressLocationName',
                          value: deliveryAddressLocationName,
                          onChange: this.handleChange,
                        }}
                      />
                      <StandardSelectField
                        meta={{
                          touched: true,
                          error: errors['deliveryAddressCountry'],
                        }}
                        input={{
                          name: 'deliveryAddressCountry',
                          value:
                            deliveryAddressCountry || customerAddress?.country,
                          onChange: this.handleChange,
                        }}
                      >
                        <option value=''>Select country</option>
                        <option value='USA'>United States</option>
                        <option value='CAN'>Canada</option>
                      </StandardSelectField>
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error: errors['deliveryAddressStreetAddress1'],
                        }}
                        className='lg spanThree'
                        placeholder='Street Address*'
                        id='cartAddress'
                        input={{
                          name: 'deliveryAddressStreetAddress1',
                          value:
                            deliveryAddressStreetAddress1 ||
                            customerAddress?.streetAddress1,
                          onChange: this.handleChange,
                        }}
                      />
                      <StandardTextField
                        type='text'
                        meta={{ error: {} }}
                        className='sm spanOne'
                        placeholder='Suite #'
                        id='cartApt'
                        input={{
                          name: 'deliveryAddressStreetAddress2',
                          value:
                            deliveryAddressStreetAddress2 ||
                            customerAddress?.streetAddress1,
                          onChange: this.handleChange,
                        }}
                      />
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error: errors['deliveryAddressCity'],
                        }}
                        className='med spanTwo'
                        placeholder='City*'
                        input={{
                          name: 'deliveryAddressCity',
                          value: deliveryAddressCity || customerAddress?.city,
                          onChange: this.handleChange,
                        }}
                      />
                      <RegionDropdown
                        name='deliveryAddressLocale'
                        defaultOptionLabel={
                          deliveryAddressCountry === 'USA'
                            ? 'State'
                            : 'Province'
                        }
                        className={classnames({
                          sm: true,
                          error: errors['deliveryAddressLocale'],
                          spanOne: true,
                        })}
                        disableWhenEmpty={true}
                        countryValueType='short'
                        labelType='short'
                        valueType='short'
                        country={deliveryAddressCountry}
                        value={deliveryAddressLocale || customerAddress?.locale}
                        onChange={this.handleChange}
                      />
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error:
                            errors['deliveryAddressPostalCode'] ||
                            errors['postal_code'],
                        }}
                        className='sm spanOne'
                        placeholder='Zip*'
                        input={{
                          name: 'deliveryAddressPostalCode',
                          value:
                            deliveryAddressPostalCode ||
                            customerAddress?.postalCode,
                          onChange: this.handleChange,
                        }}
                      />

                      {this.state.deliveryType !== 'ship' && (
                        <div className='fieldsRight'>
                          {this.deliveryTimesSelect(
                            'preferredDeliveryWindow',
                            'delivery',
                            'Drop Off'
                          )}
                        </div>
                      )}
                      {this.state.deliveryType !== 'ship' && (
                        <div className='fieldsRight'>
                          {this.deliveryTimesSelect(
                            'preferredPickupWindow',
                            'pickup',
                            'Pick Up'
                          )}
                        </div>
                      )}
                      {this.state.deliveryType === 'default_delivery' &&
                        storefrontShopSetting.shopShowItemPricing && (
                          <div className='actions' id='calculateDelivery'>
                            <a
                              className='btn'
                              onClick={this.handleCalculateDeliveryPrice}
                            >
                              Calculate Estimated Delivery
                            </a>
                            <CurrencyLabel value={event.deliveryCost} />
                          </div>
                        )}
                    </div>
                    <div id='pickupCheckbox'>
                      {['default_delivery', 'custom_delivery'].indexOf(
                        deliveryType
                      ) >= 0 && (
                        <Checkbox
                          name='pickupSameAsDelivery'
                          checked={pickupSameAsDelivery}
                          onCheck={this.handlePickupSameCheck}
                          label='Pick Up Address Is Same as Drop Off Address'
                        />
                      )}
                    </div>
                  </div>
                  <div className='fieldsContainer'>
                    {!pickupSameAsDelivery &&
                      this.state.deliveryType !== 'ship' && (
                        <div className='fields' id='pickupFields'>
                          <StandardTextField
                            type='text'
                            meta={{ error: {} }}
                            placeholder='Location Name (optional)'
                            input={{
                              name: 'pickupAddressLocationName',
                              value: pickupAddressLocationName,
                              onChange: this.handleChange,
                            }}
                          />
                          <StandardSelectField
                            meta={{
                              touched: true,
                              error: errors['pickupAddressCountry'],
                            }}
                            input={{
                              name: 'pickupAddressCountry',
                              value: pickupAddressCountry,
                              onChange: this.handleChange,
                            }}
                          >
                            <option value=''>Select country</option>
                            <option value='USA'>United States</option>
                            <option value='CAN'>Canada</option>
                          </StandardSelectField>
                          <StandardTextField
                            type='text'
                            meta={{
                              touched: true,
                              error: errors['pickupAddressStreetAddress1'],
                            }}
                            className='lg spanThree'
                            placeholder='Street Address*'
                            input={{
                              name: 'pickupAddressStreetAddress1',
                              value: pickupAddressStreetAddress1,
                              onChange: this.handleChange,
                            }}
                          />
                          <StandardTextField
                            type='text'
                            meta={{ error: {} }}
                            className='sm spanOne'
                            placeholder='Suite #'
                            input={{
                              name: 'pickupAddressStreetAddress2',
                              value: pickupAddressStreetAddress2,
                              onChange: this.handleChange,
                            }}
                          />
                          <StandardTextField
                            type='text'
                            meta={{
                              touched: true,
                              error: errors['pickupAddressCity'],
                            }}
                            className='med'
                            placeholder='City*'
                            input={{
                              name: 'pickupAddressCity',
                              value: pickupAddressCity,
                              onChange: this.handleChange,
                            }}
                          />
                          <RegionDropdown
                            name='pickupAddressLocale'
                            defaultOptionLabel={
                              pickupAddressCountry === 'USA'
                                ? 'State'
                                : 'Province'
                            }
                            className={classnames({
                              sm: true,
                              error: errors['pickupAddressLocale'],
                              spanOne: true,
                            })}
                            disableWhenEmpty={true}
                            countryValueType='short'
                            labelType='short'
                            valueType='short'
                            country={pickupAddressCountry}
                            value={pickupAddressLocale}
                            onChange={this.handleChange}
                          />
                          <StandardTextField
                            type='text'
                            meta={{
                              touched: true,
                              error:
                                errors['pickupAddressPostalCode'] ||
                                errors['postal_code'],
                            }}
                            className='sm spanOne'
                            placeholder='Zip*'
                            input={{
                              name: 'pickupAddressPostalCode',
                              value: pickupAddressPostalCode,
                              onChange: this.handleChange,
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
              )}
              <div className='fields'>
                <StandardTextareaField
                  id='deliveryNotes'
                  meta={{}}
                  placeholder='Additional Delivery Info/Notes'
                  input={{
                    name: 'additionalDeliveryInfo',
                    value: event.additionalDeliveryInfo,
                    style: { resize: 'none' },
                    onChange: this.handleChange,
                  }}
                />
              </div>
            </div>
          }
          mobile={
            <div className='form'>
              <DeliveryMethods
                deliveryMethods={deliveryMethods}
                onChange={this.handleDeliveryTypeChange}
                deliveryType={deliveryType}
              />
              {['default_delivery', 'ship'].includes(
                this.state.deliveryType
              ) && (
                <div>
                  <div className='fieldsContainer'>
                    <div className='fields' id='deliveryFields'>
                      <StandardTextField
                        type='text'
                        meta={{ error: {} }}
                        placeholder='Location Name (optional)'
                        input={{
                          name: 'deliveryAddressLocationName',
                          value: deliveryAddressLocationName,
                          onChange: this.handleChange,
                        }}
                      />
                      <StandardSelectField
                        meta={{
                          touched: true,
                          error: errors['deliveryAddressCountry'],
                        }}
                        input={{
                          name: 'deliveryAddressCountry',
                          value: deliveryAddressCountry,
                          onChange: this.handleChange,
                        }}
                      >
                        <option value=''>Select country</option>
                        <option value='USA'>United States</option>
                        <option value='CAN'>Canada</option>
                      </StandardSelectField>
                      <div id='cartAddressApt'>
                        <StandardTextField
                          type='text'
                          meta={{
                            touched: true,
                            error: errors['deliveryAddressStreetAddress1'],
                          }}
                          className='lg'
                          placeholder='Street Address*'
                          id='cartAddress'
                          input={{
                            name: 'deliveryAddressStreetAddress1',
                            value: deliveryAddressStreetAddress1,
                            onChange: this.handleChange,
                          }}
                        />
                        <StandardTextField
                          type='text'
                          meta={{ error: {} }}
                          className='sm'
                          placeholder='Suite #'
                          id='cartApt'
                          input={{
                            name: 'deliveryAddressStreetAddress2',
                            value: deliveryAddressStreetAddress2,
                            onChange: this.handleChange,
                          }}
                        />
                      </div>
                      <div id='cartCityStateZip'>
                        <StandardTextField
                          type='text'
                          meta={{
                            touched: true,
                            error: errors['deliveryAddressCity'],
                          }}
                          className='med'
                          placeholder='City*'
                          input={{
                            name: 'deliveryAddressCity',
                            value: deliveryAddressCity,
                            onChange: this.handleChange,
                          }}
                        />
                        <RegionDropdown
                          name='deliveryAddressLocale'
                          defaultOptionLabel={
                            deliveryAddressCountry === 'USA'
                              ? 'State'
                              : 'Province'
                          }
                          className={classnames({
                            sm: true,
                            error: errors['deliveryAddressLocale'],
                          })}
                          disableWhenEmpty={true}
                          countryValueType='short'
                          labelType='short'
                          valueType='short'
                          country={deliveryAddressCountry}
                          value={deliveryAddressLocale}
                          onChange={this.handleChange}
                        />
                        <StandardTextField
                          type='text'
                          meta={{
                            touched: true,
                            error:
                              errors['deliveryAddressPostalCode'] ||
                              errors['postal_code'],
                          }}
                          className='sm'
                          placeholder='Zip*'
                          input={{
                            name: 'deliveryAddressPostalCode',
                            value: deliveryAddressPostalCode,
                            onChange: this.handleChange,
                          }}
                        />
                      </div>
                    </div>
                    <div id='pickupCheckbox'>
                      {['default_delivery', 'custom_delivery'].indexOf(
                        deliveryType
                      ) >= 0 && (
                        <Checkbox
                          name='pickupSameAsDelivery'
                          checked={pickupSameAsDelivery}
                          onCheck={this.handlePickupSameCheck}
                          label='Pick Up Address Is Same as Drop Off Address'
                        />
                      )}
                    </div>
                  </div>
                  <div className='fieldsContainer'>
                    {!pickupSameAsDelivery &&
                      this.state.deliveryType !== 'ship' && (
                        <div className='fields' id='pickupFields'>
                          <StandardTextField
                            type='text'
                            meta={{ error: {} }}
                            placeholder='Location Name (optional)'
                            input={{
                              name: 'pickupAddressLocationName',
                              value: pickupAddressLocationName,
                              onChange: this.handleChange,
                            }}
                          />
                          <StandardSelectField
                            meta={{
                              touched: true,
                              error: errors['pickupAddressCountry'],
                            }}
                            input={{
                              name: 'pickupAddressCountry',
                              value: pickupAddressCountry,
                              onChange: this.handleChange,
                            }}
                          >
                            <option value=''>Select country</option>
                            <option value='USA'>United States</option>
                            <option value='CAN'>Canada</option>
                          </StandardSelectField>
                          <div id='cartAddressApt'>
                            <StandardTextField
                              type='text'
                              meta={{
                                touched: true,
                                error: errors['pickupAddressStreetAddress1'],
                              }}
                              className='lg'
                              placeholder='Street Address*'
                              input={{
                                name: 'pickupAddressStreetAddress1',
                                value: pickupAddressStreetAddress1,
                                onChange: this.handleChange,
                              }}
                            />
                            <StandardTextField
                              type='text'
                              meta={{ error: {} }}
                              className='sm'
                              placeholder='Suite #'
                              input={{
                                name: 'pickupAddressStreetAddress2',
                                value: pickupAddressStreetAddress2,
                                onChange: this.handleChange,
                              }}
                            />
                          </div>
                          <div id='cartCityStateZip'>
                            <StandardTextField
                              type='text'
                              meta={{
                                touched: true,
                                error: errors['pickupAddressCity'],
                              }}
                              className='med'
                              placeholder='City*'
                              input={{
                                name: 'pickupAddressCity',
                                value: pickupAddressCity,
                                onChange: this.handleChange,
                              }}
                            />
                            <RegionDropdown
                              name='deliveryAddressLocale'
                              defaultOptionLabel={
                                deliveryAddressCountry === 'USA'
                                  ? 'State'
                                  : 'Province'
                              }
                              className={classnames({
                                sm: true,
                                error: errors['deliveryAddressLocale'],
                              })}
                              disableWhenEmpty={true}
                              countryValueType='short'
                              labelType='short'
                              valueType='short'
                              country={deliveryAddressCountry}
                              value={deliveryAddressLocale}
                              onChange={this.handleChange}
                            />
                            <StandardTextField
                              type='text'
                              meta={{
                                touched: true,
                                error:
                                  errors['pickupAddressPostalCode'] ||
                                  errors['postal_code'],
                              }}
                              className='sm'
                              placeholder='Zip*'
                              input={{
                                name: 'pickupAddressPostalCode',
                                value: pickupAddressPostalCode,
                                onChange: this.handleChange,
                              }}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  {this.state.deliveryType !== 'ship' && (
                    <div className='fieldsRight'>
                      {this.deliveryTimesSelect(
                        'preferredDeliveryWindow',
                        'delivery',
                        'Drop Off'
                      )}
                    </div>
                  )}
                  {this.state.deliveryType !== 'ship' && (
                    <div className='fieldsRight'>
                      {this.deliveryTimesSelect(
                        'preferredPickupWindow',
                        'pickup',
                        'Pick Up'
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className='fields'>
                <StandardTextareaField
                  id='deliveryNotes'
                  meta={{}}
                  placeholder='Additional Delivery Info/Notes'
                  input={{
                    name: 'additionalDeliveryInfo',
                    value: event.additionalDeliveryInfo,
                    style: { resize: 'none' },
                    onChange: this.handleChange,
                  }}
                />
              </div>
              {this.state.deliveryType === 'default_delivery' &&
                storefrontShopSetting.shopShowItemPricing && (
                  <div className='actions' id='calculateDelivery'>
                    <a
                      className='btn'
                      onClick={this.handleCalculateDeliveryPrice}
                    >
                      Calculate Estimated Delivery
                    </a>
                    <CurrencyLabel value={event.deliveryCost} />
                  </div>
                )}
              {canSkipDelivery && (
                <div className='actions'>
                  <a className='btn outline full' onClick={makeLead}>
                    Skip this step
                  </a>
                </div>
              )}
            </div>
          }
        />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    parsedStorefrontSettings: { storefrontShopSetting },
  } = state.locations;
  const { errors } = state.dashboard;
  const { authenticated } = state.auth;
  const { customer } = state.customer;

  return { storefrontShopSetting, errors, authenticated, customer };
};

export default connect(mapStateToProps)(EventForm);
