import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import SignedInNav from './SignedInNav';
import SignedOutNav from './SignedOutNav';
import MobileSignedInNav from './MobileSignedInNav';
import MobileSignedOutNav from './MobileSignedOutNav';
import SocialBox from 'Components/home/widgets/SocialBox';
import HeaderLogo from 'Utils/images/HeaderLogo';
import Navs from 'Components/home/widgets/Navs';
import classnames from 'classnames';

class MainMenu extends React.Component {
  render() {
    const { authenticated, storeLocation, parsedStorefrontSettings, location } =
      this.props;
    const { logoPicture, mobileLogoPicture, socialShowOnHeader } =
      parsedStorefrontSettings.mainSetting;
    const { storefrontMenuItems, logoLink } =
      parsedStorefrontSettings.storefrontMenuSetting;
    const { contactPhone, contactFax, contactEmail, contactPhysicalAddress } =
      parsedStorefrontSettings.storefrontContactSetting;
    const path = location.pathname.toLowerCase();
    const tgAdmin = path.startsWith('/tg-admin');
    const { sfSubscriptionTier } = storeLocation;

    if (tgAdmin) {
      return (
        <MediaBreakpoints
          desktop={
            <header>
              <HeaderLogo
                link={logoLink}
                location={storeLocation}
                logoPicture={logoPicture}
              />
              {socialShowOnHeader && <SocialBox />}
            </header>
          }
          mobile={
            <header>
              <HeaderLogo
                link={logoLink}
                location={storeLocation}
                logoPicture={mobileLogoPicture || logoPicture}
              />
            </header>
          }
        />
      );
    } else {
      const guestCheckout =
        path === '/makeapayment' || path.startsWith('/feedbacks');

      return (
        <MediaBreakpoints
          desktop={
            <header
              className={classnames({ guestCheckoutHeader: guestCheckout })}
            >
              {authenticated ? <SignedInNav /> : <SignedOutNav />}
              {guestCheckout && (
                <div className='companyDetails'>
                  {storeLocation.phoneNumber?.office && <label>Phone</label>}
                  {storeLocation.phoneNumber?.office && (
                    <div>{storeLocation.phoneNumber.office}</div>
                  )}
                  {storeLocation.phoneNumber?.fax && <label>Fax</label>}
                  {storeLocation.phoneNumber?.fax && (
                    <div>{storeLocation.phoneNumber.fax}</div>
                  )}
                  {storeLocation.email && <label>Email</label>}
                  {storeLocation.email && <div>{storeLocation.email}</div>}
                  {storeLocation.physicalAddress && <label>Address</label>}
                  {storeLocation.physicalAddress && (
                    <div>
                      <div>{storeLocation.physicalAddress.streetAddress1}</div>
                      <div>{storeLocation.physicalAddress.streetAddress2}</div>
                      <div>
                        {storeLocation.physicalAddress.city},{' '}
                        {storeLocation.physicalAddress.locale}{' '}
                        {storeLocation.physicalAddress.postalCode} ,{' '}
                        {storeLocation.physicalAddress.country}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <HeaderLogo
                link={logoLink}
                location={storeLocation}
                logoPicture={logoPicture}
              />
              {socialShowOnHeader && <SocialBox />}
              {!guestCheckout && (
                <Navs
                  theme={parsedStorefrontSettings.mainSetting.theme}
                  className='subMenu'
                  menuItems={storefrontMenuItems}
                  sfSubscriptionTier={sfSubscriptionTier}
                  showGtl={
                    parsedStorefrontSettings.storefrontCustomPages.length > 0
                  }
                />
              )}
            </header>
          }
          mobile={
            <header>
              {authenticated ? <MobileSignedInNav /> : <MobileSignedOutNav />}
              <HeaderLogo
                link={logoLink}
                location={storeLocation}
                logoPicture={mobileLogoPicture || logoPicture}
              />
            </header>
          }
          renderAll={true}
        />
      );
    }
  }
}

MainMenu.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { authenticated } = state.auth;
  const { location, parsedStorefrontSettings } = state.locations;

  return {
    authenticated,
    parsedStorefrontSettings,
    storeLocation: location,
  };
};

export default withRouter(connect(mapStateToProps)(MainMenu));
