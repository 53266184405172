import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

const SetByTimeInfo = ({ rental, schedule }) => {
  const timeZone = schedule?.timeZone;

  return (
    <div className='fullWidth'>
      <label>Set By Time</label>
      <div>
        <FormattedDate
          month='2-digit'
          day='2-digit'
          year='2-digit'
          value={rental.setByTimeDateTime}
        />
        {'-'}
        <FormattedTime
          value={rental.setByTimeDateTime}
          timeZone={timeZone}
          timeZoneName='short'
        />
        <br></br>
        {rental.rentalSetting.showSetByTimeNotes && (
          <p>{rental.setByTimeNotes}</p>
        )}
      </div>
    </div>
  );
};

export default SetByTimeInfo;
