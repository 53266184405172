import React from "react";
import { Link } from "react-router-dom";
import ConversationCard from "./MobileConversationCard";

const MobileConversations = props => {
  const { conversations, newConversationLink } = props;
  return (
    <section className="messages">
      <Link className="btn secondary" to={newConversationLink}>
        New Message
      </Link>
      <h1>Messages</h1>
      <table className="grid">
        <thead>
          <tr>
            <th>Contact</th>
            <th className="medSm">Time</th>
          </tr>
        </thead>
        <tbody>
          {conversations.map((conversation, index) => {
            return <ConversationCard conversation={conversation} key={index} />;
          })}
        </tbody>
      </table>
    </section>
  );
};

export default MobileConversations;
