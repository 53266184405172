import axios from 'axios';
import { ROOT_URL } from 'Api';
import {
  RELOAD_INVENTORY_REQUEST,
  RELOAD_INVENTORY_SUCCESS,
  SAVE_INVENTORY_FILTER,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  SHOW_TILES,
  HIDE_TILES,
  REFRESH_CART_ITEMS,
} from 'Constants/redux';
import { apiConnections } from 'Constants/displayConstants';
import { combineDateAndTime } from 'HelperFunctions/general';
import { availabilityRequestBodyFromReloadInventoryResponse } from 'HelperFunctions/availability';
import { fetchInventoryAvailability } from 'Actions/AvailabilityActions';

export const reloadInventory = (inventoryFilter) => {
  return (dispatch, getState) => {
    const { location, preview } = getState().locations;
    const {
      query,
      numberPer,
      page,
      selectedCategories,
      selectedSubCategories,
      selectedSfCategories,
      selectedSfCategoriesData,
      selectedSfSubCategories,
      minQuantity,
      maxQuantity,
      eventStart,
      eventEnd,
      selectedColors,
    } = inventoryFilter;
    const options = {
      query,
      location_id: location.id,
      per: numberPer || 12,
      page: page,
      categories: selectedCategories ? selectedCategories.join(',') : '',
      sub_categories: selectedSubCategories
        ? selectedSubCategories.join(',')
        : '',
      sf_categories: selectedSfCategories ? selectedSfCategories.join(',') : '',
      selected_sf_categories_data: selectedSfCategoriesData
        ? selectedSfCategoriesData
        : '',
      sf_sub_categories: selectedSfSubCategories
        ? selectedSfSubCategories.join(',')
        : '',
      colors: selectedColors ? selectedColors.join(',') : '',
      min_quantity: +minQuantity === 0 ? null : minQuantity,
      max_quantity: +maxQuantity === 500 ? null : maxQuantity,
      start_date: eventStart,
      end_date: eventEnd,
      preview,
    };

    dispatch({
      type: SAVE_INVENTORY_FILTER,
      inventoryFilter,
    });

    dispatch({
      type: RELOAD_INVENTORY_REQUEST,
      inventoryType: 'items',
    });

    axios
      .get(`${ROOT_URL}/portal/inventories/paginated`, {
        params: options,
      })
      .then((response) => {
        dispatch({
          products: response.data.products,
          meta: response.data.meta,
          inventoryType: 'items',
          type: RELOAD_INVENTORY_SUCCESS,
        });

        const availabilityRequestBody =
          availabilityRequestBodyFromReloadInventoryResponse(
            response.data.products
          );

        dispatch(
          fetchInventoryAvailability({
            startDate: eventStart,
            endDate: eventEnd,
            ...availabilityRequestBody,
          })
        );
      })
      .catch((error) => {
        dispatch({
          error,
          type: FETCH_PRODUCT_FAILURE,
        });
      });
  };
};

export const checkCartItems = (inventoryFilter) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { eventStart, eventEnd, inventoryObjectIds } = inventoryFilter;
    const options = {
      location_id: location.id,
      per: 100,
      page: 0,
      start_date: eventStart,
      end_date: eventEnd,
      inventory_object_ids: inventoryObjectIds,
    };
    axios
      .get(`${ROOT_URL}/portal/inventories/paginated`, {
        params: options,
      })
      .then((response) => {
        const availabilityRequestBody =
          availabilityRequestBodyFromReloadInventoryResponse(
            response.data.products
          );

        dispatch(
          fetchInventoryAvailability({
            startDate: eventStart,
            endDate: eventEnd,
            ...availabilityRequestBody,
          })
        );

        dispatch({
          products: response.data.products,
          type: REFRESH_CART_ITEMS,
        });
      })
      .catch((error) => {
        dispatch({
          error,
          type: FETCH_PRODUCT_FAILURE,
        });
      });
  };
};

export function fetchProduct(productType, id, event) {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
    const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);

    dispatch({ type: FETCH_PRODUCT_REQUEST });
    axios
      .get(
        `${ROOT_URL}/portal/${apiConnections[productType]}/${id}?location_id=${location.id}&start_date=${eventStartDateTime}&end_date=${eventEndDateTime}`
      )
      .then((response) => {
        return dispatch({
          productType,
          id,
          response,
          type: FETCH_PRODUCT_SUCCESS,
        });
      })
      .catch((error) => {
        let errorCode = error.response ? error.response.status : 500;
        dispatch({
          id,
          error,
          errorCode,
          type: FETCH_PRODUCT_FAILURE,
        });
      });
  };
}

export function showTiles() {
  return {
    type: SHOW_TILES,
  };
}

export function hideTiles() {
  return {
    type: HIDE_TILES,
  };
}
