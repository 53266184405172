import React from 'react';
import { connect } from 'react-redux';
import Imgix from 'react-imgix';
import Accordion from '../utils/Accordion';
import PageMetaData from 'Utils/PageMetaData';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class Faq extends React.Component {
  render() {
    const { parsedStorefrontSettings, storefrontFaqSetting, themeSetting } =
      this.props;
    const { mainSetting } = this.props.parsedStorefrontSettings;

    const { heroTitle, faqTitleFontTheme, heroPicture, storefrontFaqs } =
      storefrontFaqSetting;
    const usingPrescott = mainSetting.theme === 'preScott';
    return (
      <section className='faq'>
        <PageMetaData setting={storefrontFaqSetting} />
        <figure>
          {heroPicture && (
            <Imgix
              src={heroPicture.imgixUrl}
              alt='Faq'
              width={2040}
              height={1152}
            />
          )}
        </figure>
        <h1
          style={{
            color: `${
              faqTitleFontTheme === 'dark'
                ? themeSetting.darkFontColor
                : themeSetting.lightFontColor
            }`,
          }}
        >
          {heroTitle}
        </h1>
        <div className='questions'>
          {storefrontFaqs &&
            storefrontFaqs.map((faq) => {
              if (usingPrescott) {
                return (
                  <Accordion
                    header={<div>{faq.question}</div>}
                    content={
                      <div
                        className='answer'
                        dangerouslySetInnerHTML={{
                          __html: faq.answer,
                        }}
                      />
                    }
                  />
                );
              } else {
                return (
                  <div key={faq.id}>
                    <label>Q:</label>
                    <div
                      style={{
                        color: `${
                          faq.questionFontTheme === 'dark'
                            ? themeSetting.darkFontColor
                            : themeSetting.lightFontColor
                        }`,
                      }}
                    >
                      {faq.question}
                    </div>
                    <label>A:</label>
                    <div
                      className='answer'
                      dangerouslySetInnerHTML={{
                        __html: faq.answer,
                      }}
                    />
                  </div>
                );
              }
            })}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings, location } = state.locations;
  const storefrontFaqSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  const themeSetting = parsedStorefrontSettings.mainSetting;
  return { parsedStorefrontSettings, storefrontFaqSetting, themeSetting };
};

export default connect(mapStateToProps)(Faq);
