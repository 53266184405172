import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoadingSpinner from "Utils/LoadingSpinner";
import * as actions from "Actions";
import Document from "./documents/Document";

// Fetch profile info from Redux customer, then display it.

class ProfileDocuments extends React.Component {
  componentDidMount() {
    const { fetchProfile } = this.props; // Redux
    fetchProfile();
  }

  render() {
    const { customer, deleteDocument, loading } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }
    return (
      <section className="documents">
        <div>
          <h4>Documents</h4>
          <Link className="btn pull-right" to="/profile/documents/new">
            Add New Document
          </Link>
        </div>
        <div>
          {customer.documents && (
            <table className="itemList">
              <thead>
                <th>Name</th>
                <th>Expiration Date</th>
                <th></th>
              </thead>
              <tbody>
                {customer.documents.map(doc => (
                  <Document key={doc.id} doc={doc} onDelete={deleteDocument} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { customer, errors, loading } = state.customer;
  return { customer, errors, loading };
};

export default connect(mapStateToProps, actions)(ProfileDocuments);
