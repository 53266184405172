import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Imgix from "react-imgix";

class HeroImage extends React.Component {
  render() {
    const { storefrontSetting } = this.props;
    const { homeVisible, homeCarouselItems } = storefrontSetting;

    if (homeVisible && homeCarouselItems.length > 0) {
      return (
        <figure>
          {homeCarouselItems[0].picture && (
            <Imgix
              src={homeCarouselItems[0].picture.imgixUrl}
              alt="hero"
              sizes='50vw'
            />
          )}
        </figure>
      );
    } else {
      return <figure />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const storefrontSetting = parsedStorefrontSettings.storefrontHomeSetting || {};
  return { storefrontSetting };
};

export default withRouter(connect(mapStateToProps)(HeroImage));
