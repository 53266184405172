/*
 * Takes the combined inventory request body and turns into an availability request body
 * @return [Hash] {}
 */
export const availabilityRequestBodyFromReloadInventoryResponse = (
  reloadInventoryData
) => {
  const availabilityRequestBody = reloadInventoryData.reduce((obj, item) => {
    const key = productTypeToKey[item.productType];

    if (key in obj) obj[key].push(item.id);
    else obj[key] = [item.id];

    return obj;
  }, {});

  return availabilityRequestBody;
};

// @param productType [Hash] ['items', 'addons', 'bundles']
export const getNumberAvailable = (
  availabilityStore,
  inventoryObjectId,
  productType
) => {
  if (
    checkInventoryObjectAvailabilityLoading(
      availabilityStore,
      inventoryObjectId,
      productType
    )
  )
    return 0;

  const inventoryObjectKey = productTypeToKey[productType];

  // Add safe navigation to prevent errors on initial load.
  let numberAvailable =
    availabilityStore[inventoryObjectKey]?.[inventoryObjectId]?.['availableSf'];

  return numberAvailable;
};

// @param inventoryObject [Hash] must contain productType in ['items', 'addons', 'bundles']
export const checkInventoryObjectAvailabilityLoading = (
  availabilityStore,
  inventoryObjectId,
  productType
) => {
  const inventoryObjectKey = productTypeToKey[productType];

  // Add safe navigation to prevent errors on initial load.
  return availabilityStore.loadingInventory[inventoryObjectKey]?.includes(
    inventoryObjectId
  );
};

export const productTypeToKey = {
  items: 'products',
  addons: 'addOns',
  bundles: 'bundles',
};
