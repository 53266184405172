import React from "react";
import CurrencyLabel from "Utils/CurrencyLabel";
import { deliveryTypes } from "Constants/displayConstants";
import SubChangeRequestStatus from "./SubChangeRequestStatus";
import { capitalizeFirstLetter } from "HelperFunctions/general";

function renderChange(rental) {
  const {
    deliveryType,
    deliveryAddressLocationName,
    deliveryAddressStreetAddress1,
    deliveryAddressStreetAddress2,
    deliveryAddressCity,
    deliveryAddressLocale,
    deliveryAddressPostalCode,
    deliveryAddressCountry,
    deliveryCost,
    preferredDeliveryWindow,
    preferredPickupWindow
  } = rental;
  
  return (
    <div>
      <div>{deliveryTypes[deliveryType]}</div><br/>
      { deliveryType !== "customer_pick_up" ?
      (<div>
        <b>Delivery Address:</b><br/> {deliveryAddressLocationName && `${deliveryAddressLocationName},`}{" "}
        {deliveryAddressStreetAddress1}
        {deliveryAddressStreetAddress2 && (
          <div># {deliveryAddressStreetAddress2}</div>
        )}
        <div>
          {deliveryAddressCity && `${deliveryAddressCity},`}{" "}
          {deliveryAddressLocale}{" "}
          {deliveryAddressPostalCode && `${deliveryAddressPostalCode},`}{" "}
          {deliveryAddressCountry}
        </div> <br/>

        <div>
          <b>Preferred Delivery Window:</b><br/>
          {capitalizeFirstLetter(preferredDeliveryWindow) || 'Early'}
        </div> <br/>

        <div>
          <b>Preferred Pick Up Window:</b><br/>
          {capitalizeFirstLetter(preferredPickupWindow) || 'Early'}
        </div><br/>

      </div>) : null}
      <div>
        <b>Delivery Cost:</b> <CurrencyLabel value={deliveryCost || 0} />
      </div>

      <div></div>
    </div>
  );
}

export default function deliveryChange(changeRequest) {
  const { subChangeRequests } = changeRequest;
  const subChangeRequest = subChangeRequests && subChangeRequests.filter( x => x.keyName === 'delivery_data' )[0];

  if (!subChangeRequest) {
    return null;
  }

  const { beforeAttributes, afterAttributes } = subChangeRequest;

  return (
    <div className="itemChanges">
      <h4>Delivery</h4>
      {renderChange(beforeAttributes)}
      {renderChange(afterAttributes)}
      {changeRequest.status !== 'pending' &&
        <div>
          <SubChangeRequestStatus subChangeRequest={subChangeRequest} />
        </div>}
    </div>
  );
}
