import React from 'react';
import classnames from 'classnames';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import RentalItem from './RentalItem';
import RentalBundle from './RentalBundle';
import RentalStaff from './RentalStaff';
import { connect } from 'react-redux';
import {
  extractFlatFeeFromSelectedPrice,
  extractFeeFromSelectedPrice,
  renderSubTotals,
} from 'HelperFunctions/rentals';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { shouldHidePrice } from 'HelperFunctions/HidePricing';

class RentalSection extends React.Component {
  isFlatPriceInventory = (inventoryItem) => {
    return (
      inventoryItem &&
      inventoryItem.period &&
      inventoryItem.period.includes('flat_price')
    );
  };
  calculateSubTotal = () => {
    const {
      section,
      rental,
      location: { displayDiscount },
    } = this.props;
    const { sectionMemberships } = section;
    const subTotal = sectionMemberships
      .filter(
        (sectionMembership) =>
          sectionMembership.sectionMembershipType !== 'blank' &&
          sectionMembership._destroy !== '1' &&
          sectionMembership.rentalInventory
      )
      .reduce((sum, sectionMembership) => {
        if (sectionMembership.parentConnectionType === 'duplicate') {
          return (sum = sum + 0);
        } else if (sectionMembership.rentalInventory.productType === 'staffs') {
          return (sum = sum + +sectionMembership.rentalInventory.cost);
        } else if (
          sectionMembership.rentalInventory.productType === 'bundles'
        ) {
          if (
            !displayDiscount &&
            rental.rentalSetting.discountSetting === "hideDiscounts"
          ) {
            return (sum =
              sum + +sectionMembership.rentalInventory.selectedPrice);
          } else {
            return (sum =
              sum + +sectionMembership.rentalInventory.discountedPrice);
          }
        } else {
          if (
            !displayDiscount &&
            rental.rentalSetting.discountSetting === 'hideDiscounts'
          ) {
            return rental.rentalItems.reduce(
              (sum, rentalItem) =>
                (sum += +rentalItem.selectedPriceBeforeDiscount),
              0
            );
          } else {
            return rental.rentalItems.reduce(
              (sum, rentalItem) =>
                (sum += parseFloat(rentalItem.selectedPrice)),
              0
            );
          }
        }
      }, 0);
    return subTotal;
  };
  render() {
    const {
      rental,
      section,
      approvalStatus,
      showDiscountsOnPricing,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      showSubtotalsInSections,
      tileView,
      storefrontShopSetting,
    } = this.props;

    const { sectionMemberships } = section;
    const hideSubtotalPricing = shouldHidePrice(
      rental.approvalStatus,
      storefrontShopSetting
    );

    return (
      <div>
        <h4>{section.name}</h4>
        <table
          className={classnames({
            grid: true,
            thumbnail: tileView,
          })}
        >
          <MediaBreakpoints
            desktop={
              <thead>
                <tr>
                  <th colSpan={tileView && showItemImages ? '2' : '1'}>Item</th>
                  <th className='sm right'>Quantity</th>
                  {approvalStatus === 'approved' &&
                    showUnitPricingToCustomers &&
                    storefrontShopSetting.shopShowItemPricing && (
                      <th className='medSm right'>Price 2</th>
                    )}
                  <th className='exSm right'>Fee</th>
                  <th className='medSm right'>SubTotal</th>
                </tr>
              </thead>
            }
            mobile={
              <thead>
                <tr>
                  <th
                    className='title'
                    colSpan={showUnitPricingToCustomers ? '3' : '2'}
                  >
                    {section.name}
                  </th>
                </tr>
                <tr>
                  <th className='sm'>Quantity</th>
                  {showUnitPricingToCustomers && (
                    <th className='right'>Price 2</th>
                  )}
                  <th className='exSm right'>Fee</th>
                  <th className='medSm right'>SubTotal</th>
                </tr>
              </thead>
            }
          />
          {sectionMemberships.map((sectionMembership, index) => {
            const item = sectionMembership.rentalInventory;
            if (!item) return;
            const inventoryType = item.productType;
            const { parentConnectionType } = sectionMembership;

            if (inventoryType === 'items' || inventoryType === 'addons') {
              return (
                <RentalItem
                  key={index}
                  approvalStatus={approvalStatus}
                  showDiscountsOnPricing={showDiscountsOnPricing}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  sectionMembership={sectionMembership}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  parentConnectionType={parentConnectionType}
                  rentalItem={item}
                  tileView={tileView}
                  rental={rental}
                />
              );
            } else if (inventoryType === 'bundles') {
              return (
                <RentalBundle
                  approvalStatus={approvalStatus}
                  showDiscountsOnPricing={showDiscountsOnPricing}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  rentalBundle={item}
                  key={index}
                  tileView={tileView}
                  rental={rental}
                />
              );
            } else if (inventoryType === 'staffs') {
              return (
                <RentalStaff
                  key={index}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  item={item}
                  tileView={tileView}
                  rental={rental}
                />
              );
            } else {
              return null;
            }
          })}
        </table>
        {!hideSubtotalPricing && showSubtotalsInSections && (
          <div className='subtotal'>
            <strong>SubTotal</strong>
            <CurrencyLabel value={this.calculateSubTotal()} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { parsedStorefrontSettings, location } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;

  return { storefrontShopSetting, location };
};
export default connect(mapStateToProps)(RentalSection);
