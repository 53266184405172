import React from 'react';
import { FormattedNumber } from 'react-intl';
import { calculateBillingFields } from 'HelperFunctions/Billing';
import CurrencyLabel from 'Utils/CurrencyLabel';

const RentalBilling = (props) => {
  const { rental, billing, hideSubtotalPricing, location, billingLines } =
    props;

  let creditCardFees = [];
  let standardFees = [];
  rental.feeRentalRelationships.forEach((fee) => {
    if (fee.feeType === 'credit_card_fee') {
      creditCardFees.push(fee);
    } else if (fee.feeTargetType === 'global') {
      // Only Push Global Fees, Item Specific will be handled seperately
      standardFees.push(fee);
    }
  });
  const hasPriceAvailableOnRequestOnItem = rental.rentalItems.some(
    (ri) => +ri.selectedPrice === 0
  );
  const hasPriceAvailableOnRequestOnAddOn = rental.rentalAddOns.some(
    (ri) => +ri.selectedPrice === 0
  );
  const hasPriceAvailableOnRequestOnBundle = rental.rentalBundles.some(
    (ri) => +ri.selectedPrice === 0
  );
  const hasPriceAvailableOnRequest =
    (hasPriceAvailableOnRequestOnItem ||
      hasPriceAvailableOnRequestOnAddOn ||
      hasPriceAvailableOnRequestOnBundle) &&
    rental.approvalStatus === 'pending';
  const rentalItemTotalBeforeDiscount = rental.rentalItems.reduce(
    (sum, rentalItem) =>
      (sum += parseFloat(rentalItem.selectedPriceBeforeDiscount)),
    0
  );
  // const itemDiscountsTotal =
  //   rentalItemTotalBeforeDiscount - billing.rental_item_total;
  const { total, overallTotal } = calculateBillingFields(rental);
  const inventoryDiscounts = rental.inventoryDiscounts;

  // Comment out below code for reference instead of delete it.

  // const itemDiscounts = rental.rentalItems.reduce((discounts, rentalItem) => {
  //   let newDiscounts = { ...discounts };
  //   const flat_pricing =
  //     ["flat_price", "flat_unit_price", "edited_flat_price"].indexOf(
  //       rentalItem.period
  //     ) >= 0;
  //   rentalItem.rentalItemDiscountRelationships.forEach((ridr) => {
  //     let discountedAmount;
  //     if (ridr.valueType === "amount") {
  //       discountedAmount =
  //         ridr.amount * (flat_pricing ? 1 : rentalItem.quantity);
  //     } else {
  //       discountedAmount =
  //         ridr.amount * +rentalItem.selectedPriceBeforeDiscount;
  //     }
  //     if (newDiscounts.hasOwnProperty(ridr.discountId)) {
  //       newDiscounts[ridr.discountId].discountedAmount += discountedAmount;
  //     } else {
  //       newDiscounts[ridr.discountId] = {
  //         name: ridr.name,
  //         discountedAmount: discountedAmount
  //       };
  //     }
  //   });
  //   return newDiscounts;
  // }, {});

  const itemFees = rental.rentalItems.reduce((fees, rentalItem) => {
    let newFees = { ...fees };
    const flat_pricing =
      ['flat_price', 'flat_unit_price', 'edited_flat_price'].indexOf(
        rentalItem.period
      ) >= 0;
    rentalItem.rentalItemFeeRelationships.forEach((rifr) => {
      let feeAmount;
      if (rifr.valueType === 'amount') {
        feeAmount = rifr.amount * (flat_pricing ? 1 : rentalItem.quantity);
      } else {
        feeAmount =
          Math.round(
            rifr.amount * +rentalItem.selectedPriceBeforeDiscount * 100
          ) / 100;
      }
      if (newFees.hasOwnProperty(rifr.feeId)) {
        newFees[rifr.feeId].feeAmount += feeAmount;
      } else {
        newFees[rifr.feeId] = {
          name: rifr.name,
          feeAmount: feeAmount,
        };
      }
    });
    return newFees;
  }, {});

  const salesTaxes = billingLines?.filter(
    (line) => line.kind === 'salesTaxesLine'
  );

  return (
    <section className='billing'>
      <h4>Billing</h4>
      <div className='billingDetails'>
        {rental.rentalItems.length > 0 && (
          <div>
            <label>Item(s) Total</label>
            {hideSubtotalPricing ? null : hasPriceAvailableOnRequestOnItem &&
              hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={rentalItemTotalBeforeDiscount} />
            )}
          </div>
        )}
        {rental.rentalAddOns.length > 0 && (
          <div>
            <label>Purchase(s) Total</label>
            {hideSubtotalPricing ? null : hasPriceAvailableOnRequestOnAddOn &&
              hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={billing.rental_add_on_total} />
            )}
          </div>
        )}

        {rental.rentalBundles.length > 0 && (
          <div>
            <label>Bundle(s) Total</label>
            {hideSubtotalPricing ? null : hasPriceAvailableOnRequestOnBundle &&
              hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={billing.rental_bundle_total} />
            )}
          </div>
        )}

        {billing.staffing_total > 0 && (
          <div>
            <label>Staffing Total</label>
            {!hideSubtotalPricing && (
              <CurrencyLabel value={billing.staffing_total} />
            )}
          </div>
        )}

        {billing.total_delivery_cost > 0 && (
          <div>
            <label>Delivery</label>
            {!hideSubtotalPricing && (
              <CurrencyLabel value={billing.total_delivery_cost} />
            )}
          </div>
        )}
        {rental.damageWaiverFeeTotal > 0 && (
          <div>
            <label>Damage Waiver</label>
            {hideSubtotalPricing ? null : hasPriceAvailableOnRequest &&
              hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={rental.damageWaiverFeeTotal} />
            )}
          </div>
        )}
        {!hideSubtotalPricing &&
          Object.keys(rental.itemFees).map((feeId) => (
            <div key={feeId}>
              <label>{rental.itemFees[feeId].name}</label>
              <CurrencyLabel value={rental.itemFees[feeId].feeAmount} />
            </div>
          ))}
        {!hideSubtotalPricing &&
          standardFees.map((fee, i) => {
            return (
              <div key={i}>
                <label>
                  {fee.name}{' '}
                  {fee.valueType === 'percentage' && (
                    <span>
                      (
                      <FormattedNumber
                        value={fee.amount}
                        // eslint-disable-next-line react/style-prop-object
                        style='percent'
                        minimumFractionDigits={2}
                        minimumSignificantDigits={3}
                      />
                      )
                    </span>
                  )}
                </label>
                {fee.valueType === 'percentage' ? (
                  <CurrencyLabel value={fee.amount * total} />
                ) : (
                  <CurrencyLabel value={fee.amount} />
                )}
              </div>
            );
          })}
        {/* {itemDiscountsTotal > 0 && (
          <div>
            <label>Item Specific Discounts Total</label>
            <CurrencyLabel value={-itemDiscountsTotal} />
          </div> */}
        {/* )} */}
        {!hideSubtotalPricing &&
          rental.discounts.map((discount, i) => {
            if (discount.discountTargetType === 'global') {
              return (
                <div key={i}>
                  <label>
                    {discount.name}
                    {discount.valueType === 'percentage' && (
                      <span>
                        {' ('}
                        <FormattedNumber
                          value={discount.amount}
                          // eslint-disable-next-line react/style-prop-object
                          style='percent'
                          minimumFractionDigits={2}
                          minimumSignificantDigits={3}
                        />
                        {')'}
                      </span>
                    )}
                  </label>
                  {discount.valueType === 'percentage' ? (
                    parseFloat(discount.amount) === 1 ? (
                      <CurrencyLabel value={-discount.amount * overallTotal} />
                    ) : (
                      <CurrencyLabel value={-discount.amount * total} />
                    )
                  ) : (
                    <CurrencyLabel value={-discount.amount} />
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        {!hideSubtotalPricing &&
          inventoryDiscounts &&
          Object.keys(inventoryDiscounts).map((discountId) => (
            <div>
              <label>{inventoryDiscounts[discountId].name}</label>
              <CurrencyLabel
                value={-inventoryDiscounts[discountId].discountedAmount}
              />
            </div>
          ))}

        <div className='subtotal'>
          <label>Subtotal</label>
          {hideSubtotalPricing ? null : hasPriceAvailableOnRequest &&
            hasPriceAvailableOnRequest ? (
            <span>Price On Request</span>
          ) : (
            <CurrencyLabel
              value={rental.subTotal - rental.creditCardFeeTotal}
            />
          )}
        </div>
        {location && location.taxBreakdown && rental && (
          <div className='subtotal'>
            <label>Tax</label>
            <CurrencyLabel value={rental.taxTotal} />
          </div>
        )}
        {location && location.taxBreakdown ? (
          rental &&
          salesTaxes &&
          salesTaxes.map((tax) => {
            if (tax.taxPercent > 0) {
              return (
                <div className='subTaxes'>
                  <label>
                    {tax.shortName} {' ('}
                    <FormattedNumber
                      value={tax.taxPercent}
                      style='percent'
                      maximumFractionDigits={2}
                    />
                    {')'}
                    {rental.rentalVersioningEnabled &&
                      tax.createdFromOverride && <p>{tax.appliesTo}</p>}
                  </label>
                  <span>
                    {hideSubtotalPricing ? null : hasPriceAvailableOnRequest ? (
                      <span>Price On Request</span>
                    ) : (
                      <span>
                        <CurrencyLabel value={tax.finalValue} />
                      </span>
                    )}
                  </span>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div>
            <label>Tax</label>
            <span>
              {rental.taxExempt && (
                <label className='caption'>(Tax Exempt)</label>
              )}

              {hideSubtotalPricing ? null : hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={billing.tax_total} />
              )}
            </span>
          </div>
        )}
        {!hideSubtotalPricing &&
          creditCardFees.map((fee, i) => {
            return (
              <div key={i}>
                <label>
                  {fee.name}
                  {fee.valueType === 'percentage' && (
                    <span>
                      (
                      <FormattedNumber
                        value={fee.amount}
                        // eslint-disable-next-line react/style-prop-object
                        style='percent'
                        minimumFractionDigits={2}
                        minimumSignificantDigits={3}
                      />
                      )
                    </span>
                  )}
                </label>
                {fee.valueType === 'percentage' ? (
                  <CurrencyLabel value={fee.amount * total} />
                ) : (
                  <CurrencyLabel value={fee.amount} />
                )}
              </div>
            );
          })}
        <div>
          <label>Additional Taxes</label>
          <span>
            {hideSubtotalPricing ? null : hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={billing.total_custom_tax} />
            )}
          </span>
        </div>

        {!hideSubtotalPricing && rental.tapgoodsFeeTotal > 0 && (
          <div>
            <label>
              TapGoods Processing Fee{' '}
              <span>
                (
                <FormattedNumber
                  value={rental.storefrontPercent}
                  // eslint-disable-next-line react/style-prop-object
                  style='percent'
                  minimumFractionDigits={2}
                  minimumSignificantDigits={3}
                />
                )
              </span>
            </label>
            <span>
              <CurrencyLabel value={rental.tapgoodsFeeTotal} />
            </span>
          </div>
        )}
        <div className='total'>
          <label>Rental Total</label>
          {hideSubtotalPricing ? null : hasPriceAvailableOnRequest ? (
            <span>Price On Request</span>
          ) : (
            <CurrencyLabel value={billing.overall_total} />
          )}
        </div>
      </div>
    </section>
  );
};

export default RentalBilling;
