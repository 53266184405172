export * from './ServiceAreaConstants';
export * from './CategoryListConstants';
export * from './ColorListConstants';
export * from './ConversationListConstants';
export * from './ConversationConstants';
export * from './MessagableConstants';
export * from './RentalListConstants';
export * from './DashboardConstants';
export * from './UserConstants';
export * from './EventConstants';
export * from './CartConstants';
export * from './AuthConstants';
export * from './ProductConstants';
export * from './LocationConstants';
export * from './CustomPages';
export * from './AvailabilityConstants';

export const OPEN_SUCCESS_SNACKBAR = 'OPEN_SUCCESS_SNACKBAR';
export const CLOSE_SUCCESS_SNACKBAR = 'CLOSE_SUCCESS_SNACKBAR';
export const SET_OPEN_VALIDATION_SNACKBAR = 'SET_OPEN_VALIDATION_SNACKBAR';
export const SET_CLOSED_VALIDATION_SNACKBAR = 'SET_CLOSED_VALIDATION_SNACKBAR';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_CUSTOMER_QUERY = 'SET_CUSTOMER_QUERY';
export const OPEN_LOADING_SPINNER = 'OPEN_LOADING_SPINNER';
export const CLOSE_LOADING_SPINNER = 'CLOSE_LOADING_SPINNER';
export const FIELD_CHANGED = 'field_changed';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const CREATE_USER_FAIL = 'create_user_fail';
export const LOGIN_USER = 'login_user';
export const ADMIN_FIELD_CHANGED = 'admin_field_changed';
export const LOGIN_ADMIN_SUCCESS = 'login_admin_success';
export const LOGIN_ADMIN_FAIL = 'login_admin_fail';
export const CREATE_ADMIN_FAIL = 'create_admin_fail';
export const LOGIN_ADMIN = 'login_admin';
export const SIGN_OUT_USER_SUCCESS = 'sign_out_user_success';
export const SIGN_OUT_USER_FAIL = 'sign_out_user_fail';
export const SIGN_OUT_USER = 'sign_out_user';
export const SIGN_OUT_ADMIN_SUCCESS = 'sign_out_admin_success';
export const SIGN_OUT_ADMIN_FAIL = 'sign_out_admin_fail';
export const SIGN_OUT_ADMIN = 'sign_out_admin';
export const ADMIN_LOGIN_USER = 'ADMIN_LOGIN_USER';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const VALIDATE_USER = 'VALIDATE_USER';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
export const VALIDATE_USER_FAIL = 'VALIDATE_USER_FAIL';
export const VALIDATE_ADMIN = 'VALIDATE_ADMIN';
export const VALIDATE_ADMIN_SUCCESS = 'VALIDATE_ADMIN_SUCCESS';
export const VALIDATE_ADMIN_FAIL = 'VALIDATE_ADMIN_FAIL';
export const PROFILE_IMAGE_CHANGED = 'PROFILE_IMAGE_CHANGED';
export const OPEN_EDIT_PROFILE = 'OPEN_EDIT_PROFILE';
export const CLOSE_EDIT_PROFILE = 'CLOSE_EDIT_PROFILE';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';
export const FETCH_RENTAL_REQUEST = 'FETCH_RENTAL_REQUEST';
export const FETCH_RENTAL_SUCCESS = 'FETCH_RENTAL_SUCCESS';
export const FETCH_RENTAL_FAILURE = 'FETCH_RENTAL_FAILURE';
export const FETCH_RENTALS_REQUEST = 'FETCH_RENTALS_REQUEST';
export const FETCH_RENTALS_SUCCESS = 'FETCH_RENTALS_SUCCESS';
export const FETCH_RENTALS_FAILURE = 'FETCH_RENTALS_FAILURE';
export const UPDATE_RENTAL_REQUEST = 'UPDATE_RENTAL_REQUEST';
export const UPDATE_RENTAL_SUCCESS = 'UPDATE_RENTAL_SUCCESS';
export const UPDATE_RENTAL_FAILURE = 'UPDATE_RENTAL_FAILURE';
export const FETCH_BUSINESS_REQUEST = 'FETCH_BUSINESS_REQUEST';
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_FAILURE = 'FETCH_BUSINESS_FAILURE';
export const FETCH_BUSINESS_PICTURE_REQUEST = 'FETCH_BUSINESS_PICTURE_REQUEST';
export const FETCH_BUSINESS_PICTURE_SUCCESS = 'FETCH_BUSINESS_PICTURE_SUCCESS';
export const FETCH_BUSINESS_PICTURE_FAILURE = 'FETCH_BUSINESS_PICTURE_FAILURE';
export const FETCH_BUSINESSES_REQUEST = 'FETCH_BUSINESSES_REQUEST';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAILURE = 'FETCH_BUSINESSES_FAILURE';
export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';
export const FETCH_CONVERSATION_LIST_REQUEST =
  'FETCH_CONVERSATION_LIST_REQUEST';
export const FETCH_CONVERSATION_LIST_FAILURE =
  'FETCH_CONVERSATION_LIST_FAILURE';
export const FETCH_CONVERSATION_LIST_SUCCESS =
  'FETCH_CONVERSATION_LIST_SUCCESS';
export const FETCH_CONVERSATION_REQUEST = 'FETCH_CONVERSATION_REQUEST';
export const FETCH_CONVERSATION_FAILURE = 'FETCH_CONVERSATION_FAILURE';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST';
export const CREATE_CONVERSATION_FAILURE = 'CREATE_CONVERSATION_FAILURE';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';
export const READ_CONVERSATION_REQUEST = 'READ_CONVERSATION_REQUEST';
export const READ_CONVERSATION_FAILURE = 'READ_CONVERSATION_FAILURE';
export const READ_CONVERSATION_SUCCESS = 'READ_CONVERSATION_SUCCESS';
export const SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION';
export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const FETCH_CALENDAR_REQUEST = 'FETCH_CALENDAR_REQUEST';
export const FETCH_CALENDAR_SUCCESS = 'FETCH_CALENDAR_SUCCESS';
export const FETCH_CALENDAR_FAILURE = 'FETCH_CALENDAR_FAILURE';
export const REQUEST_MESSAGE_ALERTS = 'REQUEST_MESSAGE_ALERTS';
export const RECEIVE_MESSAGE_ALERTS = 'RECEIVE_MESSAGE_ALERTS';
export const BULK_READ_MESSAGE_ALERTS = 'BULK_READ_MESSAGE_ALERTS';
export const UPDATE_GLOBAL_CLASS = 'UPDATE_GLOBAL_CLASS';

// Guest Checkout
export const FETCH_GUEST_DATA_REQUEST = 'FETCH_GUEST_DATA_REQUEST';
export const FETCH_GUEST_DATA_SUCCESS = 'FETCH_GUEST_DATA_SUCCESS';
export const FETCH_GUEST_DATA_FAILURE = 'FETCH_GUEST_DATA_FAILURE';
export const CHANGE_STEP = 'CHANGE_STEP';
export const CHANGE_SIGNING = 'CHANGE_SIGNING';
export const RECEIVE_GUEST_RENTAL = 'RECEIVE_GUEST_RENTAL';

// Admin
export const FETCH_ADMIN_CUSTOMERS_REQUEST = 'FETCH_ADMIN_CUSTOMERS_REQUEST';
export const FETCH_ADMIN_CUSTOMERS_SUCCESS = 'FETCH_ADMIN_CUSTOMERS_SUCCESS';
export const FETCH_ADMIN_CUSTOMERS_FAILURE = 'FETCH_ADMIN_CUSTOMERS_FAILURE';
export const FETCH_ADMIN_CUSTOMER_NAMES_REQUEST =
  'FETCH_ADMIN_CUSTOMER_NAMES_REQUEST';
export const FETCH_ADMIN_CUSTOMER_NAMES_SUCCESS =
  'FETCH_ADMIN_CUSTOMER_NAMES_SUCCESS';
export const ADD_ADMIN_CUSTOMER_NAMES_REQUEST =
  'ADD_ADMIN_CUSTOMER_NAMES_REQUEST';
export const ADD_ADMIN_CUSTOMER_NAMES_SUCCESS =
  'ADD_ADMIN_CUSTOMER_NAMES_SUCCESS';
export const SET_ADMIN_CUSTOMER_QUERY = 'SET_ADMIN_CUSTOMER_QUERY';

// Search Customer Names
export const SEARCH_CUSTOMER_NAMES = 'SEARCH_CUSTOMER_NAMES';
export const SEARCH_CANCELLED = 'SEARCH_CANCELLED';
