import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { Alert, Burst } from 'Utils/SvgIcons';
import CurrencyLabel from 'Utils/CurrencyLabel';
import converter from 'json-style-converter/es5';
import axios from 'axios';
import classnames from 'classnames';
import { getNumberAvailable } from 'HelperFunctions/availability';

const displayDeliveryType = {
  customer_pick_up: 'pick-up',
  default_delivery: 'delivery',
};

class CartBilling extends React.Component {
  state = {
    salesTaxes: [],
  };

  componentDidMount() {
    this.getSalesTaxes();
    const { step, setCartProps } = this.props;

    if (step < 3) {
      setCartProps({ rentalTotalCalculator: null });
    }
  }

  getSalesTaxes = () => {
    const { location } = this.props;

    if (location.salesTaxType === 'manual') {
      this.setState({
        salesTaxes: [
          {
            taxType: 'state',
            name: 'Manual',
            taxPercent: location.customSalesTaxPercent,
          },
        ],
      });
    } else {
      const {
        streetAddress1,
        streetAddress2,
        city,
        locale,
        postalCode,
        country,
      } = location.physicalAddress;
      const address = converter.camelToSnakeCase({
        streetAddress1,
        streetAddress2,
        city,
        locale,
        postalCode,
        country,
      });
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/tax_details_for_portal`,
          {
            params: {
              address: address,
              location_id: location.id,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            const { state_tax, district_tax, county_tax, city_tax } =
              response.data;
            const salesTaxes = [
              {
                taxType: 'state',
                name: state_tax.name,
                taxPercent: state_tax.tax_percent,
              },
              {
                taxType: 'city',
                name: city_tax.name,
                taxPercent: city_tax.tax_percent,
              },
              {
                taxType: 'county',
                name: county_tax.name,
                taxPercent: county_tax.tax_percent,
              },
              {
                taxType: 'district',
                name: district_tax.name,
                taxPercent: district_tax.tax_percent,
              },
            ];
            this.setState({ salesTaxes });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  renderTotalLabel(step) {
    switch (step) {
      case 1:
        return 'Subtotal';
      case 2:
        return 'Running Subtotal';
      case 3:
        return 'Total';
      default:
        return 'Subtotal';
    }
  }

  renderBilling = () => {
    const {
      step,
      event,
      location,
      storefrontShopSetting,
      availability,
      rental,
    } = this.props;
    const { salesTaxes } = this.state;

    let eventTotal;
    let taxTotal;
    let deliveryCost;
    let damageWaiverFee;
    let rentalInventoryTotal;
    let subTotal;
    let processingFee;

    const rentalInventory = event.items
      .concat(event.rentalBundles, event.addOns)
      .filter((item) => item._destroy !== '1');
    rentalInventoryTotal = rentalInventory.reduce(
      (total, item_container) =>
        total + parseFloat(item_container.selectedPrice),
      0
    );

    if (event.rentalTotalCalculator) {
      subTotal = parseFloat(event.rentalTotalCalculator.subTotal);
      taxTotal = parseFloat(event.rentalTotalCalculator.taxTotal);
      eventTotal = parseFloat(event.rentalTotalCalculator.overallTotal);
      damageWaiverFee = parseFloat(
        event.rentalTotalCalculator.damageWaiverTotalForTax
      );
      deliveryCost = parseFloat(event.rentalTotalCalculator.totalDeliveryCost);
      processingFee = parseFloat(event.rentalTotalCalculator.tapgoodsFeeTotal);
    } else {
      damageWaiverFee = parseFloat(event.damageWaiverFeeTotal || 0);
      deliveryCost = parseFloat(event.deliveryCost || 0);
      subTotal = rentalInventoryTotal + damageWaiverFee;
      if (step < 4) subTotal += deliveryCost;
      taxTotal = parseFloat(event.taxTotal);
      eventTotal = subTotal + taxTotal;
    }

    const hasPriceAvailableOnRequest = rentalInventory.some(
      (item_container) =>
        item_container.selectedPrice === 0 ||
        item_container.selectedPrice === 'N/A'
    );

    const minimumItemTotal =
      event.deliveryType === 'customer_pick_up'
        ? location.minimumItemTotalForPickup
        : location.minimumItemTotalForDelivery;
    const hasMinimumItemTotalAlert =
      !hasPriceAvailableOnRequest &&
      event.deliveryType !== 'none' &&
      rentalInventoryTotal < minimumItemTotal;

    const canAutoBook = event.items.every((item) => {
      const numberAvailable = getNumberAvailable(
        availability,
        item.product.id,
        'items'
      );
      return numberAvailable > item.quantity;
    });

    const showPrice =
      event.items.filter((item) => !item.product.showPriceStorefront).length ===
      0;

    if (storefrontShopSetting.shopShowItemPricing && showPrice) {
      return (
        <div className='billingSummary'>
          <h4>Summary</h4>
          <div className='billingDetails'>
            <div>
              <label className='labelPrimary'>Items Subtotal</label>
              {hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={rentalInventoryTotal} />
              )}
            </div>
            <div>
              <label className='labelPrimary'>Damage Waiver Fee</label>
              {hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={damageWaiverFee} />
              )}
            </div>
            <div className='subtotal'>
              <label>Tax</label>
              {step < 3 ? <span>TBD</span> : <CurrencyLabel value={taxTotal} />}
            </div>
            {step >= 3 && processingFee && (
              <div>
                <label className='labelPrimary'>Processing fee</label>
                <CurrencyLabel value={processingFee} />
              </div>
            )}
            <div>
              <label className='labelPrimary'>Estimated Delivery</label>
              {step < 3 ? (
                <span>TBD</span>
              ) : (
                <CurrencyLabel value={deliveryCost} />
              )}
            </div>
            <div className='subtotal'>
              <label className='labelPrimary'>
                {this.renderTotalLabel(step)}
              </label>
              {showPrice ? (
                hasPriceAvailableOnRequest ? (
                  <span>Price On Request</span>
                ) : step < 3 ? (
                  <CurrencyLabel value={subTotal} />
                ) : (
                  <CurrencyLabel value={eventTotal} />
                )
              ) : (
                <span>TBD</span>
              )}
            </div>
            {hasMinimumItemTotalAlert && (
              <div className='alert'>
                <Alert />
                <p>
                  Your order does not meet the minimum purchase amount for{' '}
                  {displayDeliveryType[event.deliveryType]}. (
                  <CurrencyLabel value={minimumItemTotal} /> min.)
                </p>
              </div>
            )}
          </div>
          <div className='notes'>
            <h4>Notes</h4>
            <ul className='bullets'>
              {step < 3 && (
                <li>Tax and Delivery are dependent on Order Info in Step 2.</li>
              )}
              <li>
                Price is subject to change based on delivery costs, other fees,
                and taxes.
              </li>
              {location.shouldAllowAutoBook && !canAutoBook && (
                <li>
                  One or more of your items are unavailable for autobooking. You
                  are still able to submit a quote.
                </li>
              )}
              <li>
                Order Dates are the dates you need the rentals. Delivery dates
                will be set up after your order is created.
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className='billingSummary'>
          <h4>Summary</h4>
          <div className='billingDetails'>
            <div>
              <label className='labelPrimary'>Items Subtotal</label>
              <span>TBD</span>
            </div>
            <div>
              <label className='labelPrimary'>Damage Waiver Fee</label>
              <span>TBD</span>
            </div>
            <div>
              <label className='labelPrimary'>Tax</label>
              <span>TBD</span>
            </div>

            <div>
              <label className='labelPrimary'>Estimated Delivery</label>
              <span>TBD</span>
            </div>
            <div className='subtotal'>
              <label className='labelPrimary'>
                {this.renderTotalLabel(step)}
              </label>
              <span>TBD</span>
            </div>
          </div>
          <div className='notes'>
            <h4>Notes</h4>
            <ul className='bullets'>
              {step < 3 && (
                <li>Tax and Delivery are dependent on Order Info in Step 2.</li>
              )}
              <li>
                Price is subject to change based on delivery costs, other fees,
                and taxes.
              </li>
              {location.shouldAllowAutoBook && !canAutoBook && (
                <li>
                  One or more of the items in your rental require approval
                  before you can reserve them. Your quote will be sent for
                  approval when you submit.
                </li>
              )}
              <li>
                Order Dates are the dates you need the rentals. Delivery dates
                will be set up after your order is created.
              </li>
            </ul>
          </div>
        </div>
      );
    }
  };

  render() {
    const { step, newEvent, rental } = this.props;

    return (
      <div
        className={classnames({
          billing: true,
          billingComplete: step === 4,
        })}
      >
        {step === 4 ? (
          !newEvent.name ? (
            <div className='complete'>
              <Burst />
              <p>
                Thank you for your interest in ordering from{' '}
                {this.props.business.name}. {this.props.business.name} will
                reach out for more details to continue the process. In the
                meantime you can keep shopping, as you will receive a
                confirmation email with all of the details of your request.
              </p>
            </div>
          ) : (
            <div className='complete'>
              <h4 id='summarySuccess'>Success!</h4>
              <hr></hr>
              {location.shouldAllowAutoBook ||
              (rental && rental.status === 'reservation') ? (
                <p>We received your order! (#{newEvent.token})</p>
              ) : (
                <p>We received your quote! (#{newEvent.token})</p>
              )}
              <p>
                You should get a confirmation email with all the details, or you
                can manage it{' '}
                <Link className='rentalLink' to={`/events/${newEvent.id}`}>
                  here
                </Link>
                .
              </p>
            </div>
          )
        ) : (
          ''
        )}

        {step < 4 && this.renderBilling()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  const { event, newEvent } = state.cart;
  const { availability } = state;
  const rental = state.rental.rental;
  return {
    location,
    event,
    newEvent,
    storefrontShopSetting,
    availability,
    rental,
  };
};

export default withRouter(connect(mapStateToProps, actions)(CartBilling));
