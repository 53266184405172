import { combineDateAndTime } from 'HelperFunctions/general';

export const getEventDates = (eventProps, queryParams) => {
  const { eventStart: eventStartQuery, eventEnd: eventEndQuery } = queryParams;

  let { event: { eventStart, eventStartTime, eventEnd, eventEndTime } = {} } =
    eventProps;

  if (eventStartQuery) {
    const startDate = new Date(eventStartQuery);

    if (startDate instanceof Date && !isNaN(startDate))
      eventStart = eventStartTime = startDate;
  }

  if (eventEndQuery) {
    const endDate = new Date(eventEndQuery);

    if (endDate instanceof Date && !isNaN(endDate))
      eventEnd = eventEndTime = endDate;
  }

  return {
    eventStart: combineDateAndTime(eventStart, eventStartTime),
    eventEnd: combineDateAndTime(eventEnd, eventEndTime),
  };
};
