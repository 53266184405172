import React from 'react';
import { FormattedDate } from 'react-intl';
import { renderHideDeliveryTimes } from './utils/renderHideDeliveryTimes';
import SetByTimeInfo from './SetByTime';

const Delivery = ({
  delivery,
  checkout,
  checkoutWindowEnd,
  checkout_time,
  checkinWindowStart,
  checkin_time,
  hideDeliveryTimes,
  hideDeliveryInfo,
  schedule,
  rental,
}) => {
  const timeZone = schedule.timeZone;
  const pickup = {
    pickup_same_as_delivery: rental.pickupSameAsDelivery,
    pickup_address_location_name: rental.pickupAddressLocationName,
    pickup_address_street_address_1: rental.pickupAddressStreetAddress1,
    pickup_address_street_address_2: rental.pickupAddressStreetAddress2,
    pickup_address_city: rental.pickupAddressCity,
    pickup_address_locale: rental.pickupAddressLocale,
    pickup_address_postal_code: rental.pickupAddressPostalCode,
    pickup_address_country: rental.pickupAddressCountry,
  };

  return (
    <section className='delivery'>
      <h4>Delivery Info</h4>
      {hideDeliveryInfo ? (
        <div className='details'>TBD</div>
      ) : (
        <div className='details'>
          <div>
            {delivery.delivery_address_location_name && (
              <label>{delivery.delivery_address_location_name}</label>
            )}
            <div>
              {delivery.delivery_address_street_address_1},{' '}
              {delivery.delivery_address_street_address_2 &&
                delivery.delivery_address_street_address_2}
            </div>

            <div>
              {delivery.delivery_address_city},{' '}
              {delivery.delivery_address_locale}{' '}
              {delivery.delivery_address_postal_code},{' '}
              {delivery.delivery_address_country}
            </div>
          </div>
          <br />
          <div>
            <label>Drop Off</label>
            <div>
              {rental.schedule.eventStartSetting !== 'no_preference' ? (
                <div>
                  <FormattedDate
                    month='2-digit'
                    day='2-digit'
                    year='2-digit'
                    value={checkout}
                  />
                  {' - '}
                  {renderHideDeliveryTimes(
                    checkout_time,
                    timeZone,
                    hideDeliveryTimes
                  )}
                  <br />
                </div>
              ) : (
                <div>No Preference</div>
              )}
              <b>{' Through'}</b>
              <br />
              {rental.schedule.eventStartSetting !== 'no_preference' ? (
                <div>
                  <FormattedDate
                    month='2-digit'
                    day='2-digit'
                    year='2-digit'
                    value={checkoutWindowEnd}
                  />
                  {' - '}
                  {renderHideDeliveryTimes(
                    checkoutWindowEnd,
                    timeZone,
                    hideDeliveryTimes
                  )}
                </div>
              ) : (
                <div>No Preference</div>
              )}
            </div>
          </div>
          <div>
            {!pickup.pickup_same_as_delivery && (
              <div>
                <h4>Pick Up Address</h4>
                <div>
                  {pickup.pickup_address_location_name && (
                    <strong>{pickup.pickup_address_location_name}</strong>
                  )}
                  <div>{pickup.pickup_address_street_address_1}</div>
                  {pickup.pickup_address_street_address_2 && (
                    <div># {pickup.pickup_address_street_address_2}</div>
                  )}
                  <div>
                    {pickup.pickup_address_city &&
                      `${pickup.pickup_address_city}, `}
                    {pickup.pickup_address_locale}{' '}
                    {pickup.pickup_address_postal_code &&
                      `${pickup.pickup_address_postal_code}, `}
                    {pickup.pickup_address_country}
                  </div>
                </div>
              </div>
            )}
            {!pickup.pickup_same_as_delivery && <br />}
            <label>Pick Up</label>
            <div>
              {rental.schedule.eventEndSetting !== 'no_preference' ? (
                <div>
                  <FormattedDate
                    month='2-digit'
                    day='2-digit'
                    year='2-digit'
                    value={checkinWindowStart}
                  />
                  {' - '}
                  {renderHideDeliveryTimes(
                    checkinWindowStart,
                    timeZone,
                    hideDeliveryTimes
                  )}
                  <br />
                </div>
              ) : (
                <div>No Preference</div>
              )}
              <b>{' Through'}</b>
              <br />
              {rental.schedule.eventEndSetting !== 'no_preference' ? (
                <div>
                  <FormattedDate
                    month='2-digit'
                    day='2-digit'
                    year='2-digit'
                    value={checkin_time}
                  />
                  {' - '}
                  {renderHideDeliveryTimes(
                    checkin_time,
                    timeZone,
                    hideDeliveryTimes
                  )}
                </div>
              ) : (
                <div>No Preference</div>
              )}
            </div>
          </div>
          {rental.hasSetByTime && rental.location.showSetByTimeToCustomers && (
            <SetByTimeInfo {...{ rental, schedule }} />
          )}
        </div>
      )}
    </section>
  );
};

export default Delivery;
