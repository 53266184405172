import React from 'react';
import MediaQuery from 'react-responsive';
import { cloneElement } from 'react';

// Pass in the code as 2 props (desktop and mobile) that fits the media query

function MediaBreakpoints(props) {
  // If renderAll is true, then render both desktop and mobile, but only show one
  // Otherwise, only render the one that matches the media query
  if (props.renderAll) {
    return [
      cloneElement(props.desktop, {className: 'desktopLayout'}),
      cloneElement(props.mobile, {className: 'mobileLayout'}),
    ];
  } else {
    return [
      <MediaQuery minWidth={768}>{props.desktop}</MediaQuery>,
      <MediaQuery maxWidth={768}>{props.mobile}</MediaQuery>,
    ];
  }
}

export default MediaBreakpoints;
