import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Validator from "HelperFunctions/validator";
import { setErrors } from "Actions";

const validations = {
  password: {
    required: {
      value: true,
      message: "Password is required"
    }
  }
};

const PasswordResetForm = props => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="fields">
        <Field
          name="password"
          component={StandardTextField}
          type="password"
          placeholder="New Password"
        />
        <Field
          name="password_confirm"
          component={StandardTextField}
          type="password"
          placeholder="Retype New Password"
        />
      </div>
      <div className="actions">
        <button
          className="full"
          type="submit"
          disabled={pristine || submitting}
        >
          Reset Password
        </button>
      </div>
    </form>
  );
};
export default reduxForm({
  form: "PasswordReset", // a unique identifier for this form
  validate: Validator(validations),
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(PasswordResetForm);
