import React from "react";
import { connect } from "react-redux";
import DefaultCurrencyInput from "react-number-format";
import { toNumber } from "HelperFunctions/general";
import { localeToCurrencySymbol } from "Constants/currencyConstants";

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleValueChange = this.handleValueChange.bind(this);
  }
  handleValueChange(values) {
    const { name } = this.props;
    const FakeEvent = {
      target: {
        value: toNumber(values.value),
        name
      }
    };
    this.props.onChange(FakeEvent);
  }
  render() {
    const { name, placeholder, className, value, disabled, location: { locale }, onBlur } = this.props;
    const currencyPrefix = localeToCurrencySymbol[locale];
    const placeholderWithPrefix = currencyPrefix + placeholder;

    return (
      <DefaultCurrencyInput
        prefix={currencyPrefix}
        name={name}
        placeholder={placeholderWithPrefix}
        className={className}
        value={value}
        disabled={disabled}
        onValueChange={this.handleValueChange}
        onBlur={onBlur || (() => {})}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
      />
    );
  }
}

const mapStateToProps = state => {
  const { location } = state.locations;
  return { location };
}

export default connect(mapStateToProps, null)(CurrencyInput);
