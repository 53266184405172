/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'Utils/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { ArrowUp, ArrowDown } from 'Utils/SvgIcons';
import * as actions from 'Actions';
import classnames from 'classnames';
import DateSelector from './DateSelector';
import ColorPicker from 'Utils/ColorPicker';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import ScrollArea from 'Utils/react-scrollbar';
import ShopBreadCrumbs from '../utils/ShopBreadCrumbs';
import { NavLink } from 'react-router-dom';

const Range = createSliderWithTooltip(Slider.Range);

class CategoryFilterDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: {},
      filterOpen: false,
      currentCategory: props.currentCategory,
      selectedSubs: props.selectedSubCategories,
      openCategories: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { sfCategories } = this.props;
      const slug = window.location.pathname.split('/')[2];
      const currentCategory = sfCategories.find(
        (sfCategory) => sfCategory.slug === slug
      );

      this.setState({
        currentCategory: currentCategory,
        selectedSubs: this.props.selectedSubCategories,
      });
    }
  };

  toggleOpenCategories = () => {
    const { openCategories } = this.state;
    this.setState({ openCategories: !openCategories });
  };

  toggleFilter = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  };

  toggleOpen = (category) => {
    this.setState({
      open: {
        ...this.state.open,
        [category.id]: !(this.state.open[category.id] || false),
      },
    });
  };

  checkSfCategorySelected = ({ id }) => {
    const { selectedSfCategories } = this.props;

    return selectedSfCategories.includes(String(id));
  };

  checkSfSubCategorySelected = (sfSubCategory, sfCategory) => {
    const { selectedSfCategoriesData, selectedSfSubCategories } = this.props;

    if (
      Array.isArray(selectedSfCategoriesData) &&
      !!selectedSfCategoriesData.length
    ) {
      // New Url Schema
      let sfCatExists = selectedSfCategoriesData.find(
        (e) => e.category_id === sfCategory.id
      );
      if (sfCatExists) {
        return sfCatExists.sf_sub_categories.includes(String(sfSubCategory.id));
      }
      return false;
    } else {
      // Try old url format
      return selectedSfSubCategories.includes(String(sfSubCategory['id']));
    }
  };

  renderSfCategories = () => {
    const {
      sfCategories,
    } = this.props;
    const { openCategories } = this.state;

    let output = [];
    const sfCategory = this.state.currentCategory;
    // is there any sfCategory.sfSubCategories with checked equal to true?
    let indeterminate = false;

    output.push(
      <ListItem>
        <ListItemText primary='Categories' disableTypography />
        <a onClick={this.toggleOpenCategories}>
          {this.state.openCategories ? <ArrowUp /> : <ArrowDown />}
        </a>
      </ListItem>
    );
    output.push(
      <Collapse in={openCategories} unmountOnExit>
        <List>
          {sfCategories.map((sfCategory) => (
            <ListItem key={sfCategory.id}>
              <a
                href={`/shop/${sfCategory.slug}`}
              >
                {sfCategory.name}
              </a>
            </ListItem>
          ))}
        </List>
      </Collapse>
    );

    return output;
  };

  renderSfCategory = () => {
    const {
      sfCategories,
      handleSfCategorySelected,
      handleSfSubCategorySelected,
      theme,
    } = this.props;
    let output = [];
    const sfCategory = this.state.currentCategory;
    // checkbox in indeterminate state
    let indeterminate = false;
    if (sfCategory?.sfSubCategories?.length) {
      const indeterminateResults = sfCategory.sfSubCategories.map(
        (sfSubCategory) => {
          return this.checkSfSubCategorySelected(sfSubCategory, sfCategory);
        }
      );
      indeterminate =
        indeterminateResults.some((e) => e === true) &&
        indeterminateResults.some((e) => e === false);
    }

    output.push(
      <ListItem
        key={sfCategory.id}
        className={classnames({
          open: this.state.open[sfCategory.id],
        })}
      >
        <ListItemText primary={sfCategory.name} disableTypography />
        <a
          onClick={() => this.toggleOpen(sfCategory)}
          className={classnames({
            arrowPreScott: theme === 'preScott',
            arrowOpen: this.state.open[sfCategory.id],
          })}
        >
          {this.state.open[sfCategory.id] ? <ArrowUp /> : <ArrowDown />}
        </a>
      </ListItem>
    );
    output.push(
      <Collapse in={this.state.open[sfCategory.id] || false} unmountOnExit>
        <List className='checkList'>
          {sfCategory.sfSubCategories.map((sfSubCategory) => (
            <ListItem key={sfSubCategory.id}>
              <Checkbox
                onCheck={(event, isChecked) =>
                  handleSfSubCategorySelected(
                    isChecked,
                    sfSubCategory,
                    sfCategory
                  )
                }
                checked={this.checkSfSubCategorySelected(
                  sfSubCategory,
                  sfCategory
                )}
              />
              <ListItemText primary={sfSubCategory.name} disableTypography />
            </ListItem>
          ))}
        </List>
      </Collapse>
    );

    return output;
  };

  requireRentalDatesBeforePickingInventory = () => {
    return this.props.location.overbookingOnSf === 'disallowed'
  };

  render() {
    const { theme, editingEvent } = this.props;
    const {
      showFilters,
      onColorSelection,
      selectedColors,
      clearColorSelection,
      onQuantitySelection,
      selectedMinQuantity,
      selectedMaxQuantity,
      storefrontShopSetting,
    } = this.props;
    const allowedFilterList = storefrontShopSetting.allowedFilters.split(',');
    const quantityValues = [+selectedMinQuantity, +selectedMaxQuantity];
    const { filterOpen, currentCategory, selectedSubs } = this.state;
    if (
      !(
        allowedFilterList.includes('date') ||
        allowedFilterList.includes('quantity') ||
        allowedFilterList.includes('color') ||
        allowedFilterList.includes('category')
      )
    ) {
      return null;
    }

    const themeOptions = ['wisteria', 'wisteriaDark', 'elaina'];

    return (
      <aside
        className={classnames({
          withEventHeader: editingEvent,
        })}
      >
        {themeOptions.includes(theme) && (
          <a
            className={classnames({
              filterToggle: true,
              open: filterOpen,
            })}
            onClick={this.toggleFilter}
          >
            Filter
          </a>
        )}
        {(!(
          theme === 'wisteria' ||
          theme === 'wisteriaDark' ||
          theme === 'elaina'
        ) ||
          filterOpen) && (
          <div className='filter'>
            <div className='breadcrumbs'>
              <ShopBreadCrumbs
                currentCategory={currentCategory}
                selectedSubs={selectedSubs}
              />
            </div>
            {this.requireRentalDatesBeforePickingInventory() && <DateSelector />}
            {showFilters && (
              <div className='filterWrapper'>
                {allowedFilterList.includes('date') && !this.requireRentalDatesBeforePickingInventory() && <DateSelector />}

                {allowedFilterList.includes('quantity') && (
                  <div id='tooltipContainer' className='quantityFilter'>
                    <div
                      ref={(input) => {
                        this.tooltipContainer = input;
                      }}
                    />
                    Quantity
                    <Range
                      allowCross={false}
                      value={quantityValues}
                      onChange={onQuantitySelection}
                      max={500}
                      tipProps={{
                        visible: true,
                        getTooltipContainer: () => this.tooltipContainer,
                      }}
                    />
                  </div>
                )}
                {allowedFilterList.includes('color') && (
                  <div className='colorFilter'>
                    Color
                    <ColorPicker
                      onColorSelection={onColorSelection}
                      selectedColorIds={selectedColors}
                      clearColorSelection={clearColorSelection}
                    />
                  </div>
                )}
                {currentCategory && allowedFilterList.includes('category') && (
                  <div className='colorAGroup'>
                    <h4>Sub Categories</h4>
                    <ScrollArea speed={0.8} horizontal={false}>
                      <List className='checkList'>
                        {this.renderSfCategory()}
                      </List>
                    </ScrollArea>
                  </div>
                )}
              </div>
            )}
            <div className='colorAGroup' id='categoryLinks'>
              <h4>All Categories</h4>
              <ScrollArea speed={0.8} horizontal={false}>
                <List className='checkList'>{this.renderSfCategories()}</List>
              </ScrollArea>
            </div>
          </div>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  const { sfCategories } = state.categoryList;
  const { parsedStorefrontSettings, location } = state.locations;
  const theme = parsedStorefrontSettings.mainSetting.theme;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  return { sfCategories, storefrontShopSetting, theme, location };
};

export default connect(mapStateToProps, actions)(CategoryFilterDesktop);
