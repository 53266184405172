/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import CartItems from "./add_items/CartItems";
import CartBilling from "./CartBilling";
import EventSummary from "./EventSummary";
import { CartCheck } from "Utils/SvgIcons";
import { history as browserHistory } from "Components/Routes";
import { combineDateAndTime } from "HelperFunctions/general";
import Validator from "HelperFunctions/validator";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signInOpen: false,
      signUpOpen: false
    };
  }

  componentDidMount() {
    const {
      event: { id }
    } = this.props;
    this.props.fetchEvent(id);
  }

  componentWillUnmount() {
    if(this.props.event.editingEvent) {
      this.props.clearCartProps()
    }
  };

  toggleSignUp = () => {
    this.setState({
      signUpOpen: !this.state.signUpOpen,
      signInOpen: this.state.signUpOpen
    });
  };

  toggleSignIn = () => {
    this.setState({
      signInOpen: !this.state.signInOpen,
      signUpOpen: this.state.signInOpen
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false
    });
  };

  handleReview = step => {
    const { event, calculateDefaultDeliveryPrice, setCartProps, setErrors } = this.props;
    const { items, rentalBundles, addOns } = event;
    const allItems = [ ...items, ...rentalBundles, ...addOns];
    if(allItems.map( i => i.quantity ).indexOf("0") === -1){
      calculateDefaultDeliveryPrice({
        event,
        onSuccess: deliveryCost => {
          setCartProps({
            deliveryCost: deliveryCost
          });
          this.handleSelectStep(step);
        }
      });
    } else {
      setErrors("Quantity must be positive number. Press the x button to remove an item");
    }
  };

  handleSelectStep = step => {
    const { location, history } = this.props;
    setFilterParams(
      {
        step: step
      },
      location,
      history
    );
  };

  handleSubmitQuote = () => {
    const component = this;
    if (component.props.authenticated) {
      component.props.editAddItemsSubmit(event => {
        component.eventRedirect(event);
      });
    } else {
      component.toggleSignIn();
    }
  };

  renderLeftSection = step => {
    switch (step) {
      case 1:
        return <CartItems />;
      case 2:
        return <EventSummary event={this.props.event} />;
      case 3:
        return <EventSummary event={this.props.event} />;
      default:
        return <CartItems />;
    }
  };

  reviewStepOne() {
    const { event, setErrors } = this.props;
    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime)
    };
    const validations = {
      name: {
        required: {
          value: true,
          message: "Event name is required."
        },
        minLength: {
          value: 5,
          message: "Event name must be at least 5 characters."
        }
      },
      eventStart: {
        required: {
          value: true,
          message: "Event start date is required."
        }
      },
      eventStartTime: {
        required: {
          value: true,
          message: "Event start time is required."
        }
      },
      eventStartDateTime: {
        required: {
          value: true,
          message: "Event start date is required."
        },
        greaterThanOrEqualToDate: {
          value: new Date(),
          message: "Start date cannot be in the past."
        }
      },
      eventEnd: {
        required: {
          value: true,
          message: "Event end date is required."
        }
      },
      eventEndTime: {
        required: {
          value: true,
          message: "Event end time is required."
        }
      },
      eventEndDateTime: {
        required: {
          value: true,
          message: "Event end date is required."
        },
        greaterThanOrEqualToDate: {
          value: eventForValidation.eventStartDateTime,
          message: "End date must be after start date."
        }
      }
    };
    const errors = Validator(validations)(eventForValidation);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      this.handleSelectStep(2);
    }
  }

  eventRedirect = event => {
    this.props.setCartProps({ token: event.token, id: event.id });
    this.handleSelectStep(3);
  };

  trackProgress = () => {
    const { event, initCartProps } = this.props;
    browserHistory.push(`/events/${event.id}`);
    initCartProps();
  };

  renderNextButton = () => {
    const { step, event } = this.props;
    const cartItemCount =
      event.items.filter(item => item._destroy !== "1").length +
      event.rentalBundles.filter(item => item._destroy !== "1").length +
      event.addOns.filter(item => item._destroy !== "1").length;

    switch (step) {
      case 1:
        if (cartItemCount === 0) {
          return (
            <Link className="btn secondary" to="/shop">
              Shop Now
            </Link>
          );
        } else {
          return (
            <a
              className="btn secondary"
              onClick={() => this.handleReview(step + 1)}
            >
              Review and Submit
            </a>
          );
        }
      case 2:
        return (
          <a className="btn secondary" onClick={this.handleSubmitQuote}>
            Submit
          </a>
        );
      case 3:
        return (
          <a
            className="btn secondary btnTrackProgress"
            onClick={this.trackProgress}
          >
            Track Progress
          </a>
        );
      default:
        return (
          <a
            className="btn secondary"
            onClick={() => this.handleSelectStep(step + 1)}
          >
            Review and Submit
          </a>
        );
    }
  };

  render() {
    const { step } = this.props;
    return (
      <div className="cart">
        <div className="content">
          <section className="progress">
            <ul>
              <li
                className={classnames({
                  active: step === 1,
                  complete: step > 1
                })}
              >
                <a onClick={() => step > 1 && this.handleSelectStep(1)}>
                  {step > 1 ? <CartCheck /> : <div>1</div>}
                  <label>Add to Event</label>
                </a>
              </li>
              <li
                className={classnames({
                  active: step === 2,
                  complete: step > 2
                })}
              >
                <a onClick={() => step > 2 && this.handleSelectStep(2)}>
                  {step > 2 ? <CartCheck /> : <div>2</div>}
                  <label>Review & Submit</label>
                </a>
              </li>
            </ul>
            <div>{this.renderNextButton()}</div>
          </section>
          {this.renderLeftSection(step)}
          <CartBilling
            step={step}
            onSelectStep={this.handleSelectStep}
            onSubmitQuote={this.handleSubmitQuote}
            onReview={this.handleReview}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const step = newFilter.step ? +newFilter.step : 1;
  const { authenticated, errors } = state.auth;
  const { event } = state.cart;
  return { event, step, authenticated, errors };
};

export default withRouter(connect(mapStateToProps, actions)(Cart));
