import React from 'react';
import Modal from 'Utils/Modal';
import SignaturePad from 'Utils/SignaturePad';
import ScrollArea from 'Utils/react-scrollbar';
import classNames from 'classnames';
import validations from 'HelperFunctions/validations';

class DisclaimerSignatureModal extends React.Component {
  state = {
    signature: null,
    signerName: '',
    signerTitle: '',
    signerCompany: '',
    currentStep: 0,
    disclaimerRentalRelationships: [],
    orderAgreement: {},
    errors: {},
  };

  componentDidMount() {
    const { rental, disclaimerRentalRelationships } = this.props;

    const orderAgreement = {
      rentalAgreement: rental.rentalAgreement,
      signature: rental.signature,
      signerName: rental.signerName,
    };

    this.setState({
      disclaimerRentalRelationships: disclaimerRentalRelationships,
      orderAgreement: orderAgreement,
    });
  }

  saveSignature = ({ signature, signerName, signerTitle, signerCompany }) => {
    const { disclaimerRentalRelationships: drr, currentStep } = this.state;

    const currentDisclaimer = {
      ...drr[currentStep],
      signature,
      signerName,
      signerTitle,
      signerCompany,
    };

    const updatedDisclaimers = drr.map((disclaimer, index) =>
      index === currentStep ? currentDisclaimer : disclaimer
    );

    this.setState({
      disclaimerRentalRelationships: updatedDisclaimers,
      signerName,
    });
  };

  saveSignatureForAgreement = ({
    signature,
    signerName,
    signerTitle,
    signerCompany,
  }) => {
    const { orderAgreement } = this.state;

    this.setState({
      ...this.state,
      orderAgreement: {
        ...orderAgreement,
        signature,
        signerName,
        signerTitle,
        signerCompany,
      },
      signerName,
    });
  };

  validate = () => {
    const errors = {};
    const { signerName, signerTitle, signerCompany } = this.state;
    const { rental } = this.props;
    if (validations.required(signerName, true)) {
      errors.signerName = 'Required';
    }
    if (
      rental.companyRentalRelationship &&
      rental.companyRentalRelationship.isPrimaryContact
    ) {
      if (validations.required(signerTitle, true)) {
        errors.signerTitle = 'Required';
      }

      if (validations.required(signerCompany, true)) {
        errors.signerCompany = 'Required';
      }
    }
    return errors;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    const {
      orderAgreement: { signature, signerTitle, signerCompany },
      signerName,
      disclaimerRentalRelationships,
    } = this.state;

    const { onSubmitSignature } = this.props;
    onSubmitSignature({
      signature,
      signerName: signerName || this.state.signerName,
      signerTitle: signerTitle || this.state.signerTitle,
      signerCompany: signerCompany || this.state.signerCompany,
      disclaimerRentalRelationships,
    });
  };

  handleNext = () => {
    const { currentStep } = this.state;

    this.setState({
      currentStep: currentStep + 1,
      signerName: '',
    });
  };

  submitButton = () => {
    const { rental } = this.props;
    const { disclaimerRentalRelationships, currentStep, orderAgreement } =
      this.state;

    let unassignedLength;
    let unassignedAgreementLength = 0;
    let unassignedLengthTotal;
    unassignedLength = disclaimerRentalRelationships.filter(
      (disclaimer) => !disclaimer.signature && disclaimer.signatureRequired
    ).length;

    if (
      !orderAgreement.signature &&
      rental.rentalAgreement &&
      rental.signatureRequired
    ) {
      unassignedAgreementLength = 1;
    }

    unassignedLengthTotal = unassignedLength + unassignedAgreementLength;

    if (
      disclaimerRentalRelationships &&
      disclaimerRentalRelationships.length > 0 &&
      unassignedLengthTotal > 0
    ) {
      return (
        <button
          className='btn'
          onClick={this.handleNext}
          disabled={!disclaimerRentalRelationships[currentStep]?.signature}
        >
          Next
        </button>
      );
    } else {
      return (
        <button
          className='btn'
          onClick={this.handleSubmit}
          disabled={
            rental.rentalAgreement &&
            rental.signatureRequired &&
            !orderAgreement?.signature
          }
        >
          Accept Signature
        </button>
      );
    }
  };

  getCurrentDocument = () => {
    const { orderAgreement } = this.state;
    const { currentStep, disclaimerRentalRelationships } = this.state;

    if (currentStep === disclaimerRentalRelationships.length) {
      return orderAgreement;
    }

    const currentDisclaimerRentalRelationship =
      disclaimerRentalRelationships?.[currentStep] || {};

    return currentDisclaimerRentalRelationship;
  };

  isLastDocument = () => {
    const { rental } = this.props;
    const { currentStep, disclaimerRentalRelationships } = this.state;

    let agreementCount = disclaimerRentalRelationships.length - 1;
    if (rental.rentalAgreement && rental.signatureRequired) {
      agreementCount++;
    }

    return currentStep === agreementCount && rental.signatureRequired;
  };

  render() {
    const { rental, open, toggle } = this.props;
    const {
      signature,
      signerName,
      signerTitle,
      signerCompany,
      errors,
      currentStep,
    } = this.state;

    const { title, description, rentalAgreement } = this.getCurrentDocument();

    const saveSignatureFunction = this.isLastDocument()
      ? this.saveSignatureForAgreement
      : this.saveSignature;

    const currentDocumentHtml =
      (this.isLastDocument() ? rentalAgreement : description) || '';

    return (
      <Modal
        open={open}
        large={true}
        toggle={toggle}
        title={title || 'Rental Signature'}
        actions={[
          this.submitButton(),
          <button key='cancel' className='btn cancel' onClick={toggle}>
            Cancel
          </button>,
        ]}
      >
        <ScrollArea speed={0.8} horizontal={false}>
          <section
            dangerouslySetInnerHTML={{
              __html: currentDocumentHtml,
            }}
          />
        </ScrollArea>

        <SignaturePad
          key={currentStep}
          saveSignature={saveSignatureFunction}
          rental={rental}
          title={title}
          fromDisclaimerModal
        />

        <div className='form'>
          {rental?.companyRentalRelationship?.isPrimaryContact ? (
            <div className='signaturePrimary'>
              <div>
                <input
                  type='text'
                  name='signerName'
                  className={classNames({ error: errors.signerName })}
                  value={signerName}
                  placeholder='Full Name'
                  onChange={this.handleChange}
                />
                <input
                  type='text'
                  name='signerTitle'
                  className={classNames({ error: errors.signerTitle })}
                  value={signerTitle}
                  placeholder='Your Title'
                  onChange={this.handleChange}
                />
              </div>
              <input
                type='text'
                name='signerCompany'
                className={classNames({ error: errors.signerCompany })}
                value={signerCompany}
                placeholder='Company'
                onChange={this.handleChange}
              />
            </div>
          ) : (
            <div className='fields'>
              <input
                type='text'
                name='signerName'
                className={classNames({ med: true, error: errors.signerName })}
                value={signerName}
                placeholder='Type Full Name'
                onChange={this.handleChange}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default DisclaimerSignatureModal;
