import React from 'react';
import Modal from 'Utils/Modal';
import SignaturePad from 'Utils/SignaturePad';
import ScrollArea from 'Utils/react-scrollbar';
import classNames from 'classnames';
import validations from 'HelperFunctions/validations';
import { getSignatureFromRental } from 'HelperFunctions/rentalSignature';

class SignatureModal extends React.Component {
  state = {
    signature: null,
    signerName: '',
    signerTitle: '',
    signerCompany: '',
    errors: {},
  };

  saveSignature = (signature) => {
    this.setState({
      signature: signature,
    });
  };

  componentDidMount() {
    const { rental } = this.props;
    const rentalSignature = getSignatureFromRental(rental, 'customer') || {};
    const {
      signature,
      signerName,
      signerTitle,
      signerCompany,
    } = rentalSignature;
    this.setState({
      signature,
      signerName,
      signerTitle,
      signerCompany,
    });
  }

  validate = () => {
    const errors = {};
    const { signerName, signerTitle, signerCompany } = this.state;
    const { rental } = this.props;
    if (validations.required(signerName, true)) {
      errors.signerName = 'Required';
    }
    if (
      rental.companyRentalRelationship &&
      rental.companyRentalRelationship.isPrimaryContact
    ) {
      if (validations.required(signerTitle, true)) {
        errors.signerTitle = 'Required';
      }

      if (validations.required(signerCompany, true)) {
        errors.signerCompany = 'Required';
      }
    }
    return errors;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    const errors = this.validate();
    const { onSubmitSignature } = this.props;
    const { signature, signerName, signerTitle, signerCompany } = this.state;
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState({
        errors: {},
      });
      onSubmitSignature({ signature, signerName, signerTitle, signerCompany });
    }
  };

  render() {
    const { rental, open, toggle } = this.props;
    const {
      signature,
      signerName,
      signerTitle,
      signerCompany,
      errors,
    } = this.state;
    return (
      <Modal
        open={open}
        large={true}
        toggle={toggle}
        title='Rental Signature'
        actions={[
          <button key='accept' className='btn' onClick={this.handleSubmit}>
            Accept Signature
          </button>,
          <button key='cancel' className='btn cancel' onClick={toggle}>
            Cancel
          </button>,
        ]}
      >
        <ScrollArea speed={0.8} horizontal={false}>
          <section
            dangerouslySetInnerHTML={{
              __html: rental.rentalAgreement ? rental.rentalAgreement : '',
            }}
          />
        </ScrollArea>
        <SignaturePad
          saveSignature={this.saveSignature}
          signature={signature}
        />
        <div className='form'>
          {rental &&
          rental.companyRentalRelationship &&
          rental.companyRentalRelationship.isPrimaryContact ? (
            <div className='signaturePrimary'>
              <div>
                <input
                  type='text'
                  name='signerName'
                  className={classNames({ error: errors.signerName })}
                  value={signerName}
                  placeholder='Full Name'
                  onChange={this.handleChange}
                />
                <input
                  type='text'
                  name='signerTitle'
                  className={classNames({ error: errors.signerTitle })}
                  value={signerTitle}
                  placeholder='Your Title'
                  onChange={this.handleChange}
                />
              </div>
              <input
                type='text'
                name='signerCompany'
                className={classNames({ error: errors.signerCompany })}
                value={signerCompany}
                placeholder='Company'
                onChange={this.handleChange}
              />
            </div>
          ) : (
            <div className='fields'>
              <input
                type='text'
                name='signerName'
                className={classNames({ med: true, error: errors.signerName })}
                value={signerName}
                placeholder='Type Full Name'
                onChange={this.handleChange}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default SignatureModal;
