import {
  FETCH_ADMIN_CUSTOMERS_REQUEST,
  FETCH_ADMIN_CUSTOMERS_SUCCESS,
  FETCH_ADMIN_CUSTOMERS_FAILURE,
  FETCH_ADMIN_CUSTOMER_NAMES_REQUEST,
  FETCH_ADMIN_CUSTOMER_NAMES_SUCCESS,
  ADD_ADMIN_CUSTOMER_NAMES_REQUEST,
  ADD_ADMIN_CUSTOMER_NAMES_SUCCESS,
  SET_ADMIN_CUSTOMER_QUERY
} from "Constants/redux";

const INITIAL_STATE = {
  items: [],
  query: "",
  names: [],
  firstId: "",
  pagesRemaining: false,
  loading: false,
  infiniteLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ADMIN_CUSTOMERS_REQUEST:
    case FETCH_ADMIN_CUSTOMER_NAMES_REQUEST:
    case ADD_ADMIN_CUSTOMER_NAMES_REQUEST:
      return { ...state, infiniteLoading: true };
    case FETCH_ADMIN_CUSTOMERS_FAILURE:
      return { ...state, loading: false };
    case FETCH_ADMIN_CUSTOMERS_SUCCESS:
      const items = action.response.data.customers;
      return {
        ...state,
        items: items,
        firstId: items.length > 0 ? items[0].id : "",
        loading: false,
        infiniteLoading: false
      };
    case FETCH_ADMIN_CUSTOMER_NAMES_SUCCESS:
      const { names, pagination, firstId } = action.payload;
      return {
        ...state,
        loading: false,
        infiniteLoading: false,
        names,
        firstId,
        pagination,
        pagesRemaining: names.length !== pagination.totalObjects
      };
    case ADD_ADMIN_CUSTOMER_NAMES_SUCCESS:
      const newPagination = action.payload.pagination;
      const newNames = [...state.names, ...action.payload.names];
      return {
        ...state,
        infiniteLoading: false,
        names: newNames,
        pagination: newPagination,
        pagesRemaining: newNames.length !== newPagination.totalObjects
      };
    case SET_ADMIN_CUSTOMER_QUERY:
      return {
        ...state,
        query: action.payload.query
      };
    default:
      return state;
  }
};
