import { LOCATION_CHANGE } from "react-router-redux";
import {
  FETCH_RENTAL_REQUEST,
  FETCH_RENTAL_SUCCESS,
  FETCH_RENTAL_FAILURE,
  UPDATE_RENTAL_SUCCESS,
  UPDATE_RENTAL_REQUEST,
  UPDATE_RENTAL_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  rental: {
    documents: [],
    conversations: []
  },
  errors: {},
  loading: false,
  updating: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RENTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_RENTAL_SUCCESS:
      return {
        ...state,
        rental: action.payload.rental,
        loading: false
      };
    case FETCH_RENTAL_FAILURE:
      return {
        ...state,
        loading: false
      };
    case UPDATE_RENTAL_REQUEST:
      return {
        ...state,
        updating: true
      };
    case UPDATE_RENTAL_SUCCESS:
      return {
        ...state,
        rental: action.payload,
        updating: false
      };
    case UPDATE_RENTAL_FAILURE:
      return {
        ...state,
        updating: false
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        errors: INITIAL_STATE.errors
      };
    default:
      return state;
  }
};
