import React from "react";
import Imgix from "react-imgix";
import classnames from "classnames";

const HeaderLogo = ({ logoPicture, location, link }) => {

  if( !link || link == ''){
    link = '/';
  }

  return logoPicture ? (
    <figure
      className={classnames({
        headerLogo: true,
      })}
    >
      <a href={link}>
        <Imgix
          src={logoPicture.imgixUrl}
          alt="logo"
          fit="max"
          sizes='50vw'
        />
      </a>
    </figure>
  ) : (
    <h1
      className={classnames({
        headerTitle: true,
      })}
    >
      {location.name}
    </h1>
  );
};

export default HeaderLogo;
