/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import Modal from "Utils/Modal";
import { Delete } from "Utils/SvgIcons";
import classnames from "classnames";

const ItemTypes = {
  CARD: "card"
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  }
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //   return;
    // }

    // // Dragging upwards
    // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && hoverIndex !== 0) {
    //   return;
    // }

    // Time to actually perform the action
    props.movePicture(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};
const collectDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};
const collectDrop = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};
class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false
    };

    this.toggleAlertOpen = this.toggleAlertOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  toggleAlertOpen(event) {
    event.stopPropagation();
    this.setState({ alertOpen: !this.state.alertOpen });
  }
  handleDelete(index) {
    this.setState({
      alertOpen: false
    });
    this.props.onDelete(index);
  }
  render() {
    const {
      picture,
      index,
      draggable,
      isOver,
      connectDragSource,
      connectDragPreview,
      connectDropTarget
    } = this.props;
    const src = picture.url
      ? picture.url
      : window.URL.createObjectURL(picture.file);

    return connectDropTarget(
      connectDragSource(
        <figure
          className={classnames({ draggable: draggable, isOver: isOver })}
        >
          {connectDragPreview(<img src={src} alt="" />)}
          <a className="btnLink delete" onClick={this.toggleAlertOpen}>
            <Delete />
          </a>
          <Modal
            className="dialog"
            title="Delete Image"
            open={this.state.alertOpen}
            toggle={this.toggleAlertOpen}
          >
            <p>Are you sure?</p>
            <p>This cannot be undone.</p>
            <div className="actions">
              <a className="btn warn" onClick={() => this.handleDelete(index)}>
                Delete
              </a>
              <a className="btn cancel" onClick={this.toggleAlertOpen}>
                Cancel
              </a>
            </div>
          </Modal>
        </figure>
      )
    );
  }
}
export default DropTarget(
  ItemTypes.CARD,
  cardTarget,
  collectDrop
)(DragSource(ItemTypes.CARD, cardSource, collectDrag)(Picture));
