/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import GuestCheckout from "./GuestCheckout";
import SignUpGuestForm from "./SignUpGuestForm";
import SignInForm from "Components/home/SignInForm";
import { parseQuery } from "HelperFunctions/QueryString";
import { history as browserHistory } from "Components/Routes";

class MakeAPayment extends React.Component {
  state = {
    open: true
  };
  componentDidMount() {
    const { fetchGuestData } = this.props;
    const { token } = parseQuery(this.props.history.location.search);
    fetchGuestData(token);
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.rental && nextProps.authenticated && nextProps) {
  //     const { rental } = nextProps;
  //     browserHistory.push(`/events/${rental.token}`);
  //   }
  // }
  closeGuestModal = () => {
    this.setState({ open: false });
  };
  handleSignIn = data => {
    const component = this;
    const { rental, loginUser } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        browserHistory.push(`/events/${rental.token}`);
      }
    });
  };

  handleSignUp = data => {
    const component = this;
    const { rental, createUser } = component.props;
    createUser({
      ...data,
      onSuccess: () => {
        browserHistory.push(`/events/${rental.token}`);
      }
    });
  };

  render() {
    const {
      dataLoading,
      rental,
      customer,
      customerType,
      storeLocation,
      errors,
      signUpGuestSubmit
    } = this.props;

    const { open } = this.state;
    const { token } = parseQuery(this.props.location.search);

    if (dataLoading === "success") {
      if (!rental.isAllowedToMakePayment) {
        if (rental.approvalStatus === "pending") {
          return (
            <div className="guestLanding">
              <div className="content">
                <p className="noData">
                  Payment is not available for leads and pending orders. Please contact the
                  rental location for more info.
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="guestLanding">
              <div className="content">
                <p className="noData">
                  Payment is not available for this rental. Please contact the
                  rental location for more info.
                </p>
              </div>
            </div>
          );
        }
      }
      if (customerType === "Company") {
        return (
          <GuestCheckout
            visible={true}
            rental={rental}
            customer={customer}
            location={storeLocation}
          />
        )
      }
      return (
        <div>
          {open && (
            <div className="guestLanding">
              <div className="content">
                <div>
                  <h1>Sign In</h1>
                  <SignInForm errors={errors} onSubmit={this.handleSignIn} />
                  <div className="actions">
                    <a
                      className="btn full outline"
                      onClick={this.closeGuestModal}
                    >
                      Check Out As A Guest
                    </a>
                  </div>
                  <Link to="/forgotPassword">Forgot Password</Link>
                </div>

                {customer &&
                  <div>
                    <h1>Sign Up</h1>
                    <h3>Renting made easy!</h3>
                    <p>
                      Sign up using the email address we have on file to take over
                      your account. You can track rentals, make payments, request
                      changes. You name it.
                    </p>
                    <SignUpGuestForm
                      errors={errors}
                      initialValues={{
                        token: token,
                        firstName: customer.firstName,
                        lastName: customer.lastName
                      }}
                      email={customer.email}
                      onSubmit={signUpGuestSubmit}
                    />
                  </div>}
              </div>
            </div>
          )}
          <GuestCheckout
            visible={!open}
            rental={rental}
            customer={customer}
            location={storeLocation}
          />

        </div>
      );
    } else if (dataLoading === "loading") {
      return <div className="guestLanding">
        <div className="content">
          <h1 className="noData">
            Loading...
          </h1>
        </div>
      </div>
    } else {
      return (
        <div className="guestLanding">
          <div className="content">
            <p className="noData">
              Oops, we could not find your rental. Please make sure you have
              navigated to the proper url. If so, please contact the business
              for your rental.
            </p>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  const { rental, customer, dataLoading, customerType } = state.guestCheckout;
  const { location: storeLocation } = state.locations;
  const { errors } = state.auth;
  const { authenticated } = state.auth;
  return {
    authenticated,
    rental,
    storeLocation,
    customer,
    customerType,
    dataLoading,
    errors
  };
};

export default connect(mapStateToProps, actions)(MakeAPayment);
