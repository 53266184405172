import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import PaginationBoxView from "Utils/PaginationBoxView";
import AvailableItemCard from "./AvailableItemCard";
import LoadingSpinner from "Utils/LoadingSpinner";
import classnames from "classnames";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";

class AvailableItemList extends React.Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }
  state = {
    errorCardId: null
  }

  handlePageChange(data) {
    const { location, history } = this.props;
    const newPage = data.selected + 1;
    setFilterParams(
      {
        ...this.props.filter.inventoryFilter,
        page: newPage
      },
      location,
      history
    );
  }

  setErrorCardId = (value) => {
    this.setState({ errorCardId: value })
  }

  renderTable() {
    const { inventory, event } = this.props;
    const rentalItems = event && Array.concat(event.items, event.addOns, event.rentalBundles) || [];
    return inventory.map(product => {
      const rentalItem = rentalItems.filter(ri => {
        if (product.productType === 'items') {
          return ri.productId === product.id
        } else if (product.productType === 'bundles') {
          return ri.bundleId === product.id
        } else {
          return ri.addOnId === product.id
        }
      })[0];
      return (
        <AvailableItemCard key={product.id} product={product} quantity={rentalItem && rentalItem.quantity} setErrorCardId={this.setErrorCardId} errorCardId={this.state.errorCardId} />
      );
    });
  }

  render() {
    const { inventory, pagination, filter, loading, event } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    } else {
      return (
        <section className={classnames({
          storeItems: true,
          eventHeaderSpace: event && event.editingEvent
        })}>
          {inventory.length > 0 ? (
            this.renderTable()
          ) : (
            <p>No available items.</p>
          )}
          <PaginationBoxView
            className="pagerWrapper"
            containerClassName="pager"
            forcePage={+filter.page - 1}
            pageCount={pagination.totalPages}
            onPageChange={this.handlePageChange}
          />
        </section>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { inventory, pagination, loading, pagesRemaining } = state.products;
  const filter = filterFromPropsAndState(ownProps, state.products, true);
  return {
    inventory,
    pagination,
    loading,
    pagesRemaining,
    filter,
    event
  };
};

export default withRouter(connect(mapStateToProps, actions)(AvailableItemList));
