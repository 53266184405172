import React from 'react';
import SmartNavLink from 'Utils/SmartNavLink';
import classnames from 'classnames';

class MobileNavLinkAccordion extends React.Component {
  state = {
    open: false,
  };

  toggleAccordion = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  render() {
    const { menuItem, link, toggleMenu } = this.props;
    const { open } = this.state;
    return (
      <div className='mobileMenuAccordion'>
        <div>
          <SmartNavLink
            exact
            link={link}
            target={menuItem.openInNewTab ? '_blank' : ''}
            onClick={toggleMenu}
          >
            {menuItem.menuTitle}
          </SmartNavLink>
          <span
            className={classnames({
              arrowPreScott: true,
              arrowOpen: open,
            })}
            onClick={this.toggleAccordion}
          />
        </div>
        <ul
          className={classnames({
            open,
          })}
        >
          {menuItem.childMenuItems.map((childMenuItem, index) => (
            <li key={index}>
              <SmartNavLink
                exact
                link={childMenuItem.menuLink}
                target={childMenuItem.openInNewTab ? '_blank' : ''}
                onClick={toggleMenu}
              >
                {childMenuItem.menuTitle}
              </SmartNavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MobileNavLinkAccordion;
