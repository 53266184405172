/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import DatePicker from 'Utils/DatePicker';
import TimePicker from 'Utils/TimePicker';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { ListView, ImageView } from 'Utils/SvgIcons';
import { history } from 'Components/Routes';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import {
  showSelectedPrice,
  getItemsWithNewPrices,
} from 'HelperFunctions/rental_items';
import classnames from 'classnames';
import EventItem from '../cart/CartItem';
import CurrencyLabel from 'Utils/CurrencyLabel';

class EventItems extends React.Component {
  componentDidMount() {
    const { event } = this.props;
    if (event) {
      this.updatePrices(event);
    }
  }

  updatePrices = (event) => {
    const { setEventProps } = this.props;
    const { items, rentalBundles } = getItemsWithNewPrices(event);
    event.items = items;
    event.rentalBundles = rentalBundles;
    setEventProps(event);
  };

  handleChange = (e) => {
    const { event } = this.props;
    const { name, value } = e.target;
    let newEvent = {
      ...event,
      [name]: value,
    };

    this.updatePrices(newEvent);
    event.items &&
      event.items.forEach((item, itemIndex) => {
        this.updateItemData(newEvent, item, itemIndex);
      });
  };

  updateItemData = (newEvent, item, itemIndex) => {
    const { setEventProps, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
    let newItems = newEvent.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      quantity: item.quantity,
    };

    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'items',
      location
    );
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };
    setEventProps({
      items: newItems,
    });
  };

  handleItemChange = (e, itemIndex) => {
    const { event, setEventProps, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value),
    };

    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'items',
      location
    );
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
      selectedPriceBeforeDiscount: selected.total,
    };

    setEventProps({
      items: newItems,
    });
  };

  handleAddOnChange = (e, addOnIndex) => {
    const { event, setEventProps, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.addOns.slice();
    newItems[addOnIndex] = {
      ...newItems[addOnIndex],
      [name]: toNumber(value),
    };

    newItems[addOnIndex].addOn.location = newItems[addOnIndex].location;

    const selected = showSelectedPrice(
      newItems[addOnIndex].addOn,
      newItems[addOnIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'add_ons',
      location
    );

    newItems[addOnIndex] = {
      ...newItems[addOnIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };

    setEventProps({
      addOns: newItems,
    });
  };

  handleItemRemove = (itemIndex) => {
    const { event, setEventProps } = this.props;
    let newItems = event.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      _destroy: '1',
    };
    setEventProps({ items: newItems });
  };

  handleAddOnRemove = (itemIndex) => {
    const { event, setEventProps } = this.props;
    let newItems = event.addOns.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      _destroy: '1',
    };
    setEventProps({ addOns: newItems });
  };

  handleRentalBundleChange = (e, rentalBundleIndex) => {
    const { event, updateRentalBundleEditEvent } = this.props;
    const { name, value } = e.target;
    updateRentalBundleEditEvent(name, value, rentalBundleIndex, event);
  };

  handleRentalBundleRemove = (rentalBundleIndex) => {
    const { event, setEventProps } = this.props;
    let newRentalBundles = event.rentalBundles.slice();
    newRentalBundles[rentalBundleIndex] = {
      ...newRentalBundles[rentalBundleIndex],
      _destroy: '1',
    };
    setEventProps({ rentalBundles: newRentalBundles });
  };

  handleAddItems = () => {
    const { event, startAddingItemsToEvent, setCartProps } = this.props;
    startAddingItemsToEvent(event);
    setCartProps({ editingEvent: true });
    history.push('/shop');
  };

  render() {
    const {
      event,
      storefrontShopSetting,
      tileView,
      showTiles,
      hideTiles,
      errors,
    } = this.props;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      addOns,
      deliveryCost,
    } = event;
    const itemTotal = items.reduce(
      (sum, item) => (sum += +item.selectedPrice),
      0
    );
    const bundleTotal = rentalBundles.reduce(
      (sum, item) => (sum += +item.selectedPrice),
      0
    );
    const addOnTotal = addOns.reduce(
      (sum, item) => (sum += +item.selectedPrice),
      0
    );
    const total = itemTotal + bundleTotal + addOnTotal;
    return (
      <section className='itemForm'>
        <div className='details'>
          <h4>Event Dates</h4>
          <div className='form'>
            <div className='fields dateRange'>
              <div>
                <label>Event Start</label>
                <DatePicker
                  name='eventStart'
                  value={eventStart}
                  onChange={this.handleChange}
                />
                <TimePicker
                  name='eventStartTime'
                  value={eventStartTime}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>Event End</label>
                <DatePicker
                  name='eventEnd'
                  value={eventEnd}
                  startDate={eventStart ? eventStart : new Date()}
                  onChange={this.handleChange}
                  className={classnames({
                    error: errors['eventEndDateTime'] || errors['eventEnd'],
                  })}
                />
                <TimePicker
                  name='eventEndTime'
                  value={eventEndTime}
                  onChange={this.handleChange}
                  className={classnames({
                    error: errors['eventEndDateTime'] || errors['eventEnd'],
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='items'>
          <div className='addItemBtn'>
            <a onClick={this.handleAddItems} className='btn'>
              Add More Items To Order
            </a>
          </div>
          <h4>Event Items</h4>
          <div className='tileToggle'>
            <a
              onClick={hideTiles}
              className={classnames({
                active: !tileView,
              })}
            >
              <ListView />
            </a>
            <a
              onClick={showTiles}
              className={classnames({
                active: tileView,
              })}
            >
              <ImageView />
            </a>
          </div>
          <div className='companyItems'>
            <table
              className={classnames({
                grid: true,
                thumbnail: tileView,
              })}
            >
              <tbody>
                {items.map((item, itemIndex) => {
                  if (item._destroy === '1') {
                    return null;
                  } else {
                    return (
                      <EventItem
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        tileView={tileView}
                        storefrontShopSetting={storefrontShopSetting}
                        onItemChange={this.handleItemChange}
                        onItemRemove={this.handleItemRemove}
                      />
                    );
                  }
                })}
                {rentalBundles.map((item, itemIndex) => {
                  if (item._destroy === '1') {
                    return null;
                  } else {
                    return (
                      <EventItem
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        tileView={tileView}
                        storefrontShopSetting={storefrontShopSetting}
                        onItemChange={this.handleRentalBundleChange}
                        onItemRemove={this.handleRentalBundleRemove}
                      />
                    );
                  }
                })}
                {addOns.map((item, itemIndex) => {
                  if (item._destroy === '1') {
                    return null;
                  } else {
                    return (
                      <EventItem
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        tileView={tileView}
                        storefrontShopSetting={storefrontShopSetting}
                        onItemChange={this.handleAddOnChange}
                        onItemRemove={this.handleAddOnRemove}
                      />
                    );
                  }
                })}
              </tbody>
            </table>
            {storefrontShopSetting.shopShowItemPricing && (
              <div className='billingDetails'>
                <div>
                  <label>Estimated Delivery</label>
                  <span>
                    <CurrencyLabel value={deliveryCost} />
                  </span>
                </div>
                <div className='subtotal'>
                  <label>Item Total</label>
                  {total === 0 ? (
                    <span>Price On Request</span>
                  ) : (
                    <CurrencyLabel value={total} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    location: { storefrontSetting },
    parsedStorefrontSettings: { storefrontShopSetting },
    location,
  } = state.locations;
  const { tileView } = state.products;
  const { errors } = state.dashboard;

  return {
    storefrontSetting,
    storefrontShopSetting,
    tileView,
    errors,
    location,
  };
};

export default connect(mapStateToProps, actions)(EventItems);
