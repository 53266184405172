import {
  FETCH_INVENTORY_AVAILABILITY_REQUEST,
  FETCH_INVENTORY_AVAILABILITY_SUCCESS,
  FETCH_INVENTORY_AVAILABILITY_FAILURE,
  RESET_INVENTORY_AVAILABILITY,
  SET_AVAILABILITY_LOADING_INVENTORY,
} from 'Constants/redux';

const BASE_AVAILABILITY_STATE = {
  validPeriodStart: new Date(), // Start of period in which the availability numbers is valid
  validPeriodEnd: new Date(), // End of period in which the availability numbers is valid
  products: {},
  addOns: {},
  bundles: {},
  loading: false,
  loadingInventory: {
    products: [],
    addOns: [],
    bundles: [],
  },
};

const INITIAL_STATE = {
  ...BASE_AVAILABILITY_STATE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_AVAILABILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_INVENTORY_AVAILABILITY_SUCCESS:
      let availabilityState;

      const periodStartChanged =
        action.payload.validPeriodStart !==
        state.validPeriodStart?.toISOString();

      const periodEndChanged =
        action.payload.validPeriodEnd !== state.validPeriodEnd?.toISOString();

      // If period changes, overwrite type state since the previous results are invalidated.
      // If period is the same, merge response with current state
      if (periodStartChanged || periodEndChanged) {
        availabilityState = { ...action.payload };
      } else {
        availabilityState = {
          ...action.payload,
          products: {
            ...state.products,
            ...action.payload.products,
          },
          addOns: { ...state.addOns, ...action.payload.addOns },
          bundles: {
            ...state.bundles,
            ...action.payload.bundles,
          },
        };
      }

      // Convert ISO timestamps to actual Date objects
      availabilityState.validPeriodStart = new Date(
        availabilityState.validPeriodStart
      );

      availabilityState.validPeriodEnd = new Date(
        availabilityState.validPeriodEnd
      );

      return {
        ...availabilityState,
        loadingInventory: {
          products: [],
          addOns: [],
          bundles: [],
        },
        loading: false,
      };
    case FETCH_INVENTORY_AVAILABILITY_FAILURE:
      return {
        ...state,
        loadingInventory: {
          products: [],
          addOns: [],
          bundles: [],
        },
        loading: false,
      };
    case RESET_INVENTORY_AVAILABILITY:
      return { ...INITIAL_STATE };
    case SET_AVAILABILITY_LOADING_INVENTORY:
      return {
        ...state,
        loadingInventory: { ...action.payload },
        loading: true,
      }; 
    default:
      return state;
  }
};
