const sfSubsctionTiers = ['basic', 'cart', 'complete', 'advanced'];
const actionListWithMinimumRequirements = {
  '/events': 0,
  '/shop': 1,
}

export const canAccessStorefrontFeature = (action, sfTier) => {
  const parsedUrl = /\.(com|dev)(.+)/.exec(action);
  const needsToBeParsed = parsedUrl !== null;
  const userAction = (needsToBeParsed)
    ? parsedUrl[2]
    : action;

  const tierIndex = sfSubsctionTiers.indexOf(sfTier);
  let actionRequirement = actionListWithMinimumRequirements[userAction];

  // Show all navigation on SF Tier above Cart
  if (!actionRequirement && !['basic', 'cart'].includes(sfTier)) return true;

  return actionRequirement <= tierIndex;
};
