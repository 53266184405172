import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { fetchProfile, handleImageDropZoneChange } from "Actions";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import StandardRegionDropdown from "Utils/redux_form_inputs/StandardRegionDropdown";
import Validator from "HelperFunctions/validator";
import ImageDropZone from "Utils/images/ImageDropZone";
import { setErrors } from "Actions";

// Redux-form validations written in json

const validations = {
  email: {
    required: {
      value: true,
      message: "Email is required"
    },
    email: {
      value: true,
      message: "Invalid email format"
    }
  },
  firstName: {
    required: {
      value: true,
      message: "First name is required"
    }
  },
  lastName: {
    required: {
      value: true,
      message: "Last name is required"
    }
  }
};

class ProfileForm extends React.Component {
  render() {
    const {
      handleSubmit,
      handleImageDropZoneChange,
      initialValues,
      formValues
    } = this.props; // From Redux

    const pictures =
      initialValues.picture === undefined || initialValues.picture === null
        ? []
        : [initialValues.picture];
    return (
      <form onSubmit={handleSubmit} className="form">
        <ImageDropZone
          multiple={false}
          onChange={handleImageDropZoneChange}
          pictures={pictures}
          aspectRatio={1 / 1}
        />
        <div className="fields">
          <label>Name</label>
          <Field
            name="firstName"
            placeholder="First Name"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="lastName"
            placeholder="Last Name"
            component={StandardTextField}
            type="text"
          />
        </div>
        <div className="fields">
          <label>Email</label>
          <Field
            name="email"
            placeholder="Email Address"
            component={StandardTextField}
            type="text"
          />
        </div>
        <div className="fields">
          <label>Phone</label>
          <Field
            name="cell"
            placeholder="Phone Number"
            component={StandardTextField}
            type="text"
          />
        </div>
        <div className="fields">
          <label>Address</label>
          <Field
            name="country"
            placeholder="Country"
            component={StandardSelectField}
          >
            <option value="">Select country</option>
            <option value="USA">United States</option>
            <option value="CAN">Canada</option>
          </Field>
          <Field
            name="streetAddress1"
            placeholder="Street Address"
            className="lg"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="streetAddress2"
            placeholder="Suite/Apt"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="city"
            placeholder="City"
            className="med"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="locale"
            defaultOptionLabel={
              formValues && formValues.country === "USA" ? "State" : "Province"
            }
            className="sm"
            disableWhenEmpty={true}
            countryValueType="short"
            labelType="short"
            valueType="short"
            country={
              formValues && formValues.country ? formValues.country : "USA"
            }
            component={StandardRegionDropdown}
          />
          <Field
            name="postalCode"
            placeholder="Zip"
            className="sm"
            component={StandardTextField}
            type="text"
          />
        </div>
        <div className="fields">
          <label>Driver's License</label>
          <Field
            name="driverLicenseNumber"
            placeholder="Number"
            className="lg"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="driverLicenseState"
            placeholder="State"
            className="sm"
            component={StandardTextField}
            type="text"
          />
        </div>

        <div className="actions">
          <button type="submit" className="btn">
            Submit
          </button>
          <Link className="btn cancel" to="/profile">
            Cancel
          </Link>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const { customer } = state.customer;
  const { Profile } = state.form;

  return { initialValues: customer, formValues: Profile ? Profile.values : {} };
};

// Because we are using Redux-form plus our own redux state,
// this is a 3 tier HOC. The connect HOC takes the reduxForm as a paramater.
// The reduxForm HOC takes the form and Validators as parameters.
// The whole thing returns a ProfileForm HOC with access to Redux and performing validations
// on the form under the hood.

export default compose(
  connect(mapStateToProps, {
    handleImageDropZoneChange,
    load: fetchProfile
  }),
  reduxForm({
    form: "Profile",
    validate: Validator(validations),
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError, props) => {
      if (errors) {
        dispatch(setErrors(errors));
      }
    }
  })
)(ProfileForm);
