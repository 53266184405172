import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import {
  combineDateAndTime,
  debounce,
  compareObjects
} from "HelperFunctions/general";
import { setFilterParams, filterFromPropsAndState, filterObjToArray } from "HelperFunctions/urls";
import CategoryFilterDesktop from "./CategoryFilterDesktop";
import CategoryFilterMobile from "./CategoryFilterMobile";
import AvailableItemList from "./AvailableItemList";
import BusinessNoItemsPage from "./BusinessNoItemsPage";
import Suggestions from "Utils/Suggestions";
import axios from "axios";
import LoadingSpinner from "Utils/LoadingSpinner";
import classnames from "classnames";
import PageMetaData from "Utils/PageMetaData";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import EventHeader from "./EventHeader";

class SearchItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      filter: props.filter
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.performSearch = debounce(this.performSearch, 500);

    this.handleQueryChange = debounce(this.handleQueryChange, 1000);
    this.handleQuantityChange = debounce(this.handleQuantityChange, 1000);
  }

  componentDidMount() {
    const {
      filter,
      reloadInventory,
      fetchSfCategories,
      fetchInventoryColors
    } = this.props;

    reloadInventory(filter);
    fetchSfCategories(filter.query);
    fetchInventoryColors(filter.query);
  }

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;

    if (!isEqual(newFilter, oldFilter)) {
      const {
        reloadInventory,
        fetchSfCategories,
        fetchInventoryColors
      } = this.props;
      const filterToSubmit = Object.assign(newFilter);
      reloadInventory(filterToSubmit);
      fetchSfCategories(filterToSubmit.query);
      fetchInventoryColors(filterToSubmit.query);
    }
  }

  redirectToEventPage = () => {
    const { event, history } = this.props;
    history.push(`/events/${event.token}`);
  };

  handleSelectSfCategory = (sfCategory) => {
    const {
      filter: { selectedSfCategory }
    } = this.props;
  };

  handleSfCategorySelected = (isChecked, sfCategory) => {
    const {
      filter: {
        selectedSfCategories,
        selectedSfSubCategories,
        selectedSfCategoriesData
      }
    } = this.props;

    let newSelected = [...selectedSfCategories];
    let newSelectedSubCategories = [...selectedSfSubCategories];
    const sfSubCategoriesIds = sfCategory.sfSubCategories.map((s) => s.id);
    if (isChecked) {
      if (newSelected.indexOf(sfCategory.id) === -1) {
        newSelected.push(String(sfCategory.id));
      }

      sfSubCategoriesIds.forEach((id) => {
        if (newSelectedSubCategories.indexOf(String(id)) === -1) {
          newSelectedSubCategories.push(String(id));
        }
      });
    } else {
      newSelected = newSelected.filter((ns) => ns !== String(sfCategory.id));
      newSelectedSubCategories = newSelectedSubCategories.filter(
        (ns) => !sfSubCategoriesIds.includes(+ns)
      );
    }

    let newData = selectedSfCategoriesData;
    let sfCatIdIndex = newData.findIndex(
      (data) => data.category_id === sfCategory.id
    );

    if (isChecked) {
      if (sfCatIdIndex === -1) {
        newData.push({
          category_id: sfCategory.id,
          sf_sub_categories: sfCategory.sfSubCategories.map((c) => String(c.id))
        });
      } else {
        newData[
          sfCatIdIndex
        ].sf_sub_categories = sfCategory.sfSubCategories.map((c) =>
          String(c.id)
        );
      }
    } else {
      newData = newData.filter((data) => data.category_id !== sfCategory.id);
    }

    let newSelectedSfCategoriesData = JSON.stringify(newData);

    const { location, history } = this.props;
    setFilterParams(
      {
        selectedSfCategories: newSelected.join(","),
        selectedSfCategoriesData: newSelectedSfCategoriesData,
        page: null
      },
      location,
      history
    );
  };

  handleSfSubCategorySelected = (isChecked, { id }, sfCategory) => {
    const {
      filter: { selectedSfCategoriesData, selectedSfCategories }
    } = this.props;

    let newSelected = [...selectedSfCategories];
    let newSelectedSfCategoriesData = cloneDeep(selectedSfCategoriesData);

    let cIdExist = newSelectedSfCategoriesData.find(
      (c) => c.category_id === sfCategory.id
    );
    let sfSubCatIdExist;
    if (cIdExist) {
      sfSubCatIdExist = cIdExist.sf_sub_categories.includes(id);
    }
    if (isChecked && !sfSubCatIdExist) {
      if (cIdExist) {
        newSelectedSfCategoriesData
          .find((c) => c.category_id === sfCategory.id)
          .sf_sub_categories.push(String(id));
      } else {
        newSelectedSfCategoriesData.push({
          category_id: sfCategory.id,
          sf_sub_categories: [String(id)]
        });
      }
    } else {
      let reqIndex = newSelectedSfCategoriesData.findIndex(
        (c) => c.category_id === sfCategory.id
      );
      if (
        newSelectedSfCategoriesData[reqIndex].sf_sub_categories.length === 1
      ) {
        newSelectedSfCategoriesData = newSelectedSfCategoriesData.filter(
          (data) => data.category_id !== sfCategory.id
        );
        newSelected = newSelected.filter((ns) => ns !== String(sfCategory.id));
      } else {
        newSelectedSfCategoriesData[
          reqIndex
        ].sf_sub_categories = newSelectedSfCategoriesData[
          reqIndex
        ].sf_sub_categories.filter(
          (sfSubCategoryId) => sfSubCategoryId !== String(id)
        );
      }
    }

    // if all categories from subcategories are selected, select the parent category too.
    if (
      !newSelected.includes(String(sfCategory.id)) &&
      sfCategory.sfSubCategories.length ==
      newSelectedSfCategoriesData.find((c) => c.category_id === sfCategory.id)
        .sf_sub_categories.length
    ) {
      newSelected.push(String(sfCategory.id));
    }

    newSelectedSfCategoriesData = JSON.stringify(newSelectedSfCategoriesData);

    const { location, history } = this.props;
    setFilterParams(
      {
        selectedSfCategories: newSelected.join(","),
        selectedSfCategoriesData: newSelectedSfCategoriesData,
        page: null,
        selectedSfSubCategories: ""
      },
      location,
      history
    );
  };

  handleColorSelection = (isChecked, id) => {
    const {
      filter: { selectedColors }
    } = this.props;
    const index = selectedColors.indexOf(id.toString());
    let newSelection = selectedColors.slice();
    if (isChecked) {
      if (index === -1) {
        newSelection.push(id);
      }
    } else {
      if (index > -1) {
        newSelection.splice(index, 1);
      }
    }
    const { location, history } = this.props;
    setFilterParams(
      {
        selectedColors: newSelection.join(","),
        page: null
      },
      location,
      history
    );
  };

  clearColorSelection = () => {
    const { location, history } = this.props;
    setFilterParams(
      {
        selectedColors: null,
        page: null
      },
      location,
      history
    );
  };

  handleQuantitySelection = (newValues) => {
    const [minQuantity, maxQuantity] = newValues;
    this.setState({
      filter: {
        ...this.state.filter,
        minQuantity,
        maxQuantity
      }
    });
    this.handleQuantityChange(newValues);
  };

  handleQuantityChange(newValues) {
    const [minQuantity, maxQuantity] = newValues;
    const { location, history } = this.props;
    setFilterParams(
      {
        minQuantity,
        maxQuantity,
        page: null
      },
      location,
      history
    );
  }

  handleSearch(event) {
    const { value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        query: value
      }
    });
    this.performSearch(value);
  }

  handleQueryChange(value) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value,
        page: null
      },
      location,
      history
    );
  }
  performSearch = (value) => {
    this.getResults(value);
  };

  getResults = (query) => {
    const { clientLocation } = this.props;
    axios
      .get(
        process.env.REACT_APP_API_DOMAIN +
        `/api/portal/inventories/search_filter?query=${query}&location_id=${clientLocation.id}`
      )
      .then(({ data }) => {
        this.setState({
          results: data.products
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSearch = (event) => {
    const { value } = event.target;
    this.setState(
      {
        filter: {
          ...this.state.filter,
          query: value
        }
      },
      this.performSearch(value)
    );
  };

  handleClick = (productType, id) => {
    this.props.history.push(`/shop/${productType}/${id}`);
  };

  handleCategoryClick = (id) => {
    const selected = id;
    this.props.history.push(`/shop?query=&selectedCategories=${selected}`);
    this.setState({
      filter: {
        query: ""
      },
      results: []
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const { query } = this.state.filter;
      this.handleQueryChange(query);
    }
  };

  handleNumberPerChange = (event) => {
    const { location, history } = this.props;
    setFilterParams(
      {
        numberPer: event.target.value
      },
      location,
      history
    );
  };

  notFocused = () => {
    this.setState({
      results: [],
      filter: {
        query: ""
      }
    });
  };

  render() {
    const {
      filter: {
        selectedSfCategories,
        selectedSfSubCategories,
        selectedSfCategoriesData,
        selectedColors,
        numberPer
      },
      loading,
      loaded,
      inventory,
      storefrontShopSetting,
      event
    } = this.props;

    const { filter, results } = this.state;
    const allowedFilterList = storefrontShopSetting.allowedFilters?.split(",");
    const noFilter =
      allowedFilterList.length &&
      !allowedFilterList.some((r) =>
        ["date", "quantity", "color", "category"].includes(r)
      );
    const noItems = inventory.length === 0;

    return (
      <div
        className={classnames({
          shop: true,
          noItemsDeprecated: noItems
        })}
      >
        <PageMetaData setting={storefrontShopSetting} />
        <header>
          <div className='searchFilter'>
            <StandardTextField
              type='search'
              placeholder='Search for Items to Rent...'
              meta={{ error: {} }}
              input={{
                value: filter.query,
                onChange: this.handleSearch,
                onKeyPress: this.handleKeyPress,
                onBlur: this.notFocused
              }}
            />
            {filter.query !== "" && (
              <Suggestions
                results={results}
                handleClick={this.handleClick}
                handleCategoryClick={this.handleCategoryClick}
              />
            )}
          </div>
          {event && event.editingEvent && <EventHeader />}
        </header>
        {!loaded ? (
          <LoadingSpinner />
        ) : (
          <section
            className={classnames({
              storefront: true,
              noFilter: noFilter
            })}
          >
            <StandardSelectField
              className='numberSelect'
              meta={{}}
              input={{
                name: "numberPer",
                value: numberPer,
                onChange: this.handleNumberPerChange
              }}
            >
              <option value='12'>12</option>
              <option value='24'>24</option>
              <option value='48'>48</option>
            </StandardSelectField>
            <MediaBreakpoints
              desktop={
                <CategoryFilterDesktop
                  showFilters={false}
                  handleSfCategorySelected={this.handleSfCategorySelected}
                  handleSfSubCategorySelected={this.handleSfSubCategorySelected}
                  onColorSelection={this.handleColorSelection}
                  onQuantitySelection={this.handleQuantitySelection}
                  selectedSfCategories={selectedSfCategories}
                  selectedSfSubCategories={selectedSfSubCategories}
                  selectedColors={selectedColors}
                  selectedMinQuantity={filter.minQuantity || 0}
                  selectedMaxQuantity={filter.maxQuantity || 500}
                  clearColorSelection={this.clearColorSelection}
                  editingEvent={event && event.editingEvent}
                  selectedSfCategoriesData={selectedSfCategoriesData}
                />
              }
              mobile={
                <CategoryFilterMobile
                  showFilters={false}
                  handleSfCategorySelected={this.handleSfCategorySelected}
                  handleSfSubCategorySelected={this.handleSfSubCategorySelected}
                  onColorSelection={this.handleColorSelection}
                  onQuantitySelection={this.handleQuantitySelection}
                  selectedSfSubCategories={selectedSfSubCategories}
                  selectedSfCategories={selectedSfCategories}
                  selectedColors={selectedColors}
                  selectedMinQuantity={filter.minQuantity || 0}
                  selectedMaxQuantity={filter.maxQuantity || 500}
                  clearColorSelection={this.clearColorSelection}
                  selectedSfCategoriesData={selectedSfCategoriesData}
                  forConnect
                />
              }
            />
            {loading ? (
              <LoadingSpinner />
            ) : noItems ? (
              <BusinessNoItemsPage />
            ) : (
              <AvailableItemList />
            )}
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const newFilter = filterFromPropsAndState(ownProps);

  const selectedCategories = filterObjToArray(newFilter.selectedCategories);
  const selectedSubCategories = filterObjToArray(newFilter.selectedSubCategories);
  const selectedColors = filterObjToArray(newFilter.selectedColors);
  const selectedSfCategories = filterObjToArray(newFilter.selectedSfCategories);
  const selectedSfSubCategories = filterObjToArray(newFilter.selectedSfSubCategories);

  const selectedSfCategoriesData = newFilter.selectedSfCategoriesData
    ? JSON.parse(newFilter.selectedSfCategoriesData)
    : [];
  const {
    event,
    event: { eventStart, eventStartTime, eventEnd, eventEndTime }
  } = state.cart;
  const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
  const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
  const { inventory, loading, loaded } = state.products;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  return {
    filter: {
      ...newFilter,
      selectedCategories,
      selectedSubCategories,
      selectedColors,
      selectedSfCategories,
      selectedSfCategoriesData,
      selectedSfSubCategories,
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime
    },
    event,
    inventory,
    loading,
    loaded,
    clientLocation: state.locations.location,
    location: ownProps.location,
    storefrontShopSetting
  };
};

export default connect(mapStateToProps, actions)(SearchItems);
