import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import RentalSection from "./RentalSection";

class RentalItemList extends React.Component {
  render() {
    const { rental } = this.props;

    const { approvalStatus, rentalSections, rentalSetting } = rental;
    const {
      showDiscountsOnPricing,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      showSubtotalsInSections
    } = rentalSetting;
    const tileView = showItemImages;
    return (
      <section className='items'>
        {rentalSections
          .filter(
            (section) =>
              !section.sectionMemberships.every(
                (sm) => sm.rentalInventoryType === "RentalAccessory"
              )
          )
          .map(
            (section) =>
              section.sectionMemberships.length > 0 && (
                <RentalSection
                  key={section.id}
                  rental={rental}
                  section={section}
                  tileView={tileView}
                  approvalStatus={approvalStatus}
                  showDiscountsOnPricing={showDiscountsOnPricing}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  showSubtotalsInSections={showSubtotalsInSections}
                />
              )
          )}
      </section>
    );
  }
}

export default connect(null, actions)(RentalItemList);
