import React from 'react';
import { FormattedDate } from 'react-intl';
import { getTrackingUrl } from 'HelperFunctions/getTrackingUrl';
import { carrierTypes } from 'Constants/displayConstants';
import { renderHideDeliveryTimes } from './utils/renderHideDeliveryTimes';
import SetByTimeInfo from './SetByTime';

const RentalDelivery = ({
  delivery,
  checkoutWindowEnd,
  checkinWindowStart,
  hideDeliveryInfo,
  hideDeliveryTimes,
  schedule,
  rental,
}) => {
  const timeZone = schedule.timeZone;
  const pickup = {
    pickup_same_as_delivery: rental.pickupSameAsDelivery,
    pickup_address_location_name: rental.pickupAddressLocationName,
    pickup_address_street_address_1: rental.pickupAddressStreetAddress1,
    pickup_address_street_address_2: rental.pickupAddressStreetAddress2,
    pickup_address_city: rental.pickupAddressCity,
    pickup_address_locale: rental.pickupAddressLocale,
    pickup_address_postal_code: rental.pickupAddressPostalCode,
    pickup_address_country: rental.pickupAddressCountry,
  };
  return (
    <section className='delivery'>
      <h4>Delivery Info</h4>
      {hideDeliveryInfo ? (
        <div className='details'>TBD</div>
      ) : (
        <div className='details'>
          <div>
            {delivery.delivery_address_location_name && (
              <label>{delivery.delivery_address_location_name}</label>
            )}
            <div>
              {delivery.delivery_address_street_address_1},{' '}
              {delivery.delivery_address_street_address_2 &&
                delivery.delivery_address_street_address_2}
            </div>

            <div>
              {delivery.delivery_address_city},{' '}
              {delivery.delivery_address_locale}{' '}
              {delivery.delivery_address_postal_code},{' '}
              {delivery.delivery_address_country}
            </div>
          </div>
          <br />

          <div>
            <label>Ship - Deliver</label>
            {!!delivery.carrier_type && (
              <p>{carrierTypes[delivery.carrier_type]}</p>
            )}
            {delivery.delivery_tracking_number && delivery.carrier_type && (
              <a
                className='trackingLink'
                href={getTrackingUrl(
                  delivery.delivery_tracking_number,
                  delivery.carrier_type
                )}
                target='_blank'
                rel='noopener noreferrer'
              >
                {delivery.delivery_tracking_number}
              </a>
            )}
            {delivery.delivery_tracking_number && !delivery.carrier_type && (
              <p>{delivery.delivery_tracking_number}</p>
            )}
            <h6>Estimated</h6>

            <div>
              <FormattedDate
                weekday='short'
                month='short'
                day='numeric'
                year='numeric'
                value={checkoutWindowEnd}
              />
              <br />
              {renderHideDeliveryTimes(
                checkoutWindowEnd,
                timeZone,
                hideDeliveryTimes
              )}
            </div>
          </div>
          <div>
            {!pickup.pickup_same_as_delivery && (
              <div>
                <h4>Pick Up Address</h4>
                <div>
                  {pickup.pickup_address_location_name && (
                    <strong>{pickup.pickup_address_location_name}</strong>
                  )}
                  <div>{pickup.pickup_address_street_address_1}</div>
                  {pickup.pickup_address_street_address_2 && (
                    <div># {pickup.pickup_address_street_address_2}</div>
                  )}
                  <div>
                    {pickup.pickup_address_city &&
                      `${pickup.pickup_address_city}, `}
                    {pickup.pickup_address_locale}{' '}
                    {pickup.pickup_address_postal_code &&
                      `${pickup.pickup_address_postal_code}, `}
                    {pickup.pickup_address_country}
                  </div>
                </div>
              </div>
            )}
            {!pickup.pickup_same_as_delivery && <br />}
            <label>Ship - Return</label>
            {!!delivery.carrier_type && (
              <p>{carrierTypes[delivery.carrier_type]}</p>
            )}
            {delivery.return_tracking_number && delivery.carrier_type && (
              <a
                className='trackingLink'
                href={getTrackingUrl(
                  delivery.return_tracking_number,
                  delivery.carrier_type
                )}
                target='_blank'
                rel='noopener noreferrer'
              >
                {delivery.return_tracking_number}
              </a>
            )}
            {delivery.return_tracking_number && !delivery.carrier_type && (
              <p>{delivery.return_tracking_number}</p>
            )}
            <h6>Estimated</h6>
            <div>
              <FormattedDate
                weekday='short'
                month='short'
                day='numeric'
                year='numeric'
                value={checkinWindowStart}
              />
              <br />
              {renderHideDeliveryTimes(
                checkinWindowStart,
                timeZone,
                hideDeliveryTimes
              )}
            </div>
          </div>
          {rental.hasSetByTime && rental.location.showSetByTimeToCustomers && (
            <SetByTimeInfo {...{ rental, schedule }} />
          )}
        </div>
      )}
    </section>
  );
};

export default RentalDelivery;
