import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import StandardUploadField from "Utils/redux_form_inputs/StandardUploadField";
import StandardCheckbox from "Utils/redux_form_inputs/StandardCheckbox";
import StandardDatePicker from "Utils/redux_form_inputs/StandardDatePicker";
import DocumentSelectOptions from "./DocumentSelectOptions";
import Validator from "HelperFunctions/validator";
import { setErrors } from "Actions";

const validations = {
  name: {
    required: {
      value: true,
      message: "Document name is required"
    }
  },
  documentType: {
    required: {
      value: true,
      message: "Document type is required"
    },
    oneOf: {
      value: [
        "rental_agreement",
        "insurance",
        "legal_documents",
        "other",
        "signed_agreement",
        "floor_plan"
      ]
    }
  },
  file: {
    required: {
      value: true,
      message: "Document file is required"
    }
  }
};

class NewDocumentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showExpirationDate: false,
      expiresAt: null,
    }
  }

  toggleExpirationDate = () => {
    this.setState({showExpirationDate: !this.state.showExpirationDate})
  }

  render() {
    const { handleSubmit } = this.props;
    const { showExpirationDate } = this.state;
    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <Field
            name="file"
            placeholder="Upload your document"
            component={StandardUploadField}
          />
          <Field
            name="name"
            placeholder="Document Name"
            component={StandardTextField}
            type="text"
          />
          <Field name="documentType" component={StandardSelectField}>
            {DocumentSelectOptions({ forCustomer: true })}
          </Field>
          <Field 
            onChange={this.toggleExpirationDate}
            name="expirable"
            label={
              <div className="hint">Expiration Date<span>Select this checkbox if you would like to include an expiration date with your document.</span></div>
            }
            component={StandardCheckbox}
          />
          { showExpirationDate &&
            <Field name="expiresAt" className="documentDatepicker" component={StandardDatePicker} />
          }
        </div>
        <div className="actions">
          <button className="full" type="submit">
            Upload New Document
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: "newDocument",
  validate: Validator(validations),
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(NewDocumentForm);
