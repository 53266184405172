import React from "react";
import { NavLink } from "react-router-dom";
import { Cart } from "Utils/SvgIcons";
import { connect } from "react-redux";

const SignedOutNav = props => (
  <nav className="mainMenu">
    <NavLink to="/login">Sign In</NavLink>
    <NavLink to="/signUp">Create Account</NavLink>
    {props.storefrontShopSetting.shopVisible && 
      props.location.sfSubscriptionTier != "basic" && (
        <NavLink className="icon shoppingCart" to="/cart">
          <Cart />
          <label>
              {
                  (
                      props.event.items.filter(item => item._destroy !== "1").reduce((n, {quantity}) => n + quantity, 0) +
                      props.event.rentalBundles.filter(item => item._destroy !== "1").reduce((n, {quantity}) => n + quantity, 0) +
                      props.event.addOns.filter(item => item._destroy !== "1").reduce((n, {quantity}) => n + quantity, 0)
                  ) || ''
              }
          </label>
        </NavLink>
    )}
  </nav>
);

const mapStateToProps = state => {
  const { event } = state.cart;
  const { location } = state.locations;
  const {
    parsedStorefrontSettings: { storefrontShopSetting }
  } = state.locations;

  return { event, storefrontShopSetting, location };
};

export default connect(mapStateToProps)(SignedOutNav);
