import React from 'react';
import ValidationPopUp from 'Utils/ValidationPopUp';
import SuccessPopUp from 'Utils/SuccessPopUp';
import VersionPopUp from 'Utils/VersionPopUp';
import GlobalLoadingSpinner from 'Utils/GlobalLoadingSpinner';
import GlobalProgressBar from 'Utils/GlobalProgressBar';
import { connect } from 'react-redux';
import Favicon from 'react-favicon';
import GlobalMetaData from 'Utils/GlobalMetaData';

class App extends React.Component {
  render() {
    const { storefrontSetting, location } = this.props;
    return (
      <aside>
        <GlobalMetaData mainSetting={storefrontSetting} title={location.name} />
        <ValidationPopUp />
        <GlobalLoadingSpinner />
        <GlobalProgressBar />
        <SuccessPopUp />
        <VersionPopUp />
        {storefrontSetting.favicon && (
          <Favicon url={storefrontSetting.favicon.imgixUrl} />
        )}
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  const { location, loading, parsedStorefrontSettings } = state.locations;
  const storefrontSetting = parsedStorefrontSettings.mainSetting;
  return { location, loading, storefrontSetting };
};
export default connect(mapStateToProps)(App);
