import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import LoadingSpinner from "Utils/LoadingSpinner";
import {Alert } from "Utils/SvgIcons";
import * as actions from "Actions";

class ProfileNav extends React.Component {
  componentDidMount() {
    const { fetchProfile, location } = this.props; // Redux
    fetchProfile(location.id);
  }
  render() {
    const  { customer, loading } = this.props;
    const expiredDocument = customer.documents && customer.documents.find(d => d.expirable && d.expiresAt && new Date(d.expiresAt) < new Date())
    return (
      loading ? <LoadingSpinner /> :
      <aside className="sideNav">
        <h3>My Profile</h3>
        <nav>
          <NavLink exact to="/profile/info">
            Profile Info
          </NavLink>
          <NavLink to="/profile/paymentMethods">Payment Methods</NavLink>
          <NavLink to="/profile/documents">Documents {expiredDocument && <Alert className='alert' /> }</NavLink>
          <NavLink to="/profile/paymentHistory">Payment History</NavLink>
        </nav>
      </aside>
    );
  }
}


const mapStateToProps = state => {
  const { location } = state.locations;
  const { customer, errors, loading } = state.customer;
  return { customer, errors, loading, location };
};

export default connect(mapStateToProps, actions)(ProfileNav);
