import React, { Component } from "react";
import { Spinner } from "Utils/SvgIcons";

class LoadingSpinner extends Component {
  render() {
    const { loading = true, label } = this.props;

    if (!loading) {
      return null;
    }
    return (
      <div className="spinner">
        <Spinner />
        {label && <p>{label}</p>}
      </div>
    );
  }
}

export default LoadingSpinner;
