import React from 'react';
import ReactTooltip from "react-tooltip";

const OverbookingTooltip = ({ allowOverbooking, numberAvailable }) => {
  return (
    <ReactTooltip
      id={allowOverbooking ? "info" : "overbooking"}
      class="tooltip right"
      place="right"
      type="light"
      effect="solid"
    >
      {
        allowOverbooking ? (
          <div>
            <h3>Item not available for autobook</h3>
            <p>
              You may add this to a quote, but the rental company will have to
              approve it before the reservation is confirmed.
            </p>
          </div>
        ) : (
          <div>
            <p>
              This item is not available at this quantity.
            </p>
            <p>
              Quantity available: {numberAvailable}
            </p>
            <p>
              Change date/quantity and try again.
            </p>
          </div>
        )
      }
    </ReactTooltip>
  )
}

export default OverbookingTooltip;
