import React from "react";
import Cleave from "cleave.js/react";

class USACodeInput extends React.Component {
  handleKeyPress = evt => {
    var theEvent = evt || window.event;
    var key;
    // Handle paste
    if (theEvent.type === "paste") {
      key = window.event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[a-z0-9- ]*$/i;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  render() {
    return (
      <input {...this.props} type="text" onKeyPress={this.handleKeyPress} />
    );
  }
}

class CANCodeInput extends React.Component {
  render() {
    return <Cleave {...this.props} options={{ blocks: [3, 3] }} />;
  }
}

class PostalCodeInput extends React.Component {
  handleKeyPress = evt => {
    var theEvent = evt || window.event;
    var key;
    // Handle paste
    if (theEvent.type === "paste") {
      key = window.event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[a-z0-9- ]*$/i;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  render() {
    const { country, ...otherProps } = this.props;
    if (country === "CAN") {
      return <CANCodeInput {...otherProps} />;
    } else {
      return <USACodeInput {...otherProps} />;
    }
  }
}

export default PostalCodeInput;
