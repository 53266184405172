import { LOCATION_CHANGE } from "react-router-redux";
import {
  FETCH_RENTALS_REQUEST,
  FETCH_RENTALS_SUCCESS,
  FETCH_RENTALS_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  rentals: [],
  pagination: {},
  totalCount: 0,
  errors: {},
  loading: false,
  updating: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RENTALS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_RENTALS_SUCCESS:
      return {
        ...state,
        rentals: action.payload.rentals,
        totalCount: action.payload.meta.totalCount,
        loading: false
      };
    case FETCH_RENTALS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        errors: INITIAL_STATE.errors
      };
    default:
      return state;
  }
};
