import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Validator from "HelperFunctions/validator";
import { setErrors } from "Actions";

const validations = {
  email: {
    required: {
      value: true,
      message: "Email is required"
    }
  }
};

const ForgotPasswordForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="fields">
        <Field
          name="email"
          component={StandardTextField}
          type="text"
          placeholder="Email"
        />
      </div>
      <div className="actions">
        <button className="full" type="submit" disabled={submitting}>
          Send Password Reset
        </button>
      </div>
    </form>
  );
};
export default reduxForm({
  form: "ForgotPassword", // a unique identifier for this form
  validate: Validator(validations),
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(ForgotPasswordForm);
