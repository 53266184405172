import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import NumberInput from 'Utils/NumberInput';
import { Items, Info, Alert } from 'Utils/SvgIcons';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import {
  showSelectedPrice,
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from 'HelperFunctions/rental_items';
import {
  getFirstAvailablePricingType,
  getFirstAvailablePricingObject,
} from 'HelperFunctions/rentals';
import Imgix from 'react-imgix';
import classnames from 'classnames';
import OverbookingTooltip from './OverbookingTooltip';
import {
  getNumberAvailable,
  checkInventoryObjectAvailabilityLoading,
} from 'HelperFunctions/availability';
import SelectDatesModal from './SelectDatesModal';

class AvailableItemCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: props.quantity || '',
      submitted: false,
      open: false,
      selectDatesModalOpen: false,
    };
  }

  handleQuantityChange = (event) => {
    this.setState({
      quantity: toNumber(event.target.value),
      submitted: false,
    });
  };

  handleAddToQuote = () => {
    const { quantity } = this.state;
    const {
      location,
      event: { items },
    } = this.props;

    if (
      location.overbookingOnSf === 'disallowed' &&
      this.userHasNotPickedStartAndEndDateAndTimes()
    ) {
      this.setState({ selectDatesModalOpen: true });
      return;
    }

    if (quantity) {
      if (this.props.product.productType === 'items') {
        this.handleAddItemToQuote(false, items.slice() || []);
      } else if (this.props.product.productType === 'addons') {
        this.handleAddPurchaseToQuote();
      } else {
        this.handleAddBundleToQuote();
      }
    } else {
      this.props.setErrors({ quantity: 'Please enter Quantity.' });
      this.props.setErrorCardId(this.props.product.id);
    }
  };

  handleAddPurchaseToQuote = () => {
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime, addOns },
      product,
      setCartProps,
      location,
    } = this.props;

    const { quantity } = this.state;

    if (quantity > 0) {
      let newAddOns = addOns.slice();
      const oldAddOnIndex = addOns.findIndex(
        (item) => item.product.id === product.id
      );
      let addOnQuantity =
        oldAddOnIndex !== -1
          ? addOns[oldAddOnIndex].quantity + Number(quantity)
          : Number(quantity);
      const selected = showSelectedPrice(
        product,
        addOnQuantity,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        'add_ons',
        location
      );
      const newAddOn = {
        name: product.name,
        type: 'RentalAddOnStandard',
        quantity: addOnQuantity,
        _destroy: '0',
        addOnId: product.id,
        product: product,
        pricing: product.pricing,
        description: product.description,
        holidayHourlyPrice: product.holidayHourlyPrice,
        selectedPrice: selected?.total,
        selectedRate: selected?.rate,
        duration: selected?.duration,
        period: selected?.period,
        notes: product.notes,
      };

      if (oldAddOnIndex !== -1) {
        newAddOns[oldAddOnIndex] = newAddOn;
      } else {
        newAddOns.push(newAddOn);
      }

      setCartProps({
        addOns: newAddOns,
      });

      this.setState({
        submitted: true,
      });
    }
  };

  handleAddItemToQuote(recurse = false, newItems = [], index = 0) {
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime, items },
      product,
      setCartProps,
      setErrors,
      location,
    } = this.props;

    const { quantity } = this.state;

    let currentCommodityRelationship =
      product?.commodityProductRelationships[index];
    let currentCommodity =
      product?.commodityProductRelationships[index]?.commodity;
    let currentItem = recurse ? currentCommodity : product;

    if (quantity > 0) {
      const oldItemIndex = items.findIndex((item) =>
        recurse
          ? item.productId === currentCommodity.id
          : item.productId === product.id
      );

      const selected = showSelectedPrice(
        currentItem,
        recurse
          ? currentCommodityRelationship.quantity * Number(quantity)
          : Number(quantity),
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        'items',
        location
      );

      const newItem = {
        name: currentItem.name,
        type: 'RentalItemStandard',
        quantity: recurse
          ? oldItemIndex !== -1
            ? newItems[oldItemIndex].quantity +
              currentCommodityRelationship.quantity * Number(quantity)
            : currentCommodityRelationship.quantity * Number(quantity)
          : Number(quantity),
        productId: currentItem.id,
        product: currentItem,
        defaultPricing: currentItem.defaultPricing,
        hourlyPrice: currentItem.hourlyPrice,
        halfDayPrice: currentItem.halfDayPrice,
        dailyPrice: currentItem.dailyPrice,
        weeklyPrice: currentItem.weeklyPrice,
        monthlyPrice: currentItem.monthlyPrice,
        // flatPrice: product.flatPrice,
        flatPrices: currentItem.flatPrices.map((flatPrice) => ({
          id: '',
          name: flatPrice.name,
          amount: flatPrice.amount,
          pricingLabelId: flatPrice.pricing_label_id,
        })),
        pricing: currentItem.pricing,
        description: currentItem.description,
        holidayHourlyPrice: currentItem.holidayHourlyPrice,
        selectedPrice: selected.total,
        selectedRate: selected.rate,
        duration: selected.duration,
        period: selected.period,
        taxExempt: currentItem.taxExempt,
        linkedItem: recurse ? true : false,
        linkedItemId: product.id,
        linkedQuantity: recurse
          ? oldItemIndex !== -1
            ? newItems[oldItemIndex].linkedQuantity +
              currentCommodityRelationship.quantity * Number(quantity)
            : currentCommodityRelationship.quantity * Number(quantity)
          : 0,
        unlinkedQuantity: recurse ? 0 : Number(quantity),
        linkedTo: recurse
          ? oldItemIndex !== -1
            ? [
                ...newItems[oldItemIndex].linkedTo,
                {
                  id: product.id,
                  quantity:
                    currentCommodityRelationship.quantity * Number(quantity),
                },
              ]
            : [
                {
                  id: product.id,
                  quantity:
                    currentCommodityRelationship.quantity * Number(quantity),
                },
              ]
          : [],
      };
      if (oldItemIndex !== -1) {
        newItems[oldItemIndex] = newItem;
      } else {
        newItems.push(newItem);
      }

      if (product.commodityProductRelationships && !recurse) {
        this.handleAddCommodityItemToQuote(
          'item',
          product.commodityProductRelationships,
          newItems,
          newItems.length
        );
      }

      if (product.addOnProductRelationships && !recurse) {
        this.handleAddCommodityItemToQuote(
          'addOn',
          product.addOnProductRelationships,
          newItems,
          newItems.length
        );
      }

      setCartProps({
        items: newItems,
      });
      this.setState({
        submitted: true,
      });
    } else {
      setErrors('Amount must be greater than zero.');
    }
  }

  handleAddCommodityItemToQuote(
    type,
    commodityRelationships,
    newItems,
    itemIndex
  ) {
    commodityRelationships.map((itemRelationships, index) => {
      if (type == 'item') {
        this.handleAddItemToQuote(true, newItems, index, itemIndex);
      } else if (type == 'addOn') {
        this.handleAddPurchaseToQuote(true, [], index, itemIndex);
      }
    });
  }

  handleAddBundleToQuote = () => {
    const {
      event: { rentalBundles },
      product: bundle,
      addRentalBundle,
      updateRentalBundle,
      setErrors,
      setErrorCardId,
    } = this.props;
    const { quantity } = this.state;

    if (quantity > 0) {
      const rentalBundleIndex = rentalBundles.findIndex(
        (item) => item.bundleId === bundle.id
      );
      if (rentalBundleIndex !== -1) {
        let bundleQuantity =
          rentalBundles[rentalBundleIndex].quantity + Number(quantity);
        updateRentalBundle('quantity', bundleQuantity, rentalBundleIndex);
      } else {
        addRentalBundle(bundle, Number(quantity));
      }
      this.setState({
        submitted: true,
      });
      setErrorCardId(null);
    } else {
      setErrors('Amount must be greater than zero.');
      setErrorCardId(bundle.id);
    }
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderPricing = (product) => {
    if (hasSelectedFlatPrice(product, product.productType)) {
      const selectedFlatPrice = getSelectedFlatPrice(product);
      if (selectedFlatPrice) {
        return (
          <label>
            <CurrencyLabel value={selectedFlatPrice.amount} />/ Flat:{' '}
            {selectedFlatPrice.name}
          </label>
        );
      }
    } else {
      const firstAvailablePricingType = getFirstAvailablePricingType(
        product,
        product.productType
      );

      if (firstAvailablePricingType) {
        const pricingObject = getFirstAvailablePricingObject(product);
        return (
          <label>
            <CurrencyLabel value={pricingObject.amount} />/{' '}
            {pricingObject.label}
          </label>
        );
      }
    }

    return <label>Price On Request</label>;
  };

  userHasNotPickedStartAndEndDateAndTimes = () => {
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime },
    } = this.props;

    return !eventStart || !eventStartTime || !eventEnd || !eventEndTime;
  };

  closeSelectDatesModal = () => {
    this.setState({ selectDatesModalOpen: false });
  };

  render() {
    const { product, storefrontShopSetting, availability } = this.props;
    const { quantity, submitted } = this.state;

    const isAvailabilityLoading = checkInventoryObjectAvailabilityLoading(
      availability,
      product.id,
      product.productType
    );

    // TODO: Memoize it so it doesn't have to run every time the input value changes
    const numberAvailable = getNumberAvailable(
      availability,
      product.id,
      product.productType
    );

    const overbookingDisallowed =
      quantity > numberAvailable && !product.allowOverbooking;

    return (
      <div className='item'>
        <Link
          to={`/shop/${product.productType}/${product.token}/${product.slug}`}
        >
          <figure>
            {product.pictures[0] ? (
              <Imgix
                src={product.pictures[0].imgixUrl}
                alt={product.name}
                height={288}
                width={510}
              />
            ) : (
              <Items />
            )}
          </figure>
        </Link>
        {storefrontShopSetting.shopShowItemPricing &&
          product.showPriceStorefront &&
          this.renderPricing(product)}
        <strong>{product.name}</strong>
        <div>
          <NumberInput
            disabled={isAvailabilityLoading}
            type='text'
            placeholder='Qty'
            value={quantity}
            onChange={this.handleQuantityChange}
          />
          {quantity > numberAvailable && (
            <div
              className={classnames({
                info: true,
                overbooking: overbookingDisallowed,
              })}
              data-tip
              data-for={product.allowOverbooking ? 'info' : 'overbooking'}
              data-event='click'
              data-event-off='mouseleave'
            >
              {product.allowOverbooking ? <Info /> : <Alert />}
            </div>
          )}
          {quantity > numberAvailable && (
            <OverbookingTooltip
              allowOverbooking={product.allowOverbooking}
              numberAvailable={numberAvailable}
            />
          )}
          {submitted ? (
            <Link className='btn' to='/cart'>
              Added
            </Link>
          ) : (
            <button
              className={classnames({
                btn: true,
              })}
              onClick={this.handleAddToQuote}
              disabled={overbookingDisallowed}
            >
              Add
            </button>
          )}
        </div>
        <SelectDatesModal
          onClick={this.closeSelectDatesModal}
          isOpen={this.state.selectDatesModalOpen}
          onToggle={this.closeSelectDatesModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    parsedStorefrontSettings: { storefrontShopSetting },
    location,
  } = state.locations;
  const { event } = state.cart;
  const { errors } = state.dashboard;

  const { availability } = state;

  return { storefrontShopSetting, event, errors, location, availability };
};

export default withRouter(connect(mapStateToProps, actions)(AvailableItemCard));
