import { cloneDeep } from '@apollo/client/utilities';
import { showSelectedPrice } from '../../../helper_functions/rental_items';
import { apolloClient } from '../../Root';
import { GetAddOns } from '../ExternalCart.graphql';

const fetchAddOns = async (id, locationId) => {
  return await apolloClient.query({
    query: GetAddOns,
    variables: { search: '*', id, locationId },
  });
};

export const handleResponseErrors = (response) => {
  if (response?.errors?.length > 0) {
    return {
      success: false,
      reason: 'An error occurred while loading add on details.',
    };
  }
  return null;
};

export const formatAddOns = (detailedAddOns, items, eventStart, eventEnd) => {
  const formattedAddOns = detailedAddOns.map(formatAddOnAsRentalAddOn);

  formattedAddOns.forEach((item) => {
    const product = items.find(
      (addOn) => Number(addOn.id) === Number(item.addOnId)
    );

    item.quantity = product?.quantity ?? 0;

    initializeAddOnPricingData(item, eventStart, eventEnd);
  });

  return formattedAddOns;
};

const formatAddOnAsRentalAddOn = (addOn) => {
  const { id, name, description, pricing, notes, taxExempt } = addOn;

  const addOnWithCastedId = { ...addOn, id: Number(addOn.id) };

  return {
    addOnId: Number(id),
    name: name,
    description: description,
    quantity: 0,
    product: cloneDeep(addOnWithCastedId),
    addOn: cloneDeep(addOnWithCastedId),
    type: 'RentalAddOnStandard',
    pricing,
    taxExempt,
    notes,
    _destroy: '0',
  };
};

export const formatLinkedAddOnAsRentalAddOn = (
  addOnProductRelationship,
  productQuantity,
  productId
) => {
  const quantity = productQuantity * addOnProductRelationship.quantity;

  const rentalAddOn = formatAddOnAsRentalAddOn(addOnProductRelationship.addOn);

  Object.assign(rentalAddOn, {
    quantity,
    linkedItem: true,
    linkedItemId: Number(productId),
    unlinkedQuantity: 0,
  });

  return rentalAddOn;
};

export const initializeAddOnPricingData = (
  rentalAddOn,
  eventStart = new Date(),
  eventEnd = new Date()
) => {
  const { total, rate, duration, period } = showSelectedPrice(
    rentalAddOn,
    Number(rentalAddOn.quantity),
    eventStart,
    eventEnd,
    'add_ons',
    rentalAddOn.product.location
  );

  Object.assign(rentalAddOn, {
    selectedPrice: total,
    selectedRate: rate,
    duration,
    period,
  });
};

// @param items [{ id: string, quantity: number }]
export const fetchAndConvertAddOnsToRentalAddOns = async ({
  items,
  eventStart,
  eventEnd,
  locationId,
}) => {
  const id = items.map((addOn) => addOn.id);

  if (id.length === 0) return { success: true, rentalObjects: [] };

  try {
    const response = await fetchAddOns(id, locationId);

    const errors = handleResponseErrors(response);

    if (errors) return { ...errors, rentalObjects: [] };

    const detailedAddOns = response.data?.getAddOns ?? [];

    const formattedAddOns = formatAddOns(
      detailedAddOns,
      items,
      eventStart,
      eventEnd
    );

    return { success: true, rentalObjects: formattedAddOns };
  } catch (err) {
    return {
      success: false,
      reason: 'An error occurred during cart check out.',
      rentalObjects: [],
    };
  }
};
