import {
  FETCH_GUEST_DATA_REQUEST,
  FETCH_GUEST_DATA_SUCCESS,
  FETCH_GUEST_DATA_FAILURE,
  CHANGE_STEP,
  CHANGE_SIGNING,
  RECEIVE_GUEST_RENTAL
} from "Constants/redux";

const INITIAL_STATE = {
  step: "start",
  signing: false,
  location: null,
  rental: null,
  customer: null,
  dataLoading: "loading"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GUEST_DATA_REQUEST:
      return {
        ...state,
        dataLoading: "loading"
      };
    case FETCH_GUEST_DATA_SUCCESS:
      const customer = action.payload.customer || action.payload.customerLocationRelationship || action.payload.company;
      return {
        ...state,
        rental: action.payload.rental,
        location: action.payload.location,
        customer: customer,
        customerType: action.payload.customerType,
        dataLoading: "success"
      };
    case FETCH_GUEST_DATA_FAILURE:
      return {
        ...state,
        dataLoading: "missing"
      };
    case RECEIVE_GUEST_RENTAL:
      return {
        ...state,
        rental: action.payload.rental
      };
    case CHANGE_STEP:
      return {
        ...state,
        step: action.payload.step
      };
    case CHANGE_SIGNING:
      return {
        ...state,
        signing: action.payload.signing
      };
    default:
      return state;
  }
};
