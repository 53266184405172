import React from "react";
import PasswordResetForm from "Components/home/PasswordResetForm";
import { Link } from "react-router-dom";
import HeroImage from "Components/home/widgets/HeroImage";
import * as actions from "Actions";
import { connect } from "react-redux";
import { parseQuery } from "HelperFunctions/QueryString";

class PasswordReset extends React.Component {
  handleSubmit = data => {
    this.props.resetPassword({
      ...data,
      query: parseQuery(this.props.location.search)
    });
  };
  render() {
    const failed = parseQuery(this.props.location.search).success === "false";
    return (
      <section className="password">
        <HeroImage />
        <div className="details">
          <h1>Password Reset</h1>
          <p>Track rentals, make payments, request changes. You made it!</p>
        </div>
        {failed ? (
          <div className="passwordForm">
            <p>Oops! Your password reset has expired</p>
            <div className="actions">
              <Link className="btn full" to="/forgotPassword">
                Try sending another
              </Link>
            </div>
          </div>
        ) : (
          <div className="passwordForm">
            <PasswordResetForm onSubmit={this.handleSubmit} />
          </div>
        )}
      </section>
    );
  }
}

export default connect(null, actions)(PasswordReset);
