/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Suggestions = props => {
  const options = props.results.map(result => (
    <li key={result.id}>
      {result.productType === "categories" ? (
        <a onMouseDown={() => props.handleCategoryClick(result.id)}>
          In -> {result.name}
        </a>
      ) : (
        <a onMouseDown={() => props.handleClick(result.productType, result.id)}>
          {result.name}
        </a>
      )}
    </li>
  ));
  return <ul>{options}</ul>;
};

export default Suggestions;
