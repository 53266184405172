import axios from "axios";
import { saveAuthToken, getAuthToken, ROOT_URL } from "Api";
import { setErrors } from "./SnackbarActions";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

export const getDisclaimer = (rentalToken, DisclaimerId, setDisclaimerUrl) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Generating disclaimer..."));

    return axios
      .get(
        `${ROOT_URL}/portal/rentals/${rentalToken}/disclaimer/${DisclaimerId}`, {
        headers: getAuthToken()
      })
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        const { url } = response.data;
        setDisclaimerUrl(url);
      })
      .catch(err => {
        console.log(err);
        dispatch(setErrors(err.response.data));
        dispatch(closeLoadingSpinner());
      });

  };
};
