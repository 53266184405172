export const localeToCurrency = {
  "en-US": "USD",
  "en-CA": "CAD"
};

export const localeToCurrencySymbol = {
  "en-US": "$",
  "en-CA": "CAD "
};

export const localeToCurrencyText = {
  "en-US": "Dollar ($)",
  "en-CA": "(Dollar) CAD"
};
