import { cloneDeep } from '@apollo/client/utilities';
import { showSelectedPrice } from '../../../helper_functions/rental_items';
import { apolloClient } from '../../Root';
import { GetProducts } from '../ExternalCart.graphql';
import {
  formatLinkedAddOnAsRentalAddOn,
  initializeAddOnPricingData,
} from './fetchAndConvertAddOnsToRentalAddOns';

const fetchProducts = async (id, locationId) => {
  return await apolloClient.query({
    query: GetProducts,
    variables: { search: '*', id, locationId },
  });
};

const handleResponseErrors = (response) => {
  if (response?.errors?.length > 0) {
    return {
      success: false,
      reason: 'An error occurred while loading product details.',
    };
  }
  return null;
};

const formatProducts = (detailedProducts, items) => {
  const formattedItems = [];

  detailedProducts.forEach((product) => {
    const { quantity = 0 } =
      items.find((item) => Number(item.id) === Number(product.id)) ?? {};

    formattedItems.push(
      ...formatProductAsRentalItemRefactor(product, quantity)
    );
  });

  return formattedItems;
};

// @param items [{ id: string, quantity: number }]
export const fetchAndConvertProductsToRentalItems = async ({
  items,
  eventStart,
  eventEnd,
  locationId,
}) => {
  const id = items.map((product) => product.id);

  if (id.length === 0) return { success: true, rentalObjects: [] };

  try {
    const response = await fetchProducts(id, locationId);

    const errors = handleResponseErrors(response);

    if (errors) return { ...errors, rentalObjects: [] };

    const detailedProducts = response.data?.getProducts ?? [];

    const formattedItems = formatProducts(detailedProducts, items);

    const rentalObjects = mergeRentalObjectsWithSameProductId(formattedItems);

    initializePricingData(rentalObjects, eventStart, eventEnd);

    return { success: true, rentalObjects };
  } catch (err) {
    console.error(err);

    return {
      success: false,
      reason: 'An error occurred during cart check out.',
      rentalObjects: [],
    };
  }
};

const mergeRentalObjectsWithSameProductId = (rentalObjects) => {
  return rentalObjects.reduce((acc, item) => {
    // Find an item in the accumulator that has the same productId
    const existingItem = acc.find(
      (accItem) => Number(accItem.productId) === Number(item.productId)
    );

    if (existingItem) {
      // If such an item exists, merge the quantities and linkedTo arrays
      existingItem.quantity += item.quantity;
      existingItem.linkedTo = [
        ...(existingItem.linkedTo ?? []),
        ...(item.linkedTo ?? []),
      ];
    } else {
      // If no such item exists, add the current item to the accumulator
      acc.push({ ...item });
    }

    return acc;
  }, []);
};

const initializePricingData = (
  rentalObjects,
  eventStart = new Date(),
  eventEnd = new Date()
) => {
  rentalObjects.forEach((rentalObject) => {
    rentalObject.type === 'RentalItemStandard' &&
      initializeRentalItemPricingData(rentalObject, eventStart, eventEnd);

    rentalObject.type === 'RentalAddOnStandard' &&
      initializeAddOnPricingData(rentalObject, eventStart, eventEnd);
  });
};

const initializeRentalItemPricingData = (
  rentalItem,
  eventStart = new Date(),
  eventEnd = new Date()
) => {
  if (rentalItem.type !== 'RentalItemStandard') return;

  const { total, rate, duration, period } = showSelectedPrice(
    rentalItem,
    Number(rentalItem.quantity),
    eventStart,
    eventEnd,
    'items',
    rentalItem.product.location
  );

  Object.assign(rentalItem, {
    selectedPrice: total,
    selectedRate: rate,
    duration,
    period,
  });
};

const formatLinkedItemAsRentalItem = (
  commodityRelationship,
  originalProductId,
  originalProductQuantity
) => {
  const quantity = originalProductQuantity * commodityRelationship.quantity;

  return {
    ...formatProductAsRentalItemRefactor(commodityRelationship.commodity)[0],
    quantity,
    linkedItem: true,
    linkedItemId: Number(originalProductId),
    unlinkedQuantity: 0,
    linkedTo: [{ id: Number(originalProductId), quantity: quantity }],
  };
};

// @return Requested rental item + rental object representation of linked inventory
const formatProductAsRentalItemRefactor = (product, quantity) => {
  const rentalItems = [
    {
      name: product.name,
      type: 'RentalItemStandard',
      productId: Number(product.id),
      product: cloneDeep(product),
      defaultPricing: product.defaultPricing,
      hourlyPrice: product.hourlyPrice,
      halfDayPrice: product.halfDayPrice,
      dailyPrice: product.dailyPrice,
      weeklyPrice: product.weeklyPrice,
      monthlyPrice: product.monthlyPrice,
      pricing: product.pricing,
      description: product.description,
      holidayHourlyPrice: product.holidayHourlyPrice,
      taxExempt: product.taxExempt,
      quantity,
      linkedItem: false,
      linkedTo: [],
      flatPrices: product.flatPrices.map(
        ({ name, amount, pricingLabelId }) => ({
          id: '',
          name,
          amount,
          pricingLabelId,
        })
      ),
    },
  ];

  if (product.commodityProductRelationships) {
    product.commodityProductRelationships.forEach((relationship) => {
      rentalItems.push(
        formatLinkedItemAsRentalItem(relationship, product.id, quantity)
      );
    });
  }

  if (product.addOnProductRelationships) {
    product.addOnProductRelationships.forEach((relationship) => {
      rentalItems.push(
        formatLinkedAddOnAsRentalAddOn(relationship, quantity, product.id)
      );
    });
  }

  return rentalItems;
};
