export const formatSmartPrincingLabel = (rentalItem, formatter) => {
  switch (rentalItem.period) {
    case 'hourly_price':
      return `${formatter.format(rentalItem.hourlyPrice)} / Hour`
    case 'half_day_price':
      return `${formatter.format(rentalItem.halfDayPrice)} / Half-Day`
    case 'daily_price':
      return `${formatter.format(rentalItem.dailyPrice)} / Day`
    case 'weekly_price':
      return `${formatter.format(rentalItem.weeklyPrice)} / Week`
    case 'monthly_price':
      return `${formatter.format(rentalItem.monthlyPrice)} / Month`
    }
  }
