import React from 'react';
import { Link } from 'react-router-dom';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import { FormattedDate } from 'react-intl';
import RentalDelivery from './RentalDelivery';
import RentalBilling from './RentalBilling';
import RentalItemList from './RentalItemList';
import ConnectPaymentBox from './ConnectPaymentBox';
import DetailedAmountPaid from './DetailedAmountPaid';
import DetailedAmountRefunded from './DetailedAmountRefunded';
import Modal from 'Utils/Modal';
import { Alert, MessageBubble, Document } from 'Utils/SvgIcons';
import NewDocumentForm from 'Components/documents/NewDocumentForm';
import DisclaimerSignatureModal from 'Utils/DisclaimerSignatureModal';
import { isFinalBalanceDue } from 'HelperFunctions/rentals';
import ShowInventoryImagesModal from './ShowInventoryImagesModal';
import axios from 'axios';
import { ROOT_URL } from 'Api';
import CurrencyLabel from 'Utils/CurrencyLabel';
import ChangeRequestDetails from './change_request/ChangeRequestDetails';
import moment from 'moment';
import { capitalizeFirstLetter } from 'HelperFunctions/general';
import { shouldHidePrice } from 'HelperFunctions/HidePricing';
import { getDisclaimerSignatureInfo } from 'HelperFunctions/disclaimers';
import { getSignatureFromRental } from 'HelperFunctions/rentalSignature';

class Rental extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInventoryImagesModal: false,
      rentalPictures: null,
      transactions: [],
      disclaimerUrl: null,
    };
  }
  componentDidMount() {
    this.fetchTransactions();
  }

  fetchTransactions = () => {
    const {
      rental,
      rental: { location },
    } = this.props;
    rental.token &&
      axios
        .get(
          `${ROOT_URL}/portal/rentals/${rental.token}/transactions/?location_id=${location.id}`
        )
        .then((response) => {
          if (response.data.transactions) {
            this.setState({ transactions: response.data.transactions });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
  setDisclaimerUrl = (url) => {
    this.setState({ disclaimerUrl: url });
  }
  handleAddDocument = (values) => {
    const newDocument = {
      document_attributes: {
        name: values.name,
        document_type: values.documentType,
        file: values.file[0],
        visible_to_customer: true,
        expirable: values.expirable,
        expires_at: values.expiresAt,
      },
      _destroy: '0',
    };
    this.props.addDocument(newDocument);
  };
  renderActionButton = (rental) => {
    const { toggleSignModal, togglePayment } = this.props;
    const rentalSignature = getSignatureFromRental(rental, 'customer');
    const { disclaimerSignaturePresent, disclaimerSignatureRequired } =
      getDisclaimerSignatureInfo(rental);

    if (rental.cancelRequested) {
      return (
        <button className='full disabled' disabled>
          Cancellation Pending
        </button>
      );
    } else {
      switch (rental.approvalStatus) {
        case 'draft':
          return (
            <button
              className='full'
              onClick={() => this.handleSubmitQuote(rental)}
            >
              Submit
            </button>
          );
        case 'pending':
        case 'lead':
          return (
            <button className='full disabled' disabled>
              Pending
            </button>
          );
        case 'approved':
          if (
            rental.changeRequest !== null &&
            rental.changeRequest.status === 'pending'
          ) {
            return (
              <button className='full disabled' disabled>
                Changes Requested
              </button>
            );
          } else {
            if (rental.autoBooked) {
              return null;
            } else if (rental.isAllowedToMakePayment) {
              if (isFinalBalanceDue(rental)) {
                return (rental.signatureRequired &&
                  !rental.hasSignature &&
                  !rentalSignature.id) ||
                  (disclaimerSignatureRequired &&
                    !disclaimerSignaturePresent) ? (
                  <button className='btn full' onClick={toggleSignModal}>
                    Signature Required
                  </button>
                ) : (
                  <button className='full alert' onClick={togglePayment}>
                    Pay Final Balance
                  </button>
                );
              } else {
                return (rental.signatureRequired &&
                  !rental.hasSignature &&
                  !rentalSignature.id) ||
                  (disclaimerSignatureRequired &&
                    !disclaimerSignaturePresent) ? (
                  <button className='btn full' onClick={toggleSignModal}>
                    Signature Required
                  </button>
                ) : (
                  <button className='full' onClick={togglePayment}>
                    Make a Payment
                  </button>
                );
              }
            } else {
              return (
                <button className='full disabled' disabled>
                  Approved
                </button>
              );
            }
          }
        default:
          return null;
      }
    }
  };
  viewPhotos = (e) => {
    let batch_number = e.target.id;
    if (batch_number) {
      axios
        .get(
          `${ROOT_URL}/rentals/${this.props.rental.id}/show_rental_pictures/${batch_number}`,
          {
            headers: {
              'location-id': this.props.rental.locationId,
            },
          }
        )
        .then((response) => {
          this.setState({
            rentalPictures: response.data.rentalPictures,
            showInventoryImagesModal: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    const {
      fields: {
        errors,
        checkout,
        checkoutWindowEnd,
        checkout_time,
        checkin,
        checkinWindowStart,
        checkin_time,
        sameAsEventDates,
        event_start_date_time,
        event_end,
        delivery_type,
        delivery_address_location_name,
        delivery_address_street_address_1,
        delivery_address_street_address_2,
        delivery_address_city,
        delivery_address_locale,
        delivery_address_postal_code,
        delivery_address_country,
        paymentOpen,
        documentModalOpen,
        signModalOpen,
        carrier_type,
        delivery_tracking_number,
        return_tracking_number,
      },
      rental,
      disclaimerRentalRelationships,
      location,
      customer,
      onDeliveryChangeSubmit,
      onSubmitSignature,
      onPaymentSuccess,
      togglePayment,
      toggleDocumentModal,
      toggleSignModal,
      storefrontShopSetting,
      creditCardPercent,
      billingLines,
      getDisclaimer,
    } = this.props;
    const delivery = {
      delivery_type,
      delivery_address_location_name,
      delivery_address_street_address_1,
      delivery_address_street_address_2,
      delivery_address_city,
      delivery_address_locale,
      delivery_address_postal_code,
      delivery_address_country,
      carrier_type,
      delivery_tracking_number,
      return_tracking_number,
    };
    const billing = {
      rental_item_total: rental.rentalItemTotal,
      rental_add_on_total: rental.rentalAddOnTotal,
      rental_bundle_total: rental.rentalBundleTotal,
      staffing_total: rental.staffingTotal,
      delivery_cost: rental.deliveryCost,
      discount_total: rental.discountTotal,
      expenseTotal: rental.expenseTotal,
      fee_total: rental.feeTotal,
      sub_total: rental.subTotal,
      subTotalForTax: rental.subTotalForTax,
      tax_total: rental.taxTotal,
      total_custom_tax: rental.totalCustomTax,
      overall_total: rental.overallTotal,
      tapgoods_fee_total: rental.tapgoodsFeeTotal,
      tapgoodsTaxTotal: rental.tapgoodsTaxTotal,
      amount_paid: rental.totalPaid,
      amount_remaining: rental.amountRemaining,
      processingFeeRefunded: rental.processingFeeRefunded,
      tapgoodsFeeRefunded: rental.tapgoodsFeeRefunded,
      tapgoodsTaxRefunded: rental.tapgoodsTaxRefunded,
    };
    let venue = null;
    if (rental.venueRentalRelationship) {
      venue = rental.venueRentalRelationship.venue;
    }
    const customerNotes = rental.notes.filter((n) => n.visibleToCustomer);
    const { showInventoryImagesModal, rentalPictures, transactions, disclaimerUrl } =
      this.state;
    const changeRequests = rental.changeRequests;
    const hasNotes =
      customerNotes.length > 0 ||
      rental.additionalOrderInfo ||
      rental.additionalDeliveryInfo ||
      changeRequests.length > 0;
    const hideSubtotalPricing = shouldHidePrice(
      rental.approvalStatus,
      storefrontShopSetting
    );

    const { disclaimerSignaturePresent, disclaimerSignatureRequired } =
      getDisclaimerSignatureInfo(rental);

    const rentalSignature = getSignatureFromRental(rental, 'customer');

    return (
      <div>
        <RentalItemList rental={rental} />

        <section className='summary'>
          <Link to={`/conversations`}>
            <MessageBubble />
          </Link>
          <div className='billingDetails'>
            {!hideSubtotalPricing && (
              <div>
                <label>Amount</label>
                <CurrencyLabel value={rental.overallTotal} />
              </div>
            )}

            {hideSubtotalPricing ? null : rental.depositComplete ? (
              <div>
                <label>Amount Remaining</label>
                <CurrencyLabel value={rental.amountRemaining} />
              </div>
            ) : (
              <div>
                <label>Deposit Due</label>
                <CurrencyLabel value={rental.minimumDeposit} />
              </div>
            )}

            <div className='date'>
              <label>
                {delivery.delivery_type === 'customer_pick_up'
                  ? 'Pick Up/Drop Off'
                  : 'Delivery'}
              </label>
              {rental.rentalSetting.hideDeliveryTimes ? (
                <span>TBD</span>
              ) : (
                <span>
                  <FormattedDate
                    value={rental.startDate}
                    month='numeric'
                    day='numeric'
                  />
                  {' - '}
                  <FormattedDate
                    value={rental.endDate}
                    month='numeric'
                    year='numeric'
                    day='numeric'
                  />
                </span>
              )}
            </div>
          </div>

          {isFinalBalanceDue(rental) &&
            rental.autoBillEnabled &&
            rental.autoBillCustomer &&
            rental.autoBillCardInfo && (
              <div className='autoBillDetails'>
                <div>
                  <label>Final Balance Due</label>
                  <CurrencyLabel value={rental.amountRemaining} />
                </div>
                <div>
                  <label>Date of Autobill</label>
                  <FormattedDate
                    day='numeric'
                    month='numeric'
                    year='numeric'
                    value={rental.finalPaymentDueDate}
                  />
                </div>
                <div>
                  <label>Autobill Payment Method</label>
                  <span>{rental.autoBillCardInfo}</span>
                </div>
              </div>
            )}

          <div className='actions'>{this.renderActionButton(rental)}</div>

          {!hideSubtotalPricing &&
            isFinalBalanceDue(rental) &&
            !(
              rental.autoBillEnabled &&
              rental.autoBillCustomer &&
              rental.autoBillCardInfo
            ) && (
              <div className='balanceDueDetails'>
                <Alert />
                <CurrencyLabel value={rental.amountRemaining} /> - Balance Due (
                <FormattedDate
                  day='numeric'
                  month='numeric'
                  year='numeric'
                  value={rental.finalPaymentDueDate}
                />
                )
              </div>
            )}

          {rental.paymentTerm && rental.paymentTerm !== 'undefined' && (
            <div className='term'>{rental.paymentTerm.replace(/-/g, ' ')}</div>
          )}

          {rental.isRecurringPayment && rental.recurringPaymentData && (
            <div className='recurringPaymentDetails'>
              <div>
                <label>Recurring Payment Amount</label>
                <CurrencyLabel
                  value={rental.recurringPaymentData.paymentAmount}
                />
              </div>
              <div>
                <label>Start Date</label>
                <span>
                  {moment(rental.recurringPaymentData.startDate).format(
                    'MM/DD/YYYY'
                  )}
                </span>
              </div>
              {rental.recurringTransactionInfo &&
                Object.keys(rental.recurringTransactionInfo)
                  .reverse()
                  .map((itemKey, index) => (
                    <div key={index}>
                      <label>
                        {itemKey === 'endDate'
                          ? 'End Date'
                          : capitalizeFirstLetter(itemKey)}
                      </label>
                      <span>{rental.recurringTransactionInfo[itemKey]}</span>
                    </div>
                  ))}
              <div>
                <label>Next Payment Due On</label>
                <span>{rental.recurringPaymentData.nextDueDateOn}</span>
              </div>
            </div>
          )}
        </section>

        <RentalDelivery
          rental={rental}
          delivery={delivery}
          schedule={rental.schedule}
          hideDeliveryInfo={rental.rentalSetting.hideDeliveryInfo}
          hideDeliveryTimes={rental.rentalSetting.hideDeliveryTimes}
          errors={errors}
          sameAsEventDates={sameAsEventDates}
          checkout={checkout}
          checkoutWindowEnd={checkoutWindowEnd}
          checkout_time={checkout_time}
          checkin={checkin}
          checkinWindowStart={checkinWindowStart}
          checkin_time={checkin_time}
          eventStartDateTime={event_start_date_time}
          eventEndDateTime={event_end}
          onSubmit={onDeliveryChangeSubmit}
        />

        {venue && (
          <section className='venue'>
            <h4>Venue</h4>
            <div>{venue.name}</div>
            <div>{venue.streetAddress1}</div>
            {venue.streetAddress2 && <div># {venue.streetAddress2}</div>}
            <div>
              {venue.city}, {venue.locale} {venue.postalCode}, {venue.country}
            </div>
          </section>
        )}

        <section className='documents'>
          <h4>Documents</h4>
          {rental.documentRentalRelationships.map((d) => {
            let { name, expirable, expiresAt, visibleToCustomer } = d.document;
            if (d.expirable != null) {
              expirable = d.expirable;
              expiresAt = d.expiresAt;
            }
            return (
              visibleToCustomer && (
                <a
                  key={d.id}
                  href={d.document.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Document />
                  {name}
                  {expirable &&
                    expiresAt &&
                    expiresAt !== null &&
                    (new Date(expiresAt) <= new Date() ? (
                      <div className='expiration'>
                        <span className='alert'>
                          <Alert />
                          Expired:{' '}
                          <FormattedDate
                            value={expiresAt}
                            month='numeric'
                            year='numeric'
                            day='numeric'
                          />
                        </span>
                      </div>
                    ) : (
                      expirable && (
                        <div className='expiration'>
                          Expires:{' '}
                          <FormattedDate
                            value={expiresAt}
                            month='numeric'
                            year='numeric'
                            day='numeric'
                          />
                        </div>
                      )
                    ))}
                </a>
              )
            );
          })}
          <div className='actions'>
            <button className='full' onClick={toggleDocumentModal}>
              Upload a Document
            </button>
          </div>
        </section>
        {hasNotes && (
          <section className='notes'>
            <h4>Notes</h4>
            {customerNotes.map((note) => (
              <div key={note.id}>
                <FormattedDate
                  value={note.createdAt}
                  month='numeric'
                  year='numeric'
                  day='numeric'
                  hour='numeric'
                  minute='numeric'
                />
                <div
                  onClick={this.viewPhotos}
                  dangerouslySetInnerHTML={{
                    __html: note.message,
                  }}
                />
              </div>
            ))}
            {rental.additionalOrderInfo && (
              <div>
                <p>
                  <strong>Additional Order Info:</strong>
                </p>
                <p>{rental.additionalOrderInfo}</p>
              </div>
            )}
            {rental.additionalDeliveryInfo && (
              <div>
                <p>
                  <strong>Additional Delivery Info:</strong>
                </p>
                <p>{rental.additionalDeliveryInfo}</p>
              </div>
            )}
            <div>
              <ChangeRequestDetails changeRequests={changeRequests} />
            </div>
          </section>
        )}

        <section className='disclaimers'>
          <h4>Disclaimers</h4>
          {rental.disclaimerRentalRelationships.map((disclaimerContainer) => (
            <div className='disclaimersDetails' key={disclaimerContainer.id}>
              <a onClick={() => getDisclaimer( rental.token, disclaimerContainer.id, this.setDisclaimerUrl )}>
                {disclaimerContainer.title}
              </a>
            </div>
          ))}
        </section>

        <RentalBilling
          rentalId={rental.token}
          rental={rental}
          location={location}
          billing={billing}
          hideSubtotalPricing={hideSubtotalPricing}
          secondary={true}
          billingLines={billingLines}
        />

        <section className='payments'>
          <h4>Payments</h4>
          <div className='billingDetails'>
            {rental.payments &&
              rental.payments.length > 0 &&
              rental.payments.map((payment) => {
                if (
                  payment.status !== 'Authorized' &&
                  payment.status !== 'Success'
                ) {
                  return (
                    <DetailedAmountPaid
                      key={payment.id}
                      payment={payment}
                      ownerView={false}
                      recurringTransactionInfo={rental.recurringTransactionInfo}
                    />
                  );
                }
              })}
            {rental.refunds &&
              rental.refunds.length > 0 &&
              rental.refunds.map((refund) => (
                <DetailedAmountRefunded
                  key={refund.id}
                  refund={refund}
                  ownerView={false}
                />
              ))}
            {!hideSubtotalPricing && (
              <div className='subtotal'>
                <label>Amount Remaining</label>
                <CurrencyLabel value={billing.amount_remaining} />
              </div>
            )}
          </div>
          {rental.isAllowedToMakePayment && (
            <div className='actions'>
              {(rental.signatureRequired &&
                !rental.hasSignature &&
                !rentalSignature.id) ||
              (disclaimerSignatureRequired && !disclaimerSignaturePresent) ? (
                <button className='btn full' onClick={toggleSignModal}>
                  Signature Required
                </button>
              ) : (
                <button className='full' onClick={togglePayment}>
                  Make a Payment
                </button>
              )}
            </div>
          )}
          {transactions && transactions.length > 0 && (
            <div className='ccHolds'>
              <h4>Credit Card Holds</h4>
              {transactions.map((transaction) => {
                if (
                  transaction.status === 'Authorized' ||
                  ((transaction.status === 'Approved' ||
                    transaction.status === 'Success') &&
                    transaction.parentTransactionId != null)
                ) {
                  const ccFee =
                    transaction.amount -
                    transaction.amount *
                      (100 / (100 + Number(creditCardPercent)));
                  return (
                    <div className='authDetails'>
                      <div>
                        {transaction.status === 'Authorized' && (
                          <label>Authorized Holds</label>
                        )}
                        {transaction.status === 'Approved' && (
                          <label>Holds Charged</label>
                        )}
                        {transaction.status === 'Success' && (
                          <label>Holds Released</label>
                        )}
                        <CurrencyLabel value={transaction.amount} />
                        <div>
                          <label>
                            <FormattedDate
                              value={transaction.createdAt}
                              year='numeric'
                              month='numeric'
                              day='numeric'
                            />
                          </label>
                          <label>
                            {capitalizeFirstLetter(
                              transaction.paymentData.type
                            )}
                            {' ' + transaction.paymentData.number}
                          </label>
                          <label style={{ float: 'right', marginRight: '0px' }}>
                            <CurrencyLabel value={ccFee} />
                            {' ' + 'CC Fee'}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}

          <Modal
            title='Disclaimer'
            className='disclaimerModal'
            toggle={() => this.setDisclaimerUrl(null)}
            open={disclaimerUrl !== null}
          >
            <a class="btn full large" href={disclaimerUrl} download>
              Download Disclaimer
            </a>
          </Modal>
          <Modal
            title='Make a Payment'
            className='large paymentModal'
            toggle={togglePayment}
            open={paymentOpen}
          >
            <ConnectPaymentBox
              onPaymentSuccess={onPaymentSuccess}
              rentalId={rental.token}
              rental={rental}
              location={location}
              customer={customer}
            />
          </Modal>
          <Modal
            title='Upload Document'
            toggle={toggleDocumentModal}
            open={documentModalOpen}
          >
            <NewDocumentForm onSubmit={this.handleAddDocument} />
          </Modal>
          <DisclaimerSignatureModal
            open={signModalOpen}
            toggle={toggleSignModal}
            rental={rental}
            disclaimerRentalRelationships={disclaimerRentalRelationships}
            onSubmitSignature={onSubmitSignature}
          />
        </section>
        <ShowInventoryImagesModal
          show={showInventoryImagesModal}
          className='show-inventory-images-modal'
          pictures={rentalPictures}
          title={
            rentalPictures &&
            rentalPictures[0] &&
            rentalPictures[0].humanizePictureType
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { parsedStorefrontSettings, location } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  const { creditCardPercent } = location;
  return { storefrontShopSetting, creditCardPercent };
};
export default connect(mapStateToProps, actions)(Rental);
