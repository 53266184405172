import React from "react";
import { connect } from "react-redux";
import axios from "axios";

class Agreement extends React.Component {
  state = {
    agreement: null
  };
  componentDidMount() {
    this.loadAgreement();
  }
  loadAgreement = () => {
    const { location } = this.props;
    if (location && location.hasAgreement) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/api/portal/locations/${location.id}/default_agreement`
        )
        .then(response => {
          this.setState({
            agreement: response.data
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  render() {
    const { agreement } = this.state;
    if (agreement) {
      return (
        <section className="agreement">
          <div
            dangerouslySetInnerHTML={{
              __html: agreement.description
            }}
          />
        </section>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = state.locations;
  return { location };
};

export default connect(mapStateToProps)(Agreement);
