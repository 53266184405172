import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import StandardYearSelect from "Utils/redux_form_inputs/StandardYearSelect";
import StandardMonthSelect from "Utils/redux_form_inputs/StandardMonthSelect";
import StandardRegionDropdown from "Utils/redux_form_inputs/StandardRegionDropdown";
import StandardPostalCodeInput from "Utils/redux_form_inputs/StandardPostalCodeInput";
import Checkbox from "Utils/Checkbox";
import Validator from "HelperFunctions/validator";
import { Link } from "react-router-dom";
import { setErrors } from "Actions";
import { compose } from "redux";

// This component is same as CreditCardForm only with nickname field required.
// If the company is the primary contact on a rental, we want the CC nicknames to be required
// so the newly added CC is identifiable on 2x.

// Redux-form validations written in json

const validations = {
  country: {
    required: {
      value: true,
      message: "Country/Region is required"
    }
  },
  street_address1: {
    required: {
      value: true,
      message: "Street Address is required"
    }
  },
  city: {
    required: {
      value: true,
      message: "City is required"
    }
  },
  locale: {
    required: {
      value: true,
      message: "State is required"
    }
  },
  postal_code: {
    required: {
      value: true,
      message: "ZIP is required"
    }
  },
  nickname: {
    required: {
      value: true,
      message: "Nick Name is required"
    },
    maxLength: {
      value: 10,
      message: "Nickname should be 10 characters or less"
    }
  },
  name: {
    required: {
      value: true,
      message: "Name On Card is required"
    }
  },
  number: {
    required: {
      value: true,
      message: "Card Number is required"
    }
  },
  exp_month: {
    required: {
      value: true,
      message: "Month is required"
    }
  },
  exp_year: {
    required: {
      value: true,
      message: "Year is required"
    }
  },
  cvc: {
    required: {
      value: true,
      message: "CVV is required"
    }
  }
};

// This HOC has access to our own redux, plus it pulls in redux-form HOC,
// which does the validations for us.

class CreditCardFormForCompany extends React.Component {
  render() {
    const {
      formValues,
      handleSubmit,
      copyAddress,
      type,
      withPaymentType,
      hideCopy,
      paymentProcessor
    } = this.props; // From Redux

    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <label>Billing Information</label>
          {!hideCopy && (
            <Checkbox
              onCheck={copyAddress}
              label="Copy Billing Address On File"
            />
          )}
          <Field
            name="country"
            label="Country/Region"
            component={StandardSelectField}
          >
            <option value="">Select country</option>
            <option value="USA">United States</option>
            <option value="CAN">Canada</option>
          </Field>
          <Field
            name="street_address1"
            placeholder="Street Address"
            className="lg"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="street_address2"
            placeholder="Suite #"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="city"
            placeholder="City"
            className="med"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="locale"
            defaultOptionLabel={
              formValues && formValues.country === "USA" ? "State" : "Province"
            }
            className="sm"
            disableWhenEmpty={true}
            countryValueType="short"
            labelType="short"
            valueType="short"
            country={
              formValues && formValues.country ? formValues.country : "USA"
            }
            component={StandardRegionDropdown}
          />
          <Field
            name="postal_code"
            placeholder="ZIP"
            className="sm"
            country={
              formValues && formValues.country ? formValues.country : "USA"
            }
            maxLength={
              formValues && formValues.country & (formValues.country === "USA")
                ? 10
                : 7
            }
            component={StandardPostalCodeInput}
          />
          <Field
            name="phone_number"
            placeholder="Phone Number"
            component={StandardTextField}
            type="text"
          />
        </div>
        <div className="fields">
          <label>Payment Method</label>
          {withPaymentType && (
            <Field name="payment_method_type" component={StandardSelectField}>
              <option value="Select Card Type">Select Card Type</option>
              <option value="Visa">Visa</option>
              <option value="MasterCard">MasterCard</option>
              <option value="AMEX">AMEX</option>
              <option value="Discover">Discover</option>
            </Field>
          )}
          <Field
            name="nickname"
            placeholder="Nickname for Card (i.e., 'Work')"
            component={StandardTextField}
            type="text"
            maxLength={10}
          />
          <Field
            name="name"
            placeholder="Name on Card"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="number"
            placeholder="Card Number"
            className="lg"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="cvc"
            placeholder="CVV"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field
            id="credit_card_month_select_list"
            name="exp_month"
            placeholder="MM"
            className="med"
            component={StandardMonthSelect}
            type="text"
          />
          <Field
            id="credit_card_year_select_list"
            name="exp_year"
            placeholder="YYYY"
            className="med"
            component={StandardYearSelect}
            type="text"
          />
        </div>
        {type === "checkout" ? (
          <div className="actions">
            <button type="submit" className="btnIcon full">
              Submit Payment
            </button>
          </div>
        ) : (
          <div className="actions">
            <button type="submit" className="btn">
              Add Card
            </button>
            <Link to="/profile/paymentMethods" className="btn cancel">
              Cancel
            </Link>
          </div>
        )}
      </form>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let newProps = {};
  if (ownProps.initialBillingAddress) {
    newProps.initialValues = ownProps.initialBillingAddress;
  }
  const { CreditCard } = state.form;
  newProps.formValues = CreditCard ? CreditCard.values : {};

  return newProps;
};

// Because we are using Redux-form plus our own redux state,
// this is a 3 tier HOC. The connect HOC takes the reduxForm as a paramater.
// The reduxForm HOC takes the form and Validators as parameters.
// The whole thing returns a ProfileForm HOC with access to Redux and performing validations
// on the form under the hood.

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: "CreditCard",
    validate: Validator(validations),
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError, props) => {
      if (errors) {
        dispatch(setErrors(errors));
      }
    }
  })
)(CreditCardFormForCompany);
