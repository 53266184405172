import React from "react";
import { DatePickerInput } from "rc-datepicker";

export default class RenderField extends React.Component {
  render() {
    const {
      input,
      className
    } = this.props;
    return (
      <DatePickerInput
        {...input}
        className={className}
        showOnInputClick={true}
        showInputButton={false}
        minDate={new Date()}
        autoComplete="off"
      />
    );
  }
}
