import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import Autocomplete from 'react-autocomplete';
import validations from 'HelperFunctions/validations';
import axios from 'axios';
import { saveAuthToken, getAuthToken } from 'Api';
import { Invitations } from 'Utils/SvgIcons';
import StandardTextareaField from 'Utils/redux_form_inputs/StandardTextareaField';
import classnames from 'classnames';

class NewConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        name: '',
      },
      rental: {
        name: '',
      },
      body: '',
      rentalNames: [],
    };
  }

  componentDidMount() {
    this.fetchRentalNames(this.props.location.id);
  }

  fetchRentalNames = (locationId) => {
    axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/portal/rentals/names`, {
        headers: getAuthToken(),
        params: { location_id: locationId },
      })
      .then((response) => {
        saveAuthToken(response.headers);
        this.setState({ rentalNames: response.data.names });
      });
  };

  validate = () => {
    const errors = {};
    const { body, rental } = this.state;

    if (validations.required(body, true)) {
      errors.body = 'Message cannot be blank.';
    }

    if (validations.required(rental.name, true)) {
      errors.rental = 'Rental name cannot be blank.';
    }

    return errors;
  };

  handleSubmit = () => {
    const { location, customer, setErrors, createConversation } = this.props;
    const { rental, body } = this.state;
    const errors = this.validate();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      createConversation('', body, customer.id, location.id, rental.id);
    }
  };

  shouldItemRender(item, value) {
    return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { rental, rentalNames, body } = this.state;
    return (
      <section className='newConversation'>
        <div className='form'>
          <div className='fields'>
            <label>Event</label>
            <Autocomplete
              value={rental.name}
              inputProps={{
                id: 'rental-autocomplete',
                placeholder: 'Rental Name...',
                type: 'text',
              }}
              wrapperProps={{ className: 'autocomplete' }}
              items={rentalNames}
              getItemValue={(item) => item.name}
              onChange={(event, value) =>
                this.setState({ rental: { name: value } })
              }
              onSelect={(value, item) => this.setState({ rental: item })}
              renderMenu={(children) => (
                <ul
                  className={classnames({
                    results: true,
                    hideAutocomplete: rentalNames.length === 0,
                  })}
                >
                  {children}
                </ul>
              )}
              shouldItemRender={this.shouldItemRender}
              renderItem={(item, isHighlighted) => (
                <li
                  className={`${isHighlighted ? 'highlight' : ''}`}
                  key={item.id}
                >
                  {item.name}
                </li>
              )}
            />
            <label>Message</label>
            <StandardTextareaField
              placeholder='Write new message...'
              input={{
                name: 'body',
                value: body,
                onChange: this.handleChange,
              }}
            />
          </div>
          <div className='actions'>
            <button
              type='submit'
              className='btn secondary'
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <Invitations />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { location } = state.locations;
  const { user } = state.auth;
  return { location, customer: user };
};

export default connect(mapStateToProps, actions)(NewConversation);
