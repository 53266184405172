import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { FormattedDate, FormattedTime } from 'react-intl';
import classnames from 'classnames';
import Imgix from 'react-imgix';
import moment from 'moment';

class Message extends React.Component {
  belongsToEmployee = () => {
    const { message, employee } = this.props;
    if (message.senderId === employee.id && message.senderType === 'Employee') {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { message } = this.props;
    return (
      <div
        className={classnames({
          message: true,
          theirs: this.belongsToEmployee(),
          mine: !this.belongsToEmployee(),
        })}
      >
        <time>
          <FormattedDate
            value={message.createdAt}
            day='2-digit'
            month='2-digit'
          />{' '}
          <FormattedTime
            value={message.createdAt}
            timeZone={moment.tz.guess(message.createdAt)}
            timeZoneName='short'
          />
        </time>
        <p>{message.body}</p>
        <div className='author'>
          <div className='thumb'>
            {message.sender?.picture ? (
              <Imgix
                src={message.sender.picture?.imgixUrl}
                alt={message.sender.firstName}
                height={200}
                width={200}
              />
            ) : (
              <div>
                {message.sender.firstName.charAt(0)}
                {message.sender.lastName.charAt(0)}
              </div>
            )}
          </div>

          <label>
            {message.sender.firstName} {message.sender.lastName}
          </label>
          {message.senderBusinessName && (
            <span>{message.senderBusinessName}</span>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user: customer } = state.auth;
  return { employee: customer };
};

export default connect(mapStateToProps, actions)(Message);
