import React from "react";
import SubChangeRequestStatus from "./SubChangeRequestStatus";

function renderChange(items) {
  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>
          <strong>{item.destroy === "1" ? 0 : item.quantity}</strong>{" "}
          {item.name}
        </div>
      ))}
    </div>
  );
}

export default function bundleChange(changeRequest) {
  const { subChangeRequests } = changeRequest;
  const changedRequests = subChangeRequests && subChangeRequests.filter( x => x.keyName === 'rental_bundles_attributes' );

  let renderingBeforeItems = [];
  let renderingAfterItems = [];
  let renderingSubChangeRequests = []

  changedRequests && changedRequests.forEach((item, index) => {
    renderingBeforeItems.push(item.beforeAttributes);
    renderingAfterItems.push(item.afterAttributes);
    renderingSubChangeRequests.push(item)
  });

  if (renderingBeforeItems.length === 0) {
    return null;
  }
  return (
    <div className="itemChanges">
      <h4>Items</h4>
      {renderChange(renderingBeforeItems)}
      {renderChange(renderingAfterItems)}
      <div>
        {changeRequest.status !== 'pending' && renderingSubChangeRequests && renderingSubChangeRequests.map( subChangeRequest => {
          return (
            <div>
              <SubChangeRequestStatus subChangeRequest={subChangeRequest} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
