import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Helmet } from "react-helmet";
import { CreditCards } from "Utils/SvgIcons";
import CreditCardForm from "./creditCards/CreditCardForm";
import converter from "json-style-converter/es5";

// Fetch the profile from Redux customer prop,
// then call ProfileForm to render the form

class NewCreditCard extends React.Component {
  state = {
    initialBillingAddress: null
  };

  copyAddress = (event, isChecked) => {
    if (isChecked) {
      const { customer } = this.props;
      const customerAddress = {
        streetAddress1: customer.streetAddress1,
        streetAddress2: customer.streetAddress2,
        city: customer.city,
        postalCode: customer.postalCode,
        locale: customer.locale,
        country: customer.country
      };
      this.setState({
        initialBillingAddress: converter.camelToSnakeCase(customerAddress)
      });
    }
  };
  render() {
    const { location, customer, submitCreditCard } = this.props; // From Redux
    const { initialBillingAddress } = this.state;

    return (
      <section className="payments new">
        <h4>Add New Card</h4>
        <CreditCards />
        <CreditCardForm
          withPaymentType={location.paymentProcessor === "propay"}
          initialBillingAddress={initialBillingAddress}
          hideCopy={customer.streetAddress1 === null}
          type="save-card"
          copyAddress={this.copyAddress}
          onSubmit={values => submitCreditCard(values, location.id)}
        />
        <Helmet>
          <title>TapGoods - Add a new card</title>
        </Helmet>
      </section>
    );
  }
}

function mapStateToProps(state, props) {
  const { location } = state.locations;
  const { customer } = state.customer;
  return { location, customer };
}

export default connect(mapStateToProps, actions)(NewCreditCard);
