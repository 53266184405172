import { LOCATION_CHANGE } from "react-router-redux";
const INITIAL_STATE = {
  customer: {
    file: null,
    firstName: "Example Name",
    lastName: "Cool Last Name",
    creditCards: [
      {
        name: "Visa ABC",
        cardType: "Visa",
        last4: "1234",
        id: 1
      },
      {
        name: "MasterCard ABC",
        cardType: "MasterCard",
        last4: "5678",
        id: 2
      }
    ],
    documents: [
      {
        name: "Floor Plan A",
        url: "",
        type: "Floor Plan",
        id: 1
      },
      {
        name: "Tax Exemption",
        url: "",
        type: "Other",
        id: 2
      }
    ]
  },
  errors: {},
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state, errors: INITIAL_STATE.errors };
    default:
      return state;
  }
};
