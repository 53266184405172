/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Delete, Alert } from "Utils/SvgIcons";
import { FormattedDate } from "react-intl";

class Doc extends React.Component {
  render() {
    const { doc, onDelete } = this.props; // From Redux
    const { id, name, documentType, url, expirable, expiresAt } = doc;
    const expired = expiresAt ? (new Date(expiresAt) <= new Date()) : false
    const capitalize = (s) => {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const type = documentType.split('_').map((w) => capitalize(w)).join(" ")

    return (
      <tr key={id}>
        <td>
          <a href={url} download>
            {name}  ({type})
          </a>
        </td>
        <td>
          {expirable && (expiresAt !== null) && 
            (expired ?
            <div className='expiration'>
              <span className='alert'>
                <Alert />Expired: <FormattedDate
                value={expiresAt}
                month="numeric"
                year="numeric"
                day="numeric"
              /></span>
            </div> :
            <div>Expires: <FormattedDate
              value={expiresAt}
              month="numeric"
              year="numeric"
              day="numeric"
            /></div>)
          }
        </td>
        <td>
          <a className="btnLink delete" onClick={() => onDelete(doc.id)}>
            <Delete />
          </a>
        </td>
      </tr>
    );
  }
}

export default Doc;
