import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SocialBox from "Components/home/widgets/SocialBox";
import Navs from "Components/home/widgets/Navs";
import OrderAgreementLink from "Components/home/widgets/OrderAgreementLink";
import classnames from "classnames";
import { formatPhoneNumber } from "HelperFunctions/general";

class Footer extends React.Component {
  determineColumnCount() {
    const { footerShowNavigation, footerShowRentalAgreement, footerShowContact} = this.props.storefrontSetting;
    let count = 1;

    if (footerShowNavigation || footerShowRentalAgreement) count++;
    if (footerShowContact) count++;

    return count;
  }
  truncateString(str) {
    let newString = str || "";
    if (newString.length > 280) {
      newString = newString.substring(0, 280) + "...";
    }

    return newString;
  }

  render() {
    const { location, parsedStorefrontSettings, storefrontSetting } = this.props;
    const {
      hasAgreement,
      name,
      showTgBrandInStorefronts,
      sfSubscriptionTier
    } = location;
    const {
      footerShowNavigation,
      footerShowRentalAgreement,
      footerShowAboutus,
      footerAboutusCopy,
      footerShowContact,
      socialShowOnHeader
    } = storefrontSetting;
    const {
      contactPhone,
      contactFax,
      contactEmail,
      contactPhysicalAddress
    } = parsedStorefrontSettings.storefrontContactSetting;
    const { storefrontMenuSetting } = parsedStorefrontSettings;
    const showRentalAgreement = hasAgreement && footerShowRentalAgreement;
    // TODO: We need to separate TapGoods icon from styling and show it depending on the above flag. This is CSS.
    return (
      <footer
        className={classnames({
          ["col" + this.determineColumnCount()]: true,
          empty:
            !footerShowNavigation &&
            !footerShowRentalAgreement &&
            !footerShowAboutus &&
            !footerShowContact,
          tgLogo: showTgBrandInStorefronts
        })}
      >
        {footerShowNavigation && (
          <Navs
            className="nav"
            menuItems={storefrontMenuSetting.storefrontMenuItems}
            sfSubscriptionTier={sfSubscriptionTier}
            showRentalAgreement={showRentalAgreement}
          />
        )}

        {!footerShowNavigation && showRentalAgreement && (
          <div className="nav">
            <OrderAgreementLink />
          </div>
        )}

        {footerShowAboutus && (
          <div className="companyInfo">
            <h3>{name}</h3>
            <p>{this.truncateString(footerAboutusCopy)}</p>
          </div>
        )}
        {footerShowContact && (
          <div className="companyDetails">
            {contactPhone && <label>Phone</label>}
            {contactPhone && <div><a href={"tel:" + formatPhoneNumber(contactPhone)}>{formatPhoneNumber(contactPhone)}</a></div>}
            {contactFax && <label>Fax</label>}
            {contactFax && <div>{formatPhoneNumber(contactFax)}</div>}
            {contactEmail && <label>Email</label>}
            {contactEmail && <div><a href={"mailto:" + contactEmail}>{contactEmail}</a></div>}
            {contactPhysicalAddress && <label>Address</label>}
            {contactPhysicalAddress && (
              <div>
                <div>{contactPhysicalAddress.streetAddress1}</div>
                <div>{contactPhysicalAddress.streetAddress2}</div>
                <div>
                  {contactPhysicalAddress.city}, {contactPhysicalAddress.locale}{" "}
                  {contactPhysicalAddress.postalCode},{" "}
                  {contactPhysicalAddress.country}
                </div>
              </div>
            )}
          </div>
        )}
        {socialShowOnHeader && <SocialBox />}
        {showTgBrandInStorefronts &&
            <a
              href="https://www.tapgoods.com/pro/?utm_source=storefront&utm_medium=website&utm_campaign=logo"
              className="footerTgLink"
            >&nbsp;</a>
        }
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const storefrontSetting = parsedStorefrontSettings.mainSetting;
  return {
    location,
    storefrontSetting,
    parsedStorefrontSettings
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
