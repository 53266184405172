import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Link } from "react-router-dom";
import TestimonialBox from "Components/home/widgets/TestimonialBox";
import SocialBox from "Components/home/widgets/SocialBox";
import classnames from "classnames";
import { reset } from "redux-form";
import { formatPhoneNumber } from "HelperFunctions/general";
import Imgix from "react-imgix";

class ContactConfirmation extends React.Component {
  render() {
    const { parsedStorefrontSettings } = this.props;
    const {
      contactPicture,
      contactPhone,
      contactFax,
      contactEmail,
      contactPhysicalAddress,
      contactShowForm,
      contactShowSocial
    } = parsedStorefrontSettings.storefrontContactSetting;

    return (
      <section
        className={classnames({
          contactUs: true,
          withForm: contactShowForm
        })}
      >
        <figure>
          {contactPicture && (
            <Imgix
              src={contactPicture.imgixUrl}
              alt="Contact Us"
              width={2040}
              height={1152}
            />
          )}
        </figure>
        <h1>Contact Us</h1>
        <div className="companyDetails">
          {contactPhone && <label>Phone</label>}
          {contactPhone && <div><a href={"tel:" + formatPhoneNumber(contactPhone)}>{formatPhoneNumber(contactPhone)}</a></div>}
          {contactFax && <label>Fax</label>}
          {contactFax && <div>{formatPhoneNumber(contactFax)}</div>}
          {contactEmail && <label>Email</label>}
          {contactEmail && <div><a href={"mailto:" + contactEmail}>{contactEmail}</a></div>}
          {contactPhysicalAddress && <label>Address</label>}
          {contactPhysicalAddress && (
            <div>
              <div>{contactPhysicalAddress.streetAddress1}</div>
              <div>{contactPhysicalAddress.streetAddress2}</div>
              <div>
                {contactPhysicalAddress.city}
                {", "}
                {contactPhysicalAddress.locale}{" "}
                {contactPhysicalAddress.postalCode},{" "}
                {contactPhysicalAddress.country}
              </div>
            </div>
          )}
          {contactShowSocial && <SocialBox />}
        </div>
        <div className="form contactForm">
          <div className="fields">
            <p>Your message was sent!</p>
          </div>
          <div className="actions">
            <Link className="btn full" to="/contact">
              New Message
            </Link>
          </div>
        </div>
        <TestimonialBox />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  return { parsedStorefrontSettings };
};

export default connect(mapStateToProps, { ...actions, reset })(
  ContactConfirmation
);
