import React from 'react';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { renderItemQty } from 'HelperFunctions/rentals';
import { Items } from 'Utils/SvgIcons';
import RentalBundleItem from './rental_bundle/RentalBundleItem';
import Imgix from 'react-imgix';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { shouldHidePrice } from 'HelperFunctions/HidePricing';
import { formatSmartPrincingLabel } from 'HelperFunctions/smartPricing';
import CurrencyFormatter from 'HelperFunctions/CurrencyFormatter';

class RentalBundle extends React.Component {
  renderPrice() {
    const {
      rentalBundle,
      showDiscountsOnPricing,
      rental,
      location: { locale },
    } = this.props;
    const smartPricingActive = rental.smartPricingActive;
    const formatter = CurrencyFormatter({ locale });
    const comparePriceObject = smartPricingActive
      ? formatSmartPrincingLabel(rentalBundle, formatter)
      : rentalBundle.periodPriceAfterDiscount;

    if (
      rentalBundle.periodPriceAfterDiscount &&
      showDiscountsOnPricing &&
      comparePriceObject !== rentalBundle.periodPrice
    ) {
      return (
        <td className='right'>
          <span className='normalText'>
            <div className='strikeThrough'>{rentalBundle.periodPrice}</div>
            <div>
              {smartPricingActive
                ? formatSmartPrincingLabel(rentalBundle, formatter)
                : rentalBundle.periodPriceAfterDiscount}
            </div>
            {rentalBundle.bundle.purchasePrice && (
              <div>
                {' + '}
                <CurrencyLabel value={rentalBundle.bundle.purchasePrice} />
                {' / Each'}
              </div>
            )}
          </span>
        </td>
      );
    } else {
      return (
        <td className='right'>
          <span className='normalText'>
            <div>{rentalBundle.periodPrice}</div>
            {rentalBundle.bundle.purchasePrice && (
              <div>
                {' + '}
                <CurrencyLabel value={rentalBundle.bundle.purchasePrice} />
                {' / Each'}
              </div>
            )}
          </span>
        </td>
      );
    }
  }

  renderSubTotal() {
    const {
      rentalBundle,
      showDiscountsOnPricing,
      storefrontShopSetting,
      approvalStatus,
      location: { displayDiscount },
      rental,
    } = this.props;

    if (
      approvalStatus !== 'approved' &&
      !storefrontShopSetting.shopShowItemPricing
    ) {
      return <td className='medSm right' />;
    }
    if (
      rentalBundle.selectedPrice &&
      rentalBundle.selectedPriceBeforeDiscount &&
      rentalBundle.selectedPrice !== 'N/A'
    ) {
      return !displayDiscount ? (
        rental.rentalSetting.discountSetting === 'hideDiscounts' ? (
          <span>
            <CurrencyLabel value={selectedPrice} />
          </span>
        ) : (
          <span>
            {rental.rentalSetting.discountSetting === 'strikeThrough' && (
              <span className='strikeThrough'>
                <CurrencyLabel
                  value={rentalBundle.selectedPriceBeforeDiscount}
                />
              </span>
            )}
            <CurrencyLabel value={rentalBundle.selectedPrice} />
          </span>
        )
      ) : rentalBundle.discountedPrice != rentalBundle.selectedPrice ? (
        <span>
          <CurrencyLabel value={rentalBundle.selectedPrice} />
          {showDiscountsOnPricing && (
            <span className='strikeThrough'>
              <CurrencyLabel value={rentalBundle.selectedPriceBeforeDiscount} />
            </span>
          )}
        </span>
      ) : (
        <span>{rentalBundle.periodPrice}</span>
      );
    }
    return <CurrencyLabel value={rentalBundle.selectedPrice} />;
  }

  render() {
    const {
      rentalBundle,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      tileView,
      approvalStatus,
      storefrontShopSetting,
    } = this.props;
    const { rentalItems, rentalAddOns } = rentalBundle;
    const bundle = rentalBundle.bundle;
    const bundleItems = [...rentalItems, ...rentalAddOns];
    const hideSubtotalPricing = shouldHidePrice(
      approvalStatus,
      storefrontShopSetting
    );
    const itemNotesVisibleToCustomer = rentalBundle.itemNotes.filter(
      (itemNote) => itemNote.shouldIncludeNotesInRentals
    );

    return (
      <MediaBreakpoints
        desktop={
          <tbody className='itemContent'>
            <tr
              className={classnames({
                bottom: showItemNotes && itemNotesVisibleToCustomer.length > 0,
              })}
            >
              {tileView && showItemImages && (
                <td rowSpan={'1'} className='image'>
                  <figure>
                    {bundle &&
                    bundle.pictures[0] &&
                    bundle.pictures[0].imgixUrl ? (
                      <Imgix
                        src={bundle.pictures[0].imgixUrl}
                        alt={rentalBundle.name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <Items />
                    )}
                  </figure>
                </td>
              )}
              <td className='normalText'>
                {rentalBundle.name}
                {showItemNotes &&
                  itemNotesVisibleToCustomer?.map((itemNote) => (
                    <span
                      colSpan={showUnitPricingToCustomers ? '3' : '2'}
                      className='caption'
                      style={{ display: 'block' }}
                    >
                      {itemNote.message}
                    </span>
                  ))}
              </td>
              <td
                className={classnames({
                  right: true,
                  alert: rentalBundle.hasShortage,
                })}
              >
                {renderItemQty(rentalBundle)}
              </td>
              {approvalStatus === 'approved' &&
                showUnitPricingToCustomers &&
                storefrontShopSetting.shopShowItemPricing &&
                this.renderPrice()}
              <td className='fee' />
              <td className='right'>{this.renderSubTotal()}</td>
            </tr>
            {bundle.showItemsToCustomer && (
              <tr>
                <td
                  className='indent'
                  colSpan={
                    showUnitPricingToCustomers
                      ? tileView
                        ? '6'
                        : '5'
                      : tileView
                      ? '5'
                      : '4'
                  }
                >
                  <table className='grid'>
                    {bundleItems.map((bundleItem, index) => (
                      <RentalBundleItem
                        key={index}
                        item={bundleItem}
                        bundle={bundle}
                        bundlePeriod={rentalBundle.period}
                        tileView={tileView}
                        hideSubtotalPricing={hideSubtotalPricing}
                        showUnitPricingToCustomers={showUnitPricingToCustomers}
                        showItemNotes={showItemNotes}
                      />
                    ))}
                  </table>
                </td>
              </tr>
            )}
          </tbody>
        }
        mobile={
          <tbody className='mobileItemContent'>
            {tileView && showItemImages && (
              <tr>
                <td colSpan='3'>
                  <figure>
                    {bundle &&
                    bundle.pictures[0] &&
                    bundle.pictures[0].imgixUrl ? (
                      <Imgix
                        src={bundle.pictures[0].imgixUrl}
                        alt={rentalBundle.name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <Items />
                    )}
                  </figure>
                </td>
              </tr>
            )}
            <tr className='noBorder'>
              <td colSpan={showUnitPricingToCustomers ? '3' : '2'}>
                {rentalBundle.name}
              </td>
            </tr>
            <tr className='noBorder'>
              <td
                className={classnames({
                  alert: rentalBundle.hasShortage,
                })}
              >
                {renderItemQty(rentalBundle)}
              </td>
              {approvalStatus === 'approved' &&
                showUnitPricingToCustomers &&
                storefrontShopSetting.shopShowItemPricing &&
                this.renderPrice()}
              <td className='fee' />
              <td className='right'>{this.renderSubTotal()}</td>
            </tr>
            {showItemNotes &&
              itemNotesVisibleToCustomer?.map((itemNote) => (
                <tr className='top noBorder'>
                  <td
                    colSpan={showUnitPricingToCustomers ? '3' : '2'}
                    className='caption'
                  >
                    {itemNote.message}
                  </td>
                </tr>
              ))}
            {bundle.showItemsToCustomer && (
              <tr className='top noBorder'>
                <td colSpan={showUnitPricingToCustomers ? '3' : '2'}>
                  <table className='grid'>
                    {bundleItems.map((bundleItem, index) => (
                      <RentalBundleItem
                        key={index}
                        item={bundleItem}
                        bundle={bundle}
                        bundlePeriod={rentalBundle.period}
                        tileView={tileView}
                        hideSubtotalPricing={hideSubtotalPricing}
                        showUnitPricingToCustomers={showUnitPricingToCustomers}
                        showItemNotes={showItemNotes}
                      />
                    ))}
                  </table>
                </td>
              </tr>
            )}
          </tbody>
        }
      />
    );
  }
}
const mapStateToProps = (state) => {
  const { parsedStorefrontSettings, location } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;

  return { storefrontShopSetting, location };
};

export default connect(mapStateToProps)(RentalBundle);
