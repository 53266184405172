import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import { filterFromPropsAndState } from "HelperFunctions/urls";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";

class SearchFilter extends React.Component {
  state = {
    query: ""
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      const { query } = this.state;
      this.props.history.push(`/shop?query=${query}`);
    }
  };

  handleChange = event => {
    this.setState({
      query: event.target.value
    });
  };

  render() {
    const { query } = this.state;
    return (
      <div className="searchFilter">
        <StandardTextField
          type="search"
          placeholder="Search for Items to Rent..."
          meta={{ error: {} }}
          input={{
            value: query,
            onChange: this.handleChange,
            onKeyPress: this.handleKeyPress
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  return { filter: newFilter };
};

export default withRouter(connect(mapStateToProps, actions)(SearchFilter));
