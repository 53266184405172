/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import Checkbox from "Utils/Checkbox";

class ColorPicker extends React.Component {
  render() {
    const {
      colors,
      selectedColorIds,
      onColorSelection,
      clearColorSelection
    } = this.props;

    return (
      <div className="colorPicker">
        <a onClick={(_event, isChecked) => clearColorSelection(isChecked)}>
          Show All
        </a>
        <ul>
          {colors.map(({ id, name }) => {
            return (
              <li key={id} className={name}>
                <Checkbox
                  onCheck={(_event, isChecked) =>
                    onColorSelection(isChecked, id)
                  }
                  checked={selectedColorIds.includes(id.toString())}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { colors } = state.colorList;
  return { colors };
};

export default connect(mapStateToProps)(ColorPicker);
