import axios from "axios";
import { ROOT_URL, getAdminAuthToken, saveError } from "Api";
import {
  FETCH_ADMIN_CUSTOMERS_REQUEST,
  FETCH_ADMIN_CUSTOMERS_FAILURE,
  FETCH_ADMIN_CUSTOMERS_SUCCESS,
  FETCH_ADMIN_CUSTOMER_NAMES_REQUEST,
  FETCH_ADMIN_CUSTOMER_NAMES_SUCCESS,
  ADD_ADMIN_CUSTOMER_NAMES_REQUEST,
  ADD_ADMIN_CUSTOMER_NAMES_SUCCESS,
  SET_ADMIN_CUSTOMER_QUERY
} from "Constants/redux";

export const fetchAdminCustomers = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_ADMIN_CUSTOMERS_REQUEST });
    axios
      .get(`${ROOT_URL}/admin/customers/`, {
        headers: getAdminAuthToken()
      })
      .then(response => {
        return dispatch({
          response,
          type: FETCH_ADMIN_CUSTOMERS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_ADMIN_CUSTOMERS_FAILURE
        });
      });
  };
};

export const setCustomerQuery = query => {
  return {
    type: SET_ADMIN_CUSTOMER_QUERY,
    payload: { query }
  };
};

export const receiveAdminCustomerNames = response => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  let firstId = "";
  if (names.length > 0) {
    firstId = names[0].nameableId;
  }
  return {
    type: FETCH_ADMIN_CUSTOMER_NAMES_SUCCESS,
    payload: { names, pagination, firstId }
  };
};

export const addAdminCustomerNamesSuccess = response => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_ADMIN_CUSTOMER_NAMES_SUCCESS,
    payload: { names, pagination }
  };
};

export const fetchAdminCustomerNames = () => {
  return (dispatch, getState) => {
    const { query } = getState().adminCustomers;
    dispatch({
      type: FETCH_ADMIN_CUSTOMER_NAMES_REQUEST,
      query
    });
  };
};

export const searchAdminCustomerNames = query => {
  return (dispatch, getState) => {
    dispatch(setCustomerQuery(query));
    dispatch(fetchAdminCustomerNames());
  };
};

export const addAdminCustomerNames = link => {
  return (dispatch, getState) => {
    const { query } = getState().adminCustomers;
    dispatch({
      type: ADD_ADMIN_CUSTOMER_NAMES_REQUEST,
      query,
      link
    });
  };
};
