import React from "react";
import { FormattedDate } from "react-intl";
import CurrencyLabel from "Utils/CurrencyLabel";

const DetailedAmountRefunded = props => {
  const { refund } = props;
  let refundType = refund.refundType;
  if (refund.refundType === 'other') {
    refundType = `Refund Other: ${refund.refundTypeName}`
  }

  return (
    <div>
      <label>Refund</label>
      <CurrencyLabel value={0 + props.refund.amount} />
      <div>
        <label>
          <FormattedDate
            value={props.refund.updatedAt}
            year="numeric"
            month="numeric"
            day="numeric"
          />
        </label>
        <label>{refundType}</label>
      </div>
    </div>
  );
};

export default DetailedAmountRefunded;
