import React from 'react';
import { stringify, parseQuery } from 'HelperFunctions/QueryString';
import {
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from 'HelperFunctions/rental_items';
import DraggableToolTip from 'Utils/DraggableToolTip';
import { DollarsCircle } from 'Utils/SvgIcons';
import moment from 'moment';
import { displayPricingType } from 'Constants/displayConstants';
import CurrencyFormatter from './CurrencyFormatter';

export const customersString = (rental) => {
  const customers = rental.customers
    ? rental.customers
    : rental.customerRentalRelationships.map((cr) => cr.client);
  const customerNames = customers.map((customer, index) => {
    return customer.firstName || customer.name;
  });

  return customerNames.join(', ');
};

export const renderItemQty = (item, quantity = 0) => {
  const subRentalQty = item.subRental ? item.subRental.quantity : 0;
  if (subRentalQty > 0) {
    return `(${subRentalQty}) ${item.quantity}`;
  }

  return quantity || item.quantity;
};

export const isItem = (item) =>
  item.type === 'RentalItemTemporary' || item.type === 'RentalItemStandard';

export const extractFeeFromPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  basePrice = Math.floor(basePrice * 100) / 100;
  priceWithFees = Math.floor(priceWithFees * 100) / 100;
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType === 'amount') {
        // Following Line checks if qunantity is split so it extract fee only for the splitted quantity
        const feeAmount = (+rifr.amount / item.quantity) * quantity;
        priceWithFees =
          priceWithFees -
          feeAmount * (item.period !== 'edited_flat_price' ? quantity : 1);
      } else if (rifr.valueType === 'percentage') {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(Math.floor(priceWithFees * 100) / 100).toFixed(2)
};
export const renderItemSpecificFee = (item) => {
  return (
    item.rentalItemFeeRelationships &&
    item.rentalItemFeeRelationships.length > 0 && (
      <div>
        <DraggableToolTip
          tooltipContent={<p>This item has an associated fee.</p>}
          tooltipProps={{
            id: 'itemFee-tip',
            class: 'tooltip',
            effect: 'solid',
          }}
          linkProps={{
            'data-tip': true,
            'data-for': 'itemFee-tip',
            'data-event': 'mouseenter',
            'data-event-off': 'mouseleave',
          }}
        >
          <DollarsCircle />
        </DraggableToolTip>
      </div>
    )
  );
};
export const extractFeeFromSelectedPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType === 'amount') {
        priceWithFees = priceWithFees - +rifr.amount * quantity;
      } else if (rifr.valueType === 'percentage') {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(Math.floor(priceWithFees * 100) / 100).toFixed(2)
};

export const extractFlatFeeFromSelectedPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType === 'amount') {
        priceWithFees = priceWithFees - +rifr.amount;
      } else if (rifr.valueType === 'percentage') {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(Math.floor(priceWithFees * 100) / 100).toFixed(2)
};
export const isQuoteExpired = (rental) => {
  if (rental.expireDate) {
    const expireDate = new Date(rental.expireDate);
    const today = new Date();
    return expireDate < today;
  }

  return false;
};

export const setRentalFilterParams = (filter, location, history = null) => {
  const query = parseQuery(location.search);
  const newFilter = {
    ...query,
    ...filter,
  };
  const stringified = stringify(newFilter);
  history.push({
    pathname: location.pathname,
    search: `?${stringified}`,
  });
};

export const rentalsFilterFromPropsAndState = (
  state,
  ownProps,
  includeReduxFilter = true
) => {
  const { filter } = state.rentalsList;
  const { location } = ownProps;
  const query = parseQuery(location.search);
  const initialFilter = {
    startDate: null,
    endDate: null,
    source: '',
    salesperson: '',
    numberPer: 15,
  };
  const { startDate, endDate, page, numberPer, search, ...otherFromQuery } =
    query;
  const newFilter = Object.assign(initialFilter, includeReduxFilter && filter, {
    ...otherFromQuery,
    query: search,
    showAll: false,
    numberPer: numberPer ? numberPer : 10,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    page: page ? Number(page) : 1,
  });

  return newFilter;
};

export const getFirstAvailablePricingType = (product, productType) => {
  const pricingTypes = [
    'pricing',
    'hourlyPrice',
    'halfDayPrice',
    'dailyPrice',
    'weeklyPrice',
    'monthlyPrice',
    // "flatPrice"
  ];
  let availablePrice = pricingTypes.find((pt) => {
    return product[pt] && +product[pt] >= 0;
  });

  if (
    !availablePrice &&
    productType === 'bundles' &&
    !product.priceLocked &&
    product.flatPrices &&
    product.flatPrices.find((fp) => +fp.amount > 0)
  ) {
    availablePrice = 'flatPrices';
  }
  return availablePrice;
};

export const getFirstAvailablePricingObject = (product) => {
  if (hasSelectedFlatPrice(product, product.productType)) {
    const selectedFlatPrice = getSelectedFlatPrice(product);
    if (selectedFlatPrice) {
      return {
        amount: selectedFlatPrice.amount,
        label: `Flat: ${selectedFlatPrice.name}`,
      };
    }
  } else {
    const firstAvailablePricingType = getFirstAvailablePricingType(
      product,
      product.productType
    );
    if (firstAvailablePricingType !== 'flatPrices') {
      return {
        amount: product[firstAvailablePricingType],
        label: displayPricingType[firstAvailablePricingType],
      };
    } else {
      return {
        amount: product.flatPrices.reduce((sum, fp) => {
          return sum + Number(fp.amount);
        }, 0),
        label: 'Each',
      };
    }
  }
};

export const getDamageWaiverFee = (event, location) => {
  const defaultDamageFixed = location.damageWaiverFixedFee
    ? parseFloat(location.damageWaiverFixedFee)
    : 0;

  const rentalItemTotal = event.items
    .filter((item_container) => item_container._destroy !== '1')
    .reduce((total, item_container) => {
      if (
        item_container.type === 'RentalItemTemporary' ||
        !item_container.product.damageWaiverExempt
      ) {
        return total + parseFloat(item_container.selectedPrice);
      } else {
        return total;
      }
    }, 0);
  const rentalBundleTotal = event.rentalBundles
    .filter((item_container) => item_container._destroy !== '1')
    .reduce((total, item_container) => {
      return total + Number(item_container.selectedPrice);
    }, 0);
  let defaultDamage = location.damageWaiverPercent
    ? parseFloat(
        ((rentalItemTotal + rentalBundleTotal) * location.damageWaiverPercent) /
          100
      )
    : 0;
  defaultDamage += defaultDamageFixed;

  return defaultDamage;
};

export const isFinalBalanceDue = (rental) => {
  const { billingStatus, finalPaymentDueDate } = rental;
  if (
    (billingStatus === 'None' || billingStatus === 'Partial') &&
    finalPaymentDueDate &&
    moment(new Date()).isAfter(finalPaymentDueDate)
  ) {
    return true;
  }
  return false;
};

export function formatSmartPrincingLabel(rentalItem, formatter) {
  switch (rentalItem.period) {
    case 'hourly_price':
      return `${formatter.format(rentalItem.hourlyPrice)}/Hour`;
    case 'half_day_price':
      return `${formatter.format(rentalItem.halfDayPrice)}/Half-Day`;
    case 'daily_price':
      return `${formatter.format(rentalItem.dailyPrice)}/Day`;
    case 'weekly_price':
      return `${formatter.format(rentalItem.weeklyPrice)}/Week`;
    case 'monthly_price':
      return `${formatter.format(rentalItem.monthlyPrice)}/Month`;
  }
}

export const getItemPeriodPrice = (item, productType, locale, location) => {
  const formatter = CurrencyFormatter({
    locale,
    minimumFractionDigits: 2,
  });

  const {
    hourlyPrice,
    halfDayPrice,
    dailyPrice,
    weeklyPrice,
    monthlyPrice,
    flatPrice,
    flatUnitPrice,
    flatPrices,
    period,
    quantity,
  } = item;

  const { smartPricingActive } = location;

  let priceOptions = [];
  hourlyPrice &&
    priceOptions.push({
      value: 'hourly_price',
      label: `${formatter.format(hourlyPrice)}/Hour`,
    });
  halfDayPrice &&
    priceOptions.push({
      value: 'half_day_price',
      label: `${formatter.format(halfDayPrice)}/Half-Day`,
    });
  dailyPrice &&
    priceOptions.push({
      value: 'daily_price',
      label: `${formatter.format(dailyPrice)}/Day`,
    });
  weeklyPrice &&
    priceOptions.push({
      value: 'weekly_price',
      label: `${formatter.format(weeklyPrice)}/Week`,
    });
  monthlyPrice &&
    priceOptions.push({
      value: 'monthly_price',
      label: `${formatter.format(monthlyPrice)}/Month`,
    });
  flatUnitPrice &&
    priceOptions.push({
      value: 'flat_unit_price',
      label: `${formatter.format(flatUnitPrice)}/Each`,
    });
  flatPrices &&
    flatPrices.forEach((flatPrice) => {
      priceOptions.push({
        value: `standard_flat_price[${flatPrice.name}]`,
        label: `${formatter.format(flatPrice.amount)}/Flat: ${flatPrice.name}`,
      });
    });
  let overridingDisplayValue;

  if (period === 'flat_price') {
    if (item.flatPrice && item.type !== 'RentalItemTemporary') {
      overridingDisplayValue = (
        <span className="normalText pricing">
          {formatter.format(item.flatPrice)}
          /Each
        </span>
      );
    } else {
      overridingDisplayValue = (
        <span className="normalText pricing">
          {formatter.format(
            item.selectedPriceBeforeDiscount
              ? item.selectedPriceBeforeDiscount / quantity
              : item.selectedPrice / quantity
          )}
        </span>
      );
    }
  } else if (
    period === 'edited_flat_price' ||
    period === 'edited_flat_unit_price' ||
    period === 'flat_unit_price'
  ) {
    overridingDisplayValue = (
      <span className="normalText pricing">
        {formatter.format(
          item.selectedPriceBeforeDiscount
            ? item.selectedPriceBeforeDiscount / quantity
            : item.selectedPrice / quantity
        )}
        /Each
      </span>
    );
  } else if (priceOptions.find((option) => option.value === period)) {
    overridingDisplayValue = (
      <span className="normalText pricing">
        {smartPricingActive
          ? formatSmartPrincingLabel(item, formatter)
          : priceOptions.find((option) => option.value === period).label}
      </span>
    );
  }

  return overridingDisplayValue;
};

export const getHoursFromSchedule = (item, rental) => {
  let hours = 1;

  if (
    rental &&
    rental.schedule &&
    [
      'none_selected',
      'flat_price',
      'flat_unit_price',
      'edited_flat_price',
      'edited_flat_unit_price',
    ].indexOf(item.period) < 0 &&
    (item.productType === 'items' || item.productType === 'bundles') &&
    !item.period.includes('standard_flat_price')
  ) {
    let startTime = moment(rental.schedule.eventStartDate);
    let endTime = moment(rental.schedule.eventEndDate);
    hours = moment.duration(endTime.diff(startTime)).asHours();
    hours = convertHoursTo(item.period, hours);
  }
  return hours;
};
export const convertHoursTo = (unit, hours) => {
  if (hours > 0) {
    let newTime;
    switch (unit) {
      case 'half_day_price':
        newTime = Math.round(hours / 12);
        break;
      case 'daily_price':
        newTime = Math.round(hours / 24);
        break;
      case 'weekly_price':
        newTime = Math.round(hours / 24 / 7);
        break;
      case 'monthly_price':
        newTime = Math.round(hours / 24 / 30);
        break;
      default:
        newTime = Math.round(hours);
        break;
    }
    newTime = newTime <= 0 ? 1 : newTime;
    return newTime;
  } else {
    return 1;
  }
};
export const renderSubTotals = (item, quantity, rental) => {
  // The following function return time multiple, hours in case of hourly price , weeks in case of weekly price and same for months
  // Don't get confused by the name 'hours'
  const hours = getHoursFromSchedule(item, rental);
  let selectedPrice = '';
  let selectedPriceBeforeDiscount = '';

  let periodPrice = 0;
  let periodPriceAfterDiscount = 0;
  if (isItem(item)) {
    if (item.periodPrice[0] == '$') {
      // TODO refactor this
      periodPrice = +item.periodPrice
        .split(' ')[0]
        .replace('$', '')
        .replace(/,/g, '');

      periodPriceAfterDiscount = +item.periodPriceAfterDiscount
        .split(' ')[0]
        .replace('$', '')
        .replace(/,/g, '');
    } else {
      // TODO refactor this
      periodPrice = +item.periodPrice
        .split(' ')[1]
        .replace('CAD', '')
        .replace(/,/g, '');

      periodPriceAfterDiscount = +item.periodPriceAfterDiscount
        .split(' ')[1]
        .replace('CAD', '')
        .replace(/,/g, '');
    }
    if (periodPrice !== periodPriceAfterDiscount) {
      selectedPrice = (periodPriceAfterDiscount * quantity * hours).toString();
      selectedPriceBeforeDiscount = (periodPrice * quantity * hours).toString();
    } else {
      selectedPrice = (periodPrice * quantity * hours).toString();
      selectedPriceBeforeDiscount = selectedPrice;
    }
  } else {
    selectedPrice = (quantity / item.quantity) * item.selectedPrice;
    selectedPriceBeforeDiscount =
      (quantity / item.quantity) * item.selectedPrice;
  }

  return { selectedPrice, selectedPriceBeforeDiscount };
};
