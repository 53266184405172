/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import Checkbox from "Utils/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ArrowUp, ArrowDown, Close, Filter, Delete } from "Utils/SvgIcons";
import * as actions from "Actions";
import classnames from "classnames";
import DateSelector from "./DateSelector";
import Chip from "@material-ui/core/Chip";
import ScrollArea from "Utils/react-scrollbar";
import Modal from "Utils/Modal";
import ColorPicker from "Utils/ColorPicker";
import Slider, { createSliderWithTooltip } from "rc-slider";
import ShopBreadCrumbs from "../utils/ShopBreadCrumbs";
import { NavLink } from 'react-router-dom';

const Range = createSliderWithTooltip(Slider.Range);

// This component is only called from the mobile size, via
class CategoryFilterMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false,
      open: {},
      currentCategory: props.currentCategory,
      selectedSubs: props.selectedSubCategories,
      openCategories: false
    };
    this.handleFilterClose = this.handleFilterClose.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.renderChips = this.renderChips.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { sfCategories } = this.props;
      const slug = window.location.pathname.split("/")[2];
      const currentCategory = sfCategories.find(
        (sfCategory) => sfCategory.slug === slug
      );

      this.setState({
        currentCategory: currentCategory,
        selectedSubs: this.props.selectedSubCategories
      });
    }
  };

  toggleOpen = (category) => {
    this.setState({
      open: {
        ...this.state.open,
        [category.id]: !(this.state.open[category.id] || false)
      }
    });
  };

  checkSfCategorySelected = ({ id }) => {
    const { selectedSfCategories } = this.props;

    return selectedSfCategories.includes(String(id));
  };

  checkSfSubCategorySelected = (sfSubCategory, sfCategory) => {
    const { selectedSfCategoriesData, selectedSfSubCategories } = this.props;

    if (
      Array.isArray(selectedSfCategoriesData) &&
      !!selectedSfCategoriesData.length
    ) {
      // New Url Schema
      let sfCatExists = selectedSfCategoriesData.find(
        (e) => e.category_id === sfCategory.id
      );
      if (sfCatExists) {
        return sfCatExists.sf_sub_categories.includes(String(sfSubCategory.id));
      }
      return false;
    } else {
      // Try old url format
      return selectedSfSubCategories.includes(String(sfSubCategory["id"]));
    }
  };

  handleFilterClose() {
    this.setState({ filterOpen: false });
  }

  toggleFilter() {
    this.setState({ filterOpen: !this.state.filterOpen });
  }

  // Render a chip
  renderChips() {
    const {
      sfCategories,
      selectedSfSubCategories,
      selectedSubCategories,
      handleSfSubCategorySelected
    } = this.props;

    const chips = sfCategories.reduce((chipsHolder, sfCategory) => {
      let chipContainer = chipsHolder.slice();
      sfCategory.sfSubCategories.forEach((sfSubCategory) => {
        if (selectedSfSubCategories.includes(String(sfSubCategory.id))) {
          chipContainer.push(
            <Chip
              key={sfSubCategory.id}
              className='chip'
              label={sfSubCategory.name}
              onDelete={() => handleSfSubCategorySelected(false, sfSubCategory)}
              deleteIcon={<Delete />}
            />
          );
        }
      });
      return chipContainer;
    }, []);

    return chips;
  }

  renderSfCategory = () => {
    const {
      sfCategories,
      handleSfCategorySelected,
      handleSfSubCategorySelected
    } = this.props;
    let output = [];
    const sfCategory = this.state.currentCategory;
    let indeterminate = false;
    if (sfCategory?.sfSubCategories?.length) {
      const indeterminateResults = sfCategory.sfSubCategories.map(
        (sfSubCategory) => {
          return this.checkSfSubCategorySelected(sfSubCategory, sfCategory);
        }
      );
      indeterminate =
        indeterminateResults.some((e) => e === true) &&
        indeterminateResults.some((e) => e === false);
    }

    output.push(
      <ListItem
        key={sfCategory.id}
        className={classnames({
          open: this.state.open[sfCategory.id]
        })}
      >
        <ListItemText primary={sfCategory.name} disableTypography />
        <a onClick={() => this.toggleOpen(sfCategory)}>
          {this.state.open[sfCategory.id] ? <ArrowUp /> : <ArrowDown />}
        </a>
      </ListItem>
    );
    output.push(
      <Collapse in={this.state.open[sfCategory.id] || false} unmountOnExit>
        <List className='checkList'>
          {sfCategory.sfSubCategories.map((sfSubCategory) => (
            <ListItem key={sfSubCategory.id}>
              <Checkbox
                onCheck={(event, isChecked) =>
                  handleSfSubCategorySelected(
                    isChecked,
                    sfSubCategory,
                    sfCategory
                  )
                }
                checked={this.checkSfSubCategorySelected(
                  sfSubCategory,
                  sfCategory
                )}
              />
              <ListItemText primary={sfSubCategory.name} disableTypography />
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
    // });

    return output;
  };

  toggleOpenCategories = () => {
    const { openCategories } = this.state;
    this.setState({ openCategories: !openCategories });
  };

  renderSfCategories = () => {
    const {
      sfCategories,
      handleSfCategorySelected,
      handleSfSubCategorySelected
    } = this.props;
    const { openCategories } = this.state;

    let output = [];
    const sfCategory = this.state.currentCategory;
    // is there any sfCategory.sfSubCategories with checked equal to true?
    let indeterminate = false;

    output.push(
      <ListItem>
        <ListItemText primary='Categories' disableTypography />
        <a onClick={this.toggleOpenCategories}>
          {this.state.openCategories ? <ArrowUp /> : <ArrowDown />}
        </a>
      </ListItem>
    );
    output.push(
      <Collapse in={openCategories} unmountOnExit>
        <List>
          {sfCategories.map((sfCategory) => (
            <ListItem key={sfCategory.id}>
              <a
                href={`/shop/${sfCategory.slug}`}
              >
                {sfCategory.name}
              </a>
            </ListItem>
          ))}
        </List>
      </Collapse>
    );

    return output;
  };

  requireRentalDatesBeforePickingInventory = () => {
    return this.props.location.overbookingOnSf === 'disallowed'
  };

  render() {
    const {
      onColorSelection,
      selectedColors,
      clearColorSelection,
      onQuantitySelection,
      selectedMinQuantity,
      selectedMaxQuantity,
      storefrontShopSetting,
      showFilters
    } = this.props;
    const { currentCategory, selectedSubs } = this.state;

    const allowedFilterList = storefrontShopSetting.allowedFilters.split(",");
    const quantityValues = [+selectedMinQuantity, +selectedMaxQuantity];

    if (
      !(
        allowedFilterList.includes("date") ||
        allowedFilterList.includes("quantity") ||
        allowedFilterList.includes("color") ||
        allowedFilterList.includes("category")
      )
    ) {
      return null;
    }
    return (
      <aside className='mobileFilter'>
        <a onClick={this.toggleFilter}>
          <Filter />
        </a>
        <div className='breadcrumbs'>
          <ShopBreadCrumbs
            currentCategory={currentCategory}
            selectedSubs={selectedSubs}
          />
        </div>
        {/* Show Categories as Chips. */}
        <div className='chipWrapper'>{this.renderChips()}</div>

        <Modal
          className='filterModal'
          open={this.state.filterOpen}
          toggle={this.toggleFilter}
          title='Filter'
        >
          <a className='linkClose' onClick={this.handleFilterClose}>
            <Close />
          </a>
          <h3>Filter</h3>
          {this.requireRentalDatesBeforePickingInventory() && <DateSelector />}
          {showFilters === false && (
            <div className='colorAGroup' id='categoryLinks'>
              <ScrollArea speed={0.8} horizontal={false}>
                <List className='checkList'>{this.renderSfCategories()}</List>
              </ScrollArea>
            </div>
          )}
          {showFilters && (
            <div className='filterWrapper'>
              {allowedFilterList.includes('date') && !this.requireRentalDatesBeforePickingInventory() && <DateSelector />}
              
              {allowedFilterList.includes("quantity") && (
                <div id='tooltipContainer' className='quantityFilter'>
                  <div
                    ref={(input) => {
                      this.tooltipContainer = input;
                    }}
                  />
                  Quantity
                  <Range
                    allowCross={false}
                    value={quantityValues}
                    onChange={onQuantitySelection}
                    max={500}
                    tipProps={{
                      visible: true,
                      getTooltipContainer: () => this.tooltipContainer
                    }}
                  />
                </div>
              )}
              {allowedFilterList.includes("color") && (
                <div className='colorFilter'>
                  Color
                  <ColorPicker
                    onColorSelection={onColorSelection}
                    selectedColorIds={selectedColors}
                    clearColorSelection={clearColorSelection}
                  />
                </div>
              )}
              {currentCategory && allowedFilterList.includes("category") && (
                <div className='colorAGroup' id='categoryLinks'>
                  <ScrollArea speed={0.8} horizontal={false}>
                    <List className='checkList'>{this.renderSfCategories()}</List>
                  </ScrollArea>
                </div>
              )}
            </div>
          )}
        </Modal>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  const { parsedStorefrontSettings, location } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  const { sfCategories, inventoryCategoryGroups } = state.categoryList;
  return { sfCategories, inventoryCategoryGroups, storefrontShopSetting, location };
};

export default connect(mapStateToProps, actions)(CategoryFilterMobile);
