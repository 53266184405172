import axios from "axios";
import { history as browserHistory } from "Components/Routes";

import {
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  PROFILE_IMAGE_CHANGED
} from "Constants/redux";

import { saveAuthToken, getAuthToken, saveError } from "../api";
import { setErrors, openSuccessSnackBar } from "./SnackbarActions";
import { loginUserSuccess } from "./AuthActions";
import { setCartProps } from "Actions/CartActions";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

import { objectToFormData } from "HelperFunctions/formHelpers";

// fetchProfile is called to grab the profile info from backend,
// and call the appropriate redux action to save it correctly or error out.

export const fetchProfile = (location_id = null) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Loading profile..."));
    let data = {}
    if (location_id) data.location_id = location_id
    return axios
      .get(
        process.env.REACT_APP_API_DOMAIN + `/api/portal/customers/profile/`,
        {
          params: data,
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        const customer = response.data.customer;
        dispatch({
          type: FETCH_PROFILE_SUCCESS,
          payload: { customer }
        });
        dispatch(
          setCartProps({
            customerContactPhone: customer.cell
          })
        );
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: FETCH_PROFILE_FAILURE
        });
        saveError(error, getState());
      });
  };
};

export const fetchProfileIfNeeded = () => {
  return (dispatch, getState) => {
    const { loading } = getState().customer;
    if (!loading) {
      return dispatch(fetchProfile());
    }
  };
};

export const connectAccount = (optedIn, newEvent) => {
  return (dispatch, getState) => {
    const data = {
      opted_in: optedIn
    };
    return axios
      .post(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/customers/opt_in",
        data,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        loginUserSuccess(dispatch, response.data.customer);
        if (newEvent && newEvent.id) {
          browserHistory.push(`/events/${newEvent.id}`);
        } else {
          browserHistory.push("/");
        }
      });
  };
};

const submitImage = (id, picture) => {
  const data = {
    customer: {
      picture_attributes: picture
    }
  };
  return axios
    .put(
      process.env.REACT_APP_API_DOMAIN + "/api/portal/customers/" + id,
      objectToFormData(data),
      {
        headers: getAuthToken()
      }
    )
    .then(response => {
      browserHistory.push("/profile");
    });
};

// Save the customer profile to the backend, using the values
// saved from redux-form HOC into values

export const submitProfile = values => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Saving profile..."));
    const { file, picture } = getState().customer.customer;
    const {
      mainContactAddress,
      mainPhoneNumber,
      driverLicenseState,
      driverLicenseNumber
    } = values;
    const hasAddress =
      values.streetAddress1 ||
      values.streetAddress2 ||
      values.city ||
      values.postalCode ||
      values.locale ||
      values.country;

    const customer = Object.assign(
      {
        active: true,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        driver_license_number: driverLicenseNumber,
        driver_license_state: driverLicenseState
      },
      mainPhoneNumber
        ? {
            phone_numbers_attributes: {
              "0": {
                id: mainPhoneNumber.id,
                cell: values.cell,
                main_contact_number: true,
                _destroy: values.cell ? "0" : "1"
              }
            }
          }
        : {
            phone_numbers_attributes: {
              "0": {
                cell: values.cell,
                main_contact_number: true,
                _destroy: values.cell ? "0" : "1"
              }
            }
          },
      mainContactAddress
        ? {
            physical_addresses_attributes: {
              "0": {
                id: mainContactAddress.id,
                street_address_1: values.streetAddress1,
                street_address_2: values.streetAddress2,
                city: values.city,
                locale: values.locale,
                postal_code: values.postalCode,
                country: values.country,
                main_contact_address: true,
                _destroy: hasAddress ? "0" : "1"
              }
            }
          }
        : {
            physical_addresses_attributes: {
              "0": {
                street_address_1: values.streetAddress1,
                street_address_2: values.streetAddress2,
                city: values.city,
                locale: values.locale,
                postal_code: values.postalCode,
                country: values.country,
                main_contact_address: true,
                _destroy: hasAddress ? "0" : "1"
              }
            }
          },
      picture && { picture_attributes: picture }
    );

    const data = { customer: customer };

    return axios
      .put(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/customers/" + values.id,
        objectToFormData(data),
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);

        if (file != null && file.file) {
          submitImage(values.id, { image: file.file });
        } else {
          browserHistory.push("/profile");
        }
      })
      .catch(err => {
        dispatch(setErrors(err.response.data));
        dispatch(closeLoadingSpinner());
      });
  };
};

export const handleImageDropZoneChange = (files, pictures) => {
  return {
    type: PROFILE_IMAGE_CHANGED,
    payload: { picture: pictures[0], file: files.length > 0 ? files[0] : null }
  };
};

export const uploadCustomerDocuments = ({
  id,
  values,
  onSuccess,
  onFailure
}) => {
  return (dispatch, getState) => {
    const file = {
      name: values.name,
      document_type: values.documentType,
      file: values.file[0],
      expirable: values.expirable,
      expires_at: values.expiresAt
    };
    const fileData = {
      customer_location_relationship: {
        id: id,
        documents_attributes: {
          "0": file
        }
      }
    };
    dispatch(openLoadingSpinner("Submitting document..."));
    return axios
      .put(
        process.env.REACT_APP_API_DOMAIN +
          "/api/portal/customer_location_relationships/" +
          id,
        objectToFormData(fileData),
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        dispatch(openSuccessSnackBar("Successfully uploaded the document"));
        onSuccess(response);
      })
      .catch(err => {
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(err.response.data.errors));
      });
  };
};
