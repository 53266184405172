import React from "react";
import { Helmet } from "react-helmet";
import { defaultDescription, defaultKeywords } from "HelperFunctions/defaultSEO";
    
const GlobalMetaData = ({ title, mainSetting }) => {
  const globalDescription = mainSetting.gaDescription;
  const globalKeywords = mainSetting.gaKeywords;

  return (
        <Helmet>
            <meta 
                name="description" 
                content={globalDescription || defaultDescription} 
            />
            <meta 
                name="keywords"
                content={globalKeywords || defaultKeywords} 
            />
            <title>{title}</title>
        </Helmet>
    )
};

export default GlobalMetaData;  