/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import classnames from "classnames";

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.toggleOpen = this.toggleOpen.bind(this);
  }
  componentDidMount() {
    if (this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.forceOpen !== undefined &&
      this.props.forceOpen !== nextProps.forceOpen
    ) {
      this.setState({ open: nextProps.forceOpen });
    }
  }
  toggleOpen = event => {
    if (event.target.name !== "link") {
      const { name, onToggle } = this.props;
      if (onToggle) {
        const fakeEvent = {
          target: {
            value: !this.state.open,
            name: name
          }
        };
        onToggle(fakeEvent);
      }

      this.setState({
        open: !this.state.open
      });
    }
  };
  render() {
    const {
      id,
      className,
      header,
      content,
      useHeaderAsLink
    } = this.props;
    const { open } = this.state;
    const headerLinkProps = useHeaderAsLink
      ? { onTouchTap: this.toggleOpen }
      : {};

    return (
      <div id={id} className={"accordian " + (className ? className : "")}>
        {content && (
          <a
            id={id ? id + "_toggle" : null}
            onTouchTap={this.toggleOpen}
            onClick={this.toggleOpen}
            className={classnames({
              btnCollapse: open,
              btnExpand: !open
            })}
          >
            <span className={classnames({
              accordionArrow: true,
              open: open
            })}>
            </span>
          </a>
        )}
        <div className="header" {...headerLinkProps}>
          {header}
        </div>
        {open && content && <div className="content">{content}</div>}
      </div>
    );
  }
}

Accordion.defaultProps = {
  onToggle: () => { },
  useHeaderAsLink: true
};

export default Accordion;
