import React from "react";
import { parseQuery } from "HelperFunctions/QueryString";

class FeedbackThankYou extends React.Component {
  renderContent() {
    const { locationId } = parseQuery(this.props.location.search);

    if (locationId) {
      return (
        <div>
          <h3>Thank you for your honest feedback!</h3>
          <div className="score" />
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Oops, we could not find necessary info. Please make sure you have
            navigated to the proper url.
          </p>
        </div>
      );
    }
  }

  render() {
    return <div className="feedback">{this.renderContent()}</div>;
  }
}

export default FeedbackThankYou;
