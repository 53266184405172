import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { NavLink } from 'react-router-dom';
import { getAuthToken } from 'Api';
import { MessageBubble } from 'Utils/SvgIcons';
import { ROOT_URL } from 'Api';
import axios from 'axios';

class MessagesTab extends React.Component {
  state = { isFetching: true };

  componentDidMount() {
    const { fetchConversationList, location } = this.props;
    fetchConversationList(location.id);

    this.fetchUnreadMessages();
  }

  fetchUnreadMessages = () => {
    const { setUnreadMessageCount, setErrors} = this.props;

    const headers = getAuthToken();
    const url = `${ROOT_URL}/portal/conversations/count_all_conversations_unread_messages`;

    if (!this.state.isFetching) {
      this.setState({ isFetching: true });
    }

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;

        if (data.count !== this.props.unreadMessages) {
          setUnreadMessageCount(data.count);
        }
      })
      .catch((error) => {
        setErrors("Error fetching unread messages");
        console.error('Error fetching unread messages:', error);
      })
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  };

  render() {
    const { unreadMessages } = this.props;
    return (
      <NavLink className='icon message' to='/conversations'>
        <MessageBubble />
        <label>{unreadMessages > 0 && unreadMessages}</label>
      </NavLink>
    );
  }
}

const mapStateToProps = (state) => {
  const { unreadMessages } = state.conversationList;
  const { location } = state.locations;
  return {
    unreadMessages,
    location,
  };
};

export default connect(mapStateToProps, actions)(MessagesTab);
