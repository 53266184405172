import React from 'react';
import axios from 'axios';
import Message from './Message';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROOT_URL, getAuthToken } from 'Api';
import ScrollArea from 'Utils/react-scrollbar';
import NewMessage from './NewMessageContainer';
import LoadingSpinner from 'Utils/LoadingSpinner';

// Conversation Container
class Conversation extends React.Component {
  state = { isFetching: true };

  componentDidMount() {
    this.fetchConversation();
  }

  fetchConversation() {
    const {
      match,
      location,
      unreadMessages,
      setConversation,
      setUnreadMessageCount,
      removeConversationUnreadMessages,
      setErrors,
    } = this.props;
    const authToken = getAuthToken();
    const url = `${ROOT_URL}/portal/conversations/${match.params.conversationId}`;

    if (!this.state.isFetching) {
      this.setState({ isFetching: true });
    }

    axios
      .get(url, {
        headers: authToken,
        params: { location_id: location.id },
      })
      .then(({ data }) => {
        const { conversation } = data.conversation;

        setConversation(conversation);
        removeConversationUnreadMessages(conversation.id);
        setUnreadMessageCount(unreadMessages - data.unread_messages_count);
      })
      .catch((error) => {
        setErrors("Error fetching messages")
        console.error('Error fetching messages:', error);
      })
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  }

  renderLoadingSpinner() {
    if (!this.isFetching) return null;

    return <LoadingSpinner label='Loading messages...' />;
  }

  render() {
    const { conversation } = this.props;
    return (
      <section className='conversation'>
        {conversation.rentalId && (
          <Link
            className='rentalLink'
            to={`/events/rentals/${conversation.rentalId}`}
          >
            #{conversation.rentalToken}
          </Link>
        )}
        <NewMessage />
        <ScrollArea speed={0.8} horizontal={false}>
          {this.renderLoadingSpinner()}
          {conversation.messages.map((message, index) => {
            return <Message message={message} key={index} />;
          })}
        </ScrollArea>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { unreadMessages } = state.conversationList;
  const { conversation } = state.conversation;
  const { location } = state.locations;

  return { conversation, location, unreadMessages };
};

export default connect(mapStateToProps, actions)(Conversation);
