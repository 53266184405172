/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Delete } from "Utils/SvgIcons";
import Modal from "Utils/Modal";
// Fetch profile info from Redux customer, then display it.

class CreditCardLineItem extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.toggleDelete = this.toggleDelete.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
  }
  toggleDelete() {
    this.setState({
      open: !this.state.open
    });
  }
  deleteCard() {
    const { creditCard, locationId } = this.props;
    this.toggleDelete();
    this.props.deleteCreditCard(creditCard.id, locationId);
  }

  render() {
    const { creditCard } = this.props;
    const deleteActions = [
      <button className="btn warn" key={creditCard.id} onClick={this.deleteCard}>
        Yes, Delete this card
      </button>,
      <button className="btn cancel" key={creditCard.id} onClick={this.toggleDelete}>
        No
      </button>
    ];
    return (
      <li key={creditCard.id}>
        <label>{creditCard.name}</label>
        <p>
          {creditCard.cardType}
          <span>**** {creditCard.last4}</span>
        </p>
        <a className="btnLink delete" onClick={this.toggleDelete}>
          <Delete />
        </a>
        <Modal
          actions={deleteActions}
          open={this.state.open}
          className="dialog"
          title="Delete Credit Card"
          toggle={this.toggleDelete}
        >
          <p>Are you sure you want to delete this card?</p>
          <p>This cannot be undone.</p>
        </Modal>
      </li>
    );
  }
}

export default connect(null, actions)(CreditCardLineItem);
