import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import get from "lodash/get";
import { standardService } from "Api/service";
import { arraysEqual, debounce } from "HelperFunctions/general";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import PaginationBoxView from "Utils/PaginationBoxView";
import AvailableItemCard from "Components/shop/AvailableItemCard";
import PageMetaData from "Utils/PageMetaData";
import classNames from "classnames";
import * as actions from '../../actions';
import {combineDateAndTime} from "../../helper_functions/general";
import DatePickerComponentSection from "./DatePickerComponent";

class CustomPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter,
      pagination: {},
      showcaseProducts: [],
      customPage: {
        picturings: [],
        photoCredits: []
      },
      errorCardId: null,
    };

    this.handleQueryChange = debounce(this.handleQueryChange, 500);
  }

  componentDidMount() {
    this.fetchCustomPage();
  }

  fetchShowcaseProducts = filter => {
    const { page } = filter;
    const component = this;
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
        "/api/portal/storefront_custom_pages/" +
        component.state.customPage.id +
        "/showcase_products",
        {
          page: page,
          number_per: 9,
          ...filter
        }
      )
      .then(response => {
        component.setState({
          showcaseProducts: response.data.showcaseProducts,
          pagination: response.data.meta.pagination || 0,
          filter: filter
        });
      })
      .catch(error => {
        const errors = error.response.data;
        console.log(errors);
      });
  };

  fetchCustomPage = () => {
    const component = this;
    const { locationId } = this.props;
    const navigationLink = this.props.match.params.id;
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
        "/api/portal/storefront_custom_pages/page_from_navigation_link",
        { navigation_link: navigationLink, location_id: locationId }
      )
      .then(response => {
        const customPage = get(response, "data.storefrontCustomPage");
        if (customPage) {
          component.setState(
            {
              customPage: response.data.storefrontCustomPage
            },
            () => this.fetchShowcaseProducts(this.state.filter)
          );
        } else {
          component.props.history.push("/shop");
        }
      })
      .catch(error => {
        const errors = error.response.data;
        console.log(errors);
      });
  };

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;

    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        if (newFilter[property] instanceof Array) {
          if (arraysEqual(newFilter[property], oldFilter[property]) === false) {
            changed = true;
            break;
          }
        } else {
          const newTest =
            newFilter[property] instanceof Date
              ? newFilter[property].getTime()
              : newFilter[property];
          const oldTest =
            oldFilter[property] instanceof Date
              ? oldFilter[property].getTime()
              : oldFilter[property];
          if (newTest !== oldTest) {
            changed = true;
            break;
          }
        }
      }
    }

    if (changed) {
      const filterToSubmit = Object.assign(newFilter);
      this.fetchShowcaseProducts(filterToSubmit);
    }
  }

  handleSearch = event => {
    const { value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        query: value
      }
    });
    this.handleQueryChange(value);
  };

  handleQueryChange(value) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value
      },
      location,
      history
    );
  }

  handlePageChange = data => {
    const { location, history } = this.props;
    setFilterParams(
      {
        page: data.selected + 1
      },
      location,
      history
    );
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    this.props.setCartProps({ [name]: value });
    const { event } = this.props;
    let newEvent = { ...event, [name]: value };
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
    const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
    const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
    this.fetchShowcaseProducts({
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime,
    });
  };

  setErrorCardId = (value) => {
    this.setState({ errorCardId: value })
  }

  renderTable = () => {
    const { showcaseProducts } = this.state;
    const apiClassToProductType = {
      Product: "items",
      AddOn: "addons",
      Bundle: "bundles"
    };
    let table = showcaseProducts.map(showcaseProduct => {
      const product = {
        ...showcaseProduct.product,
        productType: apiClassToProductType[showcaseProduct.productType]
      };
      return <AvailableItemCard key={showcaseProduct.id} product={product} setErrorCardId={this.setErrorCardId} errorCardId={this.state.errorCardId} />;
    });
    return table;
  };

  render() {
    const { filter, customPage, showcaseProducts, pagination } = this.state;
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime },
    } = this.props;
    const { themeSetting } = this.props;
    let moveCredits = false;
    if (
      themeSetting.theme === "catalina" ||
      themeSetting.theme === "catalinaDark"
    ) {
      moveCredits = true;
    }

    const themesToRenderOutsideOfDetails = ['magnolia', 'magnoliaDark', 'catalina', 'catalinaDark'];

    return (
      <div className={classNames({ getTheLook: true, movedCredits: moveCredits })}>
        <PageMetaData setting={customPage} />
        {customPage.picturings.length > 0 && (
          <Carousel
            className="lookCarousel"
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={false}
            interval={4000}
          >
            {customPage.picturings.map(picturing => (
              <figure key={picturing.id}>
                <img src={picturing.url} alt="" />
              </figure>
            ))}
          </Carousel>
        )}
        {moveCredits && (
          <div className="photoCredits">
            {customPage.photoCredits.map(photoCredit => (
              <div key={photoCredit.id}>
                <label>{photoCredit.role}:</label> {photoCredit.name}
              </div>
            ))}
          </div>
        )}
        <div className="details">
          {!moveCredits && (
            <div className="photoCredits">
              {customPage.photoCredits.map(photoCredit => (
                <div key={photoCredit.id}>
                  <label>{photoCredit.role}:</label> {photoCredit.name}
                </div>
              ))}
            </div>
          )}
          <h1 className={`customPageNavigationName ${themeSetting.theme}`}>{customPage.navigationName}</h1>
          <p className={`customPageHeroDescription ${themeSetting.theme}`}>{customPage.heroDescription}</p>
          {!themesToRenderOutsideOfDetails.includes(themeSetting.theme) &&
            <DatePickerComponentSection
              handleDateChange={this.handleDateChange}
              eventStart={eventStart}
              eventEnd={eventEnd}
              eventStartTime={eventStartTime}
              eventEndTime={eventEndTime}
              themeSetting={themeSetting}
            />
          }
        </div>
        <section className={`storeItems ${themeSetting.theme}`}>
          {themesToRenderOutsideOfDetails.includes(themeSetting.theme) &&
            <div className={`datePickerComponent ${themeSetting.theme}`}>
              <DatePickerComponentSection
                handleDateChange={this.handleDateChange}
                eventStart={eventStart}
                eventEnd={eventEnd}
                eventStartTime={eventStartTime}
                eventEndTime={eventEndTime}
                themeSetting={themeSetting}
              />
            </div>
          }
          {showcaseProducts.length > 0 && this.renderTable()}
          <PaginationBoxView
            className="pagerWrapper"
            containerClassName="pager"
            forcePage={filter.page ? +filter.page - 1 : 0}
            pageCount={pagination.totalPages}
            onPageChange={this.handlePageChange}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);

  const { location, parsedStorefrontSettings } = state.locations;
  const themeSetting = parsedStorefrontSettings.mainSetting;
  return {
    filter: newFilter,
    locationId: location.id,
    themeSetting,
    event: state.cart.event,
  };
};

export default compose(withRouter, connect(mapStateToProps, actions))(CustomPage);
