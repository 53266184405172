import { LOCATION_CHANGE } from "react-router-redux";
import {
  ADMIN_FIELD_CHANGED,
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_FAIL,
  CREATE_ADMIN_FAIL,
  LOGIN_ADMIN,
  SIGN_OUT_ADMIN_SUCCESS,
  SIGN_OUT_ADMIN_FAIL,
  SIGN_OUT_ADMIN,
  VALIDATE_ADMIN,
  VALIDATE_ADMIN_SUCCESS,
  VALIDATE_ADMIN_FAIL
} from "Constants/redux";

const INITIAL_STATE = {
  authenticated: false,
  email: "",
  password: "",
  passwordConfirmation: "",
  admin: null,
  errors: {
    email: "",
    password: "",
    login: ""
  },
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_FIELD_CHANGED:
      const { field, text } = action.payload;
      return { ...state, [field]: text };
    case LOGIN_ADMIN:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        admin: action.payload,
        authenticated: true
      };
    case LOGIN_ADMIN_FAIL:
      return {
        ...state,
        errors: { login: action.payload[0] },
        password: "",
        loading: false
      };
    case CREATE_ADMIN_FAIL:
      const { email, password } = action.payload;
      return {
        ...state,
        errors: {
          email: email ? email[0] : "",
          password: password ? password[0] : ""
        }
      };
    case VALIDATE_ADMIN:
      return { ...state, errors: INITIAL_STATE.errors, loading: true };
    case VALIDATE_ADMIN_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        user: action.payload,
        authenticated: true,
        loading: false
      };
    case VALIDATE_ADMIN_FAIL:
      return { ...state, errors: INITIAL_STATE.errors, loading: false };
    case SIGN_OUT_ADMIN:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case SIGN_OUT_ADMIN_FAIL:
      return {
        ...state,
        errors: { signOut: action.payload[0] },
        password: "",
        loading: false
      };
    case SIGN_OUT_ADMIN_SUCCESS:
      return { ...state, ...INITIAL_STATE, admin: null, authenticated: false };
    case LOCATION_CHANGE:
      return { ...state, errors: INITIAL_STATE.errors };
    default:
      return state;
  }
};
