import {
  SET_EVENT_PROPS,
  CLEAR_CART_PROPS,
  SET_CART_PROPS,
  SET_CART_NEW_EVENT_PROPS,
  SET_CART_NEW_CUSTOMER_PROPS,
  SET_CART_TRANSACTION_PROPS,
  INIT_CART_PROPS,
  START_ADDING_ITEMS_TO_EVENT,
  STOP_ADDING_ITEMS_TO_EVENT,
  FETCH_RENTAL_SUCCESS,
  ADD_RENTAL_BUNDLE,
  UPDATE_RENTAL_BUNDLE,
} from 'Constants/redux';
import axios from 'axios';
import { getAuthToken } from 'Api';
import converter from 'json-style-converter/es5';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import { getDamageWaiverFee } from 'HelperFunctions/rentals';
import { getRentalBundleWithPrice } from 'HelperFunctions/rental_items';
import { setErrors } from './SnackbarActions';
import { ROOT_URL } from 'Api';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import moment from 'moment-timezone';

export const setCartProps = (newProps) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { event } = getState().cart;
    const newEvent = { ...event, ...newProps };
    const newDamageWaiverFee = getDamageWaiverFee(newEvent, location);
    dispatch({
      type: SET_CART_PROPS,
      payload: {
        ...newProps,
        damageWaiverFeeTotal: newDamageWaiverFee,
        location,
      },
    });
  };
};

export const clearCartProps = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_CART_PROPS,
      payload: {},
    });
  };
};

export const addRentalBundle = (bundle, bundleQuantity) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { event } = getState().cart;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      rentalBundles,
    } = event;

    dispatch({
      type: ADD_RENTAL_BUNDLE,
      payload: {
        ...bundle,
        quantity: bundleQuantity,
      },
    });

    let newBundle = {
      name: bundle.name,
      quantity: bundleQuantity,
      bundleId: bundle.id,
      defaultPricing: bundle.defaultPricing,
      hourlyPrice: bundle.hourlyPrice,
      halfDayPrice: bundle.halfDayPrice,
      dailyPrice: bundle.dailyPrice,
      weeklyPrice: bundle.weeklyPrice,
      monthlyPrice: bundle.monthlyPrice,
      flatPrices: bundle.flatPrices.map((flatPrice) => ({
        id: '',
        name: flatPrice.name,
        amount: flatPrice.amount,
        pricingLabelId: flatPrice.pricing_label_id,
      })),
      purchasePrice: bundle.purchasePrice,
      description: bundle.description,
      discountPercent: bundle.discountPercent,
      priceLocked: bundle.priceLocked,
      notes: bundle.notes,
      rentalItems: [],
      rentalAccessories: [],
      rentalAddOns: [],
    };

    axios.get(`${ROOT_URL}/portal/bundles/${bundle.token}`).then((response) => {
      const detailedBundle = response.data.bundle;
      detailedBundle.productBundleRelationships.forEach((container, index) => {
        const quant = bundleQuantity * container.quantity;
        const item = container.product;
        newBundle.rentalItems.push({
          name: item.name,
          type: 'RentalItemStandard',
          quantity: quant,
          productId: item.id,
          description: item.description,
          defaultPricing: item.defaultPricing,
          hourlyPrice: item.hourlyPrice,
          halfDayPrice: item.halfDayPrice,
          dailyPrice: item.dailyPrice,
          weeklyPrice: item.weeklyPrice,
          monthlyPrice: item.monthlyPrice,
          flatPrices: item.flatPrices.map((flatPrice) => ({
            id: '',
            name: flatPrice.name,
            amount: flatPrice.amount,
            pricingLabelId: flatPrice.pricing_label_id,
          })),
          _destroy: '0',
        });
      });

      detailedBundle.accessoryBundleRelationships.forEach(
        (container, index) => {
          const quant = bundleQuantity * container.quantity;
          const accessory = container.accessory;
          newBundle.rentalAccessories.push({
            name: accessory.name,
            quantity: quant,
            accessoryId: accessory.id,
            description: accessory.description,
            _destroy: '0',
          });
        }
      );

      detailedBundle.addOnBundleRelationships.forEach((container, index) => {
        const quant = bundleQuantity * container.quantity;
        const addOn = container.addOn;
        newBundle.rentalAddOns.push({
          name: addOn.name,
          quantity: quant,
          addOnId: addOn.id,
          pricing: addOn.pricing,
          description: addOn.description,
          _destroy: '0',
        });
      });

      newBundle = {
        ...newBundle,
        bundle: detailedBundle,
      };

      let rentalBundleWithPrice = getRentalBundleWithPrice(
        newBundle,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        location
      );

      let newRentalBundles = rentalBundles.slice();
      newRentalBundles.push(rentalBundleWithPrice);

      const newEvent = {
        ...event,
        rentalBundles: newRentalBundles,
      };
      const newDamageWaiverFee = getDamageWaiverFee(newEvent, location);
      dispatch({
        type: SET_CART_PROPS,
        payload: {
          rentalBundles: newRentalBundles,
          damageWaiverFeeTotal: newDamageWaiverFee,
          location,
        },
      });
    });
  };
};

export const updateRentalBundleEditEvent = (
  name,
  value,
  rentalBundleIndex,
  event
) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      rentalBundles,
    } = event;
    let newRentalBundles = rentalBundles.slice();
    let rentalBundle = newRentalBundles[rentalBundleIndex];

    let newRentalBundle = {
      ...rentalBundle,
      [name]: toNumber(value),
    };

    dispatch({
      type: UPDATE_RENTAL_BUNDLE,
      payload: {
        newRentalBundle,
      },
    });

    /** Update quantities of underlying bundle inventories **/
    const newBundleQuantity = Number(newRentalBundle.quantity);
    newRentalBundle.rentalItems = newRentalBundle.rentalItems.map(
      (rentalItem) => {
        if (!rentalItem.setManually) {
          const container =
            newRentalBundle.bundle.productBundleRelationships.find(
              (pbr) => pbr.productId === rentalItem.productId
            );
          rentalItem.quantity = newBundleQuantity * container.quantity;
        }
        return rentalItem;
      }
    );

    newRentalBundle.rentalAccessories = newRentalBundle.rentalAccessories.map(
      (rentalAccessory) => {
        if (!rentalAccessory.setManually) {
          const container =
            newRentalBundle.bundle.accessoryBundleRelationships.find(
              (pbr) => pbr.accessoryId === rentalAccessory.accessoryId
            );
          rentalAccessory.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAccessory;
      }
    );

    newRentalBundle.rentalAddOns = newRentalBundle.rentalAddOns.map(
      (rentalAddOn) => {
        if (!rentalAddOn.setManually) {
          const container =
            newRentalBundle.bundle.addOnBundleRelationships.find(
              (pbr) => pbr.addOnId === rentalAddOn.addOnId
            );
          rentalAddOn.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAddOn;
      }
    );
    /** Update quantities of underlying bundle inventories **/

    const rentalBundleWithPrice = getRentalBundleWithPrice(
      newRentalBundle,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      location
    );

    newRentalBundles[rentalBundleIndex] = rentalBundleWithPrice;
    const newEvent = {
      ...event,
      rentalBundles: newRentalBundles,
    };
    const newDamageWaiverFee = getDamageWaiverFee(newEvent, location);
    dispatch({
      type: SET_CART_PROPS,
      payload: {
        rentalBundles: newRentalBundles,
        damageWaiverFeeTotal: newDamageWaiverFee,
        location,
      },
    });
    dispatch({
      type: SET_EVENT_PROPS,
      payload: {
        rentalBundles: newRentalBundles,
        damageWaiverFeeTotal: newDamageWaiverFee,
        location,
      },
    });
  };
};

export const updateRentalBundle = (name, value, rentalBundleIndex) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { event } = getState().cart;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      rentalBundles,
    } = event;
    let newRentalBundles = rentalBundles.slice();
    let rentalBundle = newRentalBundles[rentalBundleIndex];

    let newRentalBundle = {
      ...rentalBundle,
      [name]: toNumber(value),
    };

    dispatch({
      type: UPDATE_RENTAL_BUNDLE,
      payload: {
        newRentalBundle,
      },
    });

    /** Update quantities of underlying bundle inventories **/
    const newBundleQuantity = Number(newRentalBundle.quantity);
    newRentalBundle.rentalItems = newRentalBundle.rentalItems.map(
      (rentalItem) => {
        if (!rentalItem.setManually) {
          const container =
            newRentalBundle.bundle.productBundleRelationships.find(
              (pbr) => pbr.productId === rentalItem.productId
            );
          rentalItem.quantity = newBundleQuantity * container.quantity;
        }
        return rentalItem;
      }
    );

    newRentalBundle.rentalAccessories = newRentalBundle.rentalAccessories.map(
      (rentalAccessory) => {
        if (!rentalAccessory.setManually) {
          const container =
            newRentalBundle.bundle.accessoryBundleRelationships.find(
              (pbr) => pbr.accessoryId === rentalAccessory.accessoryId
            );
          rentalAccessory.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAccessory;
      }
    );

    newRentalBundle.rentalAddOns = newRentalBundle.rentalAddOns.map(
      (rentalAddOn) => {
        if (!rentalAddOn.setManually) {
          const container =
            newRentalBundle.bundle.addOnBundleRelationships.find(
              (pbr) => pbr.addOnId === rentalAddOn.addOnId
            );
          rentalAddOn.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAddOn;
      }
    );
    /** Update quantities of underlying bundle inventories **/

    const rentalBundleWithPrice = getRentalBundleWithPrice(
      newRentalBundle,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      location
    );

    newRentalBundles[rentalBundleIndex] = rentalBundleWithPrice;
    const newEvent = {
      ...event,
      rentalBundles: newRentalBundles,
    };
    const newDamageWaiverFee = getDamageWaiverFee(newEvent, location);
    dispatch({
      type: SET_CART_PROPS,
      payload: {
        rentalBundles: newRentalBundles,
        damageWaiverFeeTotal: newDamageWaiverFee,
        location,
      },
    });
    dispatch({
      type: SET_EVENT_PROPS,
      payload: {
        rentalBundles: newRentalBundles,
        damageWaiverFeeTotal: newDamageWaiverFee,
        location,
      },
    });
  };
};

export const setCartNewEventProps = (newProps) => {
  return {
    type: SET_CART_NEW_EVENT_PROPS,
    payload: newProps,
  };
};

export const setCartNewCustomerProps = (newProps) => {
  return {
    type: SET_CART_NEW_CUSTOMER_PROPS,
    payload: newProps,
  };
};

export const setCartTransactionProps = (newProps) => {
  return {
    type: SET_CART_TRANSACTION_PROPS,
    payload: newProps,
  };
};

export const initCartProps = () => {
  return {
    type: INIT_CART_PROPS,
  };
};

const build_rails_object = (state) => {
  const { customer } = state.customer;
  const { event } = state.cart;
  const { billing_note, new_bank, new_card, send_receipt, transaction } =
    state.cart;
  const {
    items,
    rentalBundles,
    addOns,
    eventStart,
    eventStartTime,
    eventEnd,
    eventEndTime,
    deliveryAddressStreetAddress1,
    deliveryAddressStreetAddress2,
    pickupAddressStreetAddress1,
    pickupAddressStreetAddress2,
    ...other
  } = event;
  const { location } = state.locations;
  const customerLocationRelationship =
    customer.customerLocationRelationships?.find(
      (clr) => clr.locationId === location.id
    );
  const newCompany =
    customerLocationRelationship &&
    customerLocationRelationship.customerCompanyRelationships.length > 0
      ? {
          company_id:
            customerLocationRelationship.customerCompanyRelationships[0]
              .companyId,
          name: customerLocationRelationship.customerCompanyRelationships[0]
            .company.name,
        }
      : {};

  const itemsRailsObject = items.reduce((holder, item, currentIndex) => {
    item.selectedPriceBeforeDiscount = item.selectedPrice;
    item.flatPricesAttributes = item.flatPrices;
    item.rentalItemCustomTaxRelationshipAttributes =
      item.rentalItemCustomTaxRelationship;
    const formattedItem = converter.camelToSnakeCase(item);
    holder[currentIndex] = formattedItem;
    return holder;
  }, {});
  const addOnsRailsObject = addOns.reduce((holder, addOn, currentIndex) => {
    addOn.selectedPriceBeforeDiscount = addOn.selectedPrice;
    const formattedItem = converter.camelToSnakeCase(addOn);
    holder[currentIndex] = formattedItem;
    return holder;
  }, {});
  const rentalBundlesRailsObject = rentalBundles.reduce(
    (holder, rentalBundle, currentIndex) => {
      rentalBundle.flatPricesAttributes = rentalBundle.flatPrices;
      for (let rentalItem of rentalBundle.rentalItems) {
        if (rentalItem.hasOwnProperty('flatPrices')) {
          rentalItem['flatPricesAttributes'] = rentalItem.flatPrices;
        }
      }
      let formattedItem = converter.camelToSnakeCase(rentalBundle);
      formattedItem['rental_item_standards_attributes'] =
        formattedItem.rental_items;
      formattedItem['rental_accessories_attributes'] =
        formattedItem.rental_accessories;
      formattedItem['rental_add_ons_attributes'] = formattedItem.rental_add_ons;
      holder[currentIndex] = formattedItem;
      return holder;
    },
    {}
  );
  const timeZone = moment.tz.guess();
  const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
  const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
  const scheduleRailsObject = {
    id: event.schedule ? event.schedule.id : '',
    event_start_date_time: eventStartDateTime,
    event_end_date_time: eventEndDateTime,
    start_date_time: eventStartDateTime,
    end_date_time: eventEndDateTime,
    start_window_finish: eventStartDateTime,
    end_window_beginning: eventEndDateTime,
    off_shelf_at: eventStartDateTime,
    on_shelf_at: eventEndDateTime,
    time_zone: timeZone,
    same_as_event_date: false,
  };
  const otherRailsObject = converter.camelToSnakeCase(other);
  return {
    ...otherRailsObject,
    rental_item_standards_attributes: itemsRailsObject,
    rental_bundles_attributes: rentalBundlesRailsObject,
    rental_add_ons_attributes: addOnsRailsObject,
    schedule_attributes: scheduleRailsObject,
    delivery_address_street_address_1: deliveryAddressStreetAddress1,
    delivery_address_street_address_2: deliveryAddressStreetAddress2,
    pickup_address_street_address_1: pickupAddressStreetAddress1,
    pickup_address_street_address_2: pickupAddressStreetAddress2,
    billing_note: billing_note,
    new_bank: new_bank,
    new_card: new_card,
    send_receipt: send_receipt,
    transaction: transaction,
    company_rental_relationship_attributes: newCompany,
  };
};

export const newReview = (onSuccess) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner('Review in Progress...'));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN + '/api/portal/rentals/new_review',
        {
          location_id: location.id,
          rental: data,
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const {
          deliveryCost,
          taxTotal,
          canBeAutoBooked,
          findSalesTaxRate,
          findTapgoodsFeePercent,
          rentalTotalCalculator,
          paymentProcessor,
        } = response.data.rental;

        // set values from review
        dispatch(
          setCartProps({
            deliveryCost,
            taxTotal,
            canBeAutoBooked,
            findSalesTaxRate,
            findTapgoodsFeePercent,
            rentalTotalCalculator,
            paymentProcessor,
          })
        );
        onSuccess(response.data.rental);
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const newSubmit = (autoBookNeeded, onSuccess) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner('Submitting Order...'));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/rentals/create_with_auth?location_id=' +
          location.id,
        {
          location_id: location.id,
          auto_book: true,
          rental: data,
          location: data.location,
          ...data,
        },
        {
          headers: getAuthToken(),
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(initCartProps());
        const rental = response.data.rental;
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          payload: { rental },
        });
        onSuccess(rental);
      })
      .catch((error) => {
        dispatch(setErrors(error?.response?.data?.errors));
        dispatch(closeLoadingSpinner());
        console.log(error);
        console.log(error?.response?.data?.errors);
      });
  };
};

export const newSubmitWithoutAuth = (autoBookNeeded, onSuccess) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { newCustomer } = getState().cart;
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner('Submitting Order...'));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/rentals/create_without_auth?location_id=' +
          location.id,
        {
          location_id: location.id,
          auto_book: autoBookNeeded,
          customer: converter.camelToSnakeCase(newCustomer),
          rental: data,
          ...data,
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(initCartProps());
        const rental = response.data.rental;
        const customer = response.data.customer;
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          payload: { rental, customer },
        });
        onSuccess(rental);
      })
      .catch((error) => {
        dispatch(setErrors(error?.response?.data?.errors));
        dispatch(closeLoadingSpinner());
        console.log(error);
        console.log(error?.response?.data?.errors);
      });
  };
};

export const editAddItemsSubmit = (onSuccess) => {
  return (dispatch, getState) => {
    const { beforeAttributes } = getState().event;
    const afterAttributes = build_rails_object(getState());
    const { event } = getState().cart;
    const data = {
      before_attributes: JSON.stringify(beforeAttributes),
      after_attributes: JSON.stringify(afterAttributes),
    };

    dispatch(openLoadingSpinner('Updating Order...'));
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${event.id}/request_changes`,
        data,
        { headers: getAuthToken() }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        onSuccess(response.data.rental);
        dispatch(
          setCartProps({ items: [], addOns: [], rentalBundles: [] })
        )
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const startAddingItemsToEvent = (event) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;

    let newRentalBundles = [];
    event.rentalBundles.forEach((rentalBundle) => {
      const rentalBundleWithPrice = getRentalBundleWithPrice(
        rentalBundle,
        combineDateAndTime(event.eventStart, event.eventStartTime),
        combineDateAndTime(event.eventEnd, event.eventEndTime),
        location
      );
      newRentalBundles.push(rentalBundleWithPrice);
    });
    event = {
      ...event,
      rentalBundles: newRentalBundles,
    };
    const newDamageWaiverFee = getDamageWaiverFee(event, location);
    event = {
      ...event,
      damageWaiverFeeTotal: newDamageWaiverFee,
    };
    dispatch({
      type: START_ADDING_ITEMS_TO_EVENT,
      payload: event,
    });
  };
};

export const stopAddingItemsToEvent = (newProps) => {
  return {
    type: STOP_ADDING_ITEMS_TO_EVENT,
  };
};
