import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SearchItems from './SearchItems';
import SearchCategoryItems from './SearchCategoryItems';
import AvailableItem from './AvailableItem';

const Shop = (props) => {
  return (
    <Switch>
      <Route exact path='/shop' component={SearchItems} />
      <Route path='/shop/items/:id/:slug' component={AvailableItem('items')} />
      <Route path='/shop/items/:id' component={AvailableItem('items')} />
      <Route
        path='/shop/bundles/:id/:slug'
        component={AvailableItem('bundles')}
      />
      <Route path='/shop/bundles/:id' component={AvailableItem('bundles')} />
      <Route
        path='/shop/addons/:id/:slug'
        component={AvailableItem('add_ons')}
      />
      <Route path='/shop/addons/:id' component={AvailableItem('add_ons')} />
      <Route path='/shop/:category' component={SearchCategoryItems} />
    </Switch>
  );
};

export default Shop;
