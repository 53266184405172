import React from "react";
import { Chairs } from "../utils/SvgIcons";
import { Link } from "react-router-dom";

const BusinessNoItemsPage = () => {
  return (
    <section className="storeItems">
      <div className="icon">
        <Chairs />
      </div>
      <h2>We didn't find items for your search</h2>
      <p>
        The good news... we're always adding items! Come back and check again
        <br />
        later. You can also try searching for other items.
      </p>
      <Link className="btn" to="/shop">
        Return to Shop
      </Link>
    </section>
  );
};

export default BusinessNoItemsPage;
