import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import EventsTable from "./EventsTable";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import PageMetaData from "Utils/PageMetaData";

class EventsIndex extends React.Component {
  componentDidMount() {
    const { location, type, fetchRentalsList } = this.props;
    fetchRentalsList({ type: type, location_id: location.id });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.type !== nextProps.type) {
      this.props.fetchRentalsList({
        type: nextProps.type,
        location_id: nextProps.location.id
      });
    }
  }
  render() {
    const { type, storefrontShopSetting, storefrontEventsSetting } = this.props;

    return (
      <div className="events">
        <PageMetaData setting={storefrontEventsSetting} />
        <aside className="sideNav">
          <h3>Manage Your Orders</h3>
          <nav>
            <Link
              to="/events/quotes"
              className={classnames({ active: type === "quotes" })}
            >
              Quotes
            </Link>
            <Link
              to="/events/upcoming"
              className={classnames({ active: type === "upcoming" })}
            >
              Upcoming Orders
            </Link>
            <Link
              to="/events/past"
              className={classnames({ active: type === "past" })}
            >
              Past Orders
            </Link>
            <Link
              to="/events/cancelled"
              className={classnames({ active: type === "cancelled" })}
            >
              Cancelled Orders
            </Link>
          </nav>
        </aside>
        <EventsTable storefrontShopSetting={storefrontShopSetting} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings: { storefrontEventsSetting,
    storefrontShopSetting } } = state.locations;
  return {
    type: ownProps.type || "quotes",
    location,
    storefrontEventsSetting,
    storefrontShopSetting,
  };
};

export default withRouter(connect(mapStateToProps, actions)(EventsIndex));
