import { cloneDeep } from '@apollo/client/utilities';
import { getRentalBundleWithPrice } from '../../../helper_functions/rental_items';
import { apolloClient } from '../../Root';
import { GetBundles } from '../ExternalCart.graphql';

// @param items [{ id: string, quantity: number }]
export const fetchAndConvertBundlesToRentalBundles = async ({
  items,
  eventStart,
  eventEnd,
  locationId,
}) => {
  const id = items.map((bundle) => bundle.id);

  if (id.length === 0) return { success: true, rentalObjects: [] };

  try {
    const response = await apolloClient.query({
      query: GetBundles,
      variables: { search: '*', id, locationId },
    });

    if (response?.errors?.length > 0) {
      return {
        success: false,
        rentalObjects: [],
        reason: 'An error occurred while loading bundle details.',
      };
    }

    const detailedBundles = response.data?.getBundles ?? [];

    const formattedBundles = formatBundlesAsRentalBundles(detailedBundles);

    formattedBundles.forEach((item) => {
      const bundle = items.find(
        (bundle) => Number(bundle.id) === Number(item.bundleId)
      );

      item.quantity = bundle?.quantity ?? 0;

      item.rentalItems = item.productBundleRelationships.map((relationship) => {
        return {
          ...formatBundleProductAsRentalItem(relationship.product),
          quantity: relationship.quantity * item.quantity,
        };
      });

      item.rentalAddOns = item.addOnBundleRelationships.map((relationship) => {
        return {
          ...formatBundleAddOnAsRentalAddOn(relationship.addOn),
          quantity: relationship.quantity * item.quantity,
        };
      });

      item.rentalAccessories = item.accessoryBundleRelationships.map(
        (relationship) => {
          return {
            ...formatBundleAccessoryAsRentalAccessory(relationship.accessory),
            quantity: relationship.quantity * item.quantity,
          };
        }
      );

      Object.assign(item, {
        ...getRentalBundleWithPrice(item, eventStart, eventEnd),
      });
    });

    return { success: true, rentalObjects: formattedBundles };
  } catch (err) {
    return {
      success: false,
      reason: 'An error occurred during cart check out.',
      rentalObjects: [],
    };
  }
};

const formatBundlesAsRentalBundles = (bundles) => {
  return bundles.map((bundle) => {
    const flatPrices = bundle.flatPrices.map((p) => ({ ...p, id: '' }));

    return {
      ...bundle,
      bundleId: Number(bundle.id),
      rentalItems: [],
      rentalAddOns: [],
      rentalAccessories: [],
      bundle: cloneDeep(bundle),
      flatPrices,
    };
  });
};

const formatBundleProductAsRentalItem = (item) => {
  return {
    ...item,
    productId: Number(item.id),
    flatPrices: item.flatPrices?.map((p) => ({ ...p, id: '' })) ?? [],
    type: 'RentalItemStandard',
    _destroy: '0',
  };
};

const formatBundleAddOnAsRentalAddOn = (item) => {
  return {
    ...item,
    addOnId: Number(item.id),
    _destroy: '0',
  };
};

const formatBundleAccessoryAsRentalAccessory = (item) => {
  return {
    ...item,
    accessoryId: Number(item.id),
    _destroy: '0',
  };
};
