/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import LinearProgress from "./LinearProgressBar";
import Modal from "Utils/Modal";
import * as actions from "Actions";
import ScrollArea from "./react-scrollbar";

class GlobalProgressBar extends React.Component {
  render() {
    const {
      complete,
      visible,
      successOpen,
      progressOpen,
      closeProgressSuccessModal,
      closeProgressModal,
      importResponse
    } = this.props;

    return (
      <div>
        {visible && (
          <div>
            <Modal
              open={progressOpen}
              toggle={closeProgressModal}
              title="Import in progress"
              actions={[
                <a className="btn full" onClick={closeProgressModal}>
                  OK
                </a>
              ]}
            >
              <p>
                You have an import happening. Progress is being displayed in a
                progress bar at the top of the page.
              </p>
            </Modal>
            <LinearProgress
              mode="determinate"
              value={complete}
              color="#00ff00"
            />
          </div>
        )}
        <Modal
          open={successOpen}
          toggle={closeProgressSuccessModal}
          title="Import completed!"
          actions={[
            <a
              key="ok"
              className="btn full"
              onClick={closeProgressSuccessModal}
            >
              OK
            </a>
          ]}
        >
          <p>{importResponse.message}</p>
          {importResponse.errors && importResponse.errors.length > 0 && (
            <div>
              <h4>{importResponse.errors.length} Errors:</h4>
              <ScrollArea speed={0.8} horizontal={false}>
                {importResponse.errors.map(err => {
                  const row = err[0];
                  const rowErrors = err[1];
                  return Object.keys(rowErrors).map((rowErrName, index) => {
                    const rowErr = rowErrors[rowErrName];
                    return (
                      <div key={index}>
                        Row {row} - {rowErr}
                      </div>
                    );
                  });
                })}
              </ScrollArea>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  // const {
  //   complete,
  //   visible,
  //   successOpen,
  //   importResponse,
  //   progressOpen
  // } = state.progressBar;
  // return { complete, visible, successOpen, importResponse, progressOpen };
  return { importResponse: {}, successOpen: false, progressOpen: false };
};

export default connect(mapStateToProps, actions)(GlobalProgressBar);
