import React from 'react';
import { connect } from 'react-redux';
import { Items } from 'Utils/SvgIcons';
import classnames from 'classnames';
import Imgix from 'react-imgix';
import PageMetaData from 'Utils/PageMetaData';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class Showcase extends React.Component {
  render() {
    const { storefrontSetting, themeSetting } = this.props;
    const { storefrontShowcaseCategories } = storefrontSetting;
    return (
      <div className='showcase'>
        <PageMetaData setting={storefrontSetting} />
        <h1>What is available where?</h1>
        <section
          className={classnames({
            ['count' + storefrontShowcaseCategories
              ? storefrontShowcaseCategories.length
              : 0]: true,
          })}
        >
          {storefrontShowcaseCategories && storefrontShowcaseCategories.map(showcaseCategory => (
            <a href={showcaseCategory.categoryLink} key={showcaseCategory.id}>
              <figure>
                {showcaseCategory.picture ? (
                  <Imgix
                    src={showcaseCategory.picture.imgixUrl}
                    alt="category_picture"
                    sizes='50vw'
                  />
                ) : (
                  <Items />
                )}
              </figure>
              <div className="btn secondary"
                style={{
                  color: `${showcaseCategory.categoryTitleFontTheme === "dark" ?
                    themeSetting.darkFontColor :
                    themeSetting.lightFontColor}`
                }}
              >
                {showcaseCategory.categoryTitle}
              </div>
            </a>
          ))}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const themeSetting = parsedStorefrontSettings.mainSetting;
  const storefrontSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  return { storefrontSetting, themeSetting };
};

export default connect(mapStateToProps)(Showcase);
