import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment-timezone';
import { FormattedDate } from 'react-intl';
import { getAuthToken } from 'Api';
import * as actions from 'Actions';
import LoadingSpinner from 'Utils/LoadingSpinner';
import Modal from 'Utils/Modal';
import {
  nestedStateForRailsWithConversion,
  prepopulateArray,
} from 'HelperFunctions/formHelpers';
import { objectToFormData } from 'HelperFunctions/formHelpers';
import Rental from './Rental';
import ChangeRequestData from './change_request/ChangeRequestData';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { isFinalBalanceDue } from 'HelperFunctions/rentals';
import { Alert } from 'Utils/SvgIcons';
import cloneDeep from 'lodash/cloneDeep';
import converter from 'json-style-converter/es5';
import { getDisclaimersSignatures } from 'HelperFunctions/disclaimers';
import { getSignatureFromRental } from 'HelperFunctions/rentalSignature';

class RentalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelModalOpen: false,
      tab: 0,
      rental: {},
      disclaimerRentalRelationships: [],
      loading: false,
      hours: 0,
      name: '',
      status: '',
      schedule_id: '',
      checkout: null,
      checkoutWindowEnd: null,
      checkout_time: null,
      checkin: null,
      checkinWindowStart: null,
      checkin_time: null,
      event_start: null,
      event_start_time: null,
      event_end: null,
      event_end_time: null,
      taxExempt: false,
      depositRequired: false,
      signatureRequired: false,
      npsRequired: false,
      hasAgreement: true,
      manualDamageWaiver: false,
      depositType: 'optional',
      expireType: 'no_expire',
      customExpireDate: null,
      delivery_cost: '',
      delivery_type: 'select_a_delivery_method',
      delivery_address_location_name: '',
      delivery_address_street_address_1: '',
      delivery_address_street_address_2: '',
      delivery_address_city: '',
      delivery_address_locale: '',
      delivery_address_postal_code: '',
      delivery_address_country: '',
      carrier_type: '',
      delivery_tracking_number: '',
      return_tracking_number: '',
      shouldRecalcDelivery: true,
      additionalOrderInfo: '',
      additionalDeliveryInfo: '',
      fees: [],
      last_fee_id: 0,
      expenses: [],
      last_expense_id: 0,
      notes: [],
      last_note_id: 0,

      customers: [],
      last_customer_id: 0,

      discounts: [],
      last_discount_id: 0,

      items: [],
      last_item_id: 0,

      rentalAccessories: [],
      last_rental_accessory_id: 0,

      rentalAddOns: [],
      last_rental_add_on_id: 0,

      rentalBundles: [],
      last_rental_bundle_id: 0,

      documents: [],
      last_document_id: 0,
      employee_rental_relationship: null,
      damage_waiver_fee_total: 0,
      templateOpen: false,
      customersOpen: false,
      discountsOpen: false,
      salespersonOpen: false,
      venueOpen: false,
      companyOpen: false,
      accessoriesOpen: false,
      addOnsOpen: false,
      itemsOpen: false,
      bundlesOpen: false,
      taxExemptDialogOpen: false,
      width: 400,
      canLeave: true,
      sameAsEventDates: false,
      paymentOpen: false,
      documentModalOpen: false,
      signModalOpen: false,
      successOpen: false,
      successTransaction: {},
      billingLines: null,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.prepopulateRental(id);
  }

  componentDidUpdate() {
    const { billingLines, rental } = this.state;
    if (billingLines === null && rental && rental.token) {
      this.fetchBillingLines(rental.token);
    }
  }

  refreshRental = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.prepopulateRental(id);
  };

  prepopulateRental = (id) => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner('Loading rental...');

    this.setState({ loading: true });

    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${id}`, {
        headers: getAuthToken(),
      })
      .then((response) => {
        const { rental } = response.data;
        closeLoadingSpinner();

        this.fillNewRentalData(rental);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        if (error.response.status === 401) {
          document.location = '/notAuthorized';
        }
        // document.location = '/notFound'
      });
  };

  updateRental = (data, onSuccess) => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    const params = objectToFormData({ rental: data });

    openLoadingSpinner('Updating rental...');

    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/rentals/' +
          this.props.match.params.id,
        params
      )
      .then((response) => {
        const { rental } = response.data;
        closeLoadingSpinner();

        this.fillNewRentalData(rental);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  fetchBillingLines = (id) => {
    const { location } = this.props;

    axios
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/portal/billing_lines/' + id,
        {
          params: { location_id: location.id },
        }
      )
      .then((response) => {
        this.setState({ billingLines: response.data });
      })
      .catch((error) => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  saveBeforeAttributes = () => {
    this.beforeAttributes = this.build_rails_object();
  };

  fillNewRentalData = (rental, overwriteStatus = true) => {
    const component = this;

    let nextItemId = component.state.last_item_id + 1;
    let ItemResult = prepopulateArray(
      nextItemId,
      rental.rentalItems,
      'rental_item'
    );
    nextItemId = ItemResult[1];
    let newItems = ItemResult[0];
    newItems = newItems.map((product) => {
      const rentalItem = product.rental_item;
      const rate = rentalItem.selectedPrice / rentalItem.quantity;
      const duration = 1;

      const newRentalItem = Object.assign(rentalItem, {
        duration: duration,
        selectedRate: rate,
      });
      product.rental_item = newRentalItem;
      return product;
    });

    let timeDiffhours;
    if (
      rental.schedule.eventStartDateTime &&
      rental.schedule.eventEndDateTime
    ) {
      const timeDiffms =
        new Date(rental.schedule.eventEndDateTime) -
        new Date(rental.schedule.eventStartDateTime);
      timeDiffhours = timeDiffms / 1000 / 60 / 60;
    } else {
      timeDiffhours = 0;
    }

    let newState = Object.assign(
      component.state,
      {
        disclaimerRentalRelationships: cloneDeep(
          rental.disclaimerRentalRelationships
        ),
        hours: timeDiffhours,
        rental: rental,
        taxExempt: rental.taxExempt,
        depositRequired: rental.depositRequired,
        depositType: rental.depositType,
        expireType: rental.expireType,
        customExpireDate: rental.customExpireDate
          ? new Date(rental.customExpireDate)
          : null,
        signatureRequired: rental.signatureRequired,
        npsRequired: rental.npsRequired,
        hasAgreement: rental.hasAgreement,
        manualDamageWaiver: rental.manualDamageWaiver,
        damage_waiver_fee_total: rental.damageWaiverFeeTotal,
        items: newItems,
        last_item_id: nextItemId,
        sameAsEventDates: rental.schedule.sameAsEventDate,
        shouldRecalcDelivery: false,
        additionalOrderInfo: rental.additionalOrderInfo,
        additionalDeliveryInfo: rental.additionalDeliveryInfo,

        delivery_address_location_name:
          rental.deliveryAddressLocationName || '',
        delivery_address_street_address_1:
          rental.deliveryAddressStreetAddress1 || '',
        delivery_address_street_address_2:
          rental.deliveryAddressStreetAddress2 || '',
        delivery_address_city: rental.deliveryAddressCity || '',
        delivery_address_locale: rental.deliveryAddressLocale || '',
        delivery_address_postal_code: rental.deliveryAddressPostalCode || '',
        delivery_address_country: rental.deliveryAddressCountry || '',
        carrier_type: rental.carrierType || '',
        delivery_tracking_number: rental.deliveryTrackingNumber || '',
        return_tracking_number: rental.returnTrackingNumber || '',
      },
      overwriteStatus && { status: rental.status },
      rental.name && { name: rental.name },
      //Schedule

      rental.schedule.id && { schedule_id: rental.schedule.id },
      rental.schedule.startDate && {
        checkout: new Date(rental.schedule.startDate),
        start_date_time: new Date(rental.schedule.startDate),
      },
      rental.schedule.startTime && {
        checkout_time: new Date(rental.schedule.startTime),
      },
      rental.schedule.startWindowFinish && {
        checkoutWindowEnd: new Date(rental.schedule.startWindowFinish),
      },
      rental.schedule.endDate && {
        checkin: new Date(rental.schedule.endDate),
        end_date_time: new Date(rental.schedule.endDate),
      },
      rental.schedule.endTime && {
        checkin_time: new Date(rental.schedule.endTime),
      },
      rental.schedule.endWindowBeginning && {
        checkinWindowStart: new Date(rental.schedule.endWindowBeginning),
      },
      rental.schedule.eventStartDateTime && {
        event_start: new Date(rental.schedule.eventStartDateTime),
        event_start_time: new Date(rental.schedule.eventStartDateTime),
        event_start_date_time: new Date(rental.schedule.eventStartDateTime),
      },
      rental.schedule.eventEndDateTime && {
        event_end: new Date(rental.schedule.eventEndDateTime),
        event_end_time: new Date(rental.schedule.eventEndDateTime),
        event_end_date_time: new Date(rental.schedule.eventEndDateTime),
      },
      rental.deliveryCost && { delivery_cost: rental.deliveryCost },
      rental.deliveryType && { delivery_type: rental.deliveryType }
    );
    component.setState(newState, this.saveBeforeAttributes);
  };
  addDocument = (document) => {
    const data = {
      document_rental_relationships_attributes: {
        0: document,
      },
    };
    this.updateRental(data, (response) => {
      this.toggleDocumentModal();
    });
  };
  isOnlyLetters = (str) => {
    let strWithoutSpaces = str.replace(/\s/g, '');
    return /^[a-zA-Z]+$/.test(strWithoutSpaces);
  };
  handleSubmitSignature = ({
    signature,
    signerName,
    signerTitle,
    signerCompany,
    disclaimerRentalRelationships,
  }) => {
    const {
      openLoadingSpinner,
      closeLoadingSpinner,
      openSuccessSnackBar,
      setErrors,
      rental,
    } = this.props;

    const disclaimersSignatures = getDisclaimersSignatures(
      disclaimerRentalRelationships
    );

    const rentalSignature = getSignatureFromRental(rental, 'customer');

    if (signature || disclaimersSignatures) {
      openLoadingSpinner('Submitting signature...');
      const data = {
        rental_signatures_attributes: [
          {
            id: rentalSignature?.id || null,
            signature: signature,
            signer_name: signerName,
            signer_title: signerTitle,
            signer_company: signerCompany,
            signature_type: 'customer',
          },
        ],
        disclaimerRentalRelationshipsAttributes: disclaimerRentalRelationships,
      };
      const params = objectToFormData({
        rental: converter.camelToSnakeCase(data),
      });
      axios
        .patch(
          `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${this.props.match.params.id}/submit_signature`,
          params
        )
        .then((response) => {
          let rental = response.data.rental;
          closeLoadingSpinner();
          this.fillNewRentalData(rental);
          this.toggleSignModal();
          openSuccessSnackBar('Successfully added signature');
        })
        .catch((error) => {
          console.log(error);
          const errors = error.response.data;
          setErrors(errors);
        });
    } else {
      setErrors('Signature cannot be blank or be only empty spaces.');
    }
  };

  build_rails_object = () => {
    const {
      items,
      schedule_id,
      start_date_time,
      end_date_time,
      checkoutWindowEnd,
      checkinWindowStart,
      event_start_date_time,
      event_end_date_time,
      sameAsEventDates,
      delivery_cost,
      delivery_type,
      delivery_address_location_name,
      delivery_address_street_address_1,
      delivery_address_street_address_2,
      delivery_address_city,
      delivery_address_locale,
      delivery_address_postal_code,
      delivery_address_country,
      damage_waiver_fee_total,
    } = this.state;
    const timeZone = moment.tz.guess();

    let rental = {
      active: true,
      delivery_cost,
      delivery_type,
      delivery_address_location_name,
      delivery_address_street_address_1,
      delivery_address_street_address_2,
      delivery_address_city,
      delivery_address_locale,
      delivery_address_postal_code,
      delivery_address_country,
    };

    let schedule = Object.assign(
      {
        start_date_time: start_date_time,
        end_date_time: end_date_time,
        start_window_finish: checkoutWindowEnd,
        end_window_beginning: checkinWindowStart,
        event_start_date_time: event_start_date_time,
        event_end_date_time: event_end_date_time,
        same_as_event_date: sameAsEventDates,
        time_zone: timeZone,
      },
      schedule_id && { id: schedule_id }
    );

    const rental_item_standards = items.filter(
      (item) => item.rental_item.type === 'RentalItemStandard'
    );

    let formatted_rental_item_standards = nestedStateForRailsWithConversion(
      rental_item_standards.slice(),
      'rental_item'
    );

    /*eslint-disable */
    rental = Object.assign(rental, {
      damage_waiver_fee_total,
      schedule_attributes: schedule,
      rental_item_standards_attributes: formatted_rental_item_standards,
    });

    return rental;
  };

  requestCancel = () => {
    const component = this;
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner('Cancelling the event...');
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/rentals/' +
          this.props.match.params.id +
          '/request_cancel',
        {},
        { headers: getAuthToken() }
      )
      .then((response) => {
        let rental = response.data.rental;
        closeLoadingSpinner();
        component.toggleCancelModal();
        component.fillNewRentalData(rental);
      })
      .catch((error) => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  handleSubmitQuote = (rental) => {
    const { event } = this.state;
    const { history, submitQuote } = this.props;
    submitQuote(event.id, rental.id);
    history.push(`/events/${event.id}/rentals`);
  };

  handleDeliveryChangeSubmit = (state) => {
    const {
      deliveryType,
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      carrierType,
      deliveryTrackingNumber,
      returnTrackingNumber,
      checkout,
      checkoutWindowEnd,
      checkin,
      checkinWindowStart,
      sameAsEventDates,
    } = state;

    const newState = Object.assign(
      {
        start_date_time: checkout,
        end_date_time: checkin,
        checkout,
        checkoutWindowEnd,
        checkout_time: checkout,
        checkin,
        checkinWindowStart,
        checkin_time: checkin,
        sameAsEventDates,
        delivery_type: deliveryType,
        delivery_address_location_name: deliveryAddressLocationName,
        delivery_address_street_address_1: deliveryAddressStreetAddress1,
        delivery_address_street_address_2: deliveryAddressStreetAddress2,
        delivery_address_city: deliveryAddressCity,
        delivery_address_locale: deliveryAddressLocale,
        delivery_address_postal_code: deliveryAddressPostalCode,
        delivery_address_country: deliveryAddressCountry,
        carrier_type: carrierType,
        delivery_tracking_number: deliveryTrackingNumber,
        return_tracking_number: returnTrackingNumber,
        canLeave: false,
      },
      deliveryType === 'customer_pick_up' && { deliveryCost: 0 }
    );
    this.setState(newState);
  };

  adjustRentalItems = (rentalItem, index) => {
    let items = this.state.items.slice();
    if (rentalItem.quantity === '') {
      rentalItem.quantity = 0;
    }
    const newRentalItem = Object.assign(items[index].rental_item, {
      ...rentalItem,
    });
    items[index].rental_item = newRentalItem;
    this.setState({
      items: items,
      canLeave: false,
    });
  };

  deleteRentalItem = (index) => {
    let items = this.state.items.slice();
    items[index].rental_item = Object.assign(items[index].rental_item, {
      _destroy: '1',
    });
    this.setState({
      items: items,
      canLeave: false,
    });
  };

  toggleCancelModal = () => {
    this.setState({
      cancelModalOpen: !this.state.cancelModalOpen,
    });
  };

  togglePayment = () => {
    this.setState({
      paymentOpen: !this.state.paymentOpen,
    });
  };

  toggleDocumentModal = () => {
    this.setState({
      documentModalOpen: !this.state.documentModalOpen,
    });
  };

  toggleSignModal = () => {
    this.setState({
      signModalOpen: !this.state.signModalOpen,
    });
  };

  toggleSuccessOpen = () => {
    this.setState({
      successOpen: !this.state.successOpen,
    });
  };

  handlePaymentSuccess = (transaction) => {
    this.togglePayment();
    this.refreshRental();
    this.setState({ successTransaction: transaction, successOpen: true });
  };

  render() {
    const {
      rental,
      disclaimerRentalRelationships,
      loading,
      cancelModalOpen,
      successOpen,
      successTransaction,
      billingLines,
    } = this.state;
    const {
      match: {
        params: { id },
      },
      storefrontSetting,
      location,
    } = this.props;

    if (loading || !rental.token) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className='rental'>
          <header>
            <h3>
              {(isFinalBalanceDue(rental) || rental.documentExpired) && (
                <Alert />
              )}{' '}
              {rental.name} <label>#{rental.token}</label>
              {storefrontSetting.allowRentalChanges && (
                <div className='right'>
                  {!rental.cancelRequested && (
                    <a className='btn outline' onClick={this.toggleCancelModal}>
                      Cancel Order
                    </a>
                  )}
                  <Link className='btn' to={`/events/${id}/edit`}>
                    Request Change To Order
                  </Link>
                </div>
              )}
            </h3>
          </header>

          <ChangeRequestData
            rentalId={rental.id}
            changeRequest={rental.changeRequest}
          />

          <Rental
            rental={rental}
            disclaimerRentalRelationships={disclaimerRentalRelationships}
            location={location}
            fields={this.state}
            onDeliveryChangeSubmit={this.handleDeliveryChangeSubmit}
            adjustRentalItems={this.adjustRentalItems}
            deleteRentalItem={this.deleteRentalItem}
            addDocument={this.addDocument}
            onSubmitSignature={this.handleSubmitSignature}
            refreshRental={this.refreshRental}
            togglePayment={this.togglePayment}
            toggleDocumentModal={this.toggleDocumentModal}
            toggleSignModal={this.toggleSignModal}
            onPaymentSuccess={this.handlePaymentSuccess}
            billingLines={billingLines}
          />

          <Modal
            title='Cancel Event'
            actions={[
              <button
                key='cancel'
                className='btn warn'
                onClick={this.requestCancel}
              >
                Yes, Send Cancellation Request
              </button>,
              <button
                key='no'
                className='btn cancel'
                onClick={this.toggleCancelModal}
              >
                No
              </button>,
            ]}
            modal={false}
            open={cancelModalOpen}
            className='dialog'
            toggle={this.toggleCancelModal}
          >
            <p>
              Are you sure you want to request cancellation for this rental?
            </p>
          </Modal>
          <Modal
            open={successOpen}
            toggle={this.toggleSuccessOpen}
            title='Payment Confirmation'
            actions={[
              <a
                key='successOpen'
                className='btn full'
                onClick={this.toggleSuccessOpen}
              >
                Ok
              </a>,
            ]}
          >
            <div>
              <p>
                Thanks for your payment of{' '}
                <strong>
                  {successTransaction.amount && (
                    <CurrencyLabel value={successTransaction.amount} />
                  )}
                </strong>
              </p>
              <p>
                {successTransaction.createdAt && (
                  <FormattedDate
                    value={successTransaction.createdAt}
                    year='numeric'
                    month='numeric'
                    day='numeric'
                    hour='numeric'
                    minute='numeric'
                  />
                )}
              </p>
              <p>Confirmation #{successTransaction.token}</p>
            </div>
          </Modal>
        </div>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const locations = state.locations;
  const storefrontSetting = parsedStorefrontSettings.storefrontEventsSetting;
  return {
    storefrontSetting,
    location: locations.location,
  };
};

export default connect(mapStateToProps, actions)(RentalContainer);
