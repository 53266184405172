export const deliveryTypes = {
  customer_pick_up: "Customer Pick Up",
  custom_delivery: "Manual Delivery",
  default_delivery: "Default Delivery"
};
// @ToDo why some of them ends in colon?
export const customerDeliveryTypes = {
  customer_pick_up: "Customer Pick Up",
  custom_delivery: "Delivery:",
  default_delivery: "Delivery:",
  ship: "Ship:"
};
// @ToDo if colons are removed from previous constant replace usage of following constant in
//   cart/EventForm.js and delete this constant.
export const DeliveryTypesLabels = {
  customer_pick_up: "Pick Up",
  custom_delivery: "Delivery",
  default_delivery: "Delivery",
  ship: "Ship"
};

export const carrierTypes = {
  fedex: "FedEx",
  usps: "USPS"
};

export const npsColors = [
  "#D3347F",
  "#D83553",
  "#D63744",
  "#DB4740",
  "#E55E4A",
  "#FF886D",
  "#FB9161",
  "#FFA96B",
  "#D7B36F",
  "#9BBA70",
  "#54C06E"
];

export const convertScoreToRotation = score => {
  const degrees = Math.round(((score + 100) / 200) * 180);
  return degrees;
};
export const convertNpsScoreToColor = score => {
  const postiveScore = Math.round((score + 100) / 20);
  return npsColors[postiveScore];
};

export const displayRentalStatus = {
  quote: "Quote",
  reservation: "Reservation",
  in_use: "Checked-Out",
  checked_in: "Checked-In",
  closed: "Closed",
  cancelled: "Cancelled"
};

export const displayDocumentType = {
  rental_agreement: "Rental Agreement",
  insurance: "Insurance",
  legal_documents: "Legal Documents",
  other: "Other",
  waiver_forms: "Waiver Forms",
  customer_documents: "Customer Documents",
  contracts: "Contracts",
  customer_tax_exempt_forms: "Customer Tax Exempt Forms",
  customer_rental_agreements: "Customer Rental Agreements",
  other_customer_documents: "Other Customer Documents"
};

export const displayRoles = {
  owner: "Owner",
  admin: "Admin",
  manager: "Manager",
  devops: "Warehouse",
  salesperson: "Salesperson"
};

export const customerTitles = {
  contacts: "Contacts",
  customers: "Customers",
  venues: "Venues",
  companies: "Companies"
};
export const singleCustomerTitles = {
  contacts: "Contact",
  customers: "Customer",
  venues: "Venue",
  companies: "Company"
};

export const displaySingularInventory = {
  items: "item",
  addons: "add-on",
  accessories: "accessory"
};
export const capitalSingularInventory = {
  items: "Item",
  addons: "Add-On",
  accessories: "Accessory"
};
export const capitalHybridInventory = {
  items: "Item(s)",
  addons: "Add-On(s)",
  addOns: "Add-On(s)",
  accessories: "Accessory(s)"
};

export const displayPluralInventory = {
  items: "items",
  addons: "add-ons",
  accessories: "accessories"
};

export const apiConnections = {
  items: "products",
  addOn: "add_ons",
  addOns: "add_ons",
  add_on: "add_ons",
  addons: "add_ons",
  product: "products",
  accessory: "accessories",
  accessories: "accessories",
  bundles: "bundles",
  bundle: "bundles",
  inventories: "inventories",
  inventory: "inventory",
  commodities: "products",
  commodity: "products",
  contacts: "customers",
  customers: "customers",
  venues: "venues",
  companies: "companies",
  maintenanceRecords: "maintenance_records"
};

export const apiToSingularConnections = {
  items: "product",
  addons: "addOn",
  addOn: "addOn",
  addOns: "add_on",
  accessory: "accessory",
  accessories: "accessory"
};

export const apiResponseTransform = {
  add_on: "addOn",
  product: "product",
  bundle: "bundle"
};

export const apiToSingularRoot = {
  items: "product",
  addons: "addon",
  addOn: "addOn",
  add_ons: "add_on",
  addOns: "add_on",
  accessory: "accessory",
  accessories: "accessory",
  bundles: "bundle",
  bundle: "bundle",
  inventories: "inventory",
  inventory: "inventory",
  commodities: "commodity"
};

export const supplierTypes = {
  items: "product",
  addons: "add_on",
  accessories: "accessory"
};

export const apiReceiving = {
  items: "products",
  addons: "addOns",
  addOns: "addOns",
  accessories: "accessories",
  item: "product",
  addon: "addOn",
  addOn: "addOn",
  accessory: "accessory"
};
export const apiRentalReceiving = {
  items: "rentalItems",
  addons: "rentalAddOns",
  addOns: "rentalAddOns",
  accessories: "rentalAccessories",
  item: "rentalItems",
  addon: "rentalAddOns",
  addOn: "rentalAddOns",
  accessory: "rentalAccessories"
};

export const typeToRental = {
  items: "rental_item",
  addons: "rental_add_on",
  addOns: "rental_add_on",
  accessories: "rental_accessory"
};
export const typeToRentalPlural = {
  items: "rental_items",
  addons: "rental_add_ons",
  addOns: "rental_add_ons",
  accessories: "rental_accessories"
};
export const getInventoryId = {
  items: "productId",
  addons: "addOnId",
  addOns: "addOnId",
  accessories: "accessoryId"
};

export const periodToPricingType = {
  hourly_price: "hourlyPrice",
  half_day_price: "halfDayPrice",
  daily_price: "dailyPrice",
  weekly_price: "weeklyPrice",
  monthly_price: "monthlyPrice",
  flat_unit_price: "flatPrice",
  flat_price: "selectedPrice",
  pricing: "pricing"
};
export const displayPeriodUnit = {
  hourly_price: "Hour",
  half_day_price: "Half-Day",
  daily_price: "Day",
  weekly_price: "Week",
  monthly_price: "Month",
  flat_unit_price: "Each",
  flat_price: "Each",
  priciing: "Each"
};

export const displayPricingType = {
  hourlyPrice: "Hour",
  halfDayPrice: "Half-Day",
  dailyPrice: "Day",
  weeklyPrice: "Week",
  monthlyPrice: "Month",
  flatPrice: "Each",
  pricing: "Each"
};

export const displayEventType = {
  upcoming: "Upcoming",
  past: "Past",
  cancelled: "Cancelled"
};
