export const getDisclaimerSignatureInfo = (rental) => {
  let disclaimerSignatureRequired = false;
  let disclaimerSignaturePresent = true;

  rental.disclaimerRentalRelationships.forEach((disclaimer) => {
    if (disclaimer.signatureRequired) {
      disclaimerSignatureRequired = true;
      if (!disclaimer.signature) disclaimerSignaturePresent = false;
    }
  });

  return {
    disclaimerSignatureRequired,
    disclaimerSignaturePresent
  };
};

export const getDisclaimersSignatures = (disclaimerRentalRelationships) => {
  const disclaimersSignatures = disclaimerRentalRelationships.some(
    (disclaimer) => disclaimer.signatureRequired && !disclaimer.signature
  );

  return !disclaimersSignatures;
};
