import { stringify, parseQuery } from "HelperFunctions/QueryString";

export const setFilterParams = (filter, location, history = null) => {
  const query = parseQuery(location.search);
  const newFilter = {
    ...query,
    ...filter
  };

  const newLinkObject = {}
  Object.keys(newFilter).forEach((key) => {
    const value = newFilter[key]

    if (!value || Array.isArray(value) && value.length === 0) return;

    newLinkObject[key] = (key !== "selectedSfCategoriesData" ? value : JSON.stringify(value))
  })

  const stringified = stringify(newLinkObject)

  history.push({
    pathname: location.pathname,
    search: `?${stringified}`
  });
};

export const filterFromPropsAndState = (
  props,
  reduxFilter = {},
  includeReduxFilter = false
) => {
  const { location } = props;
  const parsed = parseQuery(location.search);
  const { page } = parsed;
  const newFilter = Object.assign({}, includeReduxFilter && reduxFilter, {
    ...parsed,
    page: page ? Number(page) : 1
  });

  return newFilter;
};

export const convertNameToUrl = (name) => {
  return name
    .split(" ")
    .join("-")
    .replace(/[^a-zA-Z0-9-]+/g, "");
};

export const filterObjToArray = (filter) => {
  if (Array.isArray(filter)) {
    return filter;
  } else if (typeof filter === 'string') {
    return filter.split(',');
  } else if (typeof filter === 'undefined') {
    return [];
  } else {
    console.warn("Unknown type of filter:", filter);
    return [];
  }
}
