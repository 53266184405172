import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import ContactForm from 'Components/home/ContactForm';
import TestimonialBox from 'Components/home/widgets/TestimonialBox';
import SocialBox from 'Components/home/widgets/SocialBox';
import classnames from 'classnames';
import { reset } from 'redux-form';
import { formatPhoneNumber } from 'HelperFunctions/general';
import Imgix from 'react-imgix';
import { history as browserHistory } from 'Components/Routes';
import PageMetaData from 'Utils/PageMetaData';
import axios from 'axios';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class Contact extends React.Component {
  handleSubmit = (contact) => {
    const {
      storeLocation,
      openLoadingSpinner,
      closeLoadingSpinner,
      setErrors,
      reset,
    } = this.props;

    openLoadingSpinner('Submitting your message to the location.');
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/portal/contact`, {
        location_id: storeLocation.id,
        contact: contact,
      })
      .then((response) => {
        closeLoadingSpinner();
        reset('ContactForm');
        browserHistory.push('/contact/confirmation');
      })
      .catch((error) => {
        closeLoadingSpinner();
        const errors = error.response.data;
        setErrors(errors);
      });
  };
  render() {
    const { parsedStorefrontSettings, storeLocation, themeSetting, location } =
      this.props;
    const storefrontContactSetting = getSettingByUrl(
      storeLocation.storefrontPages,
      location.pathname
    );
    const {
      contactTitle,
      contactPicture,
      contactPhone,
      contactFax,
      contactEmail,
      contactPhysicalAddress,
      contactShowHours,
      contactBusinessHours,
      contactShowForm,
      contactShowSocial,
      contactTitleFontTheme,
    } = storefrontContactSetting;

    return (
      <section
        className={classnames({
          contactUs: true,
          withForm: contactShowForm,
        })}
      >
        <PageMetaData setting={storefrontContactSetting} />
        <figure>
          {contactPicture && (
            <Imgix
              src={contactPicture.imgixUrl}
              alt="Contact Us"
              fit="max"
              width={1024}
              height={960}
            />
          )}
        </figure>
        <h1
          style={{
            color: `${
              contactTitleFontTheme === 'dark'
                ? themeSetting.darkFontColor
                : themeSetting.lightFontColor
            }`,
          }}
        >
          {contactTitle}
        </h1>
        <div className='companyDetails'>
          {parsedStorefrontSettings.mainSetting.theme === 'preScott' ||
            (parsedStorefrontSettings.mainSetting.theme === 'elaina' && (
              <h3>Contact Info</h3>
            ))}
          {contactPhone && <label className='labelPrimary'>Phone</label>}
          {contactPhone && (
            <div>
              <a href={'tel:' + formatPhoneNumber(contactPhone)}>
                {formatPhoneNumber(contactPhone)}
              </a>
            </div>
          )}
          {contactFax && <label className='labelPrimary'>Fax</label>}
          {contactFax && <div>{formatPhoneNumber(contactFax)}</div>}
          {contactEmail && <label className='labelPrimary'>Email</label>}
          {contactEmail && (
            <div>
              <a href={'mailto:' + contactEmail}>{contactEmail}</a>
            </div>
          )}
          {contactPhysicalAddress && (
            <label className='labelPrimary'>Address</label>
          )}
          {contactPhysicalAddress && (
            <div>
              <div>{contactPhysicalAddress.streetAddress1}</div>
              <div>{contactPhysicalAddress.streetAddress2}</div>
              <div>
                {contactPhysicalAddress.city}
                {', '}
                {contactPhysicalAddress.locale}{' '}
                {contactPhysicalAddress.postalCode},{' '}
                {contactPhysicalAddress.country}
              </div>
            </div>
          )}
          {contactShowHours && <label>Hours</label>}
          {contactShowHours && (
            <div
              className='contactBusinessHours'
              dangerouslySetInnerHTML={{
                __html: contactBusinessHours,
              }}
            />
          )}
          {contactShowSocial && <SocialBox />}
        </div>
        {contactShowForm && (
          <ContactForm
            onSubmit={this.handleSubmit}
            showHeader={
              parsedStorefrontSettings.mainSetting.theme === 'preScott' ||
              parsedStorefrontSettings.mainSetting.theme === 'elaina'
            }
          />
        )}
        <TestimonialBox />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const themeSetting = parsedStorefrontSettings.mainSetting;
  return { storeLocation: location, parsedStorefrontSettings, themeSetting };
};

export default connect(mapStateToProps, { ...actions, reset })(Contact);
