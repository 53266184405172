/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from "react";
import Modal from "Utils/Modal";
import { RemoveCircle, DownloadCircle } from "Utils/SvgIcons";
import moment from "moment";

class ShowInventoryImagesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      pictures: null,
      activeImage: null
    };
  }

  handleCloseModal = () => {
    this.setState({
      show: false,
      pictures: null,
      activeImage: null
    });
  };
  componentWillReceiveProps(newProp) {
    if (newProp.show !== undefined) {
      this.setState({
        show: newProp.show,
        pictures: newProp.pictures,
        title: newProp.title
      });
    }
  }
  getTitle = () => {
    const { title } = this.state;
    return (
      <div className="header-Test">
        <div className="modal-header-checkbox">
          <span> {title}</span>
          <div className="close-modal">
            <a className="close-button" onClick={this.handleCloseModal}>
              &times;
            </a>
          </div>
        </div>
      </div>
    );
  };
  onImageSelect = (e) => {
    this.setState({
      activeImage: this.state.pictures.filter(
        (p) => p.picture === e.target.src
      )[0]
    });
  };
  onRemovePhotoClick = () => {
    this.setState({ activeImage: null });
  };
  render() {
    const { pictures, show, title, activeImage } = this.state;
    return (
      <div className="uploadZone">
        <Modal
          open={show}
          large={true}
          toggle={this.handleCloseModal}
          className="show-inventory-images-modal"
          title={title}
        >
          <div style={{ overflow: "auto", "max-height": "600px" }}>
            {pictures && activeImage && (
              <div className="preview-image">
                <img src={activeImage.picture} alt="" />
                <RemoveCircle
                  className="remove-icon"
                  onClick={this.onRemovePhotoClick}
                />
                <div className="preview-image-actions">
                  <span>{activeImage.employeeName}</span>
                  <span>{moment(activeImage.createdAt).format("L")}</span>
                  <span>{moment(activeImage.createdAt).format("LT")}</span>
                  <span></span>
                  <span></span>
                  <a href={activeImage.picture} download target="_blank" rel="noopener noreferrer">
                    <DownloadCircle className="downlaod-svg" />
                  </a>
                </div>
              </div>
            )}
            <div className="modal-inner-Content">
              {pictures && pictures.length > 0 && (
                <div className="images">
                  {pictures.map((f, index) => (
                    <img
                      src={f.picture}
                      onClick={this.onImageSelect}
                      id={index}
                      alt=""
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
ShowInventoryImagesModal.defaultProps = {
  aspectRatio: 16 / 9
};

export default ShowInventoryImagesModal;
