import {
  FETCH_CUSTOM_PAGES_REQUEST,
  FETCH_CUSTOM_PAGES_SUCCESS,
  RECEIVE_CUSTOM_PAGE
} from "Constants/redux";
import { standardService } from "Api/service";

export const receiveCustomPage = customPage => {
  return {
    type: RECEIVE_CUSTOM_PAGE,
    payload: {
      customPage: customPage
    }
  };
};

export const fetchCustomPages = locationId => {
  return dispatch => {
    dispatch({
      type: FETCH_CUSTOM_PAGES_REQUEST
    });
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
          "/api/portal/storefront_custom_pages/paginated",
        {
          number_per: 6,
          page: 1,
          location_id: locationId
        }
      )
      .then(response => {
        dispatch({
          type: FETCH_CUSTOM_PAGES_SUCCESS,
          payload: {
            customPages: response.data.storefrontCustomPages,
            customFrontPages: response.data.storefrontCustomPages.slice(0, 7)
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};
