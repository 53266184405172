import {
  FETCH_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_MESSAGE_SUCCESS,
  SET_CONVERSATION,
} from 'Constants/redux';
import createReducer from '../lib/CreateReducer';

const INITIAL_STATE = {
  // conversation: {
  //   id: 1,
  //   unreadMessages: 2,
  //   title: "Rental Update",
  //   messages: [],
  //   location: {
  //     name: "Simple Celebrations",
  //     picture: "test"
  //   }
  // },
  conversation: {
    id: 1,
    title: 'Heyyy',
    contactNames: 'John Doe',
    rentalName: 'Party Polly Quoted',
    unreadMessages: 0,
    rentalToken: null,
    conversationType: 'b2c',
    supplierLocationInfo: { id: 1, name: 'Darn Good Rentals', picture: null },
    tenantLocationInfo: null,
    supplierRentalInfo: { id: 1, name: 'Party Polly Quoted', token: null },
    tenantRentalInfo: null,
    messages: [
      {
        id: 1,
        body: 'One, two, three',
        senderId: 1,
        senderType: 'Employee',
        createdAt: '2018-05-28T21:29:51.000+08:00',
        sender: {
          id: 1,
          firstName: 'John',
          lastName: 'Doe',
          role: 'admin',
          email: 'test@test.com',
          uid: 'test@test.com',
          provider: 'email',
          location: {
            id: 1,
            name: 'Darn Good Rentals',
            subdomain: null,
            website: 'www.darngoodrentals.com',
            email: 'darngoodrentals@mailinator.com',
            statementDescriptor: 'DarnGoodRe-rental',
            depositFixedFee: '1.0',
            depositPercent: '2.0',
            defaultStart: '2017-05-29T23:00:00.000+08:00',
            defaultEnd: '2017-05-30T04:00:00.000+08:00',
            createdAt: '2018-05-28T21:27:27.000+08:00',
            signatureRequired: null,
            damageWaiverFixedFee: '3.0',
            damageWaiverPercent: '4.0',
            internalOperationFee: '0.1',
            customerSelfServiceFee: '0.0',
            marketPlaceFee: '0.0',
            internalOperationTax: '0.0',
            customerSelfServiceTax: '0.0',
            marketPlaceTax: '0.0',
            creditCardPercent: '5.0',
            hasAgreement: false,
            autoReserveEnabled: false,
            reportsIncluded: true,
            tgPaymentFailed: false,
            cashChargeEnabled: true,
            expireDays: null,
            authToken: '7ad52919431647a19c43589684fd4b1f',
            showRoutingAssignments: true,
            defaultSetupTime: 45,
            warehouseLatLong: { lat: 32.8931, lon: -96.7722 },
            searchable: true,
            deletedAt: null,
            softwareTier: 'all',
          },
        },
      },
      {
        id: 2,
        body: 'four, five, six',
        senderId: 1,
        senderType: 'Customer',
        createdAt: '2018-05-28T21:29:51.000+08:00',
        sender: {
          id: 1,
          name: 'John Doe',
          firstName: 'John',
          lastName: 'Doe',
          office: '160907865',
          extension: null,
          cell: null,
          fax: null,
          stripeToken: 'cus_Cweio4PaZfQnCq',
          streetAddress1: '10651 Steppington Dr',
          streetAddress2: null,
          city: 'Dallas',
          locale: 'TX',
          country: 'USA',
          title: null,
          postalCode: '75230',
          email: 'test@test.com',
          hasSignedIn: true,
        },
      },
      {
        id: 3,
        body: 'seven, eight, nine',
        senderId: 1,
        senderType: 'Employee',
        createdAt: '2018-05-28T21:29:51.000+08:00',
        sender: {
          id: 1,
          firstName: 'John',
          lastName: 'Doe',
          role: 'admin',
          email: 'test@test.com',
          uid: 'test@test.com',
          provider: 'email',
          location: {
            id: 1,
            name: 'Darn Good Rentals',
            subdomain: null,
            website: 'www.darngoodrentals.com',
            email: 'darngoodrentals@mailinator.com',
            statementDescriptor: 'DarnGoodRe-rental',
            depositFixedFee: '1.0',
            depositPercent: '2.0',
            defaultStart: '2017-05-29T23:00:00.000+08:00',
            defaultEnd: '2017-05-30T04:00:00.000+08:00',
            createdAt: '2018-05-28T21:27:27.000+08:00',
            signatureRequired: null,
            damageWaiverFixedFee: '3.0',
            damageWaiverPercent: '4.0',
            internalOperationFee: '0.1',
            customerSelfServiceFee: '0.0',
            marketPlaceFee: '0.0',
            internalOperationTax: '0.0',
            customerSelfServiceTax: '0.0',
            marketPlaceTax: '0.0',
            creditCardPercent: '5.0',
            hasAgreement: false,
            autoReserveEnabled: false,
            reportsIncluded: true,
            tgPaymentFailed: false,
            cashChargeEnabled: true,
            expireDays: null,
            authToken: '7ad52919431647a19c43589684fd4b1f',
            showRoutingAssignments: true,
            defaultSetupTime: 45,
            warehouseLatLong: { lat: 32.8931, lon: -96.7722 },
            searchable: true,
            deletedAt: null,
            softwareTier: 'all',
          },
        },
      },
    ],
  },
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CONVERSATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversation: action.payload.conversation,
    };
  },
  [CREATE_CONVERSATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversation: action.payload.conversation,
    };
  },
  [CREATE_MESSAGE_SUCCESS]: (state, action) => {
    const newMessages = [action.payload].concat(state.conversation.messages);
    const newConversation = {
      ...state.conversation,
      messages: newMessages,
    };

    return {
      ...state,
      conversation: newConversation,
    };
  },
  [SET_CONVERSATION]: (state, action) => {
    return {
      ...state,
      conversation: action.payload,
    };
  },
});
