import React from "react";
import Checkbox from "Utils/Checkbox";

export default class RenderField extends React.Component {
  render() {
    const {
      input,
      className,
      label,
      hintText
    } = this.props;
    return (
      <div>
        <Checkbox
          className={className}
          {...input}
          label={label}
          onCheck={input.onChange}
        />
        <span className='hint'>{ hintText }</span>
      </div>
    );
  }
}
