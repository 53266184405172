/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuIcon, Close, Cart, SignOutCircle } from 'Utils/SvgIcons';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import SmartNavLink from 'Utils/SmartNavLink';
import MobileNavLinkAccordion from './MobileNavLinkAccordion';

class MobileSignedInNav extends React.Component {
  state = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  closeMenu = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      user,
      event,
      signOutUser,
      storefrontMenuSetting,
      storefrontShopSetting,
      mainSetting,
    } = this.props;
    const { open } = this.state;
    const usingPreScott = mainSetting.theme === 'preScott';
    let navs = [];
    storefrontMenuSetting.storefrontMenuItems.forEach((menuItem, index) => {
      const link = menuItem.menuLink;

      if (menuItem.childMenuItems.length === 0) {
        // If no child menu items
        navs.push(
          <SmartNavLink
            key={navs.length + 1}
            exact
            link={link}
            target={menuItem.openInNewTab ? '_blank' : ''}
            onClick={this.toggleMenu}
          >
            {menuItem.menuTitle}
          </SmartNavLink>
        );
      } else {
        // If child menu items present?
        navs.push(
          <MobileNavLinkAccordion
            toggleMenu={this.toggleMenu}
            key={navs.length + 1}
            link={link}
            menuItem={menuItem}
          />
        );
      }
    });

    return (
      <nav className='mobileMenu'>
        <p className='userNavGreet'>Hello, {user.firstName}</p>
        <a className='topLink' onClick={this.toggleMenu}>
          {open ? <Close /> : <MenuIcon />}
        </a>
        {storefrontShopSetting.shopVisible && (
          <NavLink className='topLink shoppingCart' to='/cart'>
            <Cart />
            <label>
              {event.items.filter((item) => item._destroy !== '1').length > 0 &&
                event.items.filter((item) => item._destroy !== '1').length}
            </label>
          </NavLink>
        )}

        {open && (
          <div className='mobileMenuOverlay'>
            <a className='topLink' onClick={this.toggleMenu}>
              <Close />
            </a>

            {storefrontShopSetting.shopVisible && (
              <NavLink
                className='topLink shoppingCart'
                to='/cart'
                onClick={this.toggleMenu}
              >
                <Cart />
                <label>
                  {event.items.filter((item) => item._destroy !== '1').length >
                    0 &&
                    event.items.filter((item) => item._destroy !== '1').length}
                </label>
              </NavLink>
            )}

            <h4 className='userGreet'>Hello, {user.firstName}</h4>
            <nav>
              <Link to='/profile' onClick={this.toggleMenu}>
                My Profile Settings
              </Link>
              <a href='/events'>Manage Your Orders</a>
              {navs}
            </nav>
            <a className='signOut' onClick={signOutUser}>
              <SignOutCircle />
              Sign Out
            </a>
          </div>
        )}
      </nav>
    );
  }
}
const mapStateToProps = (state) => {
  const { user, authenticated } = state.auth;
  const { event } = state.cart;
  const {
    parsedStorefrontSettings: {
      storefrontShopSetting,
      storefrontMenuSetting,
      mainSetting,
    },
  } = state.locations;

  return {
    user,
    authenticated,
    event,
    storefrontMenuSetting,
    storefrontShopSetting,
    mainSetting,
  };
};

export default connect(mapStateToProps, actions)(MobileSignedInNav);
