import React, { Component } from 'react';
import { connect } from 'react-redux';
import service from 'Api/service';
import * as actions from 'Actions';
import { arraysEqual, debounce } from 'HelperFunctions/general';
import { setFilterParams, filterFromPropsAndState } from 'HelperFunctions/urls';
import PaginationBoxView from 'Utils/PaginationBoxView';
import CustomPageCard from './CustomPageCard';
import update from 'react-addons-update';

class Favorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter,
      pagination: {
        totalPages: 0,
      },
      customPages: [],
    };

    this.handleQueryChange = debounce(this.handleQueryChange, 500);
  }

  componentDidMount() {
    const { filter } = this.props;

    this.fetchCustomPages(filter);
  }

  fetchCustomPages = (filter) => {
    const { query, page } = filter;
    const component = this;
    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/storefront_custom_pages/paginated_favorites',
        {
          query: query,
          page: page,
          number_per: 12,
          location_id: this.props.location.id,
        }
      )
      .then((response) => {
        component.setState({
          customPages: response.data.storefrontCustomPages,
          pagination: response.data.meta.pagination,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  unfavoriteCustomPage = (index) => {
    const customPage = this.state.customPages[index];
    const component = this;
    component.props.openLoadingSpinner('Unfavoriting...');
    service
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/storefront_custom_pages/' +
          customPage.id +
          '/unfavorite'
      )
      .then((response) => {
        const newState = update(this.state, {
          customPages: {
            $splice: [[index, 1]],
          },
        });
        component.setState(newState);
        component.props.closeLoadingSpinner();
      })
      .catch((error) => {
        console.log(error);
        component.props.closeLoadingSpinner();
      });
  };

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;

    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        if (newFilter[property] instanceof Array) {
          if (arraysEqual(newFilter[property], oldFilter[property]) === false) {
            changed = true;
            break;
          }
        } else {
          const newTest =
            newFilter[property] instanceof Date
              ? newFilter[property].getTime()
              : newFilter[property];
          const oldTest =
            oldFilter[property] instanceof Date
              ? oldFilter[property].getTime()
              : oldFilter[property];
          if (newTest !== oldTest) {
            changed = true;
            break;
          }
        }
      }
    }

    if (changed) {
      const filterToSubmit = Object.assign(newFilter);
      this.fetchCustomPages(filterToSubmit);
    }
  }

  handleSearch = (event) => {
    const { value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        query: value,
      },
    });
    this.handleQueryChange(value);
  };

  handleQueryChange(value) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value,
      },
      location,
      history
    );
  }

  handlePageChange = (data) => {
    const { location, history } = this.props;
    setFilterParams(
      {
        page: data.selected + 1,
      },
      location,
      history
    );
  };

  render() {
    const { filter, customPages, pagination } = this.state;

    return (
      <div className='discoverTheLook'>
        <header>
          <h1>Your Favorites</h1>
          <div className='searchFilter'>
            <input
              type='search'
              placeholder='Search Ideas'
              onChange={this.handleSearch}
              value={filter.query}
            />
          </div>
        </header>
        <section>
          <ul className='looks'>
            {customPages.map((cp, index) => (
              <CustomPageCard
                index={index}
                key={cp.id}
                customPage={cp}
                onUnfavorite={this.unfavoriteCustomPage}
              />
            ))}
          </ul>
          <PaginationBoxView
            className='pagerWrapper'
            containerClassName='pager'
            forcePage={filter.page ? +filter.page - 1 : 0}
            pageCount={pagination.totalPages}
            onPageChange={this.handlePageChange}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const { location } = state.locations;
  return {
    filter: newFilter,
    location,
  };
};

export default connect(mapStateToProps, actions)(Favorites);
