/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as actions from 'Actions';
import DatePicker from 'Utils/DatePicker';
import TimePicker from 'Utils/TimePicker';
import StandardTextField from 'Utils/redux_form_inputs/StandardTextField';
import StandardTextareaField from 'Utils/redux_form_inputs/StandardTextareaField';
import { CartOutline, ListView, ImageView, CartCheck } from 'Utils/SvgIcons';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import {
  showSelectedPrice,
  getItemsWithNewPrices,
} from 'HelperFunctions/rental_items';
import CartItem from './CartItem';
import PhoneInputField from 'Utils/redux_form_inputs/PhoneInputField';
import CurrencyLabel from 'Utils/CurrencyLabel';

class CartItems extends Component {
  handleChange = (e) => {
    const { event, setEventProperties } = this.props;
    const { name, value } = e.target;
    let newEvent = {
      ...event,
      [name]: toNumber(value),
    };
    const { items, rentalBundles } = getItemsWithNewPrices(newEvent);
    newEvent.items = items;
    newEvent.rentalBundles = rentalBundles;

    setEventProperties(newEvent);
  };

  handleContactPhoneChange = (event) => {
    const { setEventProperties } = this.props;
    const { name, value } = event.target;
    setEventProperties({ [name]: value });
  };

  handleNewCustomerChange = (event) => {
    const { setCartNewCustomerProps } = this.props;
    const { name, value } = event.target;
    setCartNewCustomerProps({ [name]: value });
  };

  handleDateFocus = (e) => {
    const { value } = e.target;
    if (value !== '') return;
    this.handleDateChange(e);
  };

  handleDateChange = (e) => {
    const {
      event,
      inventoryFilter,
      setEventProperties,
      reloadInventory,
      checkCartItems,
    } = this.props;
    const { name, value } = e.target;

    let newEvent = Object.assign(
      {
        ...event,
        [name]: value,
      },
      name === 'eventStart' &&
        (!event.eventEnd || event.eventEnd === '') && { eventEnd: value },
      name === 'eventEnd' &&
        (!event.eventStart || event.eventStart === '') && { eventStart: value }
    );

    // Adjust eventEnd if eventStart is after eventEnd
    const eventStartDateTime = combineDateAndTime(
      newEvent.eventStart,
      newEvent.eventStartTime
    );
    const eventEndDateTime = combineDateAndTime(
      newEvent.eventEnd,
      newEvent.eventEndTime
    );

    if (eventStartDateTime >= eventEndDateTime) {
      newEvent.eventEnd = new Date(
        eventStartDateTime.getTime() + 60 * 60 * 1000
      );
      newEvent.eventEndTime = new Date(
        eventStartDateTime.getTime() + 60 * 60 * 1000
      );
    }

    const { items, rentalBundles } = getItemsWithNewPrices(newEvent);
    newEvent.items = items;
    newEvent.rentalBundles = rentalBundles;
    setEventProperties(newEvent);

    // Reload inventory with new event dates
    const updatedEventStartDateTime = combineDateAndTime(
      newEvent.eventStart,
      newEvent.eventStartTime
    );
    const updatedEventEndDateTime = combineDateAndTime(
      newEvent.eventEnd,
      newEvent.eventEndTime
    );

    reloadInventory({
      ...inventoryFilter,
      eventStart: updatedEventStartDateTime,
      eventEnd: updatedEventEndDateTime,
    });
    checkCartItems({
      inventoryObjectIds: [
        ...items.map((item) => item.productId),
        ...rentalBundles.map((bundle) => bundle.bundleId),
      ],
      eventStart: updatedEventStartDateTime,
      eventEnd: updatedEventEndDateTime,
    });
  };

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    const { event: prevEvent } = prevProps;
    if (
      event.startDate !== prevEvent.startDate ||
      event.eventStartTime !== prevEvent.eventStartTime ||
      event.eventEnd !== prevEvent.eventEnd ||
      event.eventEndTime !== prevEvent.eventEndTime
    ) {
      const { setEventProperties } = this.props;
      const { items, rentalBundles } = getItemsWithNewPrices(event);
      event.items = items;
      event.rentalBundles = rentalBundles;
      setEventProperties(event);
    }
  }

  componentDidMount() {
    const { event, checkCartItems } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { items } = getItemsWithNewPrices(event);

    const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
    const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);

    checkCartItems({
      inventoryObjectIds: items.map((item) => item.productId),
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime,
    });
  }

  //Kiara Note: 'linkedItem' indicates we're on an iteration for a linked item
  //newItems keeps track of the updated item quantities- namely the main item- so we know how to scake the commodity quantity update
  //index indicates the commodity relationship index
  //commodity relationship colds the commodity product information
  handleItemChange = (
    e,
    itemIndex,
    linkedItem = false,
    newItems = [],
    index = 0,
    commodityRelationship
  ) => {
    const { event, setEventProperties, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;

    //Before newItems update
    let commodityItemIndex = itemIndex + index + 1;
    //Kiara Note: this variable determines if we're on the main item or a commodity item
    let currentIndex = linkedItem ? commodityItemIndex : itemIndex;
    newItems = linkedItem ? newItems : event.items.slice();
    let commodityQuantity = linkedItem
      ? commodityRelationship.quantity * value
      : 0;

    newItems[currentIndex] = linkedItem
      ? {
          ...newItems[currentIndex],
          [name]: toNumber(commodityQuantity),
        }
      : {
          ...newItems[currentIndex],
          [name]: toNumber(value),
        };

    //After newItems update
    let currentCommodityRelationship = linkedItem
      ? newItems[itemIndex].product?.commodityProductRelationships[index]
      : '';
    let currentCommodity = linkedItem
      ? newItems[itemIndex].product?.commodityProductRelationships[index]
          ?.commodity
      : '';
    let currentItem = linkedItem
      ? currentCommodity
      : newItems[itemIndex].product;

    const selected = showSelectedPrice(
      currentItem,
      linkedItem
        ? currentCommodityRelationship.quantity * newItems[itemIndex].quantity
        : newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'items',
      location
    );

    newItems[currentIndex] = {
      ...newItems[currentIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };

    //Kiara Note: run this functoin for every commodity item
    if (
      newItems[itemIndex].product.commodityProductRelationships &&
      !linkedItem
    ) {
      this.handleCommodityChange(
        e,
        itemIndex,
        'item',
        newItems[itemIndex].product.commodityProductRelationships,
        newItems
      );
    }

    if (newItems[itemIndex].product.addOnProductRelationships && !linkedItem) {
      this.handleCommodityChange(
        e,
        itemIndex,
        'addOn',
        newItems[itemIndex].product.addOnProductRelationships,
        event.addOns.slice()
      );
    }

    setEventProperties({
      items: newItems,
    });
  };

  handleCommodityChange(e, itemIndex, type, commodityRelationships, newItems) {
    commodityRelationships.map((commodityRelationship, index) => {
      if (type == 'item') {
        this.handleItemChange(
          e,
          itemIndex,
          true,
          newItems,
          index,
          commodityRelationship
        );
      } else if (type == 'addOn') {
        this.handleAddOnChange(
          e,
          itemIndex,
          true,
          newItems,
          index,
          commodityRelationship
        );
      }
    });
  }

  handleItemRemove = (itemIndex, removeQuantity) => {
    const { event, setEventProperties } = this.props;
    let newItems = [...event.items];
    const item = newItems[itemIndex];

    newItems = newItems.map((_item) => {
      if (
        _item.linkedItem &&
        _item.linkedTo.some((i) => i.id === item.productId)
      ) {
        const linkedToIndex = _item.linkedTo.findIndex(
          (i) => i.id === item.productId
        );
        let linkedToArray = [..._item.linkedTo];
        linkedToArray.splice(linkedToIndex, 1);
        return {
          ..._item,
          quantity: _item.quantity - _item.linkedTo[linkedToIndex].quantity,
          linkedQuantity:
            _item.linkedQuantity - _item.linkedTo[linkedToIndex].quantity,
          linkedItem:
            _item.linkedTo.length === 1 &&
            _item.quantity > _item.linkedTo[linkedToIndex].quantity
              ? false
              : true,
          linkedTo: linkedToArray,
        };
      } else {
        return _item;
      }
    });

    newItems.splice(itemIndex, 1);
    newItems = newItems.filter((i) => i.quantity > 0);

    setEventProperties({ items: newItems });
  };

  handleAddOnRemove = (addOnIndex, totalCommodities = 0) => {
    const { event, setEventProperties } = this.props;
    let newItems = [...event.addOns];
    let removeQuantity = totalCommodities ? totalCommodities : 1;
    newItems.splice(addOnIndex, removeQuantity);

    setEventProperties({ addOns: newItems });
  };

  handleAddOnChange = (
    e,
    addOnIndex,
    linkedItem = false,
    newItems = [],
    index = 0,
    commodityRelationship
  ) => {
    const { event, setEventProperties, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;

    newItems = linkedItem ? newItems : event.addOns.slice();
    let commodityIndex = newItems.findIndex(
      (addOn) => addOn.linkedItemId == commodityRelationship.productId
    );
    let commodityItemIndex = commodityIndex + index;
    let currentIndex = linkedItem ? commodityItemIndex : addOnIndex;
    let commodityQuantity = linkedItem
      ? commodityRelationship.quantity * value
      : 0;
    let currentCommodityRelationship = newItems[commodityItemIndex].product;
    let currentCommodity = newItems[commodityItemIndex];

    newItems[currentIndex] = {
      ...newItems[currentIndex],
      [name]: toNumber(linkedItem ? commodityQuantity : value),
    };

    const selected = showSelectedPrice(
      currentCommodity,
      linkedItem
        ? currentCommodityRelationship.quantity * value
        : newItems[addOnIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'add_ons',
      location
    );

    newItems[currentIndex] = {
      ...newItems[currentIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };

    setEventProperties({
      addOns: newItems,
    });
  };

  handleRentalBundleChange = (e, rentalBundleIndex) => {
    const { updateRentalBundle } = this.props;
    const { name, value } = e.target;
    updateRentalBundle(name, value, rentalBundleIndex);
  };

  handleRentalBundleRemove = (rentalBundleIndex) => {
    const { event, setEventProperties } = this.props;
    let newRentalBundles = [...event.rentalBundles];
    newRentalBundles.splice(rentalBundleIndex, 1);
    setEventProperties({ rentalBundles: newRentalBundles });
  };

  conditionallyDisplayPrice = (total) => {
    const { event } = this.props;
    const anyHiddenPrices = event.items
      .concat(event.rentalBundles, event.addOns)
      .filter((item) => item._destroy !== '1')
      .some((item) => {
        if (item.bundle) {
          return !item.bundle.showPriceStorefront;
        } else {
          return !item.product.showPriceStorefront;
        }
      });
    return anyHiddenPrices ? <span>TBD</span> : <CurrencyLabel value={total} />;
  };

  render() {
    const {
      event,
      newCustomer,
      storefrontShopSetting,
      tileView,
      showTiles,
      hideTiles,
      errors,
      authenticated,
      location,
      step,
      renderNextButton,
      handleSelectStep,
      reviewStepOne,
      reviewStepTwo,
    } = this.props;

    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      addOns,
      customerContactPhone,
    } = event;
    const rentalInventory = event.items
      .concat(event.rentalBundles, event.addOns)
      .filter((item) => item._destroy !== '1');
    const hasPriceAvailableOnRequest = rentalInventory.some(
      (item_container) =>
        item_container.selectedPrice === 0 ||
        item_container.selectedPrice === 'N/A'
    );
    const total = rentalInventory.reduce(
      (sum, item) => (sum += item.selectedPrice),
      0
    );

    // const showPrice =
    //   items.filter((item) => !item.product.showPriceStorefront).length === 0;

    //Kiara Note: lines 536-582 need to be removed, but removing it makes it so linked purchases
    //aren't removed from the order when the main item is removed.

    return (
      <section
        className={classnames({
          itemForm: true,
          noItems: items.length === 0,
        })}
      >
        {items.length + rentalBundles.length + addOns.length === 0 ? (
          <div className='prompt'>
            <CartOutline />
            <p>You haven’t added anything to your cart.</p>
            <Link className='btn' to='/shop'>
              Shop Now
            </Link>
          </div>
        ) : (
          <div>
            <div className='details'>
              <h4>Contact Information</h4>
              <div className='form' id='cartForm'>
                <div className='fields'>
                  <StandardTextField
                    type='text'
                    meta={{ touched: true, error: errors['name'] }}
                    placeholder='Rental Name*'
                    input={{
                      name: 'name',
                      value: event.name,
                      onChange: this.handleChange,
                    }}
                  />
                </div>
                <div className='fields dateRange'>
                  <div>
                    <label>Order Start*</label>
                    <DatePicker
                      name='eventStart'
                      value={eventStart}
                      onBlur={this.handleDateFocus}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error:
                          errors['eventStartDateTime'] || errors['eventStart'],
                      })}
                    />
                    <TimePicker
                      name='eventStartTime'
                      value={eventStartTime}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error:
                          errors['eventStartDateTime'] ||
                          errors['eventStartTime'],
                      })}
                    />
                  </div>
                  <div id='orderEnd'>
                    <label>Order End*</label>
                    <DatePicker
                      name='eventEnd'
                      value={eventEnd}
                      onBlur={this.handleDateFocus}
                      onChange={this.handleDateChange}
                      startDate={eventStart ? eventStart : new Date()}
                      className={classnames({
                        error: errors['eventEndDateTime'] || errors['eventEnd'],
                      })}
                    />
                    <TimePicker
                      name='eventEndTime'
                      value={eventEndTime}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error: errors['eventEndDateTime'] || errors['eventEnd'],
                      })}
                    />
                  </div>
                </div>
                {!authenticated && (
                  <div>
                    <div className='fields'>
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error: errors['firstName'],
                        }}
                        placeholder='First Name*'
                        className='med'
                        input={{
                          name: 'firstName',
                          value: newCustomer.firstName,
                          onChange: this.handleNewCustomerChange,
                        }}
                      />
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error: errors['lastName'],
                        }}
                        placeholder='Last Name*'
                        className='med'
                        input={{
                          name: 'lastName',
                          value: newCustomer.lastName,
                          onChange: this.handleNewCustomerChange,
                        }}
                      />
                    </div>
                    <div className='fields' id='unAuthEmailPhone'>
                      <StandardTextField
                        type='text'
                        placeholder='Email Address*'
                        meta={{
                          touched: true,
                          error: errors['email'],
                        }}
                        input={{
                          name: 'email',
                          value: newCustomer.email,
                          onChange: this.handleNewCustomerChange,
                        }}
                      />
                      <PhoneInputField
                        meta={{
                          touched: true,
                          error: errors['customerContactPhone'],
                        }}
                        input={{
                          name: 'customerContactPhone',
                          value: customerContactPhone,
                          onChange: this.handleContactPhoneChange,
                        }}
                      />
                    </div>
                  </div>
                )}
                {authenticated && (
                  <div className='fields'>
                    <label>Contact Phone Number </label>
                    <PhoneInputField
                      meta={{
                        touched: true,
                        error: errors['customerContactPhone'],
                      }}
                      input={{
                        name: 'customerContactPhone',
                        value: customerContactPhone,
                        onChange: this.handleContactPhoneChange,
                      }}
                    />
                  </div>
                )}
                <div className='fields'>
                  <StandardTextareaField
                    meta={{}}
                    placeholder='Additional Order Info/Notes'
                    input={{
                      name: 'additionalOrderInfo',
                      value: event.additionalOrderInfo,
                      style: { resize: 'none' },
                      onChange: this.handleChange,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='items'>
              <h4>Items</h4>
              <div className='tileToggle'>
                <a
                  onClick={hideTiles}
                  className={classnames({
                    active: !tileView,
                  })}
                >
                  <ListView />
                </a>
                <a
                  onClick={showTiles}
                  className={classnames({
                    active: tileView,
                  })}
                >
                  <ImageView />
                </a>
              </div>
              <div className='companyItems'>
                <table
                  className={classnames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <tbody className='cartItemList'>
                    {items.map((item, itemIndex) => {
                      if (item._destroy === '1') {
                        return null;
                      } else {
                        return !item.product.commodityProductRelationships ? (
                          <CartItem
                            key={itemIndex}
                            period={item.period}
                            item={item}
                            itemSelectedPrice={item.selectedPrice}
                            itemIndex={itemIndex}
                            tileView={tileView}
                            storefrontShopSetting={storefrontShopSetting}
                            onItemChange={this.handleItemChange}
                            onItemRemove={this.handleItemRemove}
                          />
                        ) : (
                          <>
                            <CartItem
                              key={itemIndex}
                              period={item.period}
                              item={item}
                              itemSelectedPrice={item.selectedPrice}
                              itemIndex={itemIndex}
                              tileView={tileView}
                              storefrontShopSetting={storefrontShopSetting}
                              onItemChange={this.handleItemChange}
                              onItemRemove={this.handleItemRemove}
                              removeAddOnCommodities={this.handleAddOnRemove}
                            />
                          </>
                        );
                      }
                    })}
                    {rentalBundles.map((item, itemIndex) => {
                      if (item._destroy === '1') {
                        return null;
                      } else {
                        return (
                          <CartItem
                            key={itemIndex}
                            item={item}
                            itemIndex={itemIndex}
                            tileView={tileView}
                            itemSelectedPrice={item.selectedPrice}
                            storefrontShopSetting={storefrontShopSetting}
                            onItemChange={this.handleRentalBundleChange}
                            onItemRemove={this.handleRentalBundleRemove}
                          />
                        );
                      }
                    })}
                    {addOns.map((addOn, itemIndex) => {
                      if (addOn._destroy === '1') {
                        return null;
                      } else {
                        return (
                          <CartItem
                            key={itemIndex}
                            item={addOn}
                            itemIndex={itemIndex}
                            tileView={tileView}
                            itemSelectedPrice={addOn.selectedPrice}
                            storefrontShopSetting={storefrontShopSetting}
                            onItemChange={this.handleAddOnChange}
                            onItemRemove={this.handleAddOnRemove}
                          />
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    location,
    parsedStorefrontSettings: { storefrontShopSetting },
  } = state.locations;
  const { inventoryFilter, tileView } = state.products;
  const { errors } = state.dashboard;
  const { authenticated } = state.auth;
  const { newCustomer } = state.cart;
  return {
    location,
    storefrontShopSetting,
    inventoryFilter,
    tileView,
    errors,
    authenticated,
    newCustomer,
  };
};

export default connect(mapStateToProps, actions)(CartItems);
