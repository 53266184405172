/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { CartOutline, ListView, ImageView } from 'Utils/SvgIcons';
import { combineDateAndTime } from 'HelperFunctions/general';
import { showSelectedPrice } from 'HelperFunctions/rental_items';
import { FormattedDate, FormattedTime } from 'react-intl';
import classnames from 'classnames';
import CartItem from 'Components/cart/CartItem';
import { formatPhoneNumber, toNumber } from 'HelperFunctions/general';
import { customerDeliveryTypes } from 'Constants/displayConstants';
import moment from 'moment';
import CurrencyLabel from 'Utils/CurrencyLabel';

class CartItems extends React.Component {
  handleItemChange = (e, itemIndex) => {
    const { event, setCartProps, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.items.slice();

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value),
    };
    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'items',
      location
    );

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };

    setCartProps({
      items: newItems,
    });
  };
  handleAddOnChange = (e, addOnIndex) => {
    const { event, setCartProps, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.addOns.slice();

    newItems[addOnIndex] = {
      ...newItems[addOnIndex],
      [name]: toNumber(value),
    };

    const selected = showSelectedPrice(
      newItems[addOnIndex],
      newItems[addOnIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'add_ons',
      location
    );

    newItems[addOnIndex] = {
      ...newItems[addOnIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };
    setCartProps({
      addOns: newItems,
    });
  };

  handleItemRemove = (itemIndex) => {
    const { event, setCartProps } = this.props;
    let newItems = event.items.slice();
    newItems.splice(itemIndex, 1);
    setCartProps({ items: newItems });
  };
  handleAddOnRemove = (itemIndex) => {
    const { event, setCartProps } = this.props;
    let newItems = event.addOns.slice();
    newItems.splice(itemIndex, 1);
    setCartProps({ addOns: newItems });
  };

  handleRentalBundleChange = (e, rentalBundleIndex) => {
    const { updateRentalBundle } = this.props;
    const { name, value } = e.target;
    updateRentalBundle(name, value, rentalBundleIndex);
  };

  handleRentalBundleRemove = (rentalBundleIndex) => {
    const { event, setEventProps } = this.props;
    let newRentalBundles = event.rentalBundles.slice();
    newRentalBundles[rentalBundleIndex] = {
      ...newRentalBundles[rentalBundleIndex],
      _destroy: '1',
    };
    setEventProps({ rentalBundles: newRentalBundles });
  };

  render() {
    const {
      event,
      customer,
      storefrontShopSetting,
      tileView,
      showTiles,
      hideTiles,
      stopAddingItemsToEvent,
    } = this.props;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      addOns,
      deliveryType,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
    } = event;
    const itemTotal = items.reduce(
      (sum, item) => (sum += item.selectedPrice),
      0
    );
    const bundleTotal = rentalBundles.reduce(
      (sum, item) => (sum += item.selectedPrice),
      0
    );
    const addOnTotal = addOns.reduce(
      (sum, item) => (sum += item.selectedPrice),
      0
    );
    const total = itemTotal + bundleTotal + addOnTotal;
    const timeZone = moment.tz.guess(eventEndTime);
    return (
      <section
        className={classnames({
          itemForm: true,
          noItems: Object.values(items).length === 0,
        })}
      >
        {items.length === 0 &&
        rentalBundles.length === 0 &&
        addOns.length === 0 ? (
          <div className='prompt'>
            <CartOutline />
            <p>You haven’t added anything to your cart.</p>
            <Link className='btn' to='/shop'>
              Shop Now
            </Link>
          </div>
        ) : (
          <div>
            <div className='eventDetails'>
              <div className='title'>
                <h2>{event.name}</h2>
                <h3>
                  <FormattedDate
                    value={eventStart}
                    month='numeric'
                    day='numeric'
                  />{' '}
                  <FormattedTime
                    value={eventStartTime}
                    timeZone={timeZone}
                    timeZoneName='short'
                  />
                  {' - '}
                  <FormattedDate
                    value={eventEnd}
                    month='numeric'
                    year='numeric'
                    day='numeric'
                  />{' '}
                  <FormattedTime
                    value={eventEndTime}
                    timeZone={timeZone}
                    timeZoneName='short'
                  />
                </h3>
              </div>
              {customer.id && (
                <div className='contact'>
                  <label>Contact:</label>
                  <strong>
                    {customer.firstName} {customer.lastName}
                  </strong>
                  <div>{customer.email}</div>
                  {customer.cell && (
                    <div>{formatPhoneNumber(customer.cell)}</div>
                  )}
                  {customer.streetAddress1 && (
                    <div>
                      {customer.streetAddress1}{' '}
                      {customer.streetAddress2 && (
                        <div>{customer.streetAddress2}</div>
                      )}
                      <div>
                        {customer.city}
                        {', '}
                        {customer.locale} {customer.postalCode},{', '}
                        {customer.country}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className='delivery'>
                <label>{customerDeliveryTypes[deliveryType]}</label>
                {deliveryType === 'default_delivery' && (
                  <div>
                    <div>
                      {deliveryAddressStreetAddress1}{' '}
                      {deliveryAddressStreetAddress2}
                    </div>
                    <div>
                      {deliveryAddressCity}, {deliveryAddressLocale}{' '}
                      {deliveryAddressPostalCode}, {deliveryAddressCountry}
                    </div>
                  </div>
                )}
              </div>
              <button className='btn' onClick={stopAddingItemsToEvent}>
                Make This Item a New Order
              </button>
            </div>
            <div className='items'>
              <h2>Items</h2>
              <div className='tileToggle'>
                <a
                  onClick={hideTiles}
                  className={classnames({
                    active: !tileView,
                  })}
                >
                  <ListView />
                </a>
                <a
                  onClick={showTiles}
                  className={classnames({
                    active: tileView,
                  })}
                >
                  <ImageView />
                </a>
              </div>
              <div className='companyItems'>
                <table
                  className={classnames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <tbody>
                    {items.map((item, itemIndex) => {
                      if (item._destroy === '1') {
                        return null;
                      } else {
                        return (
                          <CartItem
                            item={item}
                            itemIndex={itemIndex}
                            tileView={tileView}
                            storefrontShopSetting={storefrontShopSetting}
                            onItemChange={this.handleItemChange}
                            onItemRemove={this.handleItemRemove}
                          />
                        );
                      }
                    })}
                    {rentalBundles.map((item, itemIndex) => {
                      if (item._destroy === '1') {
                        return null;
                      } else {
                        return (
                          <CartItem
                            item={item}
                            itemIndex={itemIndex}
                            tileView={tileView}
                            storefrontShopSetting={storefrontShopSetting}
                            onItemChange={this.handleRentalBundleChange}
                            onItemRemove={this.handleRentalBundleRemove}
                          />
                        );
                      }
                    })}
                    {addOns.map((item, itemIndex) => {
                      if (item._destroy === '1') {
                        return null;
                      } else {
                        return (
                          <CartItem
                            item={item}
                            itemIndex={itemIndex}
                            tileView={tileView}
                            storefrontShopSetting={storefrontShopSetting}
                            onItemChange={this.handleAddOnChange}
                            onItemRemove={this.handleAddOnRemove}
                          />
                        );
                      }
                    })}
                  </tbody>
                </table>

                {storefrontShopSetting.shopShowItemPricing && (
                  <div className='billingDetails'>
                    <div>
                      <label>Estimated Delivery</label>
                      <span>TBD</span>
                    </div>

                    <div className='subtotal'>
                      <label>Item Total</label>
                      {total === 0 ? (
                        <span>Price On Request</span>
                      ) : (
                        <CurrencyLabel value={total} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    parsedStorefrontSettings: { storefrontShopSetting },
    location,
  } = state.locations;
  const { event } = state.cart;
  const { tileView } = state.products;
  const { errors } = state.dashboard;
  const { customer } = state.customer;
  return {
    event,
    storefrontShopSetting,
    tileView,
    customer,
    errors,
    location,
  };
};

export default connect(mapStateToProps, actions)(CartItems);
