import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import Swiper from 'react-id-swiper';
import TestimonialBox from './widgets/TestimonialBox';
import EventsBox from './widgets/EventsBox';
import InstagramBox from './widgets/InstagramBox';
import InstagramCarousel from './widgets/InstagramCarousel';
import { history as browserHistory } from 'Components/Routes';
import Imgix from 'react-imgix';
import PageMetaData from 'Utils/PageMetaData';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class Home extends React.Component {
  handleBrowse(path) {
    document.body.scrollTop = 0;
    browserHistory.push(path);
  }

  getVideoId(videoLink, type = 'vimeo') {
    if (type === 'youtube') {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      let match = videoLink.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    } else {
      const videoId = videoLink.substring(videoLink.lastIndexOf('/') + 1);
      return videoId;
    }
  }

  stopAutoplay = () => {
    const { shouldPauseWhenRolledOver } = this.props.storefrontSetting;
    if (this.swiperNode && shouldPauseWhenRolledOver) {
      this.swiperNode.swiper.autoplay.stop();
    }
  };

  startAutoplay = () => {
    const { carouselAutoplayDelay } = this.props.storefrontSetting;
    if (this.swiperNode && carouselAutoplayDelay) {
      this.swiperNode.swiper.autoplay.start();
    }
  };

  getSwiperParams() {
    const { themeSetting, storefrontSetting } = this.props;
    const {
      shouldPauseWhenRolledOver,
      carouselAutoplayDelay,
      homeCarouselItems,
    } = storefrontSetting;

    let params = {
      slidesPerView: 1,
      preventClicks: false,
      preventClicksPropagation: false,
      rebuildOnUpdate: true,
    };
    if (homeCarouselItems && homeCarouselItems.length > 1) {
      params = {
        ...params,
        loop: true,
        allowTouchMove: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
      if (carouselAutoplayDelay) {
        params['autoplay'] = {
          delay: +carouselAutoplayDelay * 1000,
          disableOnInteraction: shouldPauseWhenRolledOver,
        };
      }

      if (
        themeSetting.theme === 'catalina' ||
        themeSetting.theme === 'catalinaDark' ||
        themeSetting.theme === 'wisteria' ||
        themeSetting.theme === 'wisteriaDark' ||
        themeSetting.theme === 'magnolia' ||
        themeSetting.theme === 'magnoliaDark' ||
        themeSetting.theme === 'preScott' ||
        themeSetting.theme === 'elaina'
      ) {
        params = {
          ...params,
          effect: 'fade',
          speed: 1000,
        };
      }
    }

    return params;
  }

  render() {
    const { location, authenticated, themeSetting, storefrontSetting } =
      this.props;

    const {
      homeCarouselItems,
      homeShowTestimonials,
      homeShowSidekicks,
      homeSidekickItems,
    } = storefrontSetting;

    return (
      <div className='home'>
        <PageMetaData setting={storefrontSetting} />
        <div
          className='heroes'
          onMouseEnter={this.stopAutoplay}
          onMouseLeave={this.startAutoplay}
        >
          <Swiper
            ref={(node) => {
              this.swiperNode = node;
            }}
            {...this.getSwiperParams()}
          >
            {homeCarouselItems &&
              homeCarouselItems.map((carouselItem) => (
                <div key={carouselItem.id}>
                  <figure>
                    {carouselItem.contentType === 'image' ? (
                      carouselItem.picture && (
                        <Imgix
                          src={carouselItem.picture.imgixUrl}
                          alt={carouselItem.title}
                          sizes='100vw'
                        />
                      )
                    ) : carouselItem.videoType === 'youtube' ? (
                      <div className='video'>
                        <iframe
                          src={`https://www.youtube.com/embed/${this.getVideoId(
                            carouselItem.videoLink,
                            'youtube'
                          )}/?autoplay=1&controls=0&mute=1&loop=1&playlist=${this.getVideoId(
                            carouselItem.videoLink,
                            'youtube'
                          )}`}
                          width='640'
                          height='360'
                          frameborder='0'
                          allow='autoplay; fullscreen'
                          allowFullScreen
                        />
                      </div>
                    ) : (
                      <div className='video'>
                        <iframe
                          src={`https://player.vimeo.com/video/${this.getVideoId(
                            carouselItem.videoLink,
                            'vimeo'
                          )}?autoplay=1&loop=1&autopause=0&controls=0&muted=1`}
                          width='640'
                          height='360'
                          frameborder='0'
                          allow='autoplay; fullscreen'
                          allowFullScreen
                        />
                      </div>
                    )}
                  </figure>
                  <div>
                    <h1
                      style={{
                        color: `${
                          carouselItem.titleFontTheme === 'dark'
                            ? themeSetting.darkFontColor
                            : themeSetting.lightFontColor
                        }`,
                      }}
                    >
                      {carouselItem.title}
                    </h1>
                    <p
                      style={{
                        color: `${
                          carouselItem.descriptionFontTheme === 'dark'
                            ? themeSetting.darkFontColor
                            : themeSetting.lightFontColor
                        }`,
                      }}
                    >
                      {carouselItem.description}
                    </p>
                    <a className='btn' href={carouselItem.buttonLink}>
                      {carouselItem.buttonText}
                    </a>
                  </div>
                </div>
              ))}
          </Swiper>
        </div>
        {authenticated && <EventsBox />}
        {homeShowTestimonials && <TestimonialBox />}
        {homeShowSidekicks && (
          <div className='sidekicks'>
            {homeSidekickItems.map((sidekick) => (
              <div key={sidekick.id}>
                <figure>
                  {sidekick.picture && (
                    <Imgix
                      src={sidekick.picture.imgixUrl}
                      alt={sidekick.buttonText}
                      sizes='50vw'
                      fit='max'
                      imgixParams={{ ar: '16:9' }}
                    />
                  )}
                </figure>
                <a className='btn' href={sidekick.buttonLink}>
                  {sidekick.buttonText}
                </a>
              </div>
            ))}
          </div>
        )}
        {location &&
          (themeSetting.theme === 'preScott' ? (
            <InstagramCarousel location={location} />
          ) : (
            <InstagramBox location={location} />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const storefrontSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  const themeSetting = parsedStorefrontSettings.mainSetting;
  const { authenticated } = state.auth;
  return { location, storefrontSetting, authenticated, themeSetting };
};

export default connect(mapStateToProps, actions)(Home);
