import { LOCATION_CHANGE } from "react-router-redux";
import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  PROFILE_IMAGE_CHANGED
} from "Constants/redux";

const INITIAL_STATE = {
  customer: {
    file: null,
    firstName: "",
    lastName: ""
  },
  errors: {},
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
        loading: false
      };
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case PROFILE_IMAGE_CHANGED:
      return {
        ...state,
        customer: {
          ...state.customer,
          picture: action.payload.picture,
          file: action.payload.file
        }
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        errors: INITIAL_STATE.errors
      };
    default:
      return state;
  }
};
