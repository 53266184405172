import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import ProfilePaymentMethods from "./ProfilePaymentMethods";
import ProfilePaymentHistory from "./ProfilePaymentHistory";
import ProfileDocuments from "./ProfileDocuments";
import NewCreditCard from "./NewCreditCard";
import NewCustomerDocument from "./NewCustomerDocument";
import ProfileNav from "./ProfileNav";

class ProfileSection extends React.Component {
  render() {
    return (
      <div className="profile">
        <Route path="/profile" component={ProfileNav} />
        <Switch>
          <Redirect exact from="/profile" to="/profile/info" />
          <Route exact path="/profile/info" component={Profile} />
          <Route path="/profile/edit" component={EditProfile} />
          <Route
            exact
            path="/profile/paymentMethods"
            component={ProfilePaymentMethods}
          />
          <Route path="/profile/paymentMethods/new" component={NewCreditCard} />
          <Route exact path="/profile/documents" component={ProfileDocuments} />
          <Route
            exact
            path="/profile/documents/new"
            component={NewCustomerDocument}
          />
          <Route
            exact
            path="/profile/paymentHistory"
            component={ProfilePaymentHistory}
          />
        </Switch>
      </div>
    );
  }
}

export default ProfileSection;
