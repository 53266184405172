import React from 'react'
import { FormattedTime } from 'react-intl';

export const renderHideDeliveryTimes = (value, timeZone, hideDeliveryTimes) => {
  if (hideDeliveryTimes) {
    return <span>TBD</span>;
  }

  return (
    <FormattedTime value={value} timeZone={timeZone} timeZoneName='short' />
  );
};
