import React from "react";
import ForgotPasswordForm from "Components/home/ForgotPasswordForm";
import HeroImage from "Components/home/widgets/HeroImage";
import * as actions from "Actions";
import { connect } from "react-redux";

class ForgotPassword extends React.Component {
  handleSubmit = data => {
    this.props.sendPasswordReset(data);
  };
  render() {
    return (
      <section className="password">
        <HeroImage />
        <div className="details">
          <h1>Password Reset</h1>
          <p>Track rentals, make payments, request changes. You made it!</p>
        </div>
        <div className="passwordForm">
          <ForgotPasswordForm onSubmit={this.handleSubmit} />
        </div>
      </section>
    );
  }
}

export default connect(null, actions)(ForgotPassword);
