import React from "react";
import { Storefront } from "Utils/SvgIcons";

const NoBusinessFound = () => (
  <div className="notFound">
    <Storefront />
    <h1>Oops!</h1>
    <p>We couldn't find the rental business you were looking for.</p>
    <a className="btn" href={"mailto:support@tapgoods.com"}>
      Contact us if you need help finding it.
    </a>
  </div>
);

export default NoBusinessFound;
