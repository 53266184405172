import React from 'react';
import { connect } from 'react-redux';
import Imgix from 'react-imgix';
import PageMetaData from 'Utils/PageMetaData';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';
import GalleryLightbox from './widgets/GalleryLightbox';
import { isSsr } from 'HelperFunctions/ssr';

class Gallery extends React.Component {
  render() {
    const { storefrontSetting, themeSetting } = this.props;
    const {
      galleryHeroTitle,
      galleryHeroDescription,
      galleryHeroPicture,
      picturings,
      galleryTitleFontTheme,
    } = storefrontSetting;
    const images =
      picturings &&
      picturings.map((p) => {
        return {
          src: p.imgixUrl,
          title: '',
          description: p.photoCredits,
        };
      });
    return (
      <div className='gallery'>
        <PageMetaData setting={storefrontSetting} />
        <div className='summary'>
          <figure>
            {galleryHeroPicture && (
              <Imgix
                src={galleryHeroPicture.imgixUrl}
                alt={galleryHeroTitle}
                sizes='vw'
              />
            )}
          </figure>
          <div>
            <h1
              style={{
                color: `${
                  galleryTitleFontTheme === 'dark'
                    ? themeSetting.darkFontColor
                    : themeSetting.lightFontColor
                }`,
              }}
            >
              {galleryHeroTitle}
            </h1>
            <div
              className='galleryHeroDescription'
              dangerouslySetInnerHTML={{
                __html: galleryHeroDescription,
              }}
            />
          </div>
        </div>
        {!isSsr() && images && (
          <section>
            <GalleryLightbox images={images} />
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const themeSetting = parsedStorefrontSettings.mainSetting;
  const storefrontSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  return { storefrontSetting, themeSetting };
};

export default connect(mapStateToProps)(Gallery);
