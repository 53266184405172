export const SET_CART_PROPS = "SET_CART_PROPS";
export const SET_CART_NEW_EVENT_PROPS = "SET_CART_NEW_EVENT_PROPS";
export const SET_CART_NEW_CUSTOMER_PROPS = "SET_CART_NEW_CUSTOMER_PROPS";
export const SET_CART_TRANSACTION_PROPS = "SET_CART_TRANSACTION_PROPS";
export const INIT_CART_PROPS = "INIT_CART_PROPS";
export const START_ADDING_ITEMS_TO_EVENT = "START_ADDING_ITEMS_TO_EVENT";
export const STOP_ADDING_ITEMS_TO_EVENT = "STOP_ADDING_ITEMS_TO_EVENT";
export const REFRESH_CART_ITEMS = "REFRESH_CART_ITEMS";
export const ADD_RENTAL_BUNDLE = "ADD_RENTAL_BUNDLE";
export const UPDATE_RENTAL_BUNDLE = "UPDATE_RENTAL_BUNDLE";
export const CLEAR_CART_PROPS = "CLEAR_CART_PROPS";
