import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Burst } from "Utils/SvgIcons";
import CurrencyLabel from "Utils/CurrencyLabel";

class EventBilling extends React.Component {
  renderTotalLabel(step) {
    switch (step) {
      case 1:
        return "Subtotal";
      case 2:
        return "Running Subtotal";
      case 3:
        return "Total";
      default:
        return "Subtotal";
    }
  }

  render() {
    const { step, event, storefrontShopSetting } = this.props;
    const rentalInventory = event.items
      .concat(event.rentalBundles)
      .concat(event.addOns)
      .filter(item => item._destroy !== "1");
    const rentalInventoryTotal = rentalInventory.reduce(
      (total, item_container) =>
        total + parseFloat(item_container.selectedPrice),
      0
    );
    const hasPriceAvailableOnRequest = rentalInventory.some(
      item_container => item_container.selectedPrice === 0
    );
    const deliveryCost = parseFloat(event.deliveryCost || 0);
    let damageWaiverFee = parseFloat(event.damageWaiverFeeTotal || 0);

    let subTotal = rentalInventoryTotal + damageWaiverFee;
    if (step < 3) {
      subTotal += deliveryCost;
    }
    const taxTotal = event.taxExempt ? 0 : Number(event.taxTotal);
    const eventTotal = subTotal + taxTotal;
    return (
      <section className="billing">
        {step === 4 && (
          <div className="complete">
            <Burst />
            <p>We updated your quote! (#{event.token})</p>
          </div>
        )}
        {step < 4 && storefrontShopSetting.shopShowItemPricing && (
          <div className="billingSummary">
            <h4>Summary</h4>
            <div className="billingDetails">
              <div>
                <label>Items Subtotal</label>
                {hasPriceAvailableOnRequest ? (
                  <span>Price On Request</span>
                ) : (
                  <CurrencyLabel value={rentalInventoryTotal} />
                )}
              </div>
              <div>
                <label>Damage Waiver Fee</label>
                <CurrencyLabel value={damageWaiverFee} />
              </div>
              {step >= 3 &&
                <div>
                  <label>Tax</label>
                  {hasPriceAvailableOnRequest ? (
                    <span>Price On Request</span>
                  ) : (
                    <CurrencyLabel value={taxTotal} />
                  )}
                </div>
              }
              <div>
                <label>Estimated Delivery</label>
                <CurrencyLabel value={deliveryCost} />
              </div>
              <div className="subtotal">
                <label>{this.renderTotalLabel(step)}</label>
                {step < 3 ? (
                  <CurrencyLabel value={subTotal} />
                ) : (
                  <CurrencyLabel value={eventTotal} />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const { event } = state.event;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  return { event, storefrontShopSetting };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(EventBilling)
);
