/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import HeroImage from 'Components/home/widgets/HeroImage';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import { parseQuery } from 'HelperFunctions/QueryString';
import { history as browserHistory } from 'Components/Routes';

class ConnectAccount extends React.Component {
  render() {
    const { email } = parseQuery(this.props.location.search);
    const { sendPasswordReset } = this.props;
    return (
      <section className='reclaim'>
        <HeroImage />
        <div className='details'>
          <h1>Good news!</h1>
          <p>
            Looks like you've rented with a business powered by TapGoods using
            that email.
          </p>
          <p>Would you like to reset your password?</p>
        </div>
        <div className='reclaimForm'>
          <div className='actions'>
            <a
              className='btn full'
              onClick={() => sendPasswordReset({ email })}
            >
              Yes
            </a>
            <a
              className='btn cancel full'
              onClick={() => browserHistory.push('/')}
            >
              No
            </a>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { newEvent } = state.cart;
  return { newEvent };
};

export default connect(mapStateToProps, actions)(ConnectAccount);
