import { datadogRum } from '@datadog/browser-rum';
if (
  process.env.REACT_APP_APP_ENV === 'preprod' ||
  process.env.REACT_APP_APP_ENV === 'production'
) {
  datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'storefront',
      env: process.env.REACT_APP_APP_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.REACT_APP_RELEASE_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
  });
}