import React from "react";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import { localeToCurrency } from "Constants/currencyConstants";

// ref docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
const CurrencyLabel = props => {
  const {
    value,
    location: { locale },
    ...others
  } = props;
  const currency = localeToCurrency[locale];
  const currencyDisplay = currency === "CAD" ? "code" : "symbol";

  return (
    <FormattedNumber
      value={value}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currencyDisplay={currencyDisplay}
      currency={currency}
      {...others}
    />
  );
}

const mapStateToProps = state => {
  const { location } = state.locations;
  return { location };
}

export default connect(mapStateToProps, null)(CurrencyLabel);
