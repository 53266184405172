import React from 'react';
import Imgix from 'react-imgix';
import classnames from 'classnames';
import { Carousel } from 'react-responsive-carousel';

class GalleryLightbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedImageIndex: 0,
    };
    this.carouselRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.closeLightbox();
    }
  };

  openLightbox = (index) => {
    this.setState({ isOpen: true, selectedImageIndex: index }, () => {
      if (this.carouselRef.current) {
        this.carouselRef.current.focus();
      }
    });
  };

  closeLightbox = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { images } = this.props;
    const { isOpen, selectedImageIndex } = this.state;

    return (
      <div>
        <div className='gallery'>
          {images.map((image, idx) => (
            <figure
              key={idx}
              onClick={() => this.openLightbox(idx)}
              className={classnames({
                galleryThird: (idx + 1) % 3 === 0,
              })}
            >
              <Imgix src={image.src} alt={image.src} sizes='25vw' />
            </figure>
          ))}
        </div>

        {isOpen && (
          <div className='lightbox'>
            <button className='close' onClick={this.closeLightbox}>
              &times;
            </button>
            <div tabIndex='-1' ref={this.carouselRef}>
              <Carousel
                selectedItem={selectedImageIndex}
                onChange={(index) =>
                  this.setState({ selectedImageIndex: index })
                }
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                useKeyboardArrows
              >
                {images.map((image, idx) => (
                  <div key={idx}>
                    <Imgix
                      src={image.src}
                      alt={image.src}
                      sizes='50vw'
                      style={{ maxHeight: '50vh', objectFit: 'contain' }}
                    />
                    {image.description && (
                      <div className='description'>
                        {image.description.map((photoCredit) => (
                          <div key={photoCredit.id}>
                            <strong>{photoCredit.role}:</strong>{' '}
                            {photoCredit.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default GalleryLightbox;
