import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import SetByTimeInfo from './SetByTime';

const CustomerPickUp = ({
  checkout,
  checkoutWindowEnd,
  checkout_time,
  checkinWindowStart,
  checkin_time,
  hideDeliveryInfo,
  hideDeliveryTimes,
  schedule,
  rental,
}) => {
  const timeZone = schedule.timeZone;

  const renderDeliveryTimes = (windowStart, windowEnd) => {
    if (hideDeliveryTimes) {
      return <span>TBD</span>;
    }

    return (
      <>
        <FormattedTime
          value={windowStart}
          timeZone={timeZone}
          timeZoneName='short'
        />
        {' - '}
        <FormattedTime
          value={windowEnd}
          timeZone={timeZone}
          timeZoneName='short'
        />
      </>
    );
  };

  return (
    <section className='delivery'>
      <h4>Pick Up/Drop Off Info</h4>
      {hideDeliveryInfo ? (
        <div className='details'>TBD</div>
      ) : (
        <div className='details'>
          <div>
            <label>Pick Up</label>
            <div>
              <FormattedDate
                month='2-digit'
                day='2-digit'
                year='2-digit'
                value={checkout}
              />
              <br />
              {renderDeliveryTimes(checkout_time, checkoutWindowEnd)}
            </div>
          </div>
          <div>
            <label>Drop Off</label>
            <div>
              <FormattedDate
                month='2-digit'
                day='2-digit'
                year='2-digit'
                value={checkinWindowStart}
              />
              <br />
              {renderDeliveryTimes(checkinWindowStart, checkin_time)}
            </div>
          </div>
          {rental.hasSetByTime && rental.location.showSetByTimeToCustomers && (
            <SetByTimeInfo {...{ rental, schedule }} />
          )}
        </div>
      )}
    </section>
  );
};

export default CustomerPickUp;
