/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { Items, Delete } from "Utils/SvgIcons";
import { getItemPeriodPrice } from "HelperFunctions/rentals";
import { getProductType } from "HelperFunctions/rental_items";
import NumberInput from "Utils/NumberInput";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Imgix from "react-imgix";
import CurrencyFormatter from "HelperFunctions/CurrencyFormatter";

class CartItem extends React.Component {
  handleItemChange = e => {
    const { itemIndex } = this.props;
    this.props.onItemChange(e, itemIndex);
  };

  handleItemRemove = () => {
    const { itemIndex } = this.props;
    this.props.onItemRemove(itemIndex);
  };

  render() {
    const { item, storefrontShopSetting, tileView, location: { locale }, location } = this.props;
    const product = item.product || item.bundle || item.addOn;
    const productType = getProductType(item);
    const currencyFormatter = CurrencyFormatter({ locale });

    return (
      <MediaBreakpoints
        desktop={
          <tr>
            {tileView && (
              <td className="image">
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              </td>
            )}
            <td className="itemContent">

              <NumberInput
                type="text"
                name="quantity"
                value={item.quantity}
                onChange={this.handleItemChange}
              />
              <label>{item.name}</label>
              {storefrontShopSetting.shopShowItemPricing && getItemPeriodPrice(item, productType, locale, location)}
              {storefrontShopSetting.shopShowItemPricing &&
                item.bundle &&
                !product.priceLocked &&
                product.flatPrices && product.flatPrices.map(flatPrice =>
                  <span className="normalText">{currencyFormatter.format(flatPrice.amount)} / Flat: {flatPrice.name}</span>
                )}
              {storefrontShopSetting.shopShowItemPricing &&
                item.bundle &&
                !product.priceLocked &&
                product.purchasePrice && (
                  <span className="normalText">+ {currencyFormatter.format(product.purchasePrice)} / Each</span>
                )}
            </td>
            <td className="action">
              <a className="btnLink remove" onClick={this.handleItemRemove}>
                <Delete />
              </a>
            </td>
          </tr>
        }
        mobile={
          <tr>
            <td className="mobileItemContent">
              {tileView && (
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              )}
              <label>{item.name}</label>
              <a className="btnLink remove" onClick={this.handleItemRemove}>
                <Delete />
              </a>
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                input={{
                  name: "quantity",
                  value: item.quantity,
                  onChange: this.handleItemChange
                }}
              />
              {storefrontShopSetting.shopShowItemPricing && getItemPeriodPrice(item, productType, locale, location)}
            </td>
          </tr>
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = state.locations;
  return { location };
};

export default connect(mapStateToProps, null)(CartItem);
