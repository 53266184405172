import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import PhoneInputField from "Utils/redux_form_inputs/PhoneInputField"
import StandardTextareaField from "Utils/redux_form_inputs/StandardTextareaField";
import Validator from "HelperFunctions/validator";
import { setErrors } from "Actions";

const validations = {
  name: {
    required: {
      value: true,
      message: "Name is required"
    }
  },
  phone_number: {
    phone: {
      value: true,
      message: "Invalid Phone No."
    }
  },
  email: {
    required: {
      value: true,
      message: "Email is required"
    }
  },
  message: {
    required: {
      value: true,
      message: "Message is required"
    },
    maxLength: {
      value: 500,
      message: "Message should be 500 characters or less"
    }
  }
};

const ContactForm = props => {
  const { handleSubmit, pristine, submitting, showHeader } = props;
  return (
    <form onSubmit={handleSubmit} className="form contactForm">
      {showHeader && <h3>Contact Us</h3>}
      <div className="fields">
        <Field
          name="name"
          component={StandardTextField}
          type="text"
          placeholder="Name"
        />
        <Field
          name="phone_number"
          component={PhoneInputField}
          type="tel"
          placeholder="Phone"
        />
        <Field
          name="company"
          component={StandardTextField}
          type="text"
          placeholder="Company"
        />
        <Field
          name="email_address"
          component={StandardTextField}
          type="text"
          placeholder="Email"
        />
        <Field
          name="message"
          component={StandardTextareaField}
          type="text"
          placeholder="Message..."
        />
      </div>
      <div className="actions">
        <button
          className="full"
          type="submit"
          disabled={pristine || submitting}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "ContactForm",
  validate: Validator(validations),
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(ContactForm);
