import axios from "axios";
import {
  saveAuthToken,
  getAuthToken,
  getAdminAuthToken,
  saveAdminAuthToken
} from "Api";

const Service = (
  headerFunc = () => {},
  saveAuthFunc = () => {},
  useInjection = false
) => {
  class WrappedService {
    service = () => {
      let service = axios.create({
        headers: headerFunc()
      });
      service.interceptors.response.use(this.handleSuccess, this.handleError);
      return service;
    };

    handleSuccess = response => {
      if (useInjection) {
        saveAuthFunc(response.headers);
      }
      return response;
    };

    handleError = error => {
      if (useInjection) {
        switch (error.response.status) {
          case 401:
            this.redirectTo(document, "/login");
            break;
          case 404:
            this.redirectTo(document, "/notFound");
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    };
    redirectTo = (document, path) => {
      document.location = path;
    };

    get(path, params) {
      return this.service().get(path, {
        params
      });
    }

    delete(path, options) {
      return this.service().delete(path, options);
    }

    patch(path, payload) {
      return this.service().request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload
      });
    }

    put(path, payload, options = {}) {
      return this.service().request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
        params: options.params
      });
    }

    post(path, payload) {
      return this.service().request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload
      });
    }
    all(promises) {
      return Promise.all(promises);
    }
    spread(callback) {
      return arr => {
        return callback.apply(null, arr);
      };
    }
  }
  return WrappedService;
};

const ServiceClass = Service(getAuthToken, saveAuthToken, true);
export default new ServiceClass();

const AdminServiceClass = Service(getAdminAuthToken, saveAdminAuthToken, true);
export const adminService = new AdminServiceClass();

const StandardServiceClass = Service();
export const standardService = new StandardServiceClass();
