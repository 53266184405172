import React from "react";
import { Helmet } from "react-helmet";

const PageMetaData = ({ setting, isProduct }) => (
    <Helmet>
        {setting.gaDescription && (
            <meta 
                name="description" 
                content={setting.gaDescription} 
            />    
        )}
        {setting.gaKeywords && (
            <meta 
                name="keywords" 
                content={setting.gaKeywords} 
            />    
        )}
        {setting.seoTitle && (
            <title>
                {setting.seoTitle}
            </title>
        )}            
        {isProduct && setting.name && (
            <title>
               {setting.name}
            </title>
        )}
    </Helmet>
)

export default PageMetaData;