import { Component } from 'react';
import React from 'react';
class ShopBreadCrumbs extends Component {
  renderBreadCrumbs = () => {
    const { currentCategory, selectedSubs } = this.props;
    if (!currentCategory) return;
    if (selectedSubs.length > 0) {
      const subCatName = currentCategory.sfSubCategories.find(
        (subCat) => subCat.id === parseInt(selectedSubs[0])
      ).name;
      return (
        <div className='colorAGroup'>
          <a href='/shop'>Shop</a> &#62;{' '}
          <a href={`/shop/${currentCategory.slug}`}>{currentCategory.name}</a>{' '}
          &#62;{' '}
          <a>{selectedSubs.length === 1 ? subCatName : 'Custom Filter'}</a>
        </div>
      );
    } else {
      return (
        <div className='colorAGroup'>
          <a href='/shop'>Shop</a> &#62; <a>{currentCategory.name}</a>
        </div>
      );
    }
  };

  render() {
    return <div>{this.renderBreadCrumbs()}</div>;
  }
}

export default ShopBreadCrumbs;
