import React, { Component } from "react";
import { DatePickerInput } from "rc-datepicker";
import classnames from "classnames";

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(jsDate, dateString) {
    if (dateString !== "Invalid date") {
      const FakeEvent = {
        target: {
          value: jsDate,
          name: this.props.name
        }
      };
      this.props.onChange(FakeEvent);
    }
  }

  render() {
    const { onChange, value, className, ...other } = this.props;
    return (
      <DatePickerInput
        className={classnames({
          error: className
        })}
        showOnInputClick={true}
        showInputButton={false}
        onChange={this.handleChange}
        minDate={new Date()}
        value={value}
        autoComplete="off"
        {...other}
      />
    );
  }
}
