import * as actionTypes from "Constants/redux";

function CalendarReducer(
  state = {
    selectedDate: new Date()
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_DATE:
      return Object.assign({}, state, {
        selectedDate: action.payload.selectedDate
      });
    case actionTypes.FETCH_CALENDAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.FETCH_CALENDAR_SUCCESS:
      return {
        ...state,
        datesArray: action.payload.schedules,
        loading: false
      };
    case actionTypes.FETCH_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default CalendarReducer;
