import classnames from "classnames";
import React from "react";

const renderField = props => {
  const {
    input,
    placeholder,
    className,
    meta: { touched, error }
  } = props;
  return (
    <textarea
      {...input}
      placeholder={placeholder}
      className={classnames({
        [className]: className,
        error: touched && error
      })}
    />
  );
};
renderField.defaultProps = {
  meta: {}
};

export default renderField;
