import {
  SET_CART_PROPS,
  SET_EVENT_PROPS,
  SET_BEFORE_ATTRIBUTES,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE
} from "Constants/redux";
import axios from "axios";
import { getAuthToken } from "Api";
import converter from "json-style-converter/es5";
import { combineDateAndTime } from "HelperFunctions/general";
import { getDamageWaiverFee } from "HelperFunctions/rentals";
import { setErrors } from "./SnackbarActions";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";
import moment from "moment";

export const fetchEvent = id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_EVENT_REQUEST
    });
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${id}`, {
        headers: getAuthToken()
      })
      .then(response => {
       const { location } = getState().locations;
        dispatch({
          type: FETCH_EVENT_SUCCESS,
          payload: response.data.rental
        });
        dispatch({
          type: SET_BEFORE_ATTRIBUTES,
          payload: build_rails_object(getState())
        });
        dispatch({
          type: SET_CART_PROPS,
          payload: {...response.data.rental, editingEvent: true, location }
        });
        getState();
      })
      .catch(error => {
        const errors = error.response.data;
        dispatch({
          type: FETCH_EVENT_FAILURE
        });
        dispatch(setErrors(errors));
      });
  };
};

const build_rails_object = state => {
  const { event } = state.event;
  const { customer } = state.customer;
  const {
    items,
    rentalBundles,
    addOns,
    eventStart,
    eventStartTime,
    eventEnd,
    eventEndTime,
    deliveryAddressStreetAddress1,
    deliveryAddressStreetAddress2,
    deliveryCost,
    deliveryType,
    ...other
  } = event;
  const itemsRailsObject = items.reduce((holder, item, currentIndex) => {
    if (item.type !== "RentalItemTemporary") {
      item.flatPricesAttributes = item.flatPrices;
      item.rentalItemCustomTaxRelationshipAttributes = item.rentalItemCustomTaxRelationship;
      const formattedItem = converter.camelToSnakeCase(item);
      holder[currentIndex] = formattedItem;
    }
    return holder;
  }, {});

  const tempItemsRailsObject = items.reduce((holder, item, currentIndex) => {
    if (item.type === "RentalItemTemporary") {
      const formattedItem = converter.camelToSnakeCase(item);
      holder[currentIndex] = formattedItem;
    }

    return holder;
  }, {});
  const rentalAddOnsRailsObject = addOns.reduce(
    (holder, item, currentIndex) => {
      const formattedItem = converter.camelToSnakeCase(item);
      holder[currentIndex] = formattedItem;
      return holder;
    },
    {}
  );
  const rentalBundlesRailsObject = rentalBundles.reduce(
    (holder, item, currentIndex) => {
      item.flatPricesAttributes = item.flatPrices;
      for(let rentalItem of item.rentalItems) {
        if (rentalItem.hasOwnProperty("flatPrices")) {
          rentalItem["flatPricesAttributes"] = rentalItem.flatPrices;
        }
      }
      const formattedItem = converter.camelToSnakeCase(item);
      holder[currentIndex] = formattedItem;
      return holder;
    },
    {}
  );

  const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
  const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
  const scheduleRailsObject = {
    id: event.schedule ? event.schedule.id : "",
    event_start_date_time: eventStartDateTime,
    event_end_date_time: eventEndDateTime,
    start_date_time: eventStartDateTime,
    end_date_time: eventEndDateTime,
    start_window_finish: eventStartDateTime,
    end_window_beginning: eventEndDateTime,
    off_shelf_at: eventStartDateTime,
    on_shelf_at: eventEndDateTime,
    same_as_event_date: true,
    time_zone: moment.tz.guess(eventStartDateTime)
  };
  const customerRelationshipsObject = {
    "0": {
      client_id: customer.customerLocationRelationshipId,
      client_type: "CustomerLocationRelationship"
    }
  };
  const otherRailsObject = converter.camelToSnakeCase(other);
  return Object.assign(
    {
      ...otherRailsObject,
      delivery_type: deliveryType,
      rental_item_standards_attributes: itemsRailsObject,
      rental_item_temporaries_attributes: tempItemsRailsObject,
      rental_bundles_attributes: rentalBundlesRailsObject,
      rental_add_ons_attributes: rentalAddOnsRailsObject,
      schedule_attributes: scheduleRailsObject,
      delivery_address_street_address_1: deliveryAddressStreetAddress1,
      delivery_address_street_address_2: deliveryAddressStreetAddress2,
      delivery_cost: deliveryCost
    },
    !event.id && {
      customer_rental_relationships_attributes: customerRelationshipsObject
    },
    deliveryType !== "default_delivery" && {
      delivery_cost: deliveryCost
    }
  );
};

export const editSubmit = onSuccess => {
  return (dispatch, getState) => {
    const { event } = getState().event;
    const { location } = getState().locations;
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner("Updating rental..."));
    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/" + event.id,
        {
          location_id: location.id,
          rental: data
        },
        { headers: getAuthToken() }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess();
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const requestChanges = onSuccess => {
  return (dispatch, getState) => {
    const { event, beforeAttributes } = getState().event;
    const afterAttributes = build_rails_object(getState());
    const data = {
      before_attributes: JSON.stringify(beforeAttributes),
      after_attributes: JSON.stringify(afterAttributes)
    };

    dispatch(openLoadingSpinner("Requesting changes..."));
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${event.id}/request_changes`,
        data,
        { headers: getAuthToken() }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess(response.data.rental);
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const calculateDefaultDeliveryPrice = ({ event, onSuccess }) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const {
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      items,
      rentalBundles
    } = event;

    if (event.deliveryType === "customer_pick_up") {
      onSuccess(0);
      return;
    }

    if (
      deliveryAddressCity &&
      deliveryAddressLocale &&
      deliveryAddressStreetAddress1 &&
      deliveryAddressPostalCode &&
      deliveryAddressCountry &&
      (items.length > 0 || rentalBundles.length > 0)
    ) {
      let sub_total = 0;
      for (let item of items) {
        sub_total += Number(item.selectedPrice);
      }
      for (let bundle of rentalBundles) {
        sub_total += Number(bundle.selectedPrice);
      }
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN +
            "/api/portal/delivery_setting/calculate_delivery_cost",
          {
            location_id: location.id,
            calculation: {
              sub_total: sub_total,
              street_address_1: deliveryAddressStreetAddress1,
              street_address_2: deliveryAddressStreetAddress2,
              city: deliveryAddressCity,
              locale: deliveryAddressLocale,
              postal_code: deliveryAddressPostalCode,
              country: deliveryAddressCountry
            }
          }
        )
        .then(response => {
          onSuccess(response.data);
        })
        .catch(error => {
          console.log(error);
          dispatch(setErrors(error.response.data));
        });
    } else {
      const errors = Object.assign(
        {},
        !deliveryAddressStreetAddress1 && {
          checkin: "Please specify a delivery street address."
        },
        !deliveryAddressCity && { city: "Please specify a delivery city." },
        !deliveryAddressLocale && {
          locale: "Please specify a delivery state."
        },
        !deliveryAddressPostalCode && {
          postal_code: "Please specify a delivery zip code."
        },
        !deliveryAddressCountry && {
          country: "Please specify a delivery country."
        },
        items.length === 0 &&
          rentalBundles.length === 0 && { items: "Must select items to rent." }
      );
      dispatch(setErrors(errors));
    }
  };
};
export const setEventProps = newProps => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { event } = getState().event;
    const newEvent = { ...event, ...newProps };
    const newDamageWaiverFee = getDamageWaiverFee(newEvent, location);
    dispatch({
      type: SET_EVENT_PROPS,
      payload: { ...newProps, damageWaiverFeeTotal: newDamageWaiverFee, location }
    });
  };
};
