import queryString from "query-string";

const strictUriEncode = str =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    x =>
      `%${x
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  );
const encode = (value, options) => {
  if (options.encode) {
    return strictUriEncode(value);
  }

  return value;
};

const encoderForArrayFormat = options => {
  return (key, value) => {
    return value === null
      ? encode(key, options)
      : [encode(key, options), "=", encode(value, options)].join("");
  };
};

export const stringify = obj => {
  let newObj = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== "") {
      newObj[key] = value;
    }
  });

  return queryString.stringify(newObj);
};

export const parseQuery = query => {
  return queryString.parse(query);
};
