import React from 'react';
import SmartNavLink from 'Utils/SmartNavLink';
import OrderAgreementLink from './OrderAgreementLink';
import classnames from 'classnames';
import { canAccessStorefrontFeature } from 'HelperFunctions/storefrontSettingAccessibility';

class Navs extends React.Component {
  state = {
    menuOpen: {},
  };
  closeMenu = (link) => {
    this.setState({
      menuOpen: {
        ...this.state.menuOpen,
        [link]: false,
      },
    });
  };
  openMenu = (link) => {
    this.setState({
      menuOpen: {
        [link]: true,
      },
    });
  };

  render() {
    const {
      menuItems,
      className,
      showRentalAgreement,
      showGtl,
      theme,
      sfSubscriptionTier,
    } = this.props;
    const { menuOpen } = this.state;
    let navs = [];
    let menuCharactersCount = 0;
    let surplusMenuItems = [];
    let maxNavCharacterCount = 0;

    if (theme === 'preScott') {
      maxNavCharacterCount = 60;
    } else if (theme === 'wisteria' || theme === 'wisteriaDark') {
      maxNavCharacterCount = 120;
    } else {
      maxNavCharacterCount = 70;
    }

    menuItems.forEach((menuItem) => {
      menuCharactersCount += menuItem.menuTitle.length;
      const link = menuItem.menuLink;
      const displayItem = canAccessStorefrontFeature(link, sfSubscriptionTier);

      if (!displayItem) return null;

      if (menuCharactersCount < maxNavCharacterCount) {
        if (menuItem.childMenuItems.length === 0) {
          // If no child menu items
          navs.push(
            <SmartNavLink
              link={link}
              key={navs.length + 1}
              target={menuItem.openInNewTab ? '_blank' : ''}
            >
              {menuItem.menuTitle}
            </SmartNavLink>
          );
        } else {
          // If child menu items present?
          navs.push(
            <div
              key={navs.length + 1}
              onMouseEnter={() => this.openMenu(link)}
              onMouseLeave={() => this.closeMenu(link)}
              onClick={() => this.closeMenu(link)}
            >
              <SmartNavLink
                exact
                link={link}
                target={menuItem.openInNewTab ? '_blank' : ''}
              >
                {menuItem.menuTitle}
              </SmartNavLink>
              {menuOpen[link] && (
                <nav>
                  {menuItem.childMenuItems.map((childMenuItem, index) => (
                    <SmartNavLink
                      key={index}
                      exact
                      link={childMenuItem.menuLink}
                      target={childMenuItem.openInNewTab ? '_blank' : ''}
                    >
                      {childMenuItem.menuTitle}
                    </SmartNavLink>
                  ))}
                </nav>
              )}
            </div>
          );
        }
      } else {
        surplusMenuItems.push(menuItem);
        menuItem.childMenuItems.forEach((childMenuItem) => {
          surplusMenuItems.push(childMenuItem);
        });
      }
    });

    if (surplusMenuItems.length > 0) {
      navs.push(
        <div
          className='surplusItems'
          onMouseEnter={() => this.openMenu('three-dot')}
          onMouseLeave={() => this.closeMenu('three-dot')}
          onClick={() => this.closeMenu('three-dot')}
        >
          {'...'}
          {menuOpen['three-dot'] && (
            <nav
              onMouseEnter={() => this.openMenu('three-dot')}
              onMouseLeave={() => this.closeMenu('three-dot')}
            >
              {surplusMenuItems.map((menuItem) => (
                <SmartNavLink
                  exact
                  link={menuItem.menuLink}
                  target={menuItem.openInNewTab ? '_blank' : ''}
                >
                  {menuItem.menuTitle}
                </SmartNavLink>
              ))}
            </nav>
          )}
        </div>
      );
    }

    return (
      <div
        className={classnames({
          [className]: className,
        })}
      >
        <nav>{navs}</nav>
        {showGtl && (
          <nav className='rentalAgreement'>
            <SmartNavLink link='/GettheLook'>Get the Look</SmartNavLink>
          </nav>
        )}
        {showRentalAgreement && <OrderAgreementLink />}
      </div>
    );
  }
}

export default Navs;
