import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Validator from "HelperFunctions/validator";

// Redux-form validations written in json

const validations = {
  password: {
    required: {
      value: true,
      message: "Password is required"
    },
    minLength: {
      value: 6,
      message: "Password must be at least 6 characters"
    }
  },
  phone: {
    required: {
      value: true,
      message: "Phone number is required"
    }
  }
};

class SignUpGuestForm extends React.Component {
  render() {
    const { handleSubmit, email, pristine, submitting } = this.props; // From Redux

    return (
      <div className="signUpForm">
        <p>Email: {email}</p>
        <form className="form" onSubmit={handleSubmit}>
          <div className="fields">
            <Field
              name="firstName"
              label="First Name"
              placeholder="First Name"
              component={StandardTextField}
              type="text"
            />
            <Field
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              component={StandardTextField}
              type="text"
            />
            <Field
              name="phone"
              label="Phone*"
              placeholder="Phone Number"
              component={StandardTextField}
              type="text"
            />

            <Field
              name="password"
              label="Password*"
              placeholder="Password"
              component={StandardTextField}
              type="password"
            />
          </div>
          <div className="actions">
            <button
              type="submit"
              className="btn full"
              disabled={pristine || submitting}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

// Because we are using Redux-form plus our own redux state,
// this is a 3 tier HOC. The connect HOC takes the reduxForm as a paramater.
// The reduxForm HOC takes the form and Validators as parameters.
// The whole thing returns a SignUpGuestForm HOC with access to Redux and performing validations
// on the form under the hood.

export default reduxForm({
  form: "SignUpGuest",
  validate: Validator(validations),
  enableReinitialize: true
})(SignUpGuestForm);
