/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  renderItemQty,
  extractFeeFromPrice,
  isItem,
  renderItemSpecificFee,
} from 'HelperFunctions/rentals';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { Items, RemoveCircle, DollarsCircle } from 'Utils/SvgIcons';
import Imgix from 'react-imgix';
import { localeToCurrencySymbol } from 'Constants/currencyConstants';
import CurrencyFormatter from 'HelperFunctions/CurrencyFormatter';
import { formatSmartPrincingLabel } from 'HelperFunctions/smartPricing';

class RentalItem extends React.Component {
  renderItemPeriodPrice = () => {
    const {
      rentalItem,
      index,
      sectionIndex,
      removeDuplicatedItem,
      editing,
      parentConnectionType,
      location: { locale, displayDiscount },
      rental,
    } = this.props;
    const currencySymbol = localeToCurrencySymbol[locale];
    const smartPricingActive = rental.smartPricingActive;
    const item = rentalItem;
    const hasRentalItemDiscountRelationship =
      !!item.rentalItemDiscountRelationships?.length;

    let periodPriceAfterDiscount = item.selectedPriceBeforeDiscount;
    if (isItem(item)) {
      periodPriceAfterDiscount = extractFeeFromPrice(
        item,
        item.selectedPriceBeforeDiscount,
        item.selectedPrice,
        item.quantity
      );
      periodPriceAfterDiscount = `${currencySymbol}${(
        periodPriceAfterDiscount /
        +item.quantity /
        (item.duration || 1)
      ).toFixed(2)}`;

      periodPriceAfterDiscount += ` /${item.periodPrice.split('/')[1]}`;
    }
    if (parentConnectionType === 'duplicate') {
      return (
        <td className='medSm right normalText'>
          Duplicate
          {editing && (
            <a
              className='btnLink delete'
              onClick={() => removeDuplicatedItem(sectionIndex, index)}
              style={{ paddingLeft: '5px' }}
            >
              <RemoveCircle />
            </a>
          )}
        </td>
      );
    } else if (!displayDiscount || !rental.rentalSetting.showDiscountedUnitPricingToCustomers) {
      if (!rental.rentalSetting.showDiscountedUnitPricingToCustomers || rental.rentalSetting.discountSetting === 'hideDiscounts') {
        return (
          <td className='medSm right normalText'>
            {hasRentalItemDiscountRelationship && (
              isItem(item) ? (
                <div>{item.periodPrice}</div>
              ) : (
                <div>
                  <CurrencyLabel
                    value={
                      item.selectedPrice
                        ? item.selectedPrice / item.quantity
                        : 0
                    }
                  />
                </div>
              )
            )}
          </td>
        );
      } else if (rental.rentalSetting.discountSetting === 'strikeThrough') {
        return (
          <td className='medSm right normalText'>
            {!smartPricingActive &&
              hasRentalItemDiscountRelationship &&
              (isItem(item) ? (
                <div
                  className={classnames({
                    strikeThrough:
                      item.periodPrice !== periodPriceAfterDiscount,
                  })}
                >
                  {item.periodPrice}
                </div>
              ) : (
                <div>
                  <CurrencyLabel
                    value={
                      item.selectedPrice
                        ? item.selectedPrice / item.quantity
                        : 0
                    }
                  />
                </div>
              ))}
            {periodPriceAfterDiscount}
          </td>
        );
      } else {
        return (
          <td className='medSm right normalText'>
            {isItem(item) ? (
              <div>{periodPriceAfterDiscount}</div>
            ) : (
              <div>
                <CurrencyLabel
                  value={item.selectedPrice ? item.selectedPrice : 0}
                />
              </div>
            )}
          </td>
        );
      }
    } else if (rental.rentalSetting.showDiscountedUnitPricingToCustomers) {
      return (
        <td className='medSm right normalText'>
          {!smartPricingActive &&
            hasRentalItemDiscountRelationship &&
            (isItem(item) ? (
              <div
                className={classnames({
                  strikeThrough: item.periodPrice !== periodPriceAfterDiscount,
                })}
              >
                {item.periodPrice}
              </div>
            ) : (
              <div>
                <CurrencyLabel
                  value={
                    item.selectedPrice ? item.selectedPrice / item.quantity : 0
                  }
                />
              </div>
            ))}
          {periodPriceAfterDiscount}
        </td>
      );
    } else {
      return (
        <td className='medSm right normalText'>
          {isItem(item) ? (
            <div>{periodPriceAfterDiscount}</div>
          ) : (
            <div>
              <CurrencyLabel
                value={item.selectedPrice ? item.selectedPrice : 0}
              />
            </div>
          )}
        </td>
      );
    }
  };
  renderItemSelectedPrice = () => {
    const {
      rentalItem,
      storefrontShopSetting,
      approvalStatus,
      sectionMembership,
      rental,
      location: { displayDiscount },
    } = this.props;
    const item = rentalItem;
    const hasRentalItemDiscountRelationship =
      !!item.rentalItemDiscountRelationships?.length;
    const quantity = sectionMembership.quantity || item.quantity;
    let selectedPrice = item.selectedPrice;

    if (
      approvalStatus !== 'approved' &&
      !storefrontShopSetting.shopShowItemPricing
    ) {
      return <td className='medSm right' />;
    }
    if (isItem(item)) {
      selectedPrice = extractFeeFromPrice(
        item,
        item.selectedPriceBeforeDiscount,
        item.selectedPrice,
        quantity
      );
    }

    if (sectionMembership.parentConnectionType === 'duplicate') {
      return <td className='medSm right'>N/A</td>;
    } else if (!displayDiscount) {
      if (rental.rentalSetting.discountSetting === 'strikeThrough') {
        return (
          <td className='medSm right'>
            {isItem(item) &&
              item.selectedPriceBeforeDiscount !== item.selectedPrice &&
              hasRentalItemDiscountRelationship && (
                <div className='strikeThrough'>
                  <CurrencyLabel value={item.selectedPriceBeforeDiscount} />
                </div>
              )}
            <br />
            {selectedPrice && <CurrencyLabel value={selectedPrice} />}
          </td>
        );
      } else if (rental.rentalSetting.discountSetting === 'discountedOnly') {
        return (
          <td className='medSm right'>
            {item.selectedPrice && <CurrencyLabel value={item.selectedPrice} />}
          </td>
        );
      } else {
        return (
          <td className='medSm right'>
            {item.selectedPriceBeforeDiscount && (
              <CurrencyLabel value={item.selectedPriceBeforeDiscount} />
            )}
          </td>
        );
      }
    } else if (rental.rentalSetting.showDiscountsOnPricing) {
      return (
        <td className='medSm right'>
          {isItem(item) &&
            item.selectedPriceBeforeDiscount !== item.selectedPrice &&
            hasRentalItemDiscountRelationship && (
              <div className='strikeThrough'>
                <CurrencyLabel value={item.selectedPriceBeforeDiscount} />
              </div>
            )}
          <br />
          {item.selectedPrice && <CurrencyLabel value={item.selectedPrice} />}
        </td>
      );
    } else {
      return (
        <td className='medSm right'>
          {item.selectedPrice && <CurrencyLabel value={item.selectedPrice} />}
        </td>
      );
    }
  };

  render() {
    const {
      rentalItem,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      tileView,
      approvalStatus,
      storefrontShopSetting,
      sectionMembership,
    } = this.props;
    const product = rentalItem.product || rentalItem.addOn;
    const itemNotesVisibleToCustomer = rentalItem.itemNotes
      ? rentalItem.itemNotes.filter(
          (itemNote) => itemNote.shouldIncludeNotesInRentals
        )
      : [];
    return (
      <MediaBreakpoints
        desktop={
          <tbody className='itemContent'>
            <tr
              className={classnames({
                bottom: showItemNotes && itemNotesVisibleToCustomer.length > 0,
              })}
            >
              {tileView && showItemImages && (
                <td rowSpan='2' className='image'>
                  <figure>
                    {product &&
                    product.pictures[0] &&
                    product.pictures[0].imgixUrl ? (
                      <Imgix
                        src={product.pictures[0].imgixUrl}
                        alt={rentalItem.name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <Items />
                    )}
                  </figure>
                </td>
              )}
              <td className='normalText'>
                {rentalItem.name}
                {showItemNotes &&
                  itemNotesVisibleToCustomer?.map((itemNote) => (
                    <span className='caption' style={{ display: 'block' }}>
                      {itemNote.message}
                    </span>
                  ))}
              </td>
              <td
                className={classnames({
                  right: true,
                  alert: rentalItem.hasShortage,
                })}
              >
                {renderItemQty(rentalItem, sectionMembership.quantity)}
              </td>
              {approvalStatus === 'approved' &&
                showUnitPricingToCustomers &&
                storefrontShopSetting.shopShowItemPricing &&
                this.renderItemPeriodPrice()}
              <td className='right fee'>
                {rentalItem.rentalItemCustomTaxRelationship && (
                  <DollarsCircle className='dollar' />
                )}
                {renderItemSpecificFee(rentalItem)}
              </td>
              {this.renderItemSelectedPrice()}
            </tr>
          </tbody>
        }
        mobile={
          <tbody className='mobileItemContent'>
            {tileView && showItemImages && (
              <tr>
                <td colSpan={showUnitPricingToCustomers ? '3' : '2'}>
                  <figure>
                    {product &&
                    product.pictures[0] &&
                    product.pictures[0].imgixUrl ? (
                      <Imgix
                        src={product.pictures[0].imgixUrl}
                        alt={rentalItem.name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <Items />
                    )}
                  </figure>
                </td>
              </tr>
            )}
            <tr
              className={classnames({
                noBorder: tileView && showItemImages,
              })}
            >
              <td colSpan={showUnitPricingToCustomers ? '3' : '2'}>
                {rentalItem.name}
              </td>
            </tr>
            <tr className='noBorder'>
              <td
                className={classnames({
                  alert: rentalItem.hasShortage,
                })}
              >
                {renderItemQty(rentalItem, sectionMembership.quantity)}
              </td>
              {approvalStatus === 'approved' &&
                showUnitPricingToCustomers &&
                storefrontShopSetting.shopShowItemPricing &&
                this.renderItemPeriodPrice()}
              <td className='right'>{renderItemSpecificFee(rentalItem)}</td>
              <td className='right fee'>{this.renderItemSelectedPrice()}</td>
            </tr>
            {showItemNotes &&
              itemNotesVisibleToCustomer?.map((itemNote) => (
                <tr className='top noBorder'>
                  <td
                    colSpan={showUnitPricingToCustomers ? '3' : '2'}
                    className='caption'
                  >
                    {itemNote.message}
                  </td>
                </tr>
              ))}
          </tbody>
        }
      />
    );
  }
}
const mapStateToProps = (state) => {
  const { parsedStorefrontSettings, location } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;

  return { storefrontShopSetting, location };
};

export default connect(mapStateToProps)(RentalItem);
