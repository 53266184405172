import {
  SET_EVENT_PROPS,
  SET_BEFORE_ATTRIBUTES,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
} from 'Constants/redux';
import moment from 'moment';
import { formatEvent } from './CartReducer';

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const INITIAL_STATE = {
  event: {
    eventStart: new Date(),
    eventStartTime: new Date(),
    eventEnd: tomorrow,
    eventEndTime: tomorrow,
    timeZone: '',
    name: '',
    items: [],
    rentalBundles: [],
    addOns: [],
    deliveryType: 'customer_pick_up',
    deliveryCost: 0,
    deliveryAddressLocationName: '',
    deliveryAddressStreetAddress1: '',
    deliveryAddressStreetAddress2: '',
    deliveryAddressCity: '',
    deliveryAddressLocale: '',
    deliveryAddressPostalCode: '',
    deliveryAddressCountry: '',
    customerContactPhone: '',
    venue: {
      name: '',
      cell: '',
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      locale: '',
      postalCode: '',
      country: '',
    },
  },
  beforeAttributes: null,
  loading: false,
};

export const formatEventItems = (event) => {
  const items = event.rentalItems.map((item) => {
    return item;
  });

  const addOns = event.rentalAddOns.map((item) => {
    return item;
  });

  const rentalBundles = event.rentalBundles.map((item) => {
    return item;
  });

  return {
    items,
    addOns,
    rentalBundles,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENT_REQUEST:
      return { ...state, loading: true };
    case FETCH_EVENT_FAILURE:
      return { ...state, loading: true };
    case FETCH_EVENT_SUCCESS:
      const event = action.payload;
      const location = event.location;
      const formattedItems = formatEventItems(event, location);

      const {
        id,
        token,
        name,
        deliveryType,
        deliveryCost,
        deliveryAddressLocationName,
        deliveryAddressStreetAddress1,
        deliveryAddressStreetAddress2,
        deliveryAddressCity,
        deliveryAddressLocale,
        deliveryAddressPostalCode,
        deliveryAddressCountry,
        customerContactPhone,
        taxTotal,
        damageWaiverFeeTotal,
        pickupAddressCity,
        pickupAddressCountry,
        pickupAddressLocale,
        pickupAddressLocationName,
        pickupAddressPostalCode,
        pickupAddressStreetAddress1,
        pickupAddressStreetAddress2,
        pickupSameAsDelivery,
      } = event;
      const newObject = Object.assign(
        state.event,
        {
          id,
          token,
          name,
          deliveryType,
          deliveryCost,
          deliveryAddressLocationName,
          deliveryAddressStreetAddress1,
          deliveryAddressStreetAddress2,
          deliveryAddressCity,
          deliveryAddressLocale,
          deliveryAddressPostalCode,
          deliveryAddressCountry,
          customerContactPhone,
          taxTotal,
          damageWaiverFeeTotal,
          pickupAddressCity,
          pickupAddressCountry,
          pickupAddressLocale,
          pickupAddressLocationName,
          pickupAddressPostalCode,
          pickupAddressStreetAddress1,
          pickupAddressStreetAddress2,
          pickupSameAsDelivery,
        },
        {
          items: formattedItems.items,
          addOns: formattedItems.addOns,
          rentalBundles: formattedItems.rentalBundles,
          eventStart: new Date(event.schedule.eventStartDateTime),
          eventStartTime: new Date(event.schedule.eventStartDateTime),
          eventEnd: new Date(event.schedule.eventEndDateTime),
          eventEndTime: new Date(event.schedule.eventEndDateTime),
          schedule: { id: event.schedule.id },
          timeZone: moment.tz.guess(event.schedule.eventStartDateTime),
        },
        event.venueRentalRelationship && {
          venue: event.venueRentalRelationship.venue,
        }
      );
      return { ...state, event: newObject, loading: false };
    case SET_EVENT_PROPS:
      const formattedEvent = formatEvent(state.event, action.payload.location);
      const newEvent = {
        ...formattedEvent,
        ...action.payload,
      };
      return { ...state, event: newEvent };
    case SET_BEFORE_ATTRIBUTES:
      return { ...state, beforeAttributes: action.payload };
    default:
      return state;
  }
};
