import React from "react";
import { Route, Switch } from "react-router-dom";
import EventContainer from "./RentalContainer";
import EventsIndex from "./EventsIndex";
import EditEvent from "./EditEvent";

const EventsSection = props => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={EventsIndex} />
      <Route
        exact
        path={`${props.match.path}/quotes`}
        render={props => {
          return <EventsIndex {...props} type="quotes" />;
        }}
      />
      <Route
        exact
        path={`${props.match.path}/upcoming`}
        render={props => {
          return <EventsIndex {...props} type="upcoming" />;
        }}
      />
      <Route
        exact
        path={`${props.match.path}/past`}
        render={props => {
          return <EventsIndex {...props} type="past" />;
        }}
      />
      <Route
        exact
        path={`${props.match.path}/cancelled`}
        render={props => {
          return <EventsIndex {...props} type="cancelled" />;
        }}
      />
      <Route
        exact
        path={`${props.match.path}/:id`}
        component={EventContainer}
      />
      <Route
        exact
        path={`${props.match.path}/:id/edit`}
        component={EditEvent}
      />
    </Switch>
  );
};

export default EventsSection;
