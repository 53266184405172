/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import CartBilling from "./CartBilling";
import EventSummary from "./EventSummary";
import { CartCheck } from "Utils/SvgIcons";
import { history as browserHistory } from "Components/Routes";

class CartConfirmation extends React.Component {
  componentDidMount() {
    const { newEvent } = this.props;
    if (!newEvent.id) {
      browserHistory.push("/cart");
    }
  }
  renderLeftSection = step => {
    const { newEvent } = this.props;
    return <EventSummary event={newEvent} cartConfirmation={true}/>;
  };
  handleTrackProgress = () => {
    const { newEvent } = this.props;
    browserHistory.push(`/events/${newEvent.id}`);
  };
  renderNextButton = () => {
    return (
      <div>
        <a
          className="btn secondary btnTrackProgress"
          onClick={this.handleTrackProgress}
        >
          Track Progress
        </a>
      </div>
    );
  };
  render() {
    const { authenticated } = this.props;
    const step = 4;

    return (
      <div className="cart">
        <div className="content">
          <section
            className={classnames({
              progress: true,
              complete: true
            })}
          >
            <ul>
              <li
                className={classnames({
                  active: false,
                  complete: true
                })}
              >
                <a>
                  <CartCheck />
                  <label>Build</label>
                </a>
              </li>
              <li
                className={classnames({
                  active: false,
                  complete: true
                })}
              >
                <a>
                  <CartCheck />
                  <label>Delivery</label>
                </a>
              </li>
              <li
                className={classnames({
                  active: false,
                  complete: true
                })}
              >
                <a>
                  <CartCheck />
                  <label>Review & Submit</label>
                </a>
              </li>
            </ul>
            {authenticated && this.renderNextButton()}
          </section>
          {this.renderLeftSection(step)}
          <CartBilling step={step} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { newEvent, event } = state.cart;
  const { authenticated } = state.auth;
  return { newEvent, event, authenticated };
};

export default withRouter(connect(mapStateToProps, actions)(CartConfirmation));
