import React from 'react';
import { connect } from 'react-redux';
import TestimonialBox from './widgets/TestimonialBox';
import classnames from 'classnames';
import Imgix from 'react-imgix';
import PageMetaData from 'Utils/PageMetaData';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class Aboutus extends React.Component {
  render() {
    const { storefrontSetting, themeSetting } = this.props;
    const {
      aboutusHeroTitle,
      aboutusHeroDescription,
      aboutusPicture,
      aboutusShowTeam,
      aboutusTeamTitle,
      aboutusTeamMembersCount,
      aboutusTeamMembers,
      aboutusShowTestimonials,
      teamTitleFontTheme,
      aboutusTitleFontTheme,
    } = storefrontSetting;
    return (
      <div className='aboutUs'>
        <PageMetaData setting={storefrontSetting} />
        <div className='summary'>
          <figure>
            {aboutusPicture && (
              <Imgix
                src={aboutusPicture.imgixUrl}
                alt={aboutusHeroTitle}
                fit="max"
                width={1024}
                height={960}
              />
            )}
          </figure>
          <div>
            <h1
              style={{
                color: `${
                  aboutusTitleFontTheme === 'dark'
                    ? themeSetting.darkFontColor
                    : themeSetting.lightFontColor
                }`,
              }}
            >
              {aboutusHeroTitle}
            </h1>
            <div
              className='aboutUsText'
              dangerouslySetInnerHTML={{
                __html: aboutusHeroDescription,
              }}
            />
          </div>
        </div>
        {aboutusShowTeam && (
          <div className='teamMembers'>
            <h1
              style={{
                color: `${
                  teamTitleFontTheme === 'dark'
                    ? themeSetting.darkFontColor
                    : themeSetting.lightFontColor
                }`,
              }}
            >
              {aboutusTeamTitle}
            </h1>
            <div
              className={classnames({
                team: true,
                ['col' + aboutusTeamMembersCount]: true,
              })}
            >
              {aboutusTeamMembers.map((member) => (
                <div key={member.id}>
                  <figure>
                    {member.picture && (
                      <Imgix
                        src={member.picture.imgixUrl}
                        alt={member.name}
                        height={300}
                        width={300}
                      />
                    )}
                  </figure>
                  <div>
                    <h3
                      style={{
                        color: `${
                          member.teamMemberFontTheme === 'dark'
                            ? themeSetting.darkFontColor
                            : themeSetting.lightFontColor
                        }`,
                      }}
                    >
                      {member.name}
                    </h3>
                    <p>
                      <div
                        className='teamMemberDescription'
                        dangerouslySetInnerHTML={{
                          __html: member.description,
                        }}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {aboutusShowTestimonials && <TestimonialBox />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const storefrontSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  const themeSetting = parsedStorefrontSettings.mainSetting;
  return { storefrontSetting, themeSetting };
};

export default connect(mapStateToProps)(Aboutus);
