import React from "react";
import classnames from "classnames";
import PostalCodeInput from "Utils/PostalCodeInput";

const standardPostalCodeInput = props => {
  const {
    input,
    className,
    meta: { touched, error },
    ...others
  } = props;

  return (
    <PostalCodeInput
      {...input}
      {...others}
      className={classnames({
        [className]: className,
        error: touched && error
      })}
    />
  );
};

export default standardPostalCodeInput;
