import React from "react";

class NumberInput extends React.Component {
  handleKeyPress = evt => {
    var theEvent = evt || window.event;
    var key;
    // Handle paste
    if (theEvent.type === "paste") {
      key = window.event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  render() {
    return (
      <input {...this.props} type="text" onKeyPress={this.handleKeyPress} />
    );
  }
}

export default NumberInput;
