import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
import { Alert, MessageBubble } from "Utils/SvgIcons";
import { displayRentalStatus } from "Constants/displayConstants";
import ScrollArea from "Utils/react-scrollbar";
import { isFinalBalanceDue } from "HelperFunctions/rentals";

class EventsTable extends React.Component {
  getActionsCount(event) {
    let count = 0;
    if (event.approvalStatus === "pending") {
      return 0;
    } else {
      if (event.amountRemaining > 0) {
        count += 1;
      }
      if (event.signatureRequired && event.hasSignature) {
        count += 1;
      }
    }
    return count;
  }

  render() {
    const { events, showUpcomingEventsAlert } = this.props;

    return (
      <section className="items">
        {events && events.length > 0 ? (
          <ScrollArea speed={0.8} horizontal={false}>
            <table className="grid">
              <thead>
                <tr>
                  <th>Order Name</th>
                  <th>Order Date</th>
                  <th>Actions</th>
                  <th className="action" />
                </tr>
              </thead>
              <tbody>
                {events.map((event, index) => {
                  let actionCount = this.getActionsCount(event);
                  return (
                    <Link
                      key={index}
                      className="rTableRow top"
                      to={`/events/${event.token}`}
                    >
                      <td>
                        <strong>{event.name}</strong>
                        <div>{displayRentalStatus[event.status]}</div>
                      </td>
                      <td>
                        <FormattedDate
                          day="numeric"
                          month="numeric"
                          year="numeric"
                          hour="numeric"
                          minute="numeric"
                          value={event.schedule.eventStartDateTime}
                        />
                      </td>
                      <td>
                        {isFinalBalanceDue(event) ? (
                          <div>
                            <span className="alert">
                              <label>Final Balance Due</label>
                            </span>
                            <Alert />
                            {showUpcomingEventsAlert()}
                          </div>
                        ) : actionCount > 0 ? (
                          <span>
                            {actionCount} Action{actionCount !== 1 && "s"}
                          </span>
                        ) : (
                          <span>None</span>
                        )}
                      </td>
                      <td className="actionSm">
                        {event.unreadMessagesCount > 0 && (
                          <div className="unreadCount">
                            <MessageBubble />
                            <label>{event.unreadMessagesCount}</label>
                          </div>
                        )}
                      </td>
                    </Link>
                  );
                })}
              </tbody>
            </table>
          </ScrollArea>
        ) : (
          <p className="noItems">There are currently no orders.</p>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { rentals } = state.rentals;

  return {
    events: rentals
  };
};

export default withRouter(connect(mapStateToProps, actions)(EventsTable));
