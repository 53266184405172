export const handleFetchingAndConversionSuccess = async ({
  promiseResponses = [],
  onSuccess,
  onError,
}) => {
  const errors = [];
  const items = [];

  promiseResponses.forEach((response) => {
    if (!response.success) {
      errors.push(response.reason);
      return;
    }

    items.push(...response.rentalObjects);
  });

  if (errors.length > 0) {
    onError(errors);
    return;
  }

  onSuccess(items);
};
