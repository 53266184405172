import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHostname, getPathname } from 'HelperFunctions/general';

const SmartNavLinkComponent = (props) => {
  const { link, storeLocation, ...other } = props;
  const hostname = getHostname(link);
  if (!hostname) {
    return <NavLink to={link} {...other} />;
  } else {
    // eslint-disable-next-line
    return <a href={link} {...other} />;
  }
};

const mapStateToProps = (state) => {
  const { location } = state.locations;
  return { storeLocation: location };
};

export default connect(mapStateToProps)(SmartNavLinkComponent);
