import React from 'react';
import { connect } from 'react-redux';
import {
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  YouTube,
} from 'Utils/SvgIcons';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

const SocialBox = (props) => {
  const {
    socialPinterestVisible,
    socialPinterestLink,
    socialFacebookVisible,
    socialFacebookLink,
    socialInstagramVisible,
    socialInstagramLink,
    socialTwitterVisible,
    socialTwitterLink,
    socialYoutubeVisible,
    socialYoutubeLink,
  } = props.storefrontSetting;

  const { location } = props;

  return (
    <div
      className={classnames({
        socialLinks: true,
        displaySocialCatalina:
          location.pathname.includes('contact') ||
          location.pathname.includes('home'),
      })}
    >
      {socialFacebookVisible && (
        <a href={socialFacebookLink} target='_blank' rel='noopener noreferrer'>
          <Facebook />
        </a>
      )}
      {socialInstagramVisible && (
        <a href={socialInstagramLink} target='_blank' rel='noopener noreferrer'>
          <Instagram />
        </a>
      )}
      {socialPinterestVisible && (
        <a href={socialPinterestLink} target='_blank' rel='noopener noreferrer'>
          <Pinterest />
        </a>
      )}
      {socialTwitterVisible && (
        <a href={socialTwitterLink} target='_blank' rel='noopener noreferrer'>
          <Twitter />
        </a>
      )}
      {socialYoutubeVisible && (
        <a href={socialYoutubeLink} target='_blank' rel='noopener noreferrer'>
          <YouTube />
        </a>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const storefrontSetting = parsedStorefrontSettings.mainSetting;
  return { storefrontSetting };
};

export default withRouter(connect(mapStateToProps)(SocialBox));
