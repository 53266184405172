import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import * as actions from 'Actions';
import {
  periodToPricingType,
  displayPeriodUnit,
  customerDeliveryTypes,
} from 'Constants/displayConstants';
import { ListView, ImageView, Burst } from 'Utils/SvgIcons';
import {
  getProductType,
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from 'HelperFunctions/rental_items';
import { getFirstAvailablePricingType } from 'HelperFunctions/rentals';
import { formatPhoneNumber } from 'HelperFunctions/general';
import moment from 'moment';
import CurrencyFormatter from 'HelperFunctions/CurrencyFormatter';
import classnames from 'classnames';
import CartItem from './CartItem';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import { showSelectedPrice } from 'HelperFunctions/rental_items';
import AutobookPayment from 'Components/events/AutobookPayment';
import SignatureModal from 'Utils/SignatureModal';
import SignInForm from 'Components/home/SignInForm';
import SignUpForm from 'Components/home/SignUpForm';
import Modal from 'Utils/Modal';
import axios from 'axios';

class EventSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTiles: true,
      newCustomer: this.props.newCustomer,
      width: 800,
      transactionParams: {},
      signatureOpen: false,
      autobookPaymentStage: this.props.authenticated
        ? 'customerLoggedIn'
        : 'customerNotLoggedIn',
      signInOpen: false,
      signUpOpen: false,
      signatureDetails: {
        name: '',
        submittedAt: '',
      },
      period: '',
      agreement: '',
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        this.setState({
          width: window.innerWidth,
        });
      });
    }
    this.loadAgreement();
  }

  loadAgreement = () => {
    const { location } = this.props;
    if (location && location.hasAgreement) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/api/portal/locations/${location.id}/default_agreement`
        )
        .then((response) => {
          this.setState({
            agreement: response.data.description,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  renderPricing = (item) => {
    const {
      location: { locale },
    } = this.props;
    const currencyFormatter = CurrencyFormatter({ locale });
    const product = item.product || item.bundle || item.addOn;
    const productType = getProductType(item);

    if (hasSelectedFlatPrice(product, productType)) {
      const selectedFlatPrice = getSelectedFlatPrice(product);
      return `${currencyFormatter.format(selectedFlatPrice.amount)} / Flat - ${
        selectedFlatPrice.name
      }`;
    }

    const firstAvailablePricingType = getFirstAvailablePricingType(
      product,
      productType
    );
    if (firstAvailablePricingType) {
      const pricingType = periodToPricingType[item.period];
      const periodPrice = `${currencyFormatter.format(
        product[pricingType]
      )} / ${displayPeriodUnit[item.period]}`;
      if (productType === 'items' || !product.purchasePrice) {
        if (productType === 'add_ons') {
          return `${currencyFormatter.format(product.pricing)} / Each`;
        }
        return periodPrice;
      } else {
        const flatPrices =
          product.flatPrices &&
          product.flatPrices.reduce((sum, fp) => {
            return sum + Number(fp.amount);
          }, 0) + Number(product.purchasePrice);
        return `${periodPrice}, ${currencyFormatter.format(flatPrices)} / Each`;
      }
    }

    return 'Price Available Upon Request';
  };

  formatSmartPrincingLabel(rentalItem, formatter) {
    switch (rentalItem.period) {
      case 'hourly_price':
        return `${formatter.format(rentalItem.hourlyPrice)} / Hour`;
      case 'half_day_price':
        return `${formatter.format(rentalItem.halfDayPrice)} / Half-Day`;
      case 'daily_price':
        return `${formatter.format(rentalItem.dailyPrice)} / Day`;
      case 'weekly_price':
        return `${formatter.format(rentalItem.weeklyPrice)} / Week`;
      case 'monthly_price':
        return `${formatter.format(rentalItem.monthlyPrice)} / Month`;
    }
  }

  toTimeString = (date) =>
    new Date(date).toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
    });

  setTileState = (value) => {
    this.setState({
      showTiles: value,
    });
  };

  handleItemChange = (e, itemIndex) => {
    const { event, setEventProperties, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value),
    };

    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'items',
      location
    );

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };

    setEventProperties({
      items: newItems,
    });
  };

  handleItemRemove = (itemIndex) => {
    const { event, setEventProperties } = this.props;
    let newItems = [...event.items];
    newItems.splice(itemIndex, 1);

    setEventProperties({ items: newItems });
  };

  handleAddOnRemove = (addOnIndex) => {
    const { event, setEventProperties } = this.props;
    let newItems = [...event.addOns];
    newItems.splice(addOnIndex, 1);

    setEventProperties({ addOns: newItems });
  };

  handleAddOnChange = (e, addOnIndex) => {
    const { event, setEventProperties, location } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.addOns.slice();
    newItems[addOnIndex] = {
      ...newItems[addOnIndex],
      [name]: toNumber(value),
    };

    const selected = showSelectedPrice(
      newItems[addOnIndex].product,
      newItems[addOnIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      'add_ons',
      location
    );

    newItems[addOnIndex] = {
      ...newItems[addOnIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
    };

    setEventProperties({
      addOns: newItems,
    });
  };

  handleRentalBundleChange = (e, rentalBundleIndex) => {
    const { updateRentalBundle } = this.props;
    const { name, value } = e.target;
    updateRentalBundle(name, value, rentalBundleIndex);
  };

  handleRentalBundleRemove = (rentalBundleIndex) => {
    const { event, setEventProperties } = this.props;
    let newRentalBundles = [...event.rentalBundles];
    newRentalBundles.splice(rentalBundleIndex, 1);
    setEventProperties({ rentalBundles: newRentalBundles });
  };

  toggleSignatureOpen = () => {
    this.setState({
      signatureOpen: !this.state.signatureOpen,
    });
  };

  handleSubmitSignature = ({ signature, signerName, signerTitle }) => {
    const { setEventProperties, event, newCustomer, customer } = this.props;
    const firstName = newCustomer?.firstName || customer?.firstName;
    const lastName = newCustomer?.lastName || customer?.lastName;
    const newEvent = Object.assign(event, {
      rentalSignaturesAttributes: [
        {
          signature,
          signerName,
          signerTitle,
          signatureType: 'customer',
        },
      ],
    });
    setEventProperties(newEvent);
    this.setState({
      signatureOpen: false,
      signatureDetails: {
        name: `${firstName} ${lastName}`,
        submittedAt: `${new Date()
          .toISOString()
          .replace(/-/g, '/')
          .replace('T', ' ')
          .replace('Z', ' UTC')}`,
      },
    });
  };

  handleSignIn = (data) => {
    const component = this;
    const { loginUser } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false,
          autobookPaymentStage: 'customerLoggedIn',
        });
        this.props.changeWillPayNowAndReserve(true);
      },
    });
  };

  handleSignUp = (data) => {
    const component = this;
    const { createUser, newEvent } = component.props;
    const { customer } = this.state;

    if (newEvent && newEvent.id && customer) {
      data = Object.assign({
        ...data,
        fromCart: true,
        clientId: customer.clientId,
      });
    }

    createUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false,
          autobookPaymentStage: 'customerLoggedIn',
        });
        this.props.changeWillPayNowAndReserve(true);
      },
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false,
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false,
    });
  };

  goToGuestPayAndReserve = () => {
    this.setState({ autobookPaymentStage: 'guestCheckout' });
    this.props.changeWillPayNowAndReserve(true);
  };

  renderAutobookPayment = () => {
    const { autobookPaymentStage, signatureDetails, signatureOpen, agreement } =
      this.state;
    const { customer, location, event } = this.props;

    if (autobookPaymentStage === 'customerNotLoggedIn') {
      return (
        <div className='autobookSection'>
          <label>Pay Now & Reserve</label>
          <a
            className='btn'
            onClick={() =>
              this.setState({ signInOpen: true, signUpOpen: false })
            }
          >
            Sign in to pay now & reserve
          </a>
          <a
            className='btn outline'
            onClick={() => this.goToGuestPayAndReserve()}
          >
            Pay now & reserve as guest
          </a>

          {/* This hidden form is used to trigger the usual flow submission in case the user decides not to sign or pay at this moment caused by the  */}
          <div style={{ display: 'none' }}>
            <form
              id='payment-form'
              onSubmit={this.props.handleReserveQuote}
              className='form'
            ></form>
          </div>
        </div>
      );
    } else if (
      autobookPaymentStage === 'customerLoggedIn' ||
      autobookPaymentStage === 'guestCheckout'
    ) {
      return (
        <>
          {location.signatureRequired && (
            <div className='autobookSection'>
              <label>Order requires signature</label>

              <div className='signaturePad'>
                <a onClick={this.toggleSignatureOpen}>
                  {event?.rentalSignaturesAttributes &&
                    event?.rentalSignaturesAttributes[0]?.signature && (
                      <img
                        src={
                          event?.rentalSignaturesAttributes[0]?.signature
                            ? event?.rentalSignaturesAttributes[0]?.signature
                            : null
                        }
                        alt='Signature'
                      />
                    )}
                  <div className='signatureDetails'>
                    <p>
                      {event?.rentalSignaturesAttributes
                        ? event?.rentalSignaturesAttributes[0]?.signerName
                        : ''}
                    </p>
                  </div>
                </a>
              </div>
              <SignatureModal
                open={signatureOpen}
                toggle={this.toggleSignatureOpen}
                rental={{
                  rentalAgreement: agreement,
                  signature: event?.rentalSignaturesAttributes
                    ? event?.rentalSignaturesAttributes[0]?.signature
                    : '',
                  signerName: '',
                  signerTitle: '',
                  signerCompany: '',
                }}
                onSubmitSignature={this.handleSubmitSignature}
              />
            </div>
          )}
          <div
            className='autobookPayment'
            style={{ gridRow: location.signatureRequired ? 3 : 2 }}
          >
            <AutobookPayment
              rental={event}
              location={location}
              customer={customer}
              mode={autobookPaymentStage}
              toggleSignInOpen={() => this.setState({ signInOpen: true })}
              toggleSignUpOpen={() => this.setState({ signUpOpen: true })}
              handleReserveQuote={this.props.handleReserveQuote}
            />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  render() {
    const { showTiles, autobookPaymentStage, signInOpen, signUpOpen } =
      this.state;
    const {
      customer,
      event,
      newEvent,
      storefrontShopSetting,
      location,
      step,
      renderNextButton,
      newCustomer,
      handleSelectStep,
      reviewStepOne,
      reviewStepTwo,
      cartConfirmation,
      deliverySetting: {
        morningStartTime,
        morningEndTime,
        afternoonStartTime,
        afternoonEndTime,
        eveningStartTime,
        eveningEndTime,
      } = {},
    } = this.props;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      addOns,
      customerContactPhone,
      deliveryType,
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      pickupAddressLocationName,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupAddressCity,
      pickupAddressLocale,
      pickupAddressPostalCode,
      pickupAddressCountry,
      pickupSameAsDelivery,
    } = event;
    const timeZoneElon = moment.tz.guess(eventStartTime);
    const timeZone = moment.tz([2022, 5], timeZoneElon).format('z');
    const smartPricingActive = location.smartPricingActive;
    const locale = location.locale;
    const formatter = CurrencyFormatter({ locale });
    const showPrice =
      items.filter((item) => !item.product.showPriceStorefront).length === 0;

    let localFirstName;
    let localLastName;
    let localEmail;
    let localCustomerContactPhone;
    let localItems;
    let eventSchedule;
    if (newCustomer?.firstName) {
      localFirstName = newCustomer.firstName;
      localStorage.setItem('localFirstName', localFirstName);
    }
    if (newCustomer?.lastName) {
      localLastName = newCustomer.lastName;
      localStorage.setItem('localLastName', localLastName);
    }
    if (newCustomer?.email) {
      localEmail = newCustomer.email;
      localStorage.setItem('localEmail', localEmail);
    }
    if (event?.customerContactPhone) {
      localCustomerContactPhone = customerContactPhone;
      localStorage.setItem(
        'localCustomerContactPhone',
        localCustomerContactPhone
      );
    }

    let eventItems =
      JSON.parse(localStorage.getItem('cartEvent'))?.items ||
      newEvent?.items ||
      [];

    if (
      event?.eventStart &&
      event?.eventEnd &&
      event?.eventStartTime &&
      event?.eventEndTime
    ) {
      eventSchedule = {
        eventStart,
        eventStartTime,
        eventEnd,
        eventEndTime,
      };
      localStorage.setItem('eventSchedule', JSON.stringify(eventSchedule));
    }

    var firstName = localStorage.getItem('localFirstName');
    var lastName = localStorage.getItem('localLastName');
    var email = localStorage.getItem('localEmail');
    var eventCustomerContactPhone = localStorage.getItem(
      'localCustomerContactPhone'
    );
    var storedEventSchedule = JSON.parse(localStorage.getItem('eventSchedule'));

    let deliveryHeader = pickupSameAsDelivery
      ? 'Delivery/Pick Up Info'
      : 'Delivery Info';

    return (
      <section
        className={classnames({
          summary: cartConfirmation !== true,
          confirmationSummary: cartConfirmation === true,
          eventDetails: this.state.width < 768 && cartConfirmation === true,
        })}
      >
        <div
          className={classnames({
            eventDetails: cartConfirmation !== true,
            confirmationEventDetails: cartConfirmation === true,
          })}
        >
          {!cartConfirmation && (
            <div className='title'>
              <h4 id='eventNameSummary'>{event.name}</h4>
              <div className='eventSchedule'>
                <FormattedDate
                  value={eventStart}
                  month='short'
                  year='numeric'
                  day='numeric'
                />
                <span>-</span>
                <FormattedDate
                  value={eventEnd}
                  month='short'
                  year='numeric'
                  day='numeric'
                />
              </div>
            </div>
          )}
          {cartConfirmation && (
            <div className='title'>
              <h4 id='eventNameSummary'>Order Date</h4>
              <hr className='hr'></hr>
              <div className='confirmationEventSchedule'>
                <div>
                  <text>Order Start</text>
                  <FormattedDate
                    value={eventStart || storedEventSchedule?.eventStart}
                    month='numeric'
                    year='numeric'
                    day='numeric'
                  />{' '}
                  <FormattedTime
                    value={
                      eventStartTime || storedEventSchedule?.eventStartTime
                    }
                  />
                </div>
                <div>
                  <text>Order End</text>
                  <FormattedDate
                    value={eventEnd || storedEventSchedule?.eventEnd}
                    month='numeric'
                    year='numeric'
                    day='numeric'
                  />{' '}
                  <FormattedTime
                    value={eventEndTime || storedEventSchedule?.eventEndTime}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='contactWrapper'>
            {customer.id && (
              <div className='contact'>
                {!cartConfirmation ? (
                  <div>
                    <label>Contact:</label>
                    <strong>
                      {' '}
                      {customer.firstName} {customer.lastName}{' '}
                    </strong>
                  </div>
                ) : (
                  <div>
                    <hr className='hr'></hr>
                    <h4 id='eventNameSummary'>Contact Info</h4>
                    <div>
                      {' '}
                      {customer.firstName} {customer.lastName}{' '}
                    </div>
                  </div>
                )}
                <div>{customer.email}</div>
                <div>
                  <div>
                    {customerContactPhone
                      ? formatPhoneNumber(customerContactPhone)
                      : eventCustomerContactPhone}
                  </div>
                  {customer.streetAddress1 && (
                    <div>
                      {customer.streetAddress1}{' '}
                      {customer.streetAddress2 && (
                        <div>{customer.streetAddress2}</div>
                      )}
                      <div>
                        {customer.city}
                        {', '}
                        {customer.locale} {customer.postalCode}
                        {', '}
                        {customer.country}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!customer.id && (
              <div className='contact'>
                {!cartConfirmation ? (
                  <div>
                    <label>Contact:</label>
                    <strong>
                      {' '}
                      {firstName} {lastName}{' '}
                    </strong>
                  </div>
                ) : (
                  <div>
                    <hr className='hr'></hr>
                    <h4 id='eventNameSummary'>Contact Info</h4>
                    <div>
                      {' '}
                      {firstName} {lastName}{' '}
                    </div>
                  </div>
                )}
                <div>{email}</div>
                <div>{formatPhoneNumber(eventCustomerContactPhone)}</div>
              </div>
            )}
          </div>
          {deliveryType === 'none' ? (
            ''
          ) : (
            <div className='delivery'>
              {!['default_delivery', 'ship'].includes(deliveryType) && (
                <div>
                  {!cartConfirmation ? (
                    <label>{customerDeliveryTypes[deliveryType]}</label>
                  ) : (
                    <div id='eventNameContainer'>
                      <hr className='hr'></hr>
                      <h4 id='eventNameSummary'>
                        {customerDeliveryTypes[deliveryType]}
                      </h4>
                    </div>
                  )}
                </div>
              )}
              {['default_delivery', 'ship'].includes(deliveryType) && (
                <div>
                  {!cartConfirmation ? (
                    <label>Location:</label>
                  ) : (
                    <div id='eventNameContainer'>
                      <hr className='hr'></hr>
                      <h4 id='eventNameSummary'>{deliveryHeader}</h4>
                    </div>
                  )}
                  <div id='deliveryAddressLocationName'>
                    {deliveryAddressLocationName}
                  </div>
                  <div>
                    {deliveryAddressStreetAddress1}{' '}
                    {deliveryAddressStreetAddress2}
                  </div>
                  <div>
                    {deliveryAddressCity}, {deliveryAddressLocale}{' '}
                    {deliveryAddressPostalCode}, {deliveryAddressCountry}
                  </div>
                </div>
              )}
            </div>
          )}
          {!pickupSameAsDelivery &&
            ['default_delivery', 'custom_delivery'].includes(deliveryType) && (
              <div className='delivery'>
                {!cartConfirmation ? (
                  <label>Pick Up:</label>
                ) : (
                  <div id='pickupConfirmContainer'>
                    <div id='eventNameContainer'>
                      <hr className='hr'></hr>
                      <h4 id='eventNameSummary'>Pick Up Info</h4>
                    </div>
                  </div>
                )}
                <div>
                  <div id='pickupAddressLocationName'>
                    {pickupAddressLocationName}
                  </div>
                  <div>
                    {pickupAddressStreetAddress1} {pickupAddressStreetAddress2}
                  </div>
                  <div>
                    {pickupAddressCity}, {pickupAddressLocale}{' '}
                    {pickupAddressPostalCode}, {pickupAddressCountry}
                  </div>
                </div>
              </div>
            )}
          {!cartConfirmation && (
            <div id='deliveryWindowContainer'>
              <label>Delivery Window:</label>
              <div id='deliveryWindow'>
                <FormattedDate
                  value={eventStart}
                  year='numeric'
                  month='short'
                  weekday='short'
                  day='numeric'
                />
                {event.preferredDeliveryWindow == 'delivery_morning_time' &&
                  `${this.toTimeString(morningStartTime)} - ${this.toTimeString(
                    morningEndTime
                  )} ${timeZone}`}
                {event.preferredDeliveryWindow == 'delivery_afternoon_time' &&
                  `${this.toTimeString(
                    afternoonStartTime
                  )} - ${this.toTimeString(afternoonEndTime)} ${timeZone}`}
                {event.preferredDeliveryWindow == 'delivery_evening_time' &&
                  `${this.toTimeString(eveningStartTime)} - ${this.toTimeString(
                    eveningEndTime
                  )} ${timeZone}`}
                {event.preferredDeliveryWindow == 'delivery_no_preference' &&
                  `No Preference`}
              </div>
            </div>
          )}
          {!cartConfirmation && (
            <div id='returnWindowContainer'>
              <label>Return Window:</label>
              <div id='returnWindow'>
                <FormattedDate
                  value={eventStart}
                  year='numeric'
                  month='short'
                  weekday='short'
                  day='numeric'
                />
                {event.preferredPickupWindow == 'pickup_morning_time' &&
                  `${this.toTimeString(morningStartTime)} - ${this.toTimeString(
                    morningEndTime
                  )} ${timeZone}`}
                {event.preferredPickupWindow == 'pickup_afternoon_time' &&
                  `${this.toTimeString(
                    afternoonStartTime
                  )} - ${this.toTimeString(afternoonEndTime)} ${timeZone}`}
                {event.preferredPickupWindow == 'pickup_evening_time' &&
                  `${this.toTimeString(eveningStartTime)} - ${this.toTimeString(
                    eveningEndTime
                  )} ${timeZone}`}
                {event.preferredPickupWindow == 'pickup_no_preference' &&
                  `No Preference`}
              </div>
            </div>
          )}

          {this.props.step === 3 &&
            this.props.autobookPossible() &&
            this.renderAutobookPayment()}

          <Modal
            className='signInModal'
            title='Sign In'
            open={signInOpen}
            toggle={this.closeSignIn}
          >
            <div>
              <div className='icon'>
                <Burst />
              </div>
              <div className='details'>
                <p>
                  Please sign in/up to,
                  <br />
                  reserve and pay your order.
                </p>
                <SignInForm errors={{}} onSubmit={this.handleSignIn} />
                <a
                  onClick={() =>
                    this.setState({ signInOpen: false, signUpOpen: true })
                  }
                >
                  or Sign Up for FREE
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            className='signInModal'
            title='Sign Up'
            open={signUpOpen}
            toggle={this.closeSignUp}
          >
            <div>
              <div className='icon'>
                <Burst />
              </div>
              <div className='details'>
                <SignUpForm
                  errors={{}}
                  onSubmit={this.handleSignUp}
                  customer={customer}
                />
                <a
                  onClick={() =>
                    this.setState({ signInOpen: true, signUpOpen: false })
                  }
                >
                  or Sign In
                </a>
              </div>
            </div>
          </Modal>
        </div>

        <div className='items'>
          <div id='itemTitleToggle'>
            <h4>Items</h4>
            <div className='tileToggle'>
              <a onClick={() => this.setTileState(false)}>
                <ListView
                  className={classnames({
                    showPaymentListView: !showTiles,
                  })}
                />
              </a>
              <a onClick={() => this.setTileState(true)}>
                <ImageView
                  className={classnames({
                    showPaymentImageView: showTiles,
                  })}
                />
              </a>
            </div>
          </div>
          <div className='companyItems'>
            <table className='grid thumbnail'>
              <tbody className='cartItemList'>
                {eventItems?.map((item, itemIndex) => {
                  if (item._destroy === '1') {
                    return null;
                  } else {
                    return !item.product.commodityProductRelationships ? (
                      <CartItem
                        key={itemIndex}
                        period={item.period}
                        item={item}
                        itemSelectedPrice={item.selectedPrice}
                        itemIndex={itemIndex}
                        tileView={showTiles}
                        storefrontShopSetting={storefrontShopSetting}
                        onItemChange={this.handleItemChange}
                        onItemRemove={this.handleItemRemove}
                        cartConfirmation={cartConfirmation}
                      />
                    ) : (
                      <div>
                        <CartItem
                          key={itemIndex}
                          period={item.period}
                          item={item}
                          itemSelectedPrice={item.selectedPrice}
                          itemIndex={itemIndex}
                          tileView={showTiles}
                          storefrontShopSetting={storefrontShopSetting}
                          onItemChange={this.handleItemChange}
                          onItemRemove={this.handleItemRemove}
                          cartConfirmation={cartConfirmation}
                        />
                        {item.product.commodityProductRelationships.map(
                          (comRelationship, itemIndex) => {
                            return (
                              <CartItem
                                key={itemIndex}
                                period={item.period}
                                item={comRelationship}
                                itemSelectedPrice={
                                  comRelationship.commodity.selectedPrice
                                }
                                itemIndex={itemIndex}
                                tileView={true}
                                storefrontShopSetting={storefrontShopSetting}
                                onItemChange={this.handleItemChange}
                                onItemRemove={this.handleItemRemove}
                                location={location}
                                cartConfirmation={cartConfirmation}
                                isCommodity={true}
                                id={'linkedItemCart'}
                              />
                            );
                          }
                        )}
                      </div>
                    );
                  }
                })}
                {rentalBundles?.map((item, itemIndex) => {
                  if (item._destroy === '1') {
                    return null;
                  } else {
                    return (
                      <CartItem
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        tileView={showTiles}
                        itemSelectedPrice={item.selectedPrice}
                        storefrontShopSetting={storefrontShopSetting}
                        onItemChange={this.handleRentalBundleChange}
                        onItemRemove={this.handleRentalBundleRemove}
                        cartConfirmation={cartConfirmation}
                      />
                    );
                  }
                })}
                {addOns?.map((addOn, addOnIndex) => {
                  if (addOn._destroy === '1') {
                    return null;
                  } else {
                    return (
                      <CartItem
                        key={addOnIndex}
                        item={addOn}
                        itemIndex={addOnIndex}
                        tileView={showTiles}
                        itemSelectedPrice={addOn.selectedPrice}
                        storefrontShopSetting={storefrontShopSetting}
                        onItemChange={this.handleAddOnChange}
                        onItemRemove={this.handleAddOnRemove}
                        cartConfirmation={cartConfirmation}
                      />
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { authenticated } = state.auth;
  const {
    parsedStorefrontSettings: { storefrontShopSetting },
    location,
  } = state.locations;
  const { tileView } = state.products;
  const { customer } = state.customer;
  const { newEvent } = state.cart;

  return {
    customer,
    storefrontShopSetting,
    location,
    tileView,
    authenticated,
    newEvent,
  };
};

export default connect(mapStateToProps, actions)(EventSummary);
