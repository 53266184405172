import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Route } from "react-router-dom";
import UnsupportedBrowser from "Utils/UnsupportedBrowser";

const UnsupportedRoute = ({ history }) => (
  <ConnectedRouter history={history}>
    <Route path="/" component={UnsupportedBrowser} />
  </ConnectedRouter>
);

export default UnsupportedRoute;
