/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import { Items, Delete, Info, Alert } from 'Utils/SvgIcons';
import {
  getItemPeriodPrice,
  getCombinedItemPeriodPrice,
} from 'HelperFunctions/rentals';
import { getProductType } from 'HelperFunctions/rental_items';
import NumberInput from 'Utils/NumberInput';
import StandardTextField from 'Utils/redux_form_inputs/StandardTextField';
import ReactTooltip from 'react-tooltip';
import Imgix from 'react-imgix';
import CurrencyFormatter from 'HelperFunctions/CurrencyFormatter';
import classnames from 'classnames';
import OverbookingTooltip from '../shop/OverbookingTooltip';
import {
  getNumberAvailable,
  checkInventoryObjectAvailabilityLoading,
} from 'HelperFunctions/availability';
import { setErrors } from 'Actions';

class CartItem extends React.Component {
  handleItemChange = (e) => {
    const { itemIndex, item } = this.props;
    if (+item.linkedQuantity > +e.target.value) {
      setErrors('Cannot remove linked item.');
      return;
    }
    this.props.onItemChange(e, itemIndex);
  };

  handleItemRemove = () => {
    const { itemIndex, item, items } = this.props;
    let commodityItemQuantity = item.product?.commodityProductRelationships
      ? Number(item.product.commodityProductRelationships.length)
      : 0;
    let commodityAddOnQuantity = item.product?.addOnProductRelationships
      ? Number(item.product.addOnProductRelationships.length)
      : 0;

    //Kiara Note: Remove additional items if the above variable(s) are greater than 0
    if (
      item.linkedItem &&
      items.some((_item) => _item.productId === item.linkedItemId) &&
      item.linkedQuantity > 0
    ) {
      setErrors('Cannot remove linked item.');
      return;
    }

    this.props.onItemRemove(itemIndex, 1 + commodityItemQuantity);
    if (commodityAddOnQuantity > 0) {
      this.props.removeAddOnCommodities(itemIndex, commodityAddOnQuantity);
    }
  };

  formatSmartPrincingLabel(rentalItem, formatter) {
    switch (rentalItem.period) {
      case 'hourly_price':
        return `${formatter.format(rentalItem.hourlyPrice)} / Hour`;
      case 'half_day_price':
        return `${formatter.format(rentalItem.halfDayPrice)} / Half-Day`;
      case 'daily_price':
        return `${formatter.format(rentalItem.dailyPrice)} / Day`;
      case 'weekly_price':
        return `${formatter.format(rentalItem.weeklyPrice)} / Week`;
      case 'monthly_price':
        return `${formatter.format(rentalItem.monthlyPrice)} / Month`;
    }
  }

  render() {
    const {
      item,
      storefrontShopSetting,
      tileView,
      location: { locale },
      location,
      showPrice,
      itemSelectedPrice,
      cartConfirmation,
      availability,
      itemIndex,
    } = this.props;
    const product = item.product || item.bundle || item.addOn || item.commodity;
    const productType = getProductType(item);
    const currencyFormatter = CurrencyFormatter({ locale });
    const itemQuantity = item.quantity;

    const isAvailabilityLoading = checkInventoryObjectAvailabilityLoading(
      availability,
      product.id,
      productType
    );

    const availableProductNumber = isAvailabilityLoading
      ? 999999
      : getNumberAvailable(availability, product.id, productType);

    const isAllowedOverBookingForProduct =
      product.allowOverbooking && location.overbookingOnSf !== 'disallowed';
    const isItemQtyGreaterThanAvailableQty =
      itemQuantity > availableProductNumber;
    const overbookingDisallowed =
      isItemQtyGreaterThanAvailableQty && !isAllowedOverBookingForProduct;

    const formatter = CurrencyFormatter({ locale });

    return (
      <MediaBreakpoints
        desktop={
          <tr>
            {tileView && (
              <td className='image'>
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              </td>
            )}
            <td
              className={classnames({
                itemContent: true,
                pl1rem: item.linkedItem,
              })}
            >
              <div id='itemContentContainer'>
                {cartConfirmation || item.commodity ? (
                  <div style={{ width: '45%', fontWeight: 'bold' }}>
                    {itemQuantity}
                  </div>
                ) : (
                  <NumberInput
                    type='text'
                    name='quantity'
                    value={itemQuantity}
                    onChange={this.handleItemChange}
                  />
                )}

                <label>
                  <div id='itemPricing'>
                    {storefrontShopSetting.shopShowItemPricing &&
                      location.smartPricingActive &&
                      (!this.props.renderPricing ? (
                        <div>
                          @ {this.formatSmartPrincingLabel(item, formatter)}
                        </div>
                      ) : (
                        this.props.renderPricing(product, this.props.location)
                      ))}
                    {!item.commodity ? (
                      <div id='itemName'>{item.name}</div>
                    ) : (
                      <div id='itemName'>{product.name}</div>
                    )}
                  </div>
                  {isItemQtyGreaterThanAvailableQty && (
                    <div
                      className={classnames({
                        info: true,
                        overbooking: overbookingDisallowed,
                      })}
                      data-tip
                      data-for={
                        isAllowedOverBookingForProduct ? 'info' : 'overbooking'
                      }
                      data-event='click'
                      data-event-off='mouseleave'
                    >
                      {isAllowedOverBookingForProduct ? <Info /> : <Alert />}
                    </div>
                  )}
                  {isItemQtyGreaterThanAvailableQty && (
                    <OverbookingTooltip
                      allowOverbooking={isAllowedOverBookingForProduct}
                      numberAvailable={availableProductNumber}
                    />
                  )}
                  {isItemQtyGreaterThanAvailableQty && (
                    <ReactTooltip
                      id='info'
                      class='tooltip bottom'
                      place='bottom'
                      type='light'
                      effect='solid'
                      overridePosition={({ _left, top }) => ({top, left: 0})}
                    >
                      <p>
                        <h3>Item not available for autobook</h3>
                        <br></br>
                        You may add this to a quote, but the rental company will
                        <br></br>
                        have to approve it before the reservation is confirmed.
                      </p>
                    </ReactTooltip>
                  )}
                </label>

                {!(item.bundle && !item.priceLocked) &&
                  (storefrontShopSetting.shopShowItemPricing &&
                  !!product.showPriceStorefront &&
                  // showPrice &&
                  location.smartPricingActive
                    ? !!product.showPriceStorefront &&
                      !item.commodity && (
                        <span className='normalText pricing'>
                          ${itemSelectedPrice}
                        </span>
                      )
                    : !!product.showPriceStorefront && storefrontShopSetting.shopShowItemPricing && (
                        <span className='normalText pricing'>
                          {getItemPeriodPrice(
                            item,
                            productType,
                            locale,
                            location
                          )}
                        </span>
                      ))}

                {item.bundle &&
                  !product.priceLocked &&
                  product.flatPrices &&
                  product.flatPrices.map((flatPrice) => (
                    <span className='normalText pricing'>
                      {currencyFormatter.format(flatPrice.amount)} / Flat:{' '}
                      {flatPrice.name}
                    </span>
                  ))}

                {item.bundle &&
                  !product.priceLocked &&
                  product.purchasePrice > 0 && (
                    <span className='normalText pricing'>
                      + {currencyFormatter.format(product.purchasePrice)} / Each
                    </span>
                  )}
              </div>
              <div>
                {!cartConfirmation &&
                  !this.props.isCommodity &&
                  !item.linkedItem && (
                    <a
                      className='btnLink remove'
                      onClick={this.handleItemRemove}
                    >
                      Remove
                    </a>
                  )}
              </div>
            </td>
          </tr>
        }
        mobile={
          <tr>
            <td
              className={classnames({
                mobileItemContent: true,
                pl1rem: item.linkedItem,
              })}
            >
              {tileView && (
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              )}
              <label id='itemName'>
                {!item.commodity ? item.name : product.name}
              </label>
              {!cartConfirmation &&
                !this.props.isCommodity &&
                !item.linkedItem && (
                  <a className='btnLink remove' onClick={this.handleItemRemove}>
                    Remove
                  </a>
                )}
              {cartConfirmation || item.commodity ? (
                <div>
                  {itemQuantity}
                  <br />
                </div>
              ) : (
                <StandardTextField
                  type='text'
                  meta={{ error: {} }}
                  input={{
                    name: 'quantity',
                    value: item.quantity,
                    onChange: this.handleItemChange,
                  }}
                />
              )}
              {storefrontShopSetting.shopShowItemPricing &&
                getItemPeriodPrice(item, productType, locale, location)}
            </td>
          </tr>
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = state.locations;
  const { items } = state.cart.event;
  const { availability } = state;
  return { location, items, availability };
};

export default connect(mapStateToProps, null)(CartItem);
