/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "Utils/Modal";
import classnames from "classnames";
import * as actions from "Actions";
import { withRouter } from "react-router";
import { history } from "Components/Routes";

class EventHeader extends Component {
  state = {
    cancelChangesModalOpen: false
  };

  toggleCancelChangesModal = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    })
  }

  toggleCancelChangesModal = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    })
  }

  cancelEditedChanges = () => {
    const { event, history, clearCartProps, setCartProps } = this.props;

    clearCartProps();
    setCartProps({ editingEvent: false });
    history.push(`/events/${event.token}`);
  };

  render() {
    const { event } = this.props;
    const rentalItems = event && Array.concat(event.items, event.addOns, event.rentalBundles) || [];

    if (rentalItems.length <= 0) {
      return null;
    }

    return (
      <div className="cart addItemsToEvent">
        <div className="content">
          {event && event.editingEvent && <section
            className={classnames({
              progress: true
            })}
          >
            <div className="editOrder">
              <span>
                <b>Editing Order: {event.name}</b> #{event.token}
              </span>
            </div>
            <div>
              <a
                className="btn skip-step"
                onClick={this.toggleCancelChangesModal}
              >
                Cancel Changes
              </a>
              <Link className="btn secondary" to="/cart">
                Go Back To Order
              </Link>
            </div>
          </section>}
        </div>
        <Modal
          className="cancelChangeRequest"
          title="Cancel Changes"
          open={this.state.cancelChangesModalOpen}
          toggle={this.toggleCancelChangesModal}
        >
          <div>
            <p>
              Looks like you’ve made some changes to your order. Are you sure you want to cancel them?
            </p>
            <div className="actions">
              <a className="btn seconday" onClick={this.cancelEditedChanges}>
                Yes, Cancel My Changes
              </a>
              <a className="btn cancel" onClick={this.toggleCancelChangesModal}>
                Go Back
              </a>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  return { event };
}

export default withRouter(connect(
  mapStateToProps,
  actions
)(EventHeader));
