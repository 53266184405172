import React from 'react';
import { connect } from 'react-redux';
import { Items } from 'Utils/SvgIcons';
import classnames from 'classnames';
import Imgix from 'react-imgix';
import PageMetaData from 'Utils/PageMetaData';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class OurVendors extends React.Component {
  render() {
    const { storefrontSetting, themeSetting } = this.props;
    const { storefrontVendors } = storefrontSetting;
    return (
      <div className='connections'>
        <PageMetaData setting={storefrontSetting} />
        <div className='summary'>
          <figure>
            {storefrontSetting.pagePicture ? (
              <Imgix
                src={storefrontSetting.pagePicture.imgixUrl}
                alt='vendor_picture'
                width={2040}
                height={1152}
              />
            ) : (
              <Items />
            )}
          </figure>
          <h1
            style={{
              color: `${
                storefrontSetting.connectionTitleFontTheme === 'dark'
                  ? themeSetting.darkFontColor
                  : themeSetting.lightFontColor
              }`,
            }}
          >
            {storefrontSetting.pageTitle}
          </h1>
        </div>
        {storefrontVendors && (
          <div
            className={classnames({
              vendors: true,
              ['count' + storefrontVendors.length]: true,
            })}
          >
            {storefrontVendors.map((storefrontVendor) => (
              <a
                href={storefrontVendor.vendorsLink}
                key={storefrontVendor.id}
                target={storefrontSetting.openInNewTab ? '_blank' : ''}
                rel='noopener noreferrer'
              >
                <figure>
                  {storefrontVendor.picture ? (
                    <Imgix
                      src={storefrontVendor.picture.imgixUrl}
                      alt='vendor_picture'
                      width={2040}
                      height={1152}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
                <h2
                  style={{
                    color: `${
                      storefrontVendor.titleFontTheme === 'dark'
                        ? themeSetting.darkFontColor
                        : themeSetting.lightFontColor
                    }`,
                  }}
                >
                  {storefrontVendor.vendorsTitle}
                </h2>
                <p
                  style={{
                    color: `${
                      storefrontVendor.descriptionFontTheme === 'dark'
                        ? themeSetting.darkFontColor
                        : themeSetting.lightFontColor
                    }`,
                  }}
                >
                  {storefrontVendor.vendorsDescription}
                </p>
              </a>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const themeSetting = parsedStorefrontSettings.mainSetting;
  const storefrontSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  return { storefrontSetting, themeSetting };
};

export default connect(mapStateToProps)(OurVendors);
