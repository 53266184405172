export const FETCH_ALL_CATEGORIES_SUCCESS = "FETCH_ALL_CATEGORIES_SUCCESS";
export const FETCH_INVENTORY_CATEGORIES_REQUEST =
  "FETCH_INVENTORY_CATEGORIES_REQUEST";
export const FETCH_INVENTORY_CATEGORIES_SUCCESS =
  "FETCH_INVENTORY_CATEGORIES_SUCCESS";
export const FETCH_INVENTORY_CATEGORIES_FAILURE =
  "FETCH_INVENTORY_CATEGORIES_FAILURE";
export const FETCH_SF_CATEGORIES_REQUEST = "FETCH_SF_CATEGORIES_REQUEST";
export const FETCH_SF_CATEGORIES_SUCCESS = "FETCH_SF_CATEGORIES_SUCCESS";
export const FETCH_SF_CATEGORIES_FAILURE = "FETCH_SF_CATEGORIES_FAILURE";
