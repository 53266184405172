/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import Dropzone from 'react-dropzone';
import Modal from 'Utils/Modal';
import Cropper from 'react-cropper';
// import "cropperjs/dist/cropper.css";
// import update from "react/lib/update";
import update from 'react-addons-update';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Picture from './Picture';
import { ProfileInfo } from 'Utils/SvgIcons';
import { arraysEqual } from 'HelperFunctions/general';

class ImageDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      selectedFile: null,
      cropperOpen: false,
      cropperColor: '#fff',
    };

    this.handlePictureDelete = this.handlePictureDelete.bind(this);
    this.movePicture = this.movePicture.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
  }
  componentDidMount() {
    this.setState({ pictures: this.props.pictures });
  }

  componentWillReceiveProps(nextProps) {
    if (!arraysEqual(this.props.pictures, nextProps.pictures)) {
      this.setState({ pictures: [...nextProps.pictures] });
    }
  }

  onDrop(acceptedFiles) {
    this.setState({
      cropperOpen: true,
      selectedFile: acceptedFiles[0],
    });
  }

  onDropZoneOpen() {
    this.dropzone.open();
  }

  movePicture(dragIndex, hoverIndex) {
    const { pictures } = this.state;
    const dragPicture = pictures[dragIndex];
    const newState = update(this.state, {
      pictures: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragPicture],
        ],
      },
    });
    this.handleChange(newState.pictures);
    this.setState(newState);
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  handleCrop() {
    const component = this;
    const croppedCanvas = this.refs.cropper.getCroppedCanvas({
      fillColor: this.state.cropperColor,
    });

    const croppedFile = this.dataURLtoBlob(croppedCanvas.toDataURL());

    const newPictures = this.props.multiple
      ? [...this.state.pictures, { file: croppedFile }]
      : [{ file: croppedFile }];

    component.handleChange(newPictures);
    component.setState({
      pictures: newPictures,
      cropperOpen: false,
      selectedFile: null,
    });
  }

  handleCloseCropper() {
    this.setState({
      cropperOpen: false,
      selectedFile: null,
    });
  }

  handlePictureDelete(index) {
    const { pictures } = this.state;
    let newPictures = pictures.slice(0);
    newPictures[index]._destroy = 1;
    this.handleChange(newPictures);
    this.setState({ pictures: newPictures });
  }

  handleChange(pictures) {
    const newPictures = pictures.map((picture, index) => {
      return { ...picture, position: index };
    });
    const picturesToSubmit = newPictures.filter((picture) => picture.url);
    const filesToSubmit = newPictures.filter(
      (picture) => picture.file && picture._destroy !== 1
    );
    this.props.onChange(filesToSubmit, picturesToSubmit);
  }

  renderDropZoneContent() {
    const { pictures } = this.state;
    const primaryPictureIndex = pictures.findIndex(
      (picture) => picture._destroy !== 1
    );

    if (primaryPictureIndex > -1) {
      return (
        <Picture
          index={primaryPictureIndex}
          picture={pictures[primaryPictureIndex]}
          onDelete={this.handlePictureDelete}
          movePicture={this.movePicture}
          draggable={this.props.multiple}
        />
      );
    } else {
      return (
        <div>
          <ProfileInfo />
          <a className='btn'>Upload Photo</a>
        </div>
      );
    }
  }

  renderAdditionalSection() {
    const { pictures } = this.state;
    const primaryPictureIndex = pictures.findIndex(
      (picture) => picture._destroy !== 1
    );
    return (
      <ul>
        {pictures.map(
          (picture, index) =>
            picture._destroy !== 1 &&
            index !== primaryPictureIndex && (
              <li key={index}>
                <Picture
                  index={index}
                  picture={picture}
                  onDelete={this.handlePictureDelete}
                  movePicture={this.movePicture}
                  draggable={this.props.multiple}
                />
              </li>
            )
        )}
        {this.renderUploadButtons()}
      </ul>
    );
  }

  renderUploadButtons() {
    var buttons = [];
    const { pictures } = this.state;
    let additionalPictureCount = Math.max(
      0,
      pictures.filter((picture) => picture._destroy !== 1).length - 1
    );
    for (var i = 0; i < 3 - additionalPictureCount; i++) {
      buttons.push(
        <li key={i}>
          <a
            className='btnLink add'
            onClick={() => {
              this.onDropZoneOpen();
            }}
          >
            Add
          </a>
        </li>
      );
    }
    return buttons;
  }
  render() {
    const { aspectRatio } = this.props;
    let component = this;
    const actions = [
      <button className='btn' onClick={() => this.handleCrop()}>
        Upload
      </button>,
      <button
        className='btn cancel'
        onClick={() => component.handleCloseCropper()}
      >
        Cancel
      </button>,
    ];

    const { cropperOpen, selectedFile } = this.state;
    return (
      <div className='uploadZone'>
        <Dropzone
          className='dropZone'
          ref={(node) => {
            this.dropzone = node;
          }}
          onDropAccepted={(acceptedFiles) => component.onDrop(acceptedFiles)}
        >
          {this.renderDropZoneContent()}
        </Dropzone>
        {this.props.multiple && this.renderAdditionalSection()}
        <Modal
          className='modal large'
          title='Upload Image'
          open={cropperOpen}
          actions={actions}
          toggle={() => component.handleCloseCropper()}
        >
          {selectedFile !== null && (
            <div>
              <Cropper
                className='cropper'
                ref='cropper'
                src={selectedFile.preview}
                aspectRatio={aspectRatio}
                guides={false}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
ImageDropZone.defaultProps = {
  aspectRatio: 16 / 9,
};
export default DragDropContext(HTML5Backend)(ImageDropZone);
