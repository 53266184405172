/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Suspense } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Alert, ArrowUp, ArrowDown, Cart, Heart } from 'Utils/SvgIcons';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import MessagesTab from './MessagesTab';
import classnames from 'classnames';
import LoadingSpinner from 'Utils/LoadingSpinner';

class SignedInNav extends React.Component {
  state = {
    profile: {
      open: false,
    },
  };
  componentDidMount() {
    const { location, fetchProfile } = this.props;
    this.props.fetchCustomPages(location.id);
    fetchProfile(location.id);
  }
  toggleProfileMenu = (event) => {
    this.setState({
      profile: {
        open: !this.state.profile.open,
        anchorEl: event.currentTarget,
      },
    });
  };
  render() {
    const { profile } = this.state;
    const {
      user,
      event,
      storefrontShopSetting,
      mainSetting,
      customPages,
      signOutUser,
      loading,
      customer,
    } = this.props;
    const cartItemCount =
      event.items
        .filter((item) => item._destroy !== '1')
        .reduce((n, { quantity }) => n + quantity, 0) +
      event.rentalBundles
        .filter((item) => item._destroy !== '1')
        .reduce((n, { quantity }) => n + quantity, 0) +
      event.addOns
        .filter((item) => item._destroy !== '1')
        .reduce((n, { quantity }) => n + quantity, 0);

    const favoriteCustomPages = customPages.filter((scp) =>
      scp.favoritedCustomerIds.includes(user.id)
    );
    const expiredDocument =
      customer &&
      customer.documents &&
      customer.documents.find(
        (d) => d.expirable && d.expiresAt && new Date(d.expiresAt) < new Date()
      );

    const showFavorite = this.props.location.sfSubscriptionTier !== 'basic'
      && this.props.location.sfSubscriptionTier !== 'cart'
      && customPages.length > 0;

    if (loading) {
      return <LoadingSpinner />;
    }

    return (
      <nav className='mainMenu'>
        <div
          onMouseEnter={this.toggleProfileMenu}
          onMouseLeave={this.toggleProfileMenu}
          onClick={this.toggleProfileMenu}
          className={classnames({
            arrowPreScott: mainSetting.theme === 'preScott',
          })}
        >
          {expiredDocument && <Alert className='alert' />}
          Hello, {user.firstName}
          {profile.open ? <ArrowUp /> : <ArrowDown />}
          {profile.open && (
            <ul>
              <li>
                <Link to='/events'>Manage Your Orders</Link>
              </li>
              <li>
                <Link to='/profile'>My Profile Settings</Link>
              </li>
              <li>
                <a onClick={signOutUser}>Sign Out</a>
              </li>
            </ul>
          )}
        </div>
        <MessagesTab />
        {storefrontShopSetting.shopVisible && (
          <NavLink className='icon shoppingCart' to='/cart'>
            <Cart />
            <label>{cartItemCount > 0 && cartItemCount}</label>
          </NavLink>
        )}
        {
          showFavorite && (
            <NavLink className='icon favorites' to='/favorites'>
              <Heart />
              <label>
                {favoriteCustomPages.length > 0 && favoriteCustomPages.length}
              </label>
            </NavLink>
          )
        }
      </nav>
    );
  }
}
const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { location, parsedStorefrontSettings } = state.locations;
  const { storefrontShopSetting, mainSetting } = parsedStorefrontSettings;
  const { customPages } = state.customPages;
  const { event } = state.cart;
  const { customer, errors, loading } = state.customer;

  return {
    customer,
    errors,
    loading,
    user,
    location,
    event,
    storefrontShopSetting,
    mainSetting,
    customPages,
  };
};

export default connect(mapStateToProps, actions)(SignedInNav);
