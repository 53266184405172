import React from 'react';
import SmartNavLink from 'Utils/SmartNavLink';

const OrderAgreementLink = (props) => {
  return (
    <nav className='rentalAgreement'>
      <SmartNavLink id='rental_agreement_footer' link='/agreement'>
        Order Agreement
      </SmartNavLink>
    </nav>
  );
};

export default OrderAgreementLink;
