/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { standardService } from 'Api/service';
import service from 'Api/service';
import * as actions from 'Actions';
import { arraysEqual, debounce } from 'HelperFunctions/general';
import { setFilterParams, filterFromPropsAndState } from 'HelperFunctions/urls';
import PaginationBoxView from 'Utils/PaginationBoxView';
import CustomPageCard from './CustomPageCard';
import update from 'react-addons-update';
import Modal from 'Utils/Modal';
import SignInForm from 'Components/home/SignInForm';
import SignUpForm from 'Components/home/SignUpForm';
import PageMetaData from 'Utils/PageMetaData';

class CustomPages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter,
      pagination: {
        totalPages: 0,
      },
      customPages: [],
      favoriteAction: '',
      favoritingIndex: -1,
      signInOpen: false,
      signUpOpen: false,
    };

    this.handleQueryChange = debounce(this.handleQueryChange, 500);
  }

  componentDidMount() {
    const { filter } = this.props;

    this.fetchCustomPages(filter);
  }

  fetchCustomPages = (filter) => {
    const { location } = this.props;
    const { query, page } = filter;
    const component = this;
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/storefront_custom_pages/paginated',
        {
          query: query,
          page: page,
          number_per: 12,
          location_id: location.id,
        }
      )
      .then((response) => {
        component.setState({
          customPages: response.data.storefrontCustomPages,
          pagination: response.data.meta.pagination,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleFavorite = (index) => {
    const component = this;
    if (component.props.authenticated) {
      component.favoriteCustomPage(index);
    } else {
      component.setState({
        favoriteAction: 'favorite',
        favoritingIndex: index,
      });
      component.toggleSignIn();
    }
  };

  handleUnfavorite = (index) => {
    const component = this;
    if (component.props.authenticated) {
      component.unfavoriteCustomPage(index);
    } else {
      component.setState({
        favoriteAction: 'unfavorite',
        favoritingIndex: index,
      });
      component.toggleSignIn();
    }
  };

  favoriteCustomPage = (index) => {
    const customPage = this.state.customPages[index];
    const component = this;
    component.props.openLoadingSpinner('Favoriting...');
    service
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/storefront_custom_pages/' +
          customPage.id +
          '/favorite'
      )
      .then((response) => {
        const newState = update(this.state, {
          customPages: {
            [index]: { $set: response.data.storefrontCustomPage },
          },
        });
        component.props.receiveCustomPage(response.data.storefrontCustomPage);
        component.props.closeLoadingSpinner();
        component.setState(newState);
      })
      .catch((error) => {
        console.log(error);
        component.props.closeLoadingSpinner();
      });
  };

  unfavoriteCustomPage = (index) => {
    const customPage = this.state.customPages[index];
    const component = this;
    component.props.openLoadingSpinner('Unfavoriting...');
    service
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/storefront_custom_pages/' +
          customPage.id +
          '/unfavorite'
      )
      .then((response) => {
        const newState = update(this.state, {
          customPages: {
            [index]: { $set: response.data.storefrontCustomPage },
          },
        });
        component.props.receiveCustomPage(response.data.storefrontCustomPage);
        component.props.closeLoadingSpinner();
        component.setState(newState);
      })
      .catch((error) => {
        console.log(error);
        component.props.closeLoadingSpinner();
      });
  };

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;

    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        if (newFilter[property] instanceof Array) {
          if (arraysEqual(newFilter[property], oldFilter[property]) === false) {
            changed = true;
            break;
          }
        } else {
          const newTest =
            newFilter[property] instanceof Date
              ? newFilter[property].getTime()
              : newFilter[property];
          const oldTest =
            oldFilter[property] instanceof Date
              ? oldFilter[property].getTime()
              : oldFilter[property];
          if (newTest !== oldTest) {
            changed = true;
            break;
          }
        }
      }
    }

    if (changed) {
      const filterToSubmit = Object.assign(newFilter);
      this.fetchCustomPages(filterToSubmit);
    }
  }

  handleSearch = (event) => {
    const { value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        query: value,
      },
    });
    this.handleQueryChange(value);
  };

  handleQueryChange(value) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value,
      },
      location,
      history
    );
  }

  handlePageChange = (data) => {
    const { location, history } = this.props;
    setFilterParams(
      {
        page: data.selected + 1,
      },
      location,
      history
    );
  };

  handleSignIn = (data) => {
    const component = this;
    const { loginUser } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        const { favoriteAction, favoritingIndex } = component.state;
        if (favoriteAction === 'favorite') {
          component.favoriteCustomPage(favoritingIndex);
        } else if (favoriteAction === 'unfavorite') {
          component.unfavoriteCustomPage(favoritingIndex);
        }
        component.setState({
          signInOpen: false,
          signUpOpen: false,
          favoriteAction: '',
          favoritingIndex: -1,
        });
      },
    });
  };

  handleSignUp = (data) => {
    const component = this;
    const { createUser } = component.props;
    createUser({
      ...data,
      onSuccess: () => {
        const { favoriteAction, favoritingIndex } = component.state;
        if (favoriteAction === 'favorite') {
          component.favoriteCustomPage(favoritingIndex);
        } else if (favoriteAction === 'unfavorite') {
          component.unfavoriteCustomPage(favoritingIndex);
        }
        component.setState({
          signInOpen: false,
          signUpOpen: false,
          favoriteAction: '',
          favoritingIndex: -1,
        });
      },
    });
  };

  toggleSignUp = () => {
    this.setState({
      signUpOpen: !this.state.signUpOpen,
      signInOpen: this.state.signUpOpen,
    });
  };

  toggleSignIn = () => {
    this.setState({
      signInOpen: !this.state.signInOpen,
      signUpOpen: this.state.signInOpen,
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false,
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false,
    });
  };

  render() {
    const { filter, customPages, pagination, signInOpen, signUpOpen, errors } =
      this.state;

    const { storefrontSetting } = this.props;

    return (
      <div className='discoverTheLook'>
        <PageMetaData setting={storefrontSetting} />
        <header>
          <h1>Get the Look</h1>
          <div className='searchFilter'>
            <input
              type='search'
              placeholder='Search Ideas'
              onChange={this.handleSearch}
              value={filter.query}
            />
          </div>
        </header>
        <section>
          <div className='looks'>
            {customPages.map((cp, index) => (
              <CustomPageCard
                index={index}
                key={cp.id}
                customPage={cp}
                onFavorite={this.handleFavorite}
                onUnfavorite={this.handleUnfavorite}
              />
            ))}
          </div>
          <PaginationBoxView
            className='pagerWrapper'
            containerClassName='pager'
            forcePage={filter.page ? +filter.page - 1 : 0}
            pageCount={pagination.totalPages}
            onPageChange={this.handlePageChange}
          />
        </section>
        <Modal
          className='discoverSignInModal'
          title='Sign In'
          open={signInOpen}
          toggle={this.closeSignIn}
        >
          <h3>Looks like you're not signed in</h3>
          <p>Sign it to save your Get The Looks.</p>
          <SignInForm errors={errors} onSubmit={this.handleSignIn} />
          <p>
            <a onClick={this.toggleSignUp}>Sign Up for FREE</a>
            {' or '}
            <a onClick={this.closeSignIn}>Continue to Site</a>
          </p>
        </Modal>
        <Modal
          className='discoverSignInModal'
          title='Sign Up'
          open={signUpOpen}
          toggle={this.closeSignUp}
        >
          <SignUpForm errors={errors} onSubmit={this.handleSignUp} />
          <p>
            <a onClick={this.toggleSignIn}>or Sign In</a>
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const { authenticated, errors } = state.auth;
  const {
    location,
    parsedStorefrontSettings: { storefrontCustomPageSetting },
  } = state.locations;
  const storefrontSetting = storefrontCustomPageSetting;

  return {
    filter: newFilter,
    errors,
    location,
    authenticated,
    storefrontSetting,
  };
};

export default connect(mapStateToProps, actions)(CustomPages);
