/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactGA from 'react-ga';
import ReactTagManager from 'react-gtm-module';

import React from 'react';

class Tracking extends React.Component {
  componentDidMount() {
    const { parsedStorefrontSettings } = this.props;

    if (
      parsedStorefrontSettings &&
      parsedStorefrontSettings.mainSetting.gaCode
    ) {
      const gaCode = parsedStorefrontSettings.mainSetting.gaCode;
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GA_TRACKING_ID,
          gaOptions: {
            name: 'tgTracker',
          },
        },
        {
          trackingId: gaCode,
          gaOptions: {
            name: 'clientTracker',
          },
        },
      ]);
      ReactGA.ga('clientTracker.send', 'pageview', window.location.pathname);
    }
    if (
      parsedStorefrontSettings &&
      parsedStorefrontSettings.mainSetting.gtmId
    ) {
      const gtmId = parsedStorefrontSettings.mainSetting.gtmId;
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      ReactTagManager.initialize(tagManagerArgs);
    }
  }

  render() {
    return null;
  }
}

export default Tracking;
