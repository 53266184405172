import { parseQuery } from 'HelperFunctions/QueryString';
import { isSsr } from 'HelperFunctions/ssr';

export const parseStorefrontSettings = (location) => {
  const keys = [
    'storefrontShopSetting',
    'storefrontEventsSetting',
    'storefrontContactSetting',
    'storefrontCustomPages',
    'storefrontPages',
    'storefrontHomeSetting',
    'storefrontMenuSetting',
    'storefrontAboutusSetting',
    'storefrontCustomPageSetting',
    'storefrontSigninSetting',
  ];
  let parsedSetting = {};

  const parsed = parseQuery(isSsr() ? '' : window.location.search);

  keys.forEach((key) => {
    parsedSetting[key] = location[key] || {};
  });

  location.storefrontPages &&
    location.storefrontPages.forEach((storefrontPage) => {
      switch (storefrontPage.pageType) {
        case 'home':
          parsedSetting['storefrontHomeSetting'] = storefrontPage.pageSetting;
          return;
        case 'contact':
          parsedSetting['storefrontContactSetting'] =
            storefrontPage.pageSetting;
          return;
        case 'sign-in':
          parsedSetting['storefrontSigninSetting'] =
            storefrontPage.pageSetting;
          return;
        case 'about-us':
          parsedSetting['storefrontAboutusSetting'] =
            storefrontPage.pageSetting;
          return;
      }
    });

  parsedSetting['mainSetting'] = location.storefrontSetting;
  if (!parsed.preview && parsed.theme) {
    parsedSetting['mainSetting']['theme'] = parsed.theme;
  }

  return parsedSetting;
};
