/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { standardService } from "Api/service";
import LoadingSpinner from "Utils/LoadingSpinner";
import classnames from "classnames";
import { parseQuery } from "HelperFunctions/QueryString";

class NewFeedback extends React.Component {
  state = {
    location: null,
    customer: null,
    dataLoading: "loading",
    feedback: {
      rental_id: 0,
      customer_location_relationship_id: 0,
      score: 0,
      comment: ""
    },
    submitted: false
  };

  componentDidMount() {
    const { customerId, rentalId, locationId, score } = parseQuery(
      this.props.location.search
    );
    if (rentalId && customerId && locationId) {
      const component = this;
      standardService
        .all([
          this.getLocation(locationId),
          this.getCustomer(customerId),
          this.checkIfSubmittedAlready(customerId, rentalId)
        ])
        .then(
          standardService.spread((buss, cust, check) => {
            const submitted = check.data ? true : false;

            const newFeedback = Object.assign(component.state.feedback, {
              customer_location_relationship_id: customerId,
              rental_id: rentalId,
              score: parseInt(score, 10)
            });
            // Both requests are now complete
            component.setState({
              location: buss.data.location,
              customer: cust.data.customerLocationRelationship.customer,
              dataLoading: "success",
              feedback: newFeedback,
              submitted
            });
          })
        )
        .catch(function(error) {
          console.log(error);
          //  saveError(error)
        });
    } else {
      this.setState({
        dataLoading: "missing"
      });
    }
  }

  handleSubmit = () => {
    const { history, setErrors } = this.props;
    const { feedback } = this.state;
    const { locationId } = parseQuery(this.props.location.search);
    standardService
      .post(process.env.REACT_APP_API_DOMAIN + "/api/feedbacks", { feedback })
      .then(response => {
        history.push(`/feedbacks/thankyou?locationId=${locationId}`);
      })
      .catch(error => {
        setErrors(error.response.data);
      });
  };

  getLocation = id => {
    return standardService.get(
      process.env.REACT_APP_API_DOMAIN + "/api/guest/locations/" + id
    );
  };

  getCustomer = id => {
    return standardService.get(
      process.env.REACT_APP_API_DOMAIN +
        "/api/guest/customer_location_relationships/" +
        id
    );
  };

  checkIfSubmittedAlready = (customerId, rentalId) => {
    return standardService.get(
      process.env.REACT_APP_API_DOMAIN + "/api/feedbacks/check_for_existing",
      {
        customer_location_relationship_id: customerId,
        rental_id: rentalId
      }
    );
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const newFeedback = Object.assign(this.state.feedback, {
      [name]: value
    });
    this.setState({
      feedback: newFeedback
    });
  };

  handleChangeScore = newScore => {
    const newFeedback = { ...this.state.feedback, score: newScore };
    this.setState({
      feedback: newFeedback
    });
  };

  renderScoreBoard = () => {
    let { feedback } = this.state;
    const options = [];
    for (let i = 0; i <= 10; i++) {
      const index = i;
      options.push(
        <li
          key={i}
          className={classnames({
            ["nps" + i]: true,
            active: feedback.score === i
          })}
        >
          <a onClick={() => this.handleChangeScore(index)}>{i}</a>
        </li>
      );
    }
    return options;
  };

  renderContent() {
    const { dataLoading, location, submitted, feedback } = this.state;

    if (dataLoading === "success") {
      return submitted ? (
        <div>
          <h3>You have already submitted a review for this rental.</h3>
          <div
            className={classnames({
              score: true,
              ["nps" + feedback.score]: true
            })}
          />
        </div>
      ) : (
        <div>
          <h3>How likely are you to recommend {location.name}?</h3>
          <div
            className={classnames({
              score: true,
              ["nps" + feedback.score]: true
            })}
          />
          <ul>{this.renderScoreBoard()}</ul>
          <div className="details">
            <label>Share any feedback here:</label>
            <textarea name="comment" onChange={this.handleChange} />
            <div className="actions">
              <a className="btn full feedback-button" onClick={this.handleSubmit}>
                Submit
              </a>
            </div>
          </div>
        </div>
      );
    } else if (dataLoading === "loading") {
      return (
        <div>
          <LoadingSpinner label="Loading Info..." />
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Oops, we could not find necessary info. Please make sure you have
            navigated to the proper url.
          </p>
        </div>
      );
    }
  }

  render() {
    return <div className="feedback">{this.renderContent()}</div>;
  }
}

export default connect(null, actions)(NewFeedback);
