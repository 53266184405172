import axios from 'axios';
import * as actionTypes from 'Constants/redux';

export const fetchLocationFromToken = (token) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_LOCATION_REQUEST });

    return axios
      .get(process.env.REACT_APP_API_DOMAIN + '/api/external_login', {
        params: {
          token: token,
        },
      })
      .then((response) => {
        let domain = '';
        if (response.data.location.storefrontSetting.storefrontEnabled) {
          domain = response.data.location.storefrontSetting.domain;
        } else {
          domain = response.data.location.subdomain + '.tapgoods.com';
        }

        if (window.location.port !== '') {
          domain = domain + ':' + window.location.port;
        }

        window.location.replace(
          `https://${domain}/makeAPayment?token=${token}`
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_LOCATION_FAILURE,
        });
      });
  };
};
