import {
  SET_CART_PROPS,
  CLEAR_CART_PROPS,
  SET_CART_NEW_EVENT_PROPS,
  SET_CART_NEW_CUSTOMER_PROPS,
  SET_CART_TRANSACTION_PROPS,
  INIT_CART_PROPS,
  START_ADDING_ITEMS_TO_EVENT,
  STOP_ADDING_ITEMS_TO_EVENT,
  REFRESH_CART_ITEMS,
} from 'Constants/redux';
import { isSsr } from 'HelperFunctions/ssr';
import { restoreCart } from 'HelperFunctions/general';

let start = new Date();
let end = new Date();
start.setHours(start.getHours() + 1, 0, 0, 0);
end.setDate(end.getDate() + 1);
end.setHours(end.getHours() + 1, 0, 0, 0);

const cartEvent = restoreCart();

const INITIAL_CART = {
  eventStart: null,
  eventStartTime: start,
  eventEnd: null,
  eventEndTime: end,
  approvalStatus: 'pending',
  name: '',
  items: [],
  rentalBundles: [],
  addOns: [],
  customerContactPhone: '',
  deliveryType: 'none',
  deliveryCost: 0,
  deliveryAddressLocationName: '',
  deliveryAddressStreetAddress1: '',
  deliveryAddressStreetAddress2: '',
  deliveryAddressCity: '',
  deliveryAddressLocale: '',
  deliveryAddressPostalCode: '',
  preferredDeliveryWindow: '',
  preferredPickupWindow: '',
  pickupSameAsDelivery: true,
  pickupAddressLocationName: '',
  pickupAddressStreetAddress1: '',
  pickupAddressStreetAddress2: '',
  pickupAddressCity: '',
  pickupAddressLocale: '',
  pickupAddressPostalCode: '',
  taxTotal: 0,
  version: 1,
  editingEvent: false,
};

const INITIAL_STATE = {
  event: cartEvent || INITIAL_CART,
  newEvent: INITIAL_CART,
  newCustomer: {
    firstName: '',
    lastName: '',
    email: '',
  },
  loading: false,
  addingItems: false,
};

export const formatEvent = (event) => {
  const items = event.items.map((item) => {
    return item;
  });

  const addOns = event.addOns.map((item) => {
    return item;
  });

  const rentalBundles = event.rentalBundles.map((item) => {
    return item;
  });

  return {
    ...event,
    items,
    addOns,
    rentalBundles,
  };
};

export default (state = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case SET_CART_PROPS:
      const formattedEvent = formatEvent(state.event, action.payload.location);
      const newEvent = {
        ...formattedEvent,
        ...action.payload,
      };
      newState = { ...state, event: newEvent };
      break;
    case CLEAR_CART_PROPS:
      newState = INITIAL_STATE;
      break;
    case SET_CART_NEW_EVENT_PROPS:
      newState = {
        ...state,
        newEvent: { ...state.newEvent, ...action.payload },
      };
      break;
    case SET_CART_NEW_CUSTOMER_PROPS:
      newState = {
        ...state,
        newCustomer: { ...state.newCustomer, ...action.payload },
      };
      break;
    case SET_CART_TRANSACTION_PROPS:
      newState = {
        ...state,
        ...state.transaction,
        ...action.payload,
      };
      break;
    case START_ADDING_ITEMS_TO_EVENT:
      const event = {
        ...state.event,
        ...action.payload,
      };
      newState = { ...state, event, addingItems: true };
      break;
    case STOP_ADDING_ITEMS_TO_EVENT:
      newState = { ...state, addingItems: false };
      break;
    case REFRESH_CART_ITEMS:
      const products = action.products;
      const cartItems = [...state.event.items];

      cartItems.forEach((item, index) => {
        cartItems[index].product =
          products.find(
            (product) =>
              product.id === item.product?.id &&
              product.productType === item.product?.productType
          ) || item.product;
      });

      const newEventWithRefreshedItems = { ...state.event, items: cartItems };
      newState = { ...state, event: newEventWithRefreshedItems };
      break;
    case INIT_CART_PROPS:
      newState = {
        event: INITIAL_CART,
        newCustomer: {
          firstName: '',
          lastName: '',
          email: '',
        },
        loading: false,
        addingItems: false,
      };
      break;
    default:
      newState = state;
      break;
  }

  if (
    newState.event.items.length !== 0 ||
    newState.event.rentalBundles.length !== 0 ||
    newState.event.addOns.length !== 0 ||
    newState.event.eventStartTime !== INITIAL_CART.eventStartTime ||
    newState.event.eventEndTime !== INITIAL_CART.eventEndTime
  ) {
    if (!isSsr())
      localStorage.setItem('cartEvent', JSON.stringify(newState.event));
  } else {
    if (!isSsr()) localStorage.removeItem('cartEvent');
    newState = {
      event: {
        ...INITIAL_CART,
        customerContactPhone: newState.event.customerContactPhone,
      },
      newEvent: newState.newEvent,
      newCustomer: newState.newCustomer,
      loading: false,
      addingItems: false,
    };
  }

  return newState;
};
