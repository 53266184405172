import React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { Quotes } from "Utils/SvgIcons";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { isMobile } from "HelperFunctions/isMobile";

class TestimonialBox extends React.Component {
  render() {
    const { testimonials, theme } = this.props;
    let params = {
      slidesPerView: (isMobile() || theme !== 'preScott') ? 1 : 3,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      }
    };
    if (testimonials.length > 1) {
      params["navigation"] = {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      };
    }
    if (testimonials.length > 0) {
      return (
        <div className={classnames({
           testimonials: true,
           hideButtons: testimonials.length === 1,
        })}
          >
          <Swiper {...params}>
            {testimonials.map(testimonial => (
              <div key={testimonial.id}>
                <Quotes />
                <p>{testimonial.description}</p>
                <span className="name">{testimonial.name}</span>
                {testimonial.title && (
                  <span className="title">{testimonial.title}</span>
                )}
              </div>
            ))}
          </Swiper>
        </div>
      );
    } else {
      return <div className="testimonials"></div>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const storefrontSetting = parsedStorefrontSettings.mainSetting;
  return { testimonials: storefrontSetting.testimonials, theme: storefrontSetting.theme };
};

export default connect(mapStateToProps)(withRouter(TestimonialBox));
