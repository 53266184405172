import axios from "axios";
import {
  FETCH_RENTALS_REQUEST,
  FETCH_RENTALS_SUCCESS,
  FETCH_RENTALS_FAILURE
} from "Constants/redux";

import { saveAuthToken, getAuthToken, saveError } from "Api";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

// fetchRentals is called to grab the rentals from backend,
// and call the appropriate redux action to save it correctly or error out.

export const fetchRentals = params => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Loading rentals..."));
    dispatch({ type: FETCH_RENTALS_REQUEST });

    const { dateType } = params;
    let url =
      process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/paginated";
    if (dateType === "event") {
      url =
        process.env.REACT_APP_API_DOMAIN +
        "/api/portal/rentals/paginated_by_event_date";
    }
    return axios
      .get(url, {
        params: params,
        headers: getAuthToken()
      })
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        const { rentals, meta } = response.data;
        dispatch({
          type: FETCH_RENTALS_SUCCESS,
          payload: { rentals, meta }
        });
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: FETCH_RENTALS_FAILURE
        });
        saveError(error, getState());
      });
  };
};

export const fetchRentalsList = params => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Loading rentals..."));
    dispatch({ type: FETCH_RENTALS_REQUEST });

    let url = process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/list";

    return axios
      .get(url, {
        params: params,
        headers: getAuthToken()
      })
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        const { rentals, meta } = response.data;
        dispatch({
          type: FETCH_RENTALS_SUCCESS,
          payload: { rentals, meta }
        });
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: FETCH_RENTALS_FAILURE
        });
        saveError(error, getState());
      });
  };
};
