import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import PhoneInputField from "Utils/redux_form_inputs/PhoneInputField";
import Checkbox from "Utils/Checkbox";
import Validator from "HelperFunctions/validator";
import { setErrors } from "Actions";

const validations = {
  firstName: {
    required: {
      value: true,
      message: "First Name is required"
    }
  },
  email: {
    required: {
      value: true,
      message: "Email is required"
    }
  },
  password: {
    required: {
      value: true,
      message: "Password is required"
    }
  }
};

class SignUpForm extends React.Component {
  state = {
    showPassword: false
  };

  componentDidMount() {
    const { customer } = this.props;
    if (customer) {
      this.props.initialize(customer);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { customer } = nextProps;
    if (customer && customer !== this.props.customer) {
      this.props.destroy();
      this.props.initialize(customer);
    }
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      customer,
    } = this.props;

    const { showPassword } = this.state;
    return (
      <div className="signUpForm">
        <form onSubmit={handleSubmit} className="form">
          <div className="fields">
            {customer && customer.email ?
              <p>{customer.email}</p>
              :
              <Field
                name="email"
                component={StandardTextField}
                type="text"
                placeholder="Email*"
              />
            }
            <Field
              name="firstName"
              component={StandardTextField}
              type="text"
              placeholder="First Name*"
            />
            <Field
              name="lastName"
              component={StandardTextField}
              type="text"
              placeholder="Last Name"
            />
            <Field
              name="password"
              component={StandardTextField}
              type={showPassword ? "text" : "password"}
              placeholder="Password*"
            />
            <Field
              name="phone"
              component={PhoneInputField}
              type="text"
              placeholder="Phone number"
            />
            <Checkbox
              checked={showPassword}
              label="Show Password"
              onCheck={this.toggleShowPassword}
            />
          </div>
          <div className="actions">
            <button
              className="full"
              type="submit"
              disabled={pristine || submitting}
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default reduxForm({
  form: "SignUp", // a unique identifier for this form
  validate: Validator(validations),
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(SignUpForm);
