/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import EventsTable from "./EventsTable";
import classnames from "classnames";
import { Alert } from "Utils/SvgIcons";
import { Link } from "react-router-dom";

class EventsBox extends React.Component {
  state = {
    eventType: "upcoming",
    upcomingEventAlert: false
  };

  componentDidMount() {
    this.refreshEvents();
  }

  handleEventTypeChange = eventType => {
    this.setState(
      {
        eventType: eventType
      },
      this.refreshEvents
    );
  };

  showUpcomingEventsAlert = () => {
    this.setState({
      upcomingEventAlert: true
    });
  };

  refreshEvents = () => {
    const { location, fetchRentalsList } = this.props;
    const { eventType } = this.state;
    fetchRentalsList({ type: eventType, location_id: location.id });
  };

  render() {
    const { eventType, upcomingEventAlert } = this.state;

    return (
      <div className="homeEvents">
        <aside className="sideNav">
          <h3>Manage Your Orders</h3>
          <nav>
            <a
              onClick={() => this.handleEventTypeChange("quotes")}
              className={classnames({ active: eventType === "quotes" })}
            >
              Quotes
            </a>
            <a
              onClick={() => this.handleEventTypeChange("upcoming")}
              className={classnames({ active: eventType === "upcoming" })}
            >
              Upcoming Orders
              {upcomingEventAlert && <Alert />}
            </a>
            <a
              onClick={() => this.handleEventTypeChange("past")}
              className={classnames({ active: eventType === "past" })}
            >
              Past Orders
            </a>
            <a
              onClick={() => this.handleEventTypeChange("cancelled")}
              className={classnames({ active: eventType === "cancelled" })}
            >
              Cancelled Orders
            </a>
          </nav>
          <Link className="btn outline" to="/events">
            See All Orders
          </Link>
        </aside>
        <EventsTable showUpcomingEventsAlert={this.showUpcomingEventsAlert} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = state.locations;
  return { location };
};

export default connect(mapStateToProps, actions)(EventsBox);
