import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

class Trim extends React.Component {
  render() {
    const { location } = this.props;
    const current = location.pathname;

    return (
      <div
        className={classnames({
          trim: true,
          home:
            current === '/' || current === '/home' || current === '/about-us',
          login:
            current === '/login' ||
            current === '/signup' ||
            current === '/forgotpassword' ||
            current === '/passwordreset',
        })}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const storefrontSetting = parsedStorefrontSettings.mainSetting;
  return { storefrontSetting };
};

export default connect(mapStateToProps)(Trim);
