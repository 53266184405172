import React from 'react';
import DatePicker from "../utils/DatePicker";
import TimePicker from "../utils/TimePicker";

class DatePickerComponentSection extends React.Component {
  render() {
    const {
      handleDateChange,
      eventStart,
      eventEnd,
      eventStartTime,
      eventEndTime,
      themeSetting
    } = this.props;

    return (
        <section className={`getTheLookDateRange ${themeSetting.theme}`}>
          <div className='getTheLookDateRangeWrapper'>
            <span className={`label ${themeSetting.theme}`}>Order Start</span>
            <div className={`eventDatePicker ${themeSetting.theme}`}>
              <DatePicker
                name='eventStart'
                value={eventStart}
                onChange={handleDateChange}
              />
              <TimePicker
                name='eventStartTime'
                value={eventStartTime}
                onChange={handleDateChange}
              />
            </div>
            <span className="separator"></span>
            <span className={`label ${themeSetting.theme}`}>Order End</span>
            <div className={`eventDatePicker ${themeSetting.theme}`}>
              <DatePicker
                name='eventEnd'
                value={eventEnd}
                onChange={handleDateChange}
                startDate={eventStart ? eventStart : new Date()}
              />
              <TimePicker
                name='eventEndTime'
                value={eventEndTime}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </section>
    );
  }
}

export default DatePickerComponentSection;
