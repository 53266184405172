import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getHostname, getPathname } from 'HelperFunctions/general';

class Homepage extends React.Component {
  render() {
    const { parsedStorefrontSettings, storeLocation, location } = this.props;
    const menuItems =
      parsedStorefrontSettings.storefrontMenuSetting.storefrontMenuItems;
    for (let menuItem of menuItems) {
      const hostname = getHostname(menuItem.menuLink);
      const pathname = getPathname(menuItem.menuLink);
      if (
        (!hostname || hostname === storeLocation.currentHostname) &&
        pathname !== location.pathname
      ) {
        return <Redirect to={pathname} />;
      }
    }
    return <Redirect to='/events' />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings, location } = state.locations;
  return {
    parsedStorefrontSettings,
    storeLocation: location,
  };
};

export default connect(mapStateToProps)(Homepage);
