/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { FormattedNumber } from "react-intl";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import Modal from "Utils/Modal";
import CurrencyInput from "Utils/CurrencyInput";
import Radio from "Utils/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CreditCardForm from "Components/profile/creditCards/CreditCardForm";
import PropayBankForm from "Components/profile/bankAccounts/PropayBankForm";
import { MakeAPayment } from "Utils/SvgIcons";
import PlaidLink from "Utils/login/PlaidLink";
import validations from "HelperFunctions/validations";
import { toNumber } from "HelperFunctions/general";
import converter from "json-style-converter/es5";
import ScrollArea from "Utils/react-scrollbar";
import CurrencyLabel from "Utils/CurrencyLabel";
import WorldpayBankForm from "Components/profile/bankAccounts/WorldpayBankForm";
import CreditCardFormForCompany from "../profile/creditCards/CreditCardFormForCompany";

class ConnectPaymentBox extends React.Component {
  state = {
    paymentSelection: "new_credit_card",
    open: false,
    successOpen: false,
    successRefundOpen: false,
    errors: {},
    loading: false,
    new_card: null,
    new_bank: null,
    initialBillingAddress: null,
    amountType: "",
    customAmount: ""
  };

  componentDidMount() {
    const { rental } = this.props;
    let amountType;
    if (!rental.hasDeposit) {
      amountType = "amountDue";
    } else {
      if (rental.amountRemaining > 0) {
        amountType = "amountRemaining";
      } else {
        amountType = "";
      }
    }
    this.setState({
      amountType: amountType,
      customAmount: this.getPaymentAmount(amountType)
    });
  }

  componentWillReceiveProps(nextProps) {
    const { rental } = nextProps;
    if (
      rental.hasDeposit !== this.props.rental.hasDeposit ||
      rental.amountRemaining !== this.props.rental.amountRemaining
    ) {
      let amountType;
      if (!rental.hasDeposit) {
        amountType = "amountDue";
      } else {
        if (rental.amountRemaining > 0) {
          amountType = "amountRemaining";
        } else {
          amountType = "";
        }
      }
      this.setState({
        amountType: amountType,
        customAmount: this.getPaymentAmount(amountType)
      });
    }
  }

  isOnlyLetters = (str) => {
    let strWithoutSpaces = str.replace(/\s/g, '');
    return /^[a-zA-Z]+$/.test(strWithoutSpaces);
  };

  isOnlyNumbers = (str) => {
    let strWithoutSpaces = str.replace(/\s/g, '');
    return /^\d+$/.test(strWithoutSpaces);
  }

  validate = () => {
    const errors = {};
    const { rental } = this.props;
    const amountDue = rental.amountRemaining
    const { paymentSelection, transaction, customAmount } = this.state;
    if (
      paymentSelection === "credit_card" &&
      validations.required(transaction.card_token, true)
    ) {
      errors.body = "Please select a credit card.";
    }
    if (
      paymentSelection === "bank" &&
      validations.required(transaction.bank_token, true)
    ) {
      errors.body = "Please select a bank account.";
    }
    if (
      customAmount > amountDue || customAmount < 0
    ) {
      errors.body = "The Custom Amount you entered is invalid!";
    }
    return errors;
  };

  validateMerchantAccount = (location, paymentProcessor) => {
    const error = {};
    if (location.isOnTrial) {
      error.merchantAccount = 'Location is on trial and cannot accept payments';
    }
    if (
      paymentProcessor == 'stripe' &&
      !location.hasStripeAuthentication
    ) {
      error.merchantAccount = 'Merchant account is not setup for Stripe';
    }
    if (
      paymentProcessor == 'propay' &&
      !location.hasPropayAuthentication
    ) {
      error.merchantAccount = 'Merchant account is not setup for Propay';
    }
    if (
      (paymentProcessor == 'worldpay' || paymentProcessor == 'launchpay') &&
      !location.hasWorldpayAuthentication
    ) {
      error.merchantAccount = 'Merchant account is not setup.';
    }

    return error;
  }

  validateTransactionAmountAndType = (transaction, amount) => {
    const error = {};
    const { payment_type } = transaction;
    const cardTypes = ['new_credit_card', 'credit_card'];

    if (amount <= 0) {
      error.noAmount = 'Charge amount must be greater than 0.';
    }

    if (cardTypes.includes(payment_type) && amount < 0.5) {
      error.amount = 'The minimum charge is $0.50.';
    } else if (payment_type === 'new_bank' && amount < 1.5) {
      error.amount = 'The minimum bank charge is $1.50.';
    }

    return error;
  }

  validateNewCreditCard = (card) => {
    const errors = {};

    let today = new Date();
    let selectedMonth = document.getElementById('credit_card_month_select_list')
      ?.value;
    let selectedYear = document.getElementById('credit_card_year_select_list')
      ?.value;
    let expirationDate = new Date();
    expirationDate.setFullYear(selectedYear, selectedMonth, 0);

    if (expirationDate < today) {
      errors.expired = 'Card expiration dates are invalid.';
    }
    if (validations.creditcard(card.number)) {
      if (!card.number) {
        errors.number = 'Card number cannot be blank.';
      } else {
        errors.number = 'Card number is not valid.';
      }
    }
    if (!card.cvc) {
      errors.cvc = 'CVC security code is invalid.';
    }
    if (!card.country) {
      errors.country = 'Country cannot be blank.';
    }
    const validateField = (field, errorMessage, errorKey) => {
      if (card.country !== 'INT' && !field) {
        errors[errorKey] = errorMessage;
      }
    };
    validateField(card.city, 'City cannot be blank.', 'city');
    validateField(card.street_address1, 'Billing Address cannot be blank.', 'address');
    validateField(card.locale, 'Province / State cannot be blank.', 'locale');
    validateField(card.postal_code, 'Zip code cannot be blank.', 'zipcode');

    if (!card.name) {
      errors.name = 'Cardholder name cannot be blank.';
    }
    if (!this.isOnlyLetters(card.name)) {
      errors.name = 'Cardholder name must be letters only.';
    }

    return errors;
  }

  validateNewBank = (newBank) => {
    const errors = {};
    const { account_number, bank_number, first_name, last_name } = newBank;

    if (!account_number) {
      errors.accountNumber = 'Account number cannot be blank.';
    }
    if (!this.isOnlyNumbers(account_number)) {
      errors.accountNumber = 'Account number must contain only numbers.'
    }
    if (!bank_number) {
      errors.bankNumber = 'Routing number cannot be blank.';
    }
    if (!this.isOnlyNumbers(bank_number)) {
      errors.notNumber = 'Routing number must contain only numbers.'
    }
    if (bank_number.length !== 9) {
      errors.bankNumber = 'Routing number must be 9 digits.';
    }
    if (!this.isOnlyLetters(first_name) || !this.isOnlyLetters(last_name)) {
      errors.accountName = 'Account name must be letters only.';
    }

    return errors;
  }

  validatePayments = (transaction, location, paymentProcessor) => {
    const error = {};
    const { payment_type } = transaction;
    const { hasWorldpayAuthentication } = location;
    const types = ['credit_card', 'new_credit_card'];

    if (
      types.includes(payment_type) &&
      !hasWorldpayAuthentication &&
      (paymentProcessor == 'worldpay' || paymentProcessor == 'launchpay')
    ) {
      error.paymentError =
        `This businesss isn't ready to accept ${paymentProcessor} payments.`;
    }
    if (payment_type === 'new_bank' && !hasWorldpayAuthentication) {
      error.paymentError = "This business isn't ready to accept bank payments.";
    }

    return error;
  };

  validateCardType = (cardType) => {
    const error = {};

    if (cardType === 'Select Card Type') {
      error.cardType = 'A card type must be selected.';
    }

    return error;
  }

  validateBankType = (paymentMethodType, checkType, companyName) => {
    const error = {};

    if (paymentMethodType === 'Select Payment Method Type') {
      error.bankType = 'You must select an account type.';
    }
    if (checkType === 'Check Type') {
      error.checkType = 'You must select a check type.';
    }
    if (checkType === 'Business' && !companyName) {
      error.companyName = 'Please enter a Company name.';
    }

    return error;
  }

  buildRailsObject = () => {
    const { paymentSelection } = this.state;
    if (paymentSelection === "new_credit_card") {
      return this.buildCardRailsObject();
    } else {
      return this.buildBankRailsObject();
    }
  };

  buildCardRailsObject = () => {
    const { new_card, customAmount } = this.state;
    const { rental, location, customer, customerType } = this.props;

    let primaryContact;

    if (rental.companyRentalRelationship == null) {
      primaryContact = rental.customerRentalRelationships.find((cr) => cr.isPrimaryContact == true)
    } else {
      if (rental.companyRentalRelationship.isPrimaryContact == true) {
        primaryContact = rental.companyRentalRelationship
      } else {
        primaryContact = rental.customerRentalRelationships.find((cr) => cr.isPrimaryContact == true)
      }
    }

    return {
      new_card,
      transaction: {
        amount: customAmount,
        payment_type: "new_credit_card",
        payment_source: customer.id != undefined ? "guest" : "true_guest",
        customer_id: primaryContact?.clientId ? primaryContact?.clientId : primaryContact?.companyId,
        customer_type: primaryContact?.clientType ? primaryContact?.clientType : "Company"
      },
      billing_note: {
        author_name: "Guest",
        message: "From Storefront",
        note_type: "Payment"
      },
      location_id: location.id,
      send_receipt: true
    };
  };
  buildBankRailsObject() {
    const { new_bank, customAmount } = this.state;
    const { rental, location, customer, customerType } = this.props;

    let primaryContact;

    if (rental.companyRentalRelationship == null) {
      primaryContact = rental.customerRentalRelationships.find((cr) => cr.isPrimaryContact == true)
    } else {
      if (rental.companyRentalRelationship.isPrimaryContact == true) {
        primaryContact = rental.companyRentalRelationship
      } else {
        primaryContact = rental.customerRentalRelationships.find((cr) => cr.isPrimaryContact == true)
      }
    }
    return {
      new_bank,
      transaction: {
        amount: customAmount,
        payment_type: "new_bank",
        payment_source: customer.id != undefined ? "guest" : "true_guest",
        customer_id: primaryContact?.clientId ? primaryContact?.clientId : primaryContact?.companyId,
        customer_type: primaryContact?.clientType ? primaryContact?.clientType : "Company"
      },
      billing_note: {
        author_name: "Guest",
        message: "From Storefront",
        note_type: "Payment"
      },
      location_id: location.id,
      send_receipt: true
    };
  }


  handleSubmitNewCreditCard = values => {
    const errors = this.validateNewCreditCard(values);
    if (Object.keys(errors).length > 0) {
      this.props.setErrors(errors);
    } else {
      this.setState(
        {
          new_card: values
        },
        this.handleSubmit
      );
    }
  };

  handleSubmitNewPropayBank = values => {
    this.setState(
      {
        new_bank: values
      },
      this.handleSubmit
    );
  };

  handleSubmitNewBank = (token, metadata) => {
    const new_bank = {
      token: token,
      account_id: metadata.account_id
    };
    this.setState(
      {
        new_bank: new_bank
      },
      this.handleSubmit
    );
  };

  handleSubmit = () => {
    const component = this;
    const { new_card, customAmount, new_bank, paymentSelection } = this.state;
    const validateErrors = this.validate();
    const {
      rental,
      openLoadingSpinner,
      closeLoadingSpinner,
      onPaymentSuccess,
      location,
    } = this.props;
    const cardTypes = ['new_credit_card', 'credit_card'];
    const bankTypes = ['new_bank', 'bank'];
    let transactionObject;
    let newCardErrors;
    let cardTypeError;
    let newBankErrors;
    let bankTypeErrors;
    let paymentMethodType = document.getElementsByName("payment_method_type")[0]?.value;
    let checkType;
    let companyName;
    const paymentProcessor = rental?.paymentProcessor;

    if (cardTypes.includes(paymentSelection)) {
      if (paymentSelection === 'new_credit_card') {
        newCardErrors = this.validateNewCreditCard(new_card);
      }
      transactionObject = this.buildCardRailsObject();
      cardTypeError = this.validateCardType(paymentMethodType)
    } else if (bankTypes.includes(paymentSelection)) {
      if (paymentSelection === 'new_bank' && paymentProcessor !== 'stripe') {
        newBankErrors = this.validateNewBank(new_bank)
      }
      transactionObject = this.buildBankRailsObject();
      checkType = document.getElementsByName("check_type")[0]?.value;
      companyName = document.getElementsByName("company_name")[0]?.value;
      bankTypeErrors = this.validateBankType(paymentMethodType, checkType, companyName);
    }
    const { transaction } = transactionObject;
    const merchantAccountErrors = this.validateMerchantAccount(location, paymentProcessor);
    const transactionAmountAndTypeErrors = this.validateTransactionAmountAndType(transaction, customAmount);
    const paymentValidationErrors = this.validatePayments(transaction, location, paymentProcessor)
    const errors = {
      ...validateErrors,
      ...merchantAccountErrors,
      ...transactionAmountAndTypeErrors,
      ...newCardErrors,
      ...newBankErrors,
      ...cardTypeError,
      ...bankTypeErrors,
      ...paymentValidationErrors
    }
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors
      });
      const { setErrors } = this.props;
      setErrors(errors);
    } else {
      openLoadingSpinner(
        "Processing payment... Please do not refresh or navigate away from this page."
      );
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN +
          "/api/portal/rentals/" +
          rental.token +
          "/transactions",
          this.buildRailsObject()
        )
        .then(response => {
          closeLoadingSpinner();
          onPaymentSuccess(response.data.transaction);
          component.setState({
            successOpen: true,
            open: false,
            errors: {}
          });
        })
        .catch(error => {
          console.log(error);
          const errors = error.response.data;
          const { setErrors } = this.props;
          closeLoadingSpinner();
          setErrors(errors);
          component.setState({
            errors: errors
          });
        });
    }
  };

  handleSuccessClose = () => {
    this.setState({
      successOpen: false
    });
  };

  handleAmountTypeChange = event => {
    const newAmountType = event.target.value;
    this.setState({
      amountType: newAmountType,
      customAmount: this.getPaymentAmount(newAmountType)
    });
  };

  getPaymentAmount = amountType => {
    const { rental } = this.props;
    const amountDue = rental.hasDeposit
      ? 0
      : Number(rental.minimumDeposit) - Number(rental.paymentBalance);
    switch (amountType) {
      case "amountDue":
        return amountDue;
      case "amountRemaining":
        return rental.amountRemaining;
      default:
        return 0;
    }
  };

  renderCreditCardFee = () => {
    const { creditCardPercent } = this.props;
    const { paymentSelection, customAmount } = this.state;
    const percent = creditCardPercent / 100.0;
    const { location } = this.props;
    const shouldShowFee = location.chargeCreditCardFee;

    if (paymentSelection === "new_credit_card" && percent > 0 && shouldShowFee) {
      if (customAmount) {
        const newAmount = Number(customAmount) * percent;
        return (
          <p>
            You will be charged{" "}
            <CurrencyLabel
              value={newAmount}
            />{" "}
            for credit card fees.
          </p>
        );
      } else {
        return (
          <p>
            You will be charged{" "}
            <FormattedNumber
              value={percent || 0}
              // eslint-disable-next-line react/style-prop-object
              style="percent"
              minimumFractionDigits={2}
              minimumSignificantDigits={3}
            />{" "}
            for credit card fees.
          </p>
        );
      }
    } else {
      return null;
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: toNumber(value)
    });
  };

  copyAddress = (event, isChecked) => {
    if (isChecked) {
      const {
        customer: { customer }
      } = this.props;
      const customerAddress = {
        streetAddress1: customer.streetAddress1,
        streetAddress2: customer.streetAddress2,
        city: customer.city,
        postalCode: customer.postalCode,
        locale: customer.locale,
        country: customer.country
      };
      this.setState({
        initialBillingAddress: converter.camelToSnakeCase(customerAddress)
      });
    }
  };

  isPropayInUSA = (rental, location) => rental.paymentProcessor === "propay" && location.propayCountry === "USA";
  isWorldpayOrLaunchpayWithAchqData = (rental, location) => ['worldpay', 'launchpay'].includes(rental.paymentProcessor) && location.hasAchqData;
  isOtherPaymentProcessor = (rental) => rental.paymentProcessor !== 'propay' && rental.paymentProcessor !== 'worldpay' && rental.paymentProcessor !== 'launchpay';


  render() {
    const {
      errors,
      paymentSelection,
      customAmount,
      amountType
    } = this.state;
    const { location, rental, customerType } = this.props;
    return (
      <section>
        <div className="summary">
          <MakeAPayment />
          <h4>{rental.locationName}</h4>
          <div className="billingDetails">
            {!rental.hasDeposit && rental.minimumDeposit !== "" && (
              <div>
                <label>Minimum Deposit</label>
                <CurrencyLabel
                  value={
                    Number(rental.minimumDeposit) -
                    Number(rental.paymentBalance)
                  }
                />
              </div>
            )}
            <div>
              <label>Amount Remaining</label>
              <CurrencyLabel value={rental.amountRemaining} />
            </div>
          </div>
        </div>
        <ScrollArea speed={0.8} horizontal={false}>
          <div className="details">
            <div className="fields amount">
              <label>Payment Amount</label>
              <RadioGroup
                name="amountType"
                className="radioList"
                value={amountType}
                onChange={this.handleAmountTypeChange}
              >
                <FormControlLabel
                  label="Amount Due"
                  value="amountRemaining"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Minimum Deposit Due"
                  value="amountDue"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Custom Amount"
                  value="custom"
                  control={<Radio />}
                />
              </RadioGroup>
              <strong>
                <CurrencyLabel value={this.getPaymentAmount("amountRemaining")} />
              </strong>
              <strong>
                <CurrencyLabel value={this.getPaymentAmount("amountDue")} />
              </strong>
              <CurrencyInput
                name="customAmount"
                type="text"
                placeholder="0.00"
                className={classnames({
                  med: true,
                  error: errors.amount
                })}
                value={customAmount}
                onChange={this.handleChange}
              />
            </div>
            <div className="fields">
              <label>Method of Payment</label>
              <RadioGroup
                name="paymentSelection"
                className="radioList"
                onChange={this.handleChange}
                value={paymentSelection}
              >
                <FormControlLabel
                  value="new_credit_card"
                  control={<Radio />}
                  label="Credit Card"
                />

                {(this.isPropayInUSA(rental, location) || this.isWorldpayOrLaunchpayWithAchqData(rental, location) || this.isOtherPaymentProcessor(rental)) &&
                  (
                    <FormControlLabel
                      value="new_bank"
                      control={<Radio />}
                      label="Bank Account"
                    />
                  )}
              </RadioGroup>
            </div>
            <div className="fields">
              {paymentSelection === "new_credit_card" && (
                customerType === "Company"
                  ? <CreditCardFormForCompany
                    onSubmit={this.handleSubmitNewCreditCard}
                    initialBillingAddress={null}
                    type="checkout"
                    paymentProcessor={rental.paymentProcessor}
                    withPaymentType={["propay", "worldpay", "launchpay"].includes(
                      rental.paymentProcessor
                    )}
                    hideCopy />
                  : <CreditCardForm
                    onSubmit={this.handleSubmitNewCreditCard}
                    initialBillingAddress={null}
                    type="checkout"
                    paymentProcessor={rental.paymentProcessor}
                    withPaymentType={["propay", "worldpay", "launchpay"].includes(
                      rental.paymentProcessor
                    )}
                    hideCopy
                  />
              )}

              {paymentSelection === "new_bank" &&
                rental.paymentProcessor === "stripe" && (
                  <PlaidLink
                    publicKey="0809d4ccb43aa8a75b132e17f3da03"
                    product="auth"
                    env={process.env.REACT_APP_PLAID_ENV}
                    clientName={rental.locationName}
                    onSuccess={this.handleSubmitNewBank}
                    selectAccount={true}
                    className="btn full"
                    buttonText="Pay with Bank Account"
                  >
                    Pay with Bank Account
                  </PlaidLink>
                )}
              {paymentSelection === "new_bank" &&
                rental.paymentProcessor === "propay" && (
                  <PropayBankForm
                    onSubmit={this.handleSubmitNewPropayBank}
                    type="checkout"
                  />
                )}

              {paymentSelection === "new_bank" &&
                (rental.paymentProcessor === "worldpay" || rental.paymentProcessor === "launchpay") && (
                  <WorldpayBankForm
                    onSubmit={this.handleSubmitNewPropayBank}
                    type="checkout"
                  />
                )}
            </div>
            {paymentSelection === "new_credit_card" &&
              this.renderCreditCardFee()}
          </div>
        </ScrollArea>

        <Modal
          open={this.state.successOpen}
          toggle={this.handleSuccessClose}
          title="Payment Confirmation"
          actions={[
            <a className="btn full" onClick={this.handleSuccessClose}>
              Ok
            </a>
          ]}
        >
          <p>Submitted payment was received</p>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const location = ownProps.location
    ? ownProps.location
    : state.locations.location;
  const customer = ownProps.customer
    ? ownProps.customer
    : state.customer.customer;
  const customerType = ownProps.customerType
    ? ownProps.customerType
    : state.guestCheckout.customerType;

  const creditCardPercent = location.creditCardPercent;

  return { creditCardPercent, location, customer, customerType };
};

export default connect(mapStateToProps, actions)(ConnectPaymentBox);
