import { history as browserHistory } from 'Components/Routes';
import { isSsr } from 'HelperFunctions/ssr';

export let ROOT_URL;

if (isSsr()) {
  ROOT_URL = process.env.REACT_APP_SSR_API_DOMAIN + '/api';
} else {
  ROOT_URL = process.env.REACT_APP_API_DOMAIN + '/api';
}

export const isValidToken = () => {
  const currentToken = !isSsr()
    ? JSON.parse(localStorage.getItem('authTokenHeaders'))
    : null;
  if (currentToken) {
    const expDate = new Date(currentToken.expiry * 1000);
    const now = new Date();
    if (expDate > now) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const clearAuthToken = () => {
  if (!isSsr()) localStorage.removeItem('authTokenHeaders');
};
export const clearAdminAuthToken = () => {
  if (!isSsr()) localStorage.removeItem('adminAuthTokenHeaders');
};
export const getAuthToken = () => {
  if (isValidToken()) {
    const currentToken = !isSsr()
      ? JSON.parse(localStorage.getItem('authTokenHeaders'))
      : null;
    return currentToken;
  } else {
    browserHistory.push('/');
  }
};

export const authToken = () => {
  const currentToken = !isSsr()
    ? JSON.parse(localStorage.getItem('authTokenHeaders'))
    : null;
  return currentToken;
};
export const adminAuthToken = () => {
  const currentToken = !isSsr()
    ? JSON.parse(localStorage.getItem('adminAuthTokenHeaders'))
    : null;
  return currentToken;
};

export const saveAuthToken = (headers) => {
  if (headers['access-token']) {
    const { expiry, uid, client } = headers;
    const authTokenObj = {
      uid,
      expiry,
      client: client,
      'token-type': 'Bearer',
      'access-token': headers['access-token'],
      'Content-Type': 'application/json',
    };
    if (!isSsr())
      localStorage.setItem('authTokenHeaders', JSON.stringify(authTokenObj));
  }
};

export const isValidAdminToken = () => {
  const currentToken = !isSsr()
    ? JSON.parse(localStorage.getItem('adminAuthTokenHeaders'))
    : null;
  if (currentToken) {
    const expDate = new Date(currentToken.expiry * 1000);
    const now = new Date();
    if (expDate > now) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const getAdminAuthToken = () => {
  if (isValidToken) {
    const currentToken = !isSsr()
      ? JSON.parse(localStorage.getItem('adminAuthTokenHeaders'))
      : null;
    return currentToken;
  } else {
    browserHistory.push('/login');
  }
};

export const saveAdminAuthToken = (headers) => {
  if (headers['access-token']) {
    const { expiry, uid, client } = headers;
    const authTokenObj = {
      uid,
      expiry,
      client: client,
      'token-type': 'Bearer',
      'access-token': headers['access-token'],
      'Content-Type': 'application/json',
    };
    if (!isSsr())
      localStorage.setItem(
        'adminAuthTokenHeaders',
        JSON.stringify(authTokenObj)
      );
  }
};

export const saveError = (error, state = {}) => {
  console.error(error);
};
