import React from "react";
import { FormattedDate, FormattedTime } from "react-intl";
import moment from "moment";
import CurrencyLabel from "Utils/CurrencyLabel";

class RentalStaff extends React.Component {
  render() {
    const {
      item,
      showItemImages,
      showUnitPricingToCustomers
    } = this.props;
    const timeZone = moment.tz.guess(item.staffSchedule.inTime);
    return (
      <tbody>
        <tr className="bottom">
          <td colSpan={showItemImages ? "2" : "1"}>{item.name}</td>
          <td className="right">{item.size}</td>
          {showUnitPricingToCustomers && (
            <td className="right">
              <CurrencyLabel value={item.cost / item.size} />
              /Each
            </td>
          )}
          <td className="right">
            <CurrencyLabel value={item.cost} />
          </td>
        </tr>
        <tr className="top noBorder">
          <td
            colSpan={
              showItemImages
                ? showUnitPricingToCustomers
                  ? "5"
                  : "4"
                : showUnitPricingToCustomers
                ? "4"
                : "3"
            }
          >
            <label className="highlight">Time In</label>{" "}
            <FormattedDate
              value={item.staffSchedule.inTime}
              month="numeric"
              year="numeric"
              day="numeric"
            />{" "}
            <FormattedTime
              value={item.staffSchedule.inTime}
              timeZone={timeZone}
              timeZoneName="short"
            />{" "}
            <label className="highlight">Time Out</label>{" "}
            <FormattedDate
              value={item.staffSchedule.outTime}
              month="numeric"
              year="numeric"
              day="numeric"
            />{" "}
            <FormattedTime
              value={item.staffSchedule.outTime}
              timeZone={timeZone}
              timeZoneName="short"
            />
          </td>
        </tr>
      </tbody>
    );
  }
}

export default RentalStaff;
