import React from "react";
import ChangeRequestModal from "./ChangeRequestModal";
import { FormattedDate } from "react-intl";

class ChangeRequestDetails extends React.Component {
  render(){
    const { changeRequests } = this.props;
    const requests = changeRequests && changeRequests.filter( x => x.status !== 'pending' ) || [];

    return(
      <div>
        {requests.map(request => {
          return (
            <div className="changeRequestDetails" key={request.id}>
              <h6>
                <span className="adjusted-by">
                  {request.adjustedBy}
                </span>
                <FormattedDate
                  value={request.adjustedAt}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              </h6>
              
              <ChangeRequestModal key={request.id} request={request} />
            </div>
          )
        })}
      </div>
    )
  }
}

export default ChangeRequestDetails;
