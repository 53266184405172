/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import RegionDropdown from "Utils/RegionDropdown";
import { connect } from "react-redux";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import { DeliveryTypesLabels } from "Constants/displayConstants";
import CurrencyLabel from "Utils/CurrencyLabel";

class EventForm extends React.Component {
  handleChange = event => {
    const { setEventProperties, calculateDefaultDeliveryPrice } = this.props;
    const { name, value } = event.target;
    const newEvent = Object.assign(
      { [name]: value },
      name === "deliveryAddressCountry" && { deliveryAddressLocale: "" }
    );
    if(this.props.event && newEvent){
      const newEventObj = { ...this.props.event, ...newEvent };
      const {
        deliveryAddressCountry, 
        deliveryAddressStreetAddress1, 
        deliveryAddressStreetAddress2, 
        deliveryAddressCity, 
        deliveryAddressPostalCode,
        deliveryAddressLocale
      } = newEventObj;
      deliveryAddressCountry && 
      deliveryAddressStreetAddress1 && 
      deliveryAddressStreetAddress2 && 
      deliveryAddressCity && 
      deliveryAddressPostalCode &&
      deliveryAddressLocale &&
      calculateDefaultDeliveryPrice({
        event: newEventObj,
        onSuccess: deliveryCost => {
          setEventProperties({
            deliveryCost: deliveryCost
          });
        }
      });
    }
    setEventProperties(newEvent);
  };

  handleDeliveryTypeChange = e => {
    const { name, value } = e.target;
    let newEventProps = {
      [name]: value
    };
    if (value === "customer_pick_up") {
      newEventProps["deliveryCost"] = 0;
    }
    this.props.setEventProperties(newEventProps);
  };

  handleCalculateDeliveryPrice = () => {
    const {
      event,
      setEventProperties,
      calculateDefaultDeliveryPrice
    } = this.props;
    calculateDefaultDeliveryPrice({
      event,
      onSuccess: deliveryCost => {
        setEventProperties({
          deliveryCost: deliveryCost
        });
      }
    });
  };

  render() {
    const { event, storefrontShopSetting } = this.props;
    const {
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry
    } = event;
    const { deliveryMethods } = storefrontShopSetting;
    const showAddress = event.deliveryType === "default_delivery" || event.deliveryType === "ship";

    return (
      <section className="eventForm">
        <h4>Delivery</h4>
        <div className="form">
          <div className="fields">
            <label>Delivery/Pick Up</label>
            <StandardSelectField
              meta={{}}
              input={{
                name: "deliveryType",
                value: (event.deliveryType ? event.deliveryType : ""),
                onChange: this.handleDeliveryTypeChange
              }}
            >
              <option>Select Delivery Type</option>
              {deliveryMethods.split(",").map(delivery_method => {
                if (delivery_method === "custom_delivery") {
                  if (event.deliveryType === "custom_delivery") {
                    return (
                      <option value={delivery_method}>
                        {DeliveryTypesLabels[delivery_method]}
                      </option>
                    );
                  } else {
                    return null;
                  }
                } else {
                  return (
                    <option value={delivery_method}>
                      {DeliveryTypesLabels[delivery_method]}
                    </option>
                  );
                }
              })}
            </StandardSelectField>
          </div>
          {showAddress && (
            <div className="fields">
              <StandardSelectField
                meta={{}}
                input={{
                  name: "deliveryAddressCountry",
                  value: deliveryAddressCountry,
                  onChange: this.handleChange
                }}
              >
                <option value="">Select country</option>
                <option value="USA">United States</option>
                <option value="CAN">Canada</option>
              </StandardSelectField>
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                placeholder="Location Name"
                input={{
                  name: "deliveryAddressLocationName",
                  value: deliveryAddressLocationName,
                  onChange: this.handleChange
                }}
              />
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                className="lg"
                placeholder="Street Address*"
                input={{
                  name: "deliveryAddressStreetAddress1",
                  value: deliveryAddressStreetAddress1,
                  onChange: this.handleChange
                }}
              />
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                className="sm"
                placeholder="Apt No."
                input={{
                  name: "deliveryAddressStreetAddress2",
                  value: deliveryAddressStreetAddress2,
                  onChange: this.handleChange
                }}
              />
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                className="med"
                placeholder="City*"
                input={{
                  name: "deliveryAddressCity",
                  value: deliveryAddressCity,
                  onChange: this.handleChange
                }}
              />
              <RegionDropdown
                name="deliveryAddressLocale"
                defaultOptionLabel={
                  deliveryAddressCountry === "USA" ? "State" : "Province"
                }
                className="sm"
                disableWhenEmpty={true}
                countryValueType="short"
                labelType="short"
                valueType="short"
                country={deliveryAddressCountry}
                value={deliveryAddressLocale}
                onChange={this.handleChange}
              />
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                className="sm"
                placeholder="Zip*"
                input={{
                  name: "deliveryAddressPostalCode",
                  value: deliveryAddressPostalCode,
                  onChange: this.handleChange
                }}
              />
            </div>
          )}
          {event.deliveryType === "default_delivery" &&
            storefrontShopSetting.shopShowItemPricing && (
              <div className="actions">
                <a className="btn" onClick={this.handleCalculateDeliveryPrice}>
                  Calculate Delivery
                </a>
                <CurrencyLabel value={event.deliveryCost} />
              </div>
            )}
          {showAddress && (
            <div>
              <div className="fields">
                <label>Preferred Delivery Window</label>
                <p>
                  Setting this preference doesn't guarantee delivery in this
                  window, it just gives us a heads up to schedule our trucks.
                </p>
                <StandardSelectField
                  meta={{}}
                  input={{
                    name: "preferredDeliveryWindow",
                    value: event.preferredDeliveryWindow,
                    onChange: this.handleChange
                  }}
                >
                  <option value="early">Early (7a-10a)</option>
                  <option value="midday">Midday (10a-2p)</option>
                  <option value="late">Late (2p-6p)</option>
                </StandardSelectField>
              </div>
              <div className="fields">
                <label>Preferred Pick Up Window</label>
                <p>
                  Setting this preference doesn't guarantee delivery in this
                  window, it just gives us a heads up to schedule our trucks.
                </p>
                <StandardSelectField
                  meta={{}}
                  input={{
                    name: "preferredPickupWindow",
                    value: event.preferredPickupWindow,
                    onChange: this.handleChange
                  }}
                >
                  <option value="early">Early (7a-10a)</option>
                  <option value="midday">Midday (10a-2p)</option>
                  <option value="late">Late (2p-6p)</option>
                </StandardSelectField>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    parsedStorefrontSettings: { storefrontShopSetting }
  } = state.locations;
  return { storefrontShopSetting };
};

export default connect(mapStateToProps)(EventForm);
