import axios from "axios";
import {
  FETCH_RENTAL_REQUEST,
  FETCH_RENTAL_SUCCESS,
  FETCH_RENTAL_FAILURE,
  UPDATE_RENTAL_SUCCESS,
  UPDATE_RENTAL_REQUEST,
  UPDATE_RENTAL_FAILURE
} from "Constants/redux";

import { saveAuthToken, getAuthToken, saveError } from "Api";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

import { objectToFormData } from "HelperFunctions/formHelpers";
import { setErrors } from "./SnackbarActions";

// fetchRental is called to grab the full rental from backend,
// and call the appropriate redux action to save it correctly or error out.

export const fetchRental = id => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Loading rental..."));
    dispatch({ type: FETCH_RENTAL_REQUEST });
    return axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/portal/rentals/` + id, {
        headers: getAuthToken()
      })
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        const rental = response.data.rental;
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          payload: { rental }
        });
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: FETCH_RENTAL_FAILURE
        });
        saveError(error, getState());
      });
  };
};

export const fetchRentalIfNeeded = id => {
  return (dispatch, getState) => {
    const { loading } = getState().rental;
    if (!loading) {
      return dispatch(fetchRental(id));
    }
  };
};

export const updateRental = ({ id, data, onSuccess, onFailure }) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_RENTAL_REQUEST });
    axios
      .patch(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${id}`,
        data,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          payload: response.data.rental
        });
        onSuccess && onSuccess(response);
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          payload: error
        });
        onFailure && onFailure(error);
      });
  };
};

export const submitTransaction = (
  id,
  data,
  onSuccess = null,
  signedIn = false
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Submitting a new transaction..."));
    let params = {};
    if (signedIn) {
      params.headers = getAuthToken();
    }
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${id}/transactions`,
        data,
        params
      )
      .then(response => {
        if (signedIn) {
          saveAuthToken(response.headers);
        }

        dispatch(closeLoadingSpinner());
        onSuccess && onSuccess(response);
      })
      .catch(error => {
        if (error.response) {
          dispatch(setErrors(error.response.data));
        } else {
          console.log(error);
          dispatch(
            setErrors(
              "There appears to be a problem with your connection. Please try again later."
            )
          );
          saveError(error, getState());
        }

        dispatch(closeLoadingSpinner());
      });
  };
};

export const uploadNewRentalDocument = ({
  id,
  values,
  onSuccess,
  onFailure
}) => {
  return (dispatch, getState) => {
    const file = {
      name: values.name,
      document_type: values.documentType,
      file: values.file[0]
    };
    const fileData = {
      rental: {
        document_rental_relationships_attributes: {
          "0": {
            document_attributes: file
          }
        }
      }
    };
    const rentalData = objectToFormData(fileData);
    dispatch(openLoadingSpinner("Submitting document..."));
    dispatch(updateRental({ id, data: rentalData, onSuccess, onFailure }));
  };
};

export const uploadExistingRentalDocument = ({
  id,
  values,
  onSuccess,
  onFailure
}) => {
  return (dispatch, getState) => {
    const fileData = {
      rental: {
        document_rental_relationships_attributes: {
          "0": {
            document_id: values.documentId
          }
        }
      }
    };
    const rentalData = objectToFormData(fileData);
    dispatch(openLoadingSpinner("Submitting document..."));
    dispatch(updateRental({ id, data: rentalData, onSuccess, onFailure }));
  };
};

export const deleteRentalDocument = ({ rentalId, id }) => {
  return (dispatch, getState) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${rentalId}/document_rental_relationships/${id}`,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch({
          type: UPDATE_RENTAL_SUCCESS,
          payload: response.data.rental
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_RENTAL_FAILURE,
          payload: error
        });
      });
  };
};
