import axios from 'axios';

import converter from 'json-style-converter/es5';

import {
  FETCH_INVENTORY_AVAILABILITY_REQUEST,
  FETCH_INVENTORY_AVAILABILITY_SUCCESS,
  FETCH_INVENTORY_AVAILABILITY_FAILURE,
  SET_AVAILABILITY_LOADING_INVENTORY,
} from 'Constants/redux';

import { ROOT_URL } from 'Api';

export const fetchInventoryAvailability =
  ({ startDate, endDate, products = [], bundles = [], addOns = [] }) =>
  (dispatch, getState) => {
    const inventory = {
      products,
      addOns,
      bundles,
    };

    const {
      locations: { location },
    } = getState();

    dispatch(setLoadingInventory(inventory));

    const requestPayload = {
      startDate,
      endDate,
      ...inventory,
    };

    dispatch({
      type: FETCH_INVENTORY_AVAILABILITY_REQUEST,
      requestPayload,
    });

    return axios
      .post(
        `${ROOT_URL}/portal/inventories/available_in_period?location_id=${location.id}`,
        converter.camelToSnakeCase(requestPayload)
      )
      .then((res) =>
        dispatch(fetchInventoryAvailabilitySuccess(res.data.availability))
      )
      .catch((err) => dispatch(fetchInventoryAvailabilityFailure(err)));
  };

export const fetchInventoryAvailabilitySuccess = (payload) => (dispatch) =>
  dispatch({
    type: FETCH_INVENTORY_AVAILABILITY_SUCCESS,
    payload,
  });

export const fetchInventoryAvailabilityFailure = (payload) => (dispatch) =>
  dispatch({ type: FETCH_INVENTORY_AVAILABILITY_FAILURE, payload });

export const setLoadingInventory = (payload) => (dispatch) =>
  dispatch({
    type: SET_AVAILABILITY_LOADING_INVENTORY,
    payload,
  });
