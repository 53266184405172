import { LOCATION_CHANGE } from 'react-router-redux';
import {
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_PICTURE_REQUEST,
  FETCH_LOCATION_PICTURE_SUCCESS,
  FETCH_LOCATION_PICTURE_FAILURE,
  SET_SELECTED_LOCATION,
} from 'Constants/redux';
import { parseStorefrontSettings } from 'HelperFunctions/storefront';

const INITIAL_STATE = {
  locations: [],
  parsedStorefrontSettings: {
    storefrontAboutusSettings: {},
    storefrontContactSettings: {},
    mainSetting: {},
  },
  location: null,
  selectedLocation: null,
  errors: {},
  picture: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload.selectedLocation,
      };
    case FETCH_LOCATIONS_REQUEST:
    case FETCH_LOCATION_REQUEST:
    case FETCH_LOCATION_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOCATION_FAILURE:
    case FETCH_LOCATION_PICTURE_FAILURE:
    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_LOCATIONS_SUCCESS:
      const { locations } = action.response.data;
      return {
        ...state,
        locations: locations,
        selectedLocation: locations.length > 0 ? locations[0] : null,
        loading: false,
      };
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location,
        parsedStorefrontSettings: parseStorefrontSettings(action.location),
        preview: action.preview,
        loading: false,
      };
    case FETCH_LOCATION_PICTURE_SUCCESS:
      const picture = action.response.data
        ? action.response.data.picture
        : null;
      return {
        ...state,
        picture: picture,
        loading: false,
      };
    case LOCATION_CHANGE:
      return { ...state, errors: INITIAL_STATE.errors };
    default:
      return state;
  }
};
