import React from "react";
import axios from "axios";
import { ROOT_URL } from "Api";
import LoadingSpinner from "Utils/LoadingSpinner";
import Swiper from "react-id-swiper";
import { isMobile } from "HelperFunctions/isMobile";

class InstagramCarousel extends React.Component {
  state = {
    loading: true,
    instagramImages: [],
    accountInfo: null,
  };

  componentDidMount() {
    this.fetchInstagramImages();
  }

  fetchInstagramImages = () => {
    axios
      .get(
        `${ROOT_URL}/portal/locations/${
          this.props.location.id
        }/instagram_images`
      )
      .then(response => {
        this.setState({
          accountInfo: response.data.account_info,
          instagramImages: response.data.images,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        console.log(error);
      });
  };
  render() {
    const { loading, accountInfo, instagramImages } = this.state;
    const swiperParams = {
      slidesPerView: isMobile() ? 1 : 4,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    };
    if (loading) {
      return <LoadingSpinner />;
    } else {
      if (accountInfo && instagramImages.length > 0) {
        return (
          <div className="instagram instagramCarousel">
            <h4>View Our Feed</h4>
            <a
              href={`https://www.instagram.com/${accountInfo.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`@${accountInfo.username}`}
            </a>
            <Swiper {...swiperParams} >
              {instagramImages.map((image, index) => (
                <figure key={index}>
                  <img 
                    src={image["media_url"]}
                    alt=""
                  />
                </figure>
              ))}
            </Swiper>
          </div>
        );
      } else {
        return null
      }
    }
  }
}
export default InstagramCarousel;
