import React from "react";
import HeroImage from "Components/home/widgets/HeroImage";
import { Link } from "react-router-dom";
import SignInForm from "Components/home/SignInForm";
import * as actions from "Actions";
import { connect } from "react-redux";
import Imgix from 'react-imgix';
import { getSettingByUrl } from 'HelperFunctions/StorefrontPage';

class SignIn extends React.Component {
  render() {
    const { errors, loginUser } = this.props;
    const { storefrontSetting } = this.props;
    const {
      pageTitle,
      pageBody,
      signinPicture,
    } = storefrontSetting;
    const defaultBody = 'Track rentals, make payments, request changes. You name it!'
    return (
      <section className="signIn">
        {signinPicture ?
          <figure>
            <Imgix
              src={signinPicture.imgixUrl}
              alt={pageTitle}
              width={510}
              height={288}
            />
          </figure> :
          <HeroImage />}
        <div className="details">
          <h1>{pageTitle || 'Sign In'}</h1>
          <p>{pageBody || defaultBody}</p>
        </div>
        <div className="signInForm">
          <SignInForm errors={errors} onSubmit={loginUser} />
          <Link to="/forgotPassword">Forgot Password</Link>
          <Link to="/signUp">Sign Up for FREE!</Link>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parsedStorefrontSettings } = state.locations;
  const { location } = state.locations;
  const { errors, loading } = state.auth;
  // const storefrontSetting = parsedStorefrontSettings.mainSetting;
  const storefrontSetting = getSettingByUrl(
    location.storefrontPages,
    ownProps.location.pathname
  );
  return { errors, loading, storefrontSetting: parsedStorefrontSettings.storefrontSigninSetting };
};

export default connect(mapStateToProps, actions)(SignIn);
