import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedDate } from "react-intl";
import * as actions from "Actions";
import RentalDelivery from "Components/events/RentalDelivery";
import RentalBilling from "Components/events/RentalBilling";
import RentalItemList from "Components/events/RentalItemList";
import DetailedAmountPaid from "Components/events/DetailedAmountPaid";
import DetailedAmountRefunded from "Components/events/DetailedAmountRefunded";
import Modal from "Utils/Modal";
import { Document } from "Utils/SvgIcons";
import DisclaimerSignatureModal from "Utils/DisclaimerSignatureModal";
import ConnectPaymentBox from "./ConnectPaymentBox";
import CurrencyLabel from "Utils/CurrencyLabel";
import { getDisclaimerSignatureInfo } from "HelperFunctions/disclaimers";

class Rental extends Component {
  handleAddDocument = (values) => {
    const newDocument = {
      document_attributes: {
        name: values.name,
        document_type: values.documentType,
        file: values.file[0]
      },
      _destroy: "0"
    };

    this.props.addDocument(newDocument);
  };

  renderActionButton = (rental) => {
    const { disclaimerSignaturePresent, disclaimerSignatureRequired } =
      getDisclaimerSignatureInfo(rental);

    if (rental.cancelRequested) {
      return (
        <button className="full disabled" disabled>
          Cancellation Pending
        </button>
      );
    }

    switch (rental.approvalStatus) {
      case "draft":
        return (
          <button
            className="full"
            onClick={() => this.handleSubmitQuote(rental)}
          >
            Submit
          </button>
        );
      case "pending":
        return (
          <button className="full disabled" disabled>
            Pending
          </button>
        );
      case "approved":
        if (
          rental.changeRequest !== null &&
          rental.changeRequest.status === "pending"
        ) {
          return (
            <button className="full disabled" disabled>
              Changes Requested
            </button>
          );
        }

        if (rental.isAllowedToMakePayment) {
          if (
            (rental.signatureRequired && !rental.hasSignature) ||
            (disclaimerSignatureRequired && !disclaimerSignaturePresent)
          ) {
            return (
              <button className="btn full" onClick={this.props.toggleSignModal}>
                Signature Required
              </button>
            );
          }
          if (Number(rental.amountRemaining) > "0.0")
            return (
              <button className="full" onClick={this.props.togglePayment}>
                Make a Payment
              </button>
            );
        }

        return null;
      default:
        return null;
    }
  };

  render() {
    const {
      fields: {
        errors,
        checkout,
        checkoutWindowEnd,
        checkout_time,
        checkin,
        checkinWindowStart,
        checkin_time,
        sameAsEventDates,
        event_start_date_time,
        event_end,
        delivery_type,
        delivery_address_location_name,
        delivery_address_street_address_1,
        delivery_address_street_address_2,
        delivery_address_city,
        delivery_address_locale,
        delivery_address_postal_code,
        delivery_address_country,
        paymentOpen,
        signModalOpen
      },
      rental,
      location,
      customer,
      onDeliveryChangeSubmit,
      onSubmitSignature,
      togglePayment,
      toggleSignModal,
      onPaymentSuccess
    } = this.props;

    const {
      hideDeliveryInfo,
      hideDeliveryTimes
    } = rental.rentalSetting

    const delivery = {
      delivery_type,
      delivery_address_location_name,
      delivery_address_street_address_1,
      delivery_address_street_address_2,
      delivery_address_city,
      delivery_address_locale,
      delivery_address_postal_code,
      delivery_address_country
    };

    const billing = {
      rental_item_total: rental.rentalItemTotal,
      rental_add_on_total: rental.rentalAddOnTotal,
      rental_bundle_total: rental.rentalBundleTotal,
      delivery_cost: rental.deliveryCost,
      discount_total: rental.discountTotal,
      expenseTotal: rental.expenseTotal,
      fee_total: rental.feeTotal,
      sub_total: rental.subTotal,
      subTotalForTax: rental.subTotalForTax,
      tax_total: rental.taxTotal,
      total_custom_tax: rental.totalCustomTax,
      overall_total: rental.overallTotal,
      tapgoods_fee_total: rental.tapgoodsFeeTotal,
      tapgoodsTaxTotal: rental.tapgoodsTaxTotal,
      amount_paid: rental.totalPaid,
      amount_remaining: rental.amountRemaining,
      processingFeeRefunded: rental.processingFeeRefunded,
      tapgoodsFeeRefunded: rental.tapgoodsFeeRefunded,
      tapgoodsTaxRefunded: rental.tapgoodsTaxRefunded
    };

    let venue = null;
    if (rental.venueRentalRelationship) {
      venue = rental.venueRentalRelationship.venue;
    }

    const customerNotes = rental.notes.filter(
      (notes) => notes.visibleToCustomer
    );

    const { disclaimerSignaturePresent, disclaimerSignatureRequired } =
      getDisclaimerSignatureInfo(rental);

    return (
      <div>
        <RentalItemList rental={rental} />

        <section className="summary">
          <div className="billingDetails">
            <div>
              <label>Amount</label>
              <CurrencyLabel value={rental.overallTotal} />
            </div>
            {rental.depositComplete ? (
              <div>
                <label>Amount Remaining</label>
                <CurrencyLabel value={rental.amountRemaining} />
              </div>
            ) : (
              <div>
                <label>Deposit Due</label>
                <CurrencyLabel value={rental.minimumDeposit} />
              </div>
            )}
            <div>
              <label>
                {delivery.delivery_type === "customer_pick_up"
                  ? "Pick Up/Drop Off"
                  : "Delivery"}
              </label>
              {rental.rentalSetting.hideDeliveryTimes ? (
                <span>TBD</span>
              ) : (
                <span>
                  <FormattedDate
                    value={rental.startDate}
                    month="numeric"
                    day="numeric"
                  />
                  {" - "}
                  <FormattedDate
                    value={rental.endDate}
                    month="numeric"
                    year="numeric"
                    day="numeric"
                  />
                </span>
              )}
            </div>
          </div>

          <div className="actions">{this.renderActionButton(rental)}</div>
        </section>

        <RentalDelivery
          rental={rental}
          delivery={delivery}
          schedule={rental.schedule}
          hideDeliveryInfo={hideDeliveryInfo}
          hideDeliveryTimes={hideDeliveryTimes}
          errors={errors}
          sameAsEventDates={sameAsEventDates}
          checkout={checkout}
          checkoutWindowEnd={checkoutWindowEnd}
          checkout_time={checkout_time}
          checkin={checkin}
          checkinWindowStart={checkinWindowStart}
          checkin_time={checkin_time}
          eventStartDateTime={event_start_date_time}
          eventEndDateTime={event_end}
          onSubmit={onDeliveryChangeSubmit}
        />

        {venue && (
          <section className="venue">
            <h4>Venue</h4>
            <div>{venue.name}</div>
            <div>{venue.streetAddress1}</div>
            {venue.streetAddress2 && <div># {venue.streetAddress2}</div>}
            <div>
              {venue.city}, {venue.locale} {venue.postalCode}, {venue.country}
            </div>
          </section>
        )}

        {rental.documents.length > 0 && (
          <section className="documents">
            <h4>Documents</h4>
            {rental.documents.map((document) => (
              <a
                key={document.id}
                href={document.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Document />
                {document.name}
              </a>
            ))}
          </section>
        )}

        {customerNotes.length > 0 && (
          <section className="notes">
            <h4>Notes</h4>
            {customerNotes.map((note) => (
              <div key={note.id}>
                <FormattedDate
                  value={note.createdAt}
                  month="numeric"
                  year="numeric"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />
                <p>
                  <div dangerouslySetInnerHTML={{
                    __html: note.message
                  }}
                  />
                </p>
              </div>
            ))}
          </section>
        )}

        <RentalBilling
          rentalId={rental.token}
          rental={rental}
          billing={billing}
          secondary={true}
          location={location}
        />

        <section className="payments">
          <h4>Payments</h4>
          <div className="billingDetails">
            {rental.payments &&
              rental.payments.length > 0 &&
              rental.payments.map((payment) => (
                <DetailedAmountPaid
                  key={payment.id}
                  payment={payment}
                  ownerView={false}
                />
              ))}
            {rental.refunds &&
              rental.refunds.length > 0 &&
              rental.refunds.map((refund) => (
                <DetailedAmountRefunded
                  key={refund.id}
                  refund={refund}
                  ownerView={false}
                />
              ))}
            <div className="subtotal">
              <label>Amount Remaining</label>
              <CurrencyLabel value={billing.amount_remaining} />
            </div>
          </div>

          {rental.isAllowedToMakePayment &&
            (!rental.signatureRequired || rental.hasSignature) &&
            (!disclaimerSignatureRequired || disclaimerSignaturePresent) &&
            Number(rental.amountRemaining) > "0.0" && (
              <div className="actions">
                <button className="full" onClick={togglePayment}>
                  Make a Payment
                </button>
              </div>
            )}

          <Modal
            title="Make a Payment"
            className="large paymentModal"
            toggle={togglePayment}
            open={paymentOpen}
          >
            <ConnectPaymentBox
              onPaymentSuccess={onPaymentSuccess}
              rentalId={rental.token}
              rental={rental}
              location={location}
              customer={customer}
            />
          </Modal>

          <DisclaimerSignatureModal
            open={signModalOpen}
            toggle={toggleSignModal}
            rental={rental}
            disclaimerRentalRelationships={rental.disclaimerRentalRelationships}
            onSubmitSignature={onSubmitSignature}
          />
        </section>
      </div>
    );
  }
}

export default connect(null, actions)(Rental);
